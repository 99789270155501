import styled from 'styled-components';

import { body4 } from '../common/typography/Body';

export const TextField = styled.input`
  ${body4}
  border-radius: 9.5px;
  border: 1px solid var(--grey300);
  background-color: var(--input-background);
  color: var(--grey800);
  padding: 10px 12px;
  ::placeholder {
    color: var(--grey500);
  }
  :hover,
  :focus {
    border-color: var(--primary500);
  }
  :focus {
    box-shadow: 0px 0px 0px 4px rgba(29, 171, 242, 0.2);
    ::placeholder {
      color: var(--grey300);
    }
  }

  &[aria-invalid='true'] {
    border-color: var(--error300);

    :focus,
    :hover {
      border-color: var(--error500);
    }

    :focus {
      box-shadow: 0px 0px 0px 4px rgba(229, 52, 81, 0.08);
    }
  }

  &[disabled] {
    background: var(--grey100);
    :hover {
      border-color: var(--grey300);
    }
  }
`;
