import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { int } from '../../lib/convert';
import { AppScreen } from '../common/AppScreen';
import { Btn } from '../common/Btn';
import { LoadingCircle } from '../common/LoadingCircle';
import { MessageBox } from '../common/MessageBox';
import { Body4 } from '../common/typography/Body';
import { H3 } from '../common/typography/Headings';
import { Center } from '../layout/Center';
import { Stack } from '../layout/Stack';
import { useApi } from '../UserState';
import SuccessPosting from './SuccessOfferPosting.png';

const Box = styled.div`
  max-width: 400px;
`;

const ToolTipCon = styled.div`
  margin: auto;
`;

const NoMatchLayout = styled.div`
  height: 100%;
  padding: 24px 40px;
  background: #ffffff;
  border-radius: 20px;
`;

export const SharedOfferSuccessed = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const api = useApi();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function shareWithAddressbook() {
    setLoading(true);
    if (type === 'capacity') {
      const { error } = await api.informContactsAboutCapacity([int(id)]);
      setSuccess(!error);
      setError(error);
    } else {
      const { error } = await api.informContactsAboutCargo([int(id)]);
      setSuccess(!error);
      setError(error);
    }
    setLoading(false);
  }

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <AppScreen title="Matching">
      <NoMatchLayout>
        {error && <MessageBox type="error">{error}</MessageBox>}
        {success && (
          <MessageBox type="success">Erfolgreich gesendet</MessageBox>
        )}
        <Center direction="column">
          <img src={SuccessPosting} width="400px" />
          <Stack gap="16px">
            <H3>Erfolgreich gepostet!</H3>
            <Box>
              <Body4 color="var(--grey500)">
                Dein Angebot wurde erfolgreich gepostet. Schau später auf
                Cargofaces oder in deinem E-Mail Postfach nach, ob neue Matches
                generiert wurden. Um deine Reichweite zu erhöhen, kannst du nun
                zusätzlich dein Angebot per E-Mail an dein Adressbuch versenden.
              </Body4>
            </Box>
            {!success && (
              <ToolTipCon data-tip="Bald Verfügbar !" data-for="main">
                <Btn
                  color="primary"
                  onClick={() => shareWithAddressbook()}
                  disabled={true}
                >
                  An mein Adressbuch senden
                </Btn>
                <ReactTooltip id="main" backgroundColor="var(--primary500)" />
              </ToolTipCon>
            )}

            <Btn appearance="transparent" onClick={() => navigate('/offer')}>
              Weiteres Angebot hinzufügen
            </Btn>
          </Stack>
          <Btn appearance="transparent" onClick={() => navigate('/dashboard')}>
            Zum Dashboard
          </Btn>
        </Center>
      </NoMatchLayout>
    </AppScreen>
  );
};
