import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Body } from '../common/typography';

const Root = styled.div`
  border: 1px solid var(--neutral300);
  border-radius: 9.5px;
  padding: 7px;
`;

const Input = styled.input`
  ::-webkit-file-upload-button {
    ${Body.body6}
    color: var(--tertiary700);
    background: var(--white);
    border: 1px solid var(--neutral300);
    border-radius: 3px;
  }
  :disabled {
    color: var(--neutral400);
    ::-webkit-file-upload-button {
      color: var(--neutral400);
    }
  }
`;

type Props = JSX.IntrinsicElements['input'];

export const FileInput = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, ref) => (
    <Root>
      <Input type="file" {...props} ref={ref} />
    </Root>
  )
);
