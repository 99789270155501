import React, { forwardRef, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Attribute } from '../../lib/types/offers';
import { CapacityPreset, CargoPreset } from '../../lib/types/preset';
import { Chip } from '../common/Chip';
import { CountryFlag } from '../common/CountryFlag';
import * as DropdownMenu from '../common/DropdownMenu';
import { LocationIcon } from '../common/Icons';
import { Body5 } from '../common/typography/Body';
import { ActionMedium } from '../common/typography/Special';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { Attributes } from '../offer/Attributes';
import { CreateCapacityDialog } from '../offer/CreateCapacityDialog';
import { CreateCargoDialog } from '../offer/CreateCargoDialog';
import cargoIcon from '../offer/icons/ColoredGate.svg';
import capacityIcon from '../offer/icons/ColoredTruck.svg';
import { Requirements } from '../offer/Requirements';
import { SizeIcon } from '../offer/SizeIcon';
import { useApi } from '../UserState';
import { CapacityPresetDialog } from './CapacityPresetDialog';
import { CargoPresetDialog } from './CargoPresetDialog';

const Root = styled.div`
  border-radius: 10px;
  border: 1px solid var(--grey200);
  color: var(--primary500);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  & + & {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: var(--tertiary100);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LocationInfo = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: var(--grey600);
    align-self: flex-start;
    margin-right: 2px;
  }
  img {
    margin-left: 10px;
  }
`;

const Zip = styled(Body5)`
  color: var(--grey600);
`;

const Place = styled(Body5)`
  color: var(--grey400);
  ::before {
    content: '・';
  }
`;

const Chips = styled.div`
  align-self: stretch;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
`;

type ListItemProps = {
  icon: string;
  title: string;
  zip?: string | null;
  place?: string | null;
  country?: string | null;
  size: string;
  requirements: string[];
  attributes: Attribute[];
  deletePreset?: () => Promise<void>;
  renderEdit?: (onClose: () => void) => ReactElement;
};
const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
  (
    {
      icon,
      title,
      zip,
      place,
      country,
      size,
      attributes,
      requirements,
      deletePreset,
      renderEdit,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    return (
      <Root ref={ref} {...props}>
        {edit && renderEdit?.(() => setEdit(false))}
        <Icon>
          <img src={icon} width="32" height="32" />
        </Icon>
        <Stack>
          <ActionMedium>{title}</ActionMedium>
          <div>
            {zip && (
              <LocationInfo>
                <LocationIcon size="16px" />
                <Zip>{zip}</Zip>
                {place && <Place>{place}</Place>}
                {country && <CountryFlag country={country} />}
              </LocationInfo>
            )}
          </div>
        </Stack>
        <Chips>
          <Chip>
            <SizeIcon size={size} />
            {t(size)}
          </Chip>
          <Row gap="8px">
            <Attributes values={attributes} />
            <Requirements values={requirements} />
          </Row>
        </Chips>
        {deletePreset && renderEdit && (
          <DropdownMenu.KebabMenu>
            <DropdownMenu.Item
              onClick={(ev) => {
                ev.stopPropagation();
                deletePreset();
              }}
            >
              Löschen
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={(ev) => {
                ev.stopPropagation();
                setEdit(true);
              }}
            >
              Bearbeiten
            </DropdownMenu.Item>
          </DropdownMenu.KebabMenu>
        )}
      </Root>
    );
  }
);

type PresetProps = {
  invalidate: () => void;
};
type CargoProps = PresetProps & {
  preset: CargoPreset;
  readonly?: boolean;
};
export function CargoPresetListItem({
  preset,
  invalidate,
  readonly,
}: CargoProps) {
  const api = useApi();
  const editProps = readonly
    ? {}
    : {
        deletePreset: async () => {
          await api.deleteCargoPreset(preset.id);
          invalidate();
        },
        renderEdit: (onClose: () => void) => (
          <CargoPresetDialog
            edit={preset}
            onClose={onClose}
            onSave={invalidate}
          />
        ),
      };
  return (
    <CreateCargoDialog preset={preset}>
      <ListItem
        icon={cargoIcon}
        title={preset.preset_name}
        zip={preset.zip_location}
        place={preset.zip_location_place}
        country={preset.country_location}
        size={preset.cargo_units[0].size}
        attributes={preset.cargo_units[0].attributes ?? []}
        requirements={preset.cargo_units[0].requirements ?? []}
        {...editProps}
      />
    </CreateCargoDialog>
  );
}

type CapacityProps = PresetProps & {
  preset: CapacityPreset;
  readonly?: boolean;
};
export function CapacityPresetListItem({
  preset,
  invalidate,
  readonly,
}: CapacityProps) {
  const api = useApi();
  const editProps = readonly
    ? {}
    : {
        deletePreset: async () => {
          await api.deleteCapacityPreset(preset.id);
          invalidate();
        },
        renderEdit: (onClose: () => void) => (
          <CapacityPresetDialog
            edit={preset}
            onClose={onClose}
            onSave={invalidate}
          />
        ),
      };
  return (
    <CreateCapacityDialog preset={preset}>
      <ListItem
        icon={capacityIcon}
        title={preset.preset_name}
        zip={preset.zip_location}
        place={preset.zip_location_place}
        country={preset.country_location}
        size={preset.size}
        attributes={preset.attribute_capabilities ?? []}
        requirements={preset.requirement_capabilities ?? []}
        {...editProps}
      />
    </CreateCapacityDialog>
  );
}
