import React, { useRef } from 'react';
import styled from 'styled-components';

import { useKeyboard } from '../../lib/useKeyboard';
import { CancelIcon } from '../common/Icons';
import {
  Backdrop,
  CloseButton,
  Dialog,
  DialogContent,
  DialogFrame,
  DialogHeader,
} from '../common/Modal';
import { Portal } from '../common/Portal';
import { Body, Headings } from '../common/typography';
import { VisuallyHidden } from '../common/typography/VisuallyHidden';
import { OnboardingCarousel } from './OnboardingCarousel';

type Props = {
  close: () => void;
};

const Frame = styled(DialogFrame)`
  border-radius: 20px;
`;

const Header = styled(DialogHeader)`
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 720px) {
    border-radius: 10px 10px 0 0;
    padding: 16px;
  }
`;

const Content = styled(DialogContent)`
  background: var(--white);
  border-radius: 0 0 20px 20px;
  h2 {
    margin-bottom: 1rem;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
  }

  p {
    text-align: center;
    color: var(--grey500);
    max-width: 500px;
    margin: 0 auto;
  }

  .slide img,
  .slide picture {
    max-width: 90%;
    max-height: 300px;
    text-align: center;
    object-fit: cover;
    border-radius: 20px;
  }

  @media screen and (max-width: 720px) {
    padding: 0 8px;
    border-radius: 0 0 20px 20px;

    p {
      ${Body.body6}
    }

    h2 {
      ${Headings.h4}
    }

    h3 {
      ${Headings.h5}
    }

    .slide img,
    .slide picture {
      border-radius: 10px;
      max-width: 100%;
    }
  }
`;

export function OnboardingModal({ close }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  useKeyboard({ Escape: close });
  return (
    <Portal>
      <Dialog>
        <Backdrop tabIndex={0} onClick={close} />
        <Frame width="720px" role="dialog" aria-labelledby="onboardingTitle">
          <Header>
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
            {/* we can use a h1 here because we are inside a role="dialog" */}
            <VisuallyHidden as="h1" id="onboardingTitle">
              Erste Schritte
            </VisuallyHidden>
          </Header>
          <Content>
            <OnboardingCarousel close={close} />
          </Content>
        </Frame>
      </Dialog>
    </Portal>
  );
}
