import styled, { css } from 'styled-components';

const manrope = css`
  font-family: Manrope, sans-serif;
  font-style: normal;
`;

export const subtitleLarge = css`
  ${manrope}
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

export const subtitleMedium = css`
  ${manrope}
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
`;

export const subtitleSmall = css`
  ${manrope}
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const actionLarge = css`
  ${manrope}
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
`;

export const actionHuge = css`
  ${manrope}
  font-weight: bold;
  font-size: 32px;
  line-height: 43.71px;
  letter-spacing: 0.25px;
`;

export const actionMedium = css`
  ${manrope}
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.25px;
`;

export const actionSmall = css`
  ${manrope}
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

export const inputLabel = css`
  ${manrope}
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
`;

export const allCapsLarge = css`
  ${manrope}
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const allCapsSmall = css`
  ${manrope}
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

type Props = {
  color?: string;
};
export const SubtitleLarge = styled.div<Props>`
  ${subtitleLarge}
  color: ${(p) => p.color}
`;
export const SubtitleMedium = styled.div<Props>`
  ${subtitleMedium}
  color: ${(p) => p.color}
`;
export const SubtitleSmall = styled.div<Props>`
  ${subtitleSmall}
  color: ${(p) => p.color}
`;
export const ActionLarge = styled.div<Props>`
  ${actionLarge}
  color: ${(p) => p.color}
`;

export const ActionHuge = styled.div<Props>`
  ${actionHuge}
  color: ${(p) => p.color}
`;

export const ActionMedium = styled.div<Props>`
  ${actionMedium}
  color: ${(p) => p.color}
`;
export const ActionSmall = styled.div<Props>`
  ${actionSmall}
  color: ${(p) => p.color}
`;
export const InputLabel = styled.div<Props>`
  ${inputLabel}
  color: ${(p) => p.color}
`;
export const AllCapsLarge = styled.div<Props>`
  ${allCapsLarge}
  color: ${(p) => p.color}
`;
export const AllCapsSmall = styled.div<Props>`
  ${allCapsSmall}
  color: ${(p) => p.color}
`;
