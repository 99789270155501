import styled from 'styled-components';

export const Center = styled.div<{
  direction?: string;
  height?: string;
  gap?: string;
}>`
  display: flex;
  flex-direction: ${(p) => p.direction ?? 'row'};
  align-items: center;
  justify-content: center;
  height: ${(p) => p.height ?? '100%'};
  gap: ${(p) => p.gap ?? '0'};
`;
