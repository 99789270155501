import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { MessageType } from '../common/MessageBox';
import { Special } from '../common/typography';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type?: MessageType;
  children: ReactNode;
}

const iconColors: Record<MessageType, string> = {
  default: 'var(--primary500)',
  info: 'var(--primary500)',
  error: 'var(--error500)',
  warning: 'var(--warning500)',
  success: 'var(--success500)',
};

const Wrapper = styled.div<{ type: MessageType }>`
  display: flex;
  align-items: center;

  ${Special.subtitleSmall}
  color: var(--grey500);

  svg {
    display: block;
    color: ${(p) => iconColors[p.type]};
    margin-right: 8px;
  }
`;

const ErrorIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.138 2.808 1.637 12.16a1 1 0 0 0 .862 1.507h11.002a1 1 0 0 0 .862-1.507L8.862 2.808a1 1 0 0 0-1.724 0ZM8 6.333c.171 0 .312.129.331.295l.002.039v2a.333.333 0 0 1-.664.039l-.002-.04v-2c0-.183.149-.333.333-.333Zm.236 4.57a.333.333 0 1 0-.472-.472.333.333 0 0 0 .472.471Z"
      fill="currentColor"
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm10.569-2.236a.333.333 0 0 0-.471 0l-4.431 4.431-1.765-1.764-.031-.028a.333.333 0 0 0-.44.5l2.236 2.235 4.902-4.902.028-.032a.333.333 0 0 0-.028-.44Z"
      fill="#46D68C"
    />
  </svg>
);

export function FieldFeedback({ type, children, ...attribs }: Props) {
  return (
    <Wrapper type={type ?? 'default'} {...attribs}>
      {(type === 'error' || type === 'warning') && <ErrorIcon />}
      {type === 'success' && <SuccessIcon />}
      {children}
    </Wrapper>
  );
}
