import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Body } from './typography';

export type MessageType = 'default' | 'info' | 'warning' | 'error' | 'success';

const styles = {
  default: {
    icon: 'var(--primary500)',
    text: 'var(--neutral600)',
    background: 'var(--neutral200)',
  },
  info: {
    icon: 'var(--primary500)',
    text: 'var(--neutral600)',
    background: 'var(--neutral200)',
  },
  warning: {
    icon: 'var(--warning700)',
    text: 'var(--warning700)',
    background: 'var(--warning100)',
  },
  error: {
    icon: 'var(--error700)',
    text: 'var(--error700)',
    background: 'var(--error100)',
  },
  success: {
    icon: 'var(--success700)',
    text: 'var(--success700)',
    background: 'var(--success100)',
  },
};
const Box = styled.div<{ type: MessageType }>`
  display: flex;
  align-items: flex-start;
  ${Body.body5}
  border-radius: 8px;
  padding: 6px 16px;
  color: ${(p) => styles[p.type].text};
  background: ${(p) => styles[p.type].background};
  & > svg {
    display: block;
    flex-shrink: 0;
    color: ${(p) => styles[p.type].icon};
    margin-right: 8px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type?: MessageType;
  children: ReactNode;
}
export function MessageBox({ type = 'default', children, ...props }: Props) {
  return (
    <Box type={type} {...props}>
      <svg
        width="14"
        height="24"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66667 0C2.98667 0 0 2.98667 0 6.66667C0 10.3467 2.98667 13.3333 6.66667 13.3333C10.3467 13.3333 13.3333 10.3467 13.3333 6.66667C13.3333 2.98667 10.3467 0 6.66667 0ZM6.66667 7.33333C6.3 7.33333 6 7.03333 6 6.66667V4C6 3.63333 6.3 3.33333 6.66667 3.33333C7.03333 3.33333 7.33333 3.63333 7.33333 4V6.66667C7.33333 7.03333 7.03333 7.33333 6.66667 7.33333ZM6 8.66667V10H7.33333V8.66667H6Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
        />
      </svg>
      {children}
    </Box>
  );
}
