import {
  MarkerClusterer,
  onClusterClickHandler,
  Renderer,
} from '@googlemaps/markerclusterer';
import { MapContext, useMapContext } from 'googlemaps-react-primitives';
import React, { ReactNode, useMemo } from 'react';

export type ClusterProps = {
  renderer?: Renderer;
  onClusterClick?: onClusterClickHandler;
  children: ReactNode;
};

export function Cluster({ renderer, onClusterClick, children }: ClusterProps) {
  const ctx = useMapContext();
  const cluster = useMemo(
    () =>
      new MarkerClusterer({
        map: ctx.map,
        markers: [],
        onClusterClick,
        renderer,
      }),
    []
  );
  const wrappedCtx = useMemo(
    () => ({
      ...ctx,
      addMarker(marker: google.maps.Marker) {
        cluster.addMarker(marker);
        ctx.extendBounds(marker.getPosition());
      },
      removeMarker(marker: google.maps.Marker) {
        cluster.removeMarker(marker);
      },
    }),
    []
  );
  return (
    <MapContext.Provider value={wrappedCtx}>{children}</MapContext.Provider>
  );
}
