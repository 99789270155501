import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Footer } from './Footer';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const PageContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

type Props = {
  children: ReactNode;
};
export function Page({ children }: Props) {
  return (
    <PageContainer>
      <PageContent>{children}</PageContent>
      <Footer />
    </PageContainer>
  );
}
