import * as Collapsible from '@radix-ui/react-collapsible';
import React, { ReactElement, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import cargofacesLogo from '../landing/CargofacesLogo.svg';
import { Stack } from '../layout/Stack';
import { Badge } from './Badge';
import { Btn } from './Btn';
import { FullArrowDownIcon } from './Icons';
import { body5 } from './typography/Body';

export const Wrapper = styled.div`
  flex: 244px 0 0;
  max-width: 244px;
  transition: max-width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  background: var(--primary500);
  &.collapsed {
    max-width: 66px;
    ${Badge} {
      position: absolute;
      top: 9px;
      left: 32px;
      overflow: hidden;
      padding: 0;
      color: transparent;
      width: 8px;
      height: 8px;
    }
  }
`;

export const Logo = styled(Link)`
  width: 140px;
  height: 24px;
  background: url(${cargofacesLogo}) no-repeat center;
  background-size: contain;
  .collapsed & {
    display: none;
  }
`;

export const Header = styled.div`
  padding: 20px 16px 20px;
  border-bottom: 1px solid var(--neutral300);
  display: flex;
  justify-content: space-between;
  .collapsed & {
    justify-content: center;
  }
`;

export const Content = styled(Stack).attrs({ gap: '1px' })`
  flex-grow: 1;
  padding: 13px;
  overflow-y: auto;
`;

export const Nav = styled.div`
  margin-bottom: 16px;
`;

export const NavItem = styled(NavLink)`
  ${body5}
  flex-shrink: 0;
  color: var(--white);
  margin: 1px 0px;
  text-decoration: none;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding: 12px;
  position: relative;
  overflow: hidden;
  :hover,
  &.active {
    background-color: rgba(191, 199, 209, 0.09);
  }
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
export const ItemLabel = styled.div`
  margin-right: 8px;
  svg + & {
    margin-left: 8px;
  }
  .collapsed & {
    display: none;
  }
`;

const SubMenuRoot = styled(Collapsible.Root)`
  display: flex;
  flex-direction: column;
`;

const SubMenuTrigger = styled(Collapsible.Trigger)`
  ${body5}
  text-align: left;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px 0 12px 12px;
  cursor: pointer;
  color: var(--white);
  :hover {
    color: var(--primary200);
  }
  & > svg:first-child {
    width: 16px;
    height: 16px;
  }
  & > * + * {
    margin-left: 8px;
  }
  & > span {
    margin-right: auto;
  }
  .arrow {
    transition: transform 0.5s;
  }
  &[data-state='open'] .arrow {
    transform: rotateX(180deg);
  }
  &[data-state='open'] .badge {
    display: none;
  }
`;

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
});

const SubMenuContent = styled(Collapsible.Content)`
  margin-left: 24px;
  overflow: hidden;
  &[data-state='open'] {
    animation: ${open} 300ms ease-out forwards;
  }
  &[data-state='closed'] {
    animation: ${close} 300ms ease-out forwards;
  }
`;

type SubMenuProps = {
  icon: ReactElement;
  title: ReactNode;
  children: ReactNode;
  Badge?: any;
};
export function SubMenu({ icon, title, children, Badge }: SubMenuProps) {
  return (
    <SubMenuRoot>
      <SubMenuTrigger>
        {icon}
        <span>{title}</span>
        {Badge && <Badge className="badge" />}
        <FullArrowDownIcon className="arrow" />
      </SubMenuTrigger>
      <SubMenuContent>
        <Stack gap="1px">{children}</Stack>
      </SubMenuContent>
    </SubMenuRoot>
  );
}

export const SubMenuItem = styled.button`
  ${body5}
  color: var(--primary200);
  background: transparent;
  border: none;
  text-align: left;
  text-decoration: none;
  padding: 12px 2px 12px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const SolidItem = styled.div`
  padding: 16px;
  color: var(--white);
`;
export const WhiteButton = styled(Btn)`
  width: 100%;
  color: var(--primary500);
  background: var(--white);
  padding: 12px 8px;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
export const DarkBox = styled(Stack).attrs({ gap: '12px' })`
  background: var(--primary600);
  border-radius: 20px;
  margin-top: auto;
  padding: 16px;
  color: var(--white);
  .collapsed & {
    background: transparent;
    padding: 0;
    & > *:not(:first-child) {
      display: none;
    }
  }
`;
export const QuestionMarkBox = styled.div`
  color: var(--primary500);
  background: white;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-self: flex-start;
`;
