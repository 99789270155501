import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Btn } from '../../components/common/Btn';
import { CancelIcon } from '../../components/common/Icons';
import {
  Backdrop,
  CloseButton,
  Dialog,
  DialogContent,
  DialogFrame,
  DialogHeader,
} from '../../components/common/Modal';
import { Body4 } from '../../components/common/typography/Body';
import { H3 } from '../../components/common/typography/Headings';
import { Field } from '../../components/form/Field';
import { Form } from '../../components/form/Form';
import { TextArea } from '../../components/form/TextArea';
import { TextField } from '../../components/form/TextField';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { useApi, useUserData } from '../../components/UserState';
import ContactusLogo from './Contactus.svg';

const Contactus = styled(Row)`
  background-image: url(${ContactusLogo});
  width: 48px;
  height: 48px;
  justify-content: center;
`;

type Props = {
  close: () => void;
};

export const ContactUsModal = ({ close }: Props) => {
  const form = useForm();
  const user = useUserData();
  const api = useApi();

  async function onSubmit() {
    const { name, contact_email, title, content } = form.getValues();
    const res = await api.askForSupport(name, contact_email, title, content);
    if (res === 204) {
      close();
    }
  }

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            <Stack>
              <Row justify="center">
                <Contactus />
              </Row>
              <Row justify="center">
                <H3>Dein Kontakt zu Cargofaces</H3>
                <Body4 color="var(--grey500)">
                  Brauchst du Hilfe oder hast du eine Anregung? Schreib uns und
                  wir werden deine Nachricht so schnell wie möglich beantworten.{' '}
                </Body4>
              </Row>

              <Field name="name" label="Name" required>
                <TextField
                  defaultValue={`${user.first_name} ${user.last_name}`}
                />
              </Field>
              <Field name="contact_email" label="Email" required>
                <TextField defaultValue={user.email ?? ''} />
              </Field>
              <Field name="title" label="Betreff" required>
                <TextField />
              </Field>
              <Field name="content" label="Nachricht" required>
                <TextArea required />
              </Field>
              <Row justify="right">
                <Btn color="primary" type="submit">
                  Nachricht senden
                </Btn>
              </Row>
            </Stack>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};
