import React, { useMemo } from 'react';

import { AppScreen } from '../../components/common/AppScreen';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { PageTitle } from '../../components/common/PageTitle';
import { ConnectionsSubNav } from '../../components/connections/ConnectionsSubNav';
import { EmptyPending } from '../../components/connections/EmptyTables/EmptyPending';
import { ReceivedContactRequestsTable } from '../../components/connections/ReceivedContactRequestsTable';
import { SentContactRequestsTable } from '../../components/connections/SentContactRequestsTable';
import { Stack } from '../../components/layout/Stack';
import { useApiCall } from '../../lib/useApiCall';

export function Pending() {
  const { data, invalidate } = useApiCall('getPendingConnections');
  const { sent, received } = useMemo(
    () => ({
      sent: data?.filter((c) => c.contact.id === c.connection.receiver_id),
      received: data?.filter((c) => c.contact.id === c.connection.sender_id),
    }),
    [data]
  );
  return (
    <AppScreen HeaderNavi={<ConnectionsSubNav />}>
      <PageTitle title="Anfragen">
        Hier findest du empfangene und gesendete Freundschaftsanfragen.
      </PageTitle>
      {!data ? (
        <LoadingCircle />
      ) : sent?.length || received?.length ? (
        <Stack>
          {!!sent?.length && (
            <SentContactRequestsTable items={sent} onAction={invalidate} />
          )}
          {!!received?.length && (
            <ReceivedContactRequestsTable
              key="received"
              items={received}
              onAction={invalidate}
            />
          )}
        </Stack>
      ) : (
        <EmptyPending />
      )}
    </AppScreen>
  );
}
