import * as React from 'react';

import { BtnLink } from '../../components/common/Btn';
import { Body4 } from '../../components/common/typography/Body';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Stack } from '../../components/layout/Stack';

export function RegisterDone() {
  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="E-Mail bestätigen">
              <Stack gap="24px">
                <Body4>
                  Bitte prüfe deinen Posteingang auf eine Bestätigungs-E-Mail.
                  Klicke auf den Link in der E-Mail, um deine E-Mail-Adresse zu
                  bestätigen.
                </Body4>
                <BtnLink to="/login">Zurück zur Anmeldung</BtnLink>
              </Stack>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
