import React from 'react';
import styled from 'styled-components';

import { TimelineComment, TimelinePost } from '../../lib/types/user';
import { Btn } from '../common/Btn';
import * as DropdownMenu from '../common/DropdownMenu';
import { MenuDotsIcon } from '../common/Icons';
import { useUser } from '../UserState';

const MenuButton = styled(Btn)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

type Props = {
  post: TimelinePost | TimelineComment;
  onDelete: () => unknown;
  onEdit: () => unknown;
};
export function PostMenu({ post, onDelete, onEdit }: Props) {
  const user = useUser();
  if (!post.user.id) return null;
  if (user.data.id !== post.user.id) return null;

  return (
    <DropdownMenu.Menu
      trigger={
        <MenuButton appearance="transparent" size="tiny">
          <MenuDotsIcon />
        </MenuButton>
      }
    >
      <DropdownMenu.Item onClick={onEdit}>Bearbeiten</DropdownMenu.Item>
      <DropdownMenu.Item onClick={onDelete}>Löschen</DropdownMenu.Item>
    </DropdownMenu.Menu>
  );
}
