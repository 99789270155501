export function debounce(callback: (...args: any) => any, timeout = 250) {
  let timer = -1;
  return (...args: any[]) => {
    if (timer !== -1) {
      window.clearTimeout(timer);
    }
    timer = window.setTimeout(() => {
      callback(...args);
    }, timeout);
  };
}
