import React from 'react';
import { useLocation } from 'react-router-dom';

import { useClickOutside } from '../../lib/useClickOutside';
import { Btn } from '../common/Btn';
import { BurgerIcon } from '../common/Icons';
import {
  Buttons,
  CargofacesLogo,
  DesktopView,
  HeaderLink,
  Links,
  LoginButton,
  MobilView,
  ResponsiveBtnLink,
  Root,
  SideMenuWrapper,
} from '../common/LandingHeader';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';

const MenuItems = [
  { title: 'Übersicht', id: '/#overview' },
  { title: 'Features', id: '/#feature' },
  { title: 'People’s Business', id: '/#logistics' },
  { title: 'Lösungen', id: '/#solution' },
  { title: 'Preisgestaltung', id: '/#price' },
  { title: 'Datenschutz', id: '/#privacy' },
];

export const HeaderLinks = () => {
  return (
    <Links>
      {MenuItems.map((item) => (
        <HeaderLink key={item.id} to={item.id}>
          {item.title}
        </HeaderLink>
      ))}
    </Links>
  );
};

export function LandingHeader() {
  const [openMenu, setOpenMenu] = React.useState(false);
  const { pathname } = useLocation();
  return (
    <Root>
      <MobilView>
        {openMenu ? (
          <SideMenu closeMenu={() => setOpenMenu(false)} />
        ) : (
          <Row justify="spread">
            <Btn appearance="transparent" onClick={() => setOpenMenu(true)}>
              <div style={{ color: 'white' }}>
                <BurgerIcon />
              </div>
            </Btn>
            <HeaderLink to="/">
              <CargofacesLogo />
            </HeaderLink>
          </Row>
        )}
      </MobilView>
      <DesktopView>
        <Row justify="spread">
          <HeaderLink to="/">
            <CargofacesLogo />
          </HeaderLink>
          <HeaderLinks />
          <Buttons>
            <LoginButton
              appearance="outline"
              to={{ pathname: '/login', search: `origin=${pathname}` }}
            >
              Anmelden
            </LoginButton>
            <ResponsiveBtnLink color="secondary" to="/signup">
              Registrieren
            </ResponsiveBtnLink>
          </Buttons>
        </Row>
      </DesktopView>
    </Root>
  );
}

type Props = {
  closeMenu: () => void;
};

const SideMenu = ({ closeMenu }: Props) => {
  const ref = useClickOutside<HTMLDivElement>(closeMenu);
  return (
    <SideMenuWrapper ref={ref}>
      <Row justify="spread" noWrap>
        <CargofacesLogo />
        <Btn appearance="transparent" onClick={closeMenu}>
          <div style={{ color: 'white' }}>&#10006;</div>
        </Btn>
      </Row>
      <Stack>
        {MenuItems.map((item) => (
          <HeaderLink key={item.id} to={item.id}>
            {item.title}
          </HeaderLink>
        ))}
        <HeaderLink to="/login">Anmelden</HeaderLink>
        <HeaderLink to="/signup">Registrieren</HeaderLink>
      </Stack>
    </SideMenuWrapper>
  );
};
