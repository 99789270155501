import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { Body4 } from './typography/Body';
import { H4 } from './typography/Headings';

const SubTitle = styled(Body4)`
  font-size: 18px;
  color: var(--primary400);
`;

const Intro = styled(Body4)`
  color: var(--primary400);
  max-width: 70ch;
`;

type Props = {
  title: string;
  subTitle?: string;
  children?: ReactNode;
};
export function PageTitle({ title, subTitle, children }: Props) {
  return (
    <Stack gap="16px">
      <Row gap="8px" align="baseline">
        <H4 color="var(--primary500)">{title}</H4>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </Row>
      {children && <Intro>{children}</Intro>}
    </Stack>
  );
}
