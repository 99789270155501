import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Btn } from '../../components/common/Btn';
import { TinyLink } from '../../components/common/Link';
import {
  ActionMedium,
  SubtitleMedium,
} from '../../components/common/typography/Special';
import { Field } from '../../components/form/Field';
import { Form } from '../../components/form/Form';
import { PasswordField } from '../../components/form/PasswordField';
import { TextField } from '../../components/form/TextField';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { signUp, visitErrors } from '../../lib/publicApi';

type Inputs = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
  agree: boolean;
};

const ColoredLink = styled(TinyLink)`
  color: var(--tertiary600);
  font-size: 10px;
`;

const FieledSmallBox = styled.div`
  width: 45%;
`;

export function Signup() {
  const navigate = useNavigate();
  const form = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async ({ agree, ...data }) => {
    const res = await signUp(data);
    if ('errors' in res) {
      visitErrors(data, res, form.setError);
    } else {
      navigate('/registerdone');
    }
  };
  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="Registrieren">
              <Form {...form} onSubmit={onSubmit}>
                <Stack gap="24px">
                  <Stack>
                    <Row justify="spread">
                      <FieledSmallBox>
                        <Field
                          label="Vorname"
                          name="first_name"
                          required="Bitte geben Sie Ihren Vornamen ein."
                        >
                          <TextField
                            placeholder="Vorname"
                            autoComplete="given-name"
                          />
                        </Field>
                      </FieledSmallBox>
                      <FieledSmallBox>
                        <Field
                          label="Nachname"
                          name="last_name"
                          required="Bitte geben Sie Ihren Nachnamen ein."
                        >
                          <TextField
                            placeholder="Nachname"
                            autoComplete="family-name"
                          />
                        </Field>
                      </FieledSmallBox>
                    </Row>
                    <Field
                      label="Email"
                      name="email"
                      required="Bitte geben Sie Ihre Email ein."
                    >
                      <TextField placeholder="Email" autoComplete="email" />
                    </Field>
                    <Field
                      label="Passwort"
                      name="password"
                      required="Bitte wählen Sie ein Passwort."
                    >
                      <PasswordField
                        placeholder="Passwort"
                        autoComplete="new-password"
                      />
                    </Field>
                  </Stack>
                  <Row justify="center">
                    <ActionMedium>
                      Mit deiner Anmeldung bestätigst du, dass mit unserer
                      <ColoredLink to="/privacypolicy">
                        {' '}
                        Datenschutzerklärung{' '}
                      </ColoredLink>
                      einverstanden bist.
                    </ActionMedium>
                  </Row>
                  <Btn type="submit">Weiter</Btn>
                  <Row justify="center" align="center">
                    <SubtitleMedium>Du hast bereits ein Konto?</SubtitleMedium>
                    <ColoredLink to="/login"> Hier Anmelden</ColoredLink>
                  </Row>
                </Stack>
              </Form>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
