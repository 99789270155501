import * as Select from '@radix-ui/react-select';
import React from 'react';
import styled from 'styled-components';

import { Visibility } from '../../lib/types/offers';
import {
  CargoFacesIcon,
  FullArrowDownIcon,
  UserOutlineIcon,
  UsersIcon,
} from '../common/Icons';
import { body5 } from '../common/typography/Body';

const Label = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    color: var(--tertiary500);
    margin-right: 10px;
  }
`;

const Trigger = styled(Select.Trigger)`
  ${body5}
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey400);
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  ${Label} svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const Content = styled(Select.Content)`
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  max-width: 342px;
`;

const Item = styled(Select.Item)`
  padding: 16px;
  cursor: pointer;
  user-select: none;
  &[aria-checked='true'] {
    background: var(--tertiary100);
  }
  :focus {
    background: var(--tertiary100);
  }
`;

const options = {
  owner: {
    label: 'Nur für mich sichtbar',
    icon: <UserOutlineIcon />,
  },
  connections: {
    label: 'Für meine Kontakte sichtbar',
    icon: <UsersIcon />,
  },
  everyone: {
    label: 'Für alle sichtbar',
    icon: <CargoFacesIcon />,
  },
};

type Props = {
  value: Visibility;
  onChange: (value: Visibility) => void;
};

export function CompactVisibilityDropdown({ value, onChange }: Props) {
  return (
    <Select.Root value={value} onValueChange={onChange}>
      <Trigger>
        <Select.Value />
        <Select.Icon>
          <FullArrowDownIcon />
        </Select.Icon>
      </Trigger>
      <Content>
        <Select.Viewport>
          {Object.keys(options).map((value) => (
            <Item key={value} value={value}>
              <Select.ItemText>
                <Label>
                  {(options as any)[value].icon}
                  {(options as any)[value].label}
                </Label>
              </Select.ItemText>
            </Item>
          ))}
        </Select.Viewport>
      </Content>
    </Select.Root>
  );
}
