import React, { ReactElement, useMemo } from 'react';

import {
  cargoPresetFromForm,
  cargoPresetToPresetForm,
} from '../../lib/convert';
import { CargoPreset } from '../../lib/types/preset';
import { CargoPresetFormValues } from '../../lib/types/ui';
import * as Dialog from '../common/Dialog';
import { CargoDialog } from '../offer/CargoDialog';
import addIcon from '../offer/icons/SpecialAddIcon.svg';
import { useApi } from '../UserState';

type Props = {
  edit?: CargoPreset;
  onSave?: () => void;
  onClose?: () => void;
  children?: ReactElement;
};

export function CargoPresetDialog({ edit, onSave, onClose, children }: Props) {
  const api = useApi();

  const values = useMemo(
    () => ({ ...(edit && cargoPresetToPresetForm(edit)) }),
    [edit]
  );

  const handleSave = async (values: CargoPresetFormValues) => {
    const cargo = cargoPresetFromForm(values);
    if (edit) {
      await api.editCargoPreset(cargo, edit.id);
    } else {
      await api.addCargoPreset(cargo);
    }
    onSave?.();
    onClose?.();
  };

  return (
    <CargoDialog
      preset
      header={<Dialog.Header title="Überhang Vorlage" icon={addIcon} />}
      values={values}
      onSave={handleSave}
      onCancel={onClose}
      trigger={children}
    />
  );
}
