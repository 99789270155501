import Lottie from 'lottie-react';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Btn } from '../common/Btn';
import { CardButton } from '../common/CardButton';
import * as Dialog from '../common/Dialog';
import { Row } from '../layout/Row';
import addIcon from '../offer/icons/SpecialAddIcon.svg';
import Truck from '../offer/icons/truck.json';
import WareHouse from '../offer/icons/warehouse.json';
import { CapacityPresetDialog } from './CapacityPresetDialog';
import { CargoPresetDialog } from './CargoPresetDialog';

const Animation = styled(Lottie).attrs({ loop: true })`
  width: 80px;
  height: 80px;
`;

type Props = {
  onCreate?: () => void;
};
export function AddPresetDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const trigger = <Btn>Neue Vorlage</Btn>;
  const onCreate = () => {
    setOpen(false);
    props.onCreate?.();
  };
  return (
    <Dialog.Root open={open} onOpenChange={setOpen} trigger={trigger}>
      <Dialog.Header title="Neue Vorlage" icon={addIcon} />
      <Dialog.Body>
        <Row justify="center" align="stretch" gap="48px">
          <CapacityPresetDialog onSave={onCreate}>
            <CardButton
              title="Leerchassis"
              icon={<Animation animationData={Truck} />}
            >
              Füge hier ein Leerchassis aus deinem Fuhrpark hinzu.
            </CardButton>
          </CapacityPresetDialog>
          <CargoPresetDialog onSave={onCreate}>
            <CardButton
              title="Überhang"
              icon={<Animation animationData={WareHouse} />}
            >
              Füge hier eine häufige Tour eines Überhangs hinzu.
            </CardButton>
          </CargoPresetDialog>
        </Row>
      </Dialog.Body>
    </Dialog.Root>
  );
}
