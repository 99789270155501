import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Circle = styled.div<{ size: string }>`
  font-size: ${(p) => p.size};
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  border-radius: 100%;
  border: solid 1px #fff0;
  background-color: #edf3f9;
  color: var(--primary500);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > span {
    font-size: 0.25em;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

function initials(name: string) {
  const letters = name.split(' ').map((s) => s.slice(0, 1).toUpperCase());
  const len = letters.length;
  if (!len) return '';
  return letters[0] + (len > 1 ? letters[len - 1] : '');
}

export type PresenceStatus = 'online' | 'offline' | 'unknown';

const presenceColors = {
  online: 'var(--success600)',
  offline: 'var(--error600)',
  unknown: 'var(--neutral600)',
};

const Presence = styled.div<{ status: PresenceStatus }>`
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 22%;
  height: 22%;
  border-radius: 100%;
  border: 2px solid var(--white);
  background: ${(p) => presenceColors[p.status]};
`;

type Props = {
  image?: string | null;
  name: string;
  presence?: PresenceStatus;
  size?: string;
};
export function Avatar({ image, name, presence, size = '42px' }: Props) {
  return (
    <Container>
      <Circle size={size}>
        {image ? <img src={image} alt={name} /> : <span>{initials(name)}</span>}
      </Circle>
      {presence && <Presence status={presence} />}
    </Container>
  );
}
