import React from 'react';

import { ConfirmDialog } from '../common/ConfirmDialog';

type Props = {
  onConfirm: () => any;
  onClose: () => any;
};

export const ConfirmDeleteCompanyMember = (props: Props) => {
  return (
    <ConfirmDialog
      title="Firmenmitglied entfernen?"
      confirmAction="Entfernen"
      {...props}
    >
      Bitte bestätige, dass du dieses Firmenmitglied entfernen möchtest.
    </ConfirmDialog>
  );
};
