import styled from 'styled-components';

export const Card = styled.div`
  background: var(--white);
  color: var(--grey600);
  border-radius: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  padding: 24px;
  border: 2px solid transparent;
  &[aria-current='true'] {
    background: var(--tertiary100);
    border-color: var(--tertiary500);
  }
`;
