import React from 'react';

import bookmarks from '../../empty/bookmarks.svg';
import { EmptyState } from '../../empty/EmptyState';

export function EmptyBookmarks() {
  return (
    <EmptyState
      image={bookmarks}
      imageStyle={{ height: 384, marginBottom: -15 }}
      title="Keine Lesezeichen"
    >
      Um ein Lesezeichen hinzuzufügen, suchen Sie ein Profil und klicken Sie auf
      das Lesezeichensymbol.
    </EmptyState>
  );
}
