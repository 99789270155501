import styled, { css } from 'styled-components';

import { Body, Special } from '../common/typography';

type Props = {
  required?: boolean;
};
export const Label = styled.label<Props>`
  ${Special.inputLabel}
  color: var(--grey800);
  position: relative;
  cursor: pointer;

  ${(p) =>
    p.required &&
    css`
      ::after {
        display: inline-block;
        transform: translateY(-20%);
        margin-left: 4px;
        content: '*';
        ${Body.body6}
        color: var(--error700);

        [disabled] & {
          content: '';
        }
      }
    `}
`;
