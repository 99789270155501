import styled from 'styled-components';

import { Card, CardHeader } from './Card';
import { body5 } from './typography/Body';

export const WizardCard = styled(Card)`
  padding: 0;
`;

export const WizardCardHeader = styled(CardHeader)`
  padding: 20px 16px 8px 16px;
  margin-bottom: 0%;
`;

export const WizardItem = styled.div<{ active: boolean }>`
  ${body5}
  display: flex;
  padding: 12px 16px;
  color: ${(p) => (p.active ? 'var(--tertiary700)' : 'var(--primary500)')};
  background: ${(p) => (p.active ? 'var(--neutral100)' : 'none')};
  cursor: default;
`;
