import React, { useState } from 'react';

import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { Body4 } from '../common/typography/Body';
import { Row } from '../layout/Row';

type Props = {
  onClick: (includeCompanyContacts?: boolean) => any;
  onClose: () => any;
  offersAmount: number;
};
export function SendEmailDialog({ onClick, onClose, offersAmount }: Props) {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Dialog.Root
      open
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <Dialog.Header title="E-Mail-Versand" />
      <Dialog.Body>
        <Row justify="center" align="stretch" style={{ marginBottom: '12px' }}>
          <Body4>
            An welche Kontakte{' '}
            {offersAmount === 1
              ? 'soll das Angebot '
              : `sollen die ${offersAmount} Angebote `}
            per E-Mail verschickt werden?
          </Body4>
        </Row>
        <Row
          justify="center"
          align="stretch"
          gap="48px"
          style={{ margin: '0 40px' }}
        >
          <Btn
            color="tertiary"
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              try {
                await onClick();
              } finally {
                setSubmitting(false);
              }
              onClose();
            }}
          >
            Nur meine Kontakte
          </Btn>
          <Btn
            color="primary"
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              try {
                await onClick(true);
              } finally {
                setSubmitting(false);
              }
              onClose();
            }}
          >
            Kompletter Firmenverteiler
          </Btn>
        </Row>
      </Dialog.Body>
    </Dialog.Root>
  );
}
