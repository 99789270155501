import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Avatar } from '../common/Avatar';
import { Btn } from '../common/Btn';
import { body4 } from '../common/typography/Body';
import { h3 } from '../common/typography/Headings';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';

const ProfileHeaderContainer = styled(Row).attrs({ gap: '48px' })`
  padding: 10px 44px;
  --avatar-size: 148px;
  justify-content: space-between;
  @media screen and (max-width: 1300px) {
    --avatar-size: 102px;
  }
  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ProfileHeaderTitle = styled.div`
  ${h3}
  color: var(--primary500);
`;

const ProfileHeaderSubtitle = styled(Btn)`
  width: fit-content;
`;

const ProfileHeaderMain = styled(Row).attrs({
  gap: 'calc(var(--avatar-size) / 4)',
})``;

const ProfileHeaderAside = styled(Stack).attrs({ gap: '16px' })`
  border-left: 1px solid var(--neutral300);
  padding: 24px 32px;
`;

export const ProfileHeaderInfo = styled.div`
  ${body4}
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--neutral500);
  & > svg {
    color: var(--primary500);
  }
`;

export const ContactUserButtons = styled.div`
  display: flex;
  margin-top: 20px;
  & > :last-child {
    margin-left: 16px;
  }
`;

type Props = {
  title?: string;
  subTitle?: string | null;
  image?: string | null;
  children?: ReactNode;
  buttons?: ReactNode;
  id?: string | number;
};
export function ProfileHeader({
  title,
  subTitle,
  image,
  buttons,
  children,
  id,
}: Props) {
  const navigate = useNavigate();
  return (
    <ProfileHeaderContainer>
      <ProfileHeaderMain>
        <Avatar size="var(--avatar-size)" name={title ?? ''} image={image} />
        <Stack gap="6px">
          <ProfileHeaderTitle>{title}</ProfileHeaderTitle>
          {subTitle && (
            <ProfileHeaderSubtitle
              appearance="transparent"
              size="tiny"
              disabled={!id}
              onClick={() => navigate(`/company/${id}/profile`)}
            >
              {subTitle}
            </ProfileHeaderSubtitle>
          )}
          {buttons && <ContactUserButtons>{buttons}</ContactUserButtons>}
        </Stack>
      </ProfileHeaderMain>
      {children && <ProfileHeaderAside>{children}</ProfileHeaderAside>}
    </ProfileHeaderContainer>
  );
}
