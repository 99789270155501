import { useApi } from '../components/UserState';

type Opts = {
  required?: boolean;
  country?: string;
};
export function useZipValidation(opts: Opts = {}) {
  const { required = true, country } = opts;
  const api = useApi();
  return async (value?: string) => {
    if (value && value.length) {
      const valid = await api.isValidZipCode(value, country);
      if (!valid) return 'Ungülige Postleitzahl';
    } else if (required) {
      return 'Bitte gib eine Postleitzahl an.';
    }
  };
}
