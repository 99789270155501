import styled, { css } from 'styled-components';

export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

/**
 * The difference from an element with a [hidden] attribute or with a style set to `display: none` is
 * that the VisuallyHidden element visually behaves like a hidden element but its content is still exposed to
 * screen readers. This is useful for adding hidden labels to input fields or headlines to content sections.
 */
export const VisuallyHidden = styled.div`
  ${visuallyHidden}
`;
