import React from 'react';

import { Card, CardHeader } from '../../common/Card';

export function Billing() {
  return (
    <Card>
      <CardHeader>Abrechnung</CardHeader>
      <p>
        Hier gibt es in Kürze die Einstellungen um Cargofaces-Pakete zu buchen
        und Zahlungsinformationen zu ändern.{' '}
      </p>
      <br />
      <p>
        Du wirst über die Timeline informiert, sobald diese Funktion zur
        Verfügung steht.
      </p>
    </Card>
  );
}
