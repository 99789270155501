import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { useApi } from '../components/UserState';
import { Api } from './Api';

type PromisedResult<T> = T extends (...args: any[]) => Promise<infer V>
  ? V
  : never;

/**
 * React hook to call an API method and get the result and a setter to modify it
 * as well as a callback to re-fetch the data.
 */
export function useApiCall<T extends keyof Api>(
  method: T | false | null | undefined,
  ...args: Parameters<Api[T]>
) {
  const api = useApi();
  const [data, setData] = useState<PromisedResult<Api[T]>>();
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    if (method) {
      (api[method] as any)(...args)
        .then(setData)
        .catch(Sentry.captureException);
    } else {
      setData(undefined);
    }
  }, [method, JSON.stringify(args), time]);
  const invalidate = () => setTime(Date.now());
  return { data, setData, invalidate };
}

/**
 * React hook to call an API method and get the result.
 */
export function useApiResult<T extends keyof Api>(
  method: T | false,
  ...args: Parameters<Api[T]>
) {
  const { data } = useApiCall(method, ...args);
  return data;
}
