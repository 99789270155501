import styled, { css } from 'styled-components';

import { body4 } from '../../components/common/typography/Body';
import { h2, h3 } from '../../components/common/typography/Headings';
import { actionLarge } from '../../components/common/typography/Special';

const defaultImage = '/images/port.jpg';

export const desktopOnly = css`
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const mobileOnly = css`
  @media screen and (max-width: 959px) {
    display: unset;
  }
`;

export const MobileViewFirstSide = css`
  @media screen and (max-width: 959px) {
    width: 90%;
    padding: 16px;
    max-width: 100%;
    margin-top: 24px;
  }
`;
export const MobileViewInnerBox = css`
  @media screen and (max-width: 959px) {
    background: red;
    width: 100%;
  }
`;

export const Background = styled.div<{
  background?: string;
}>`
  // height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background: linear-gradient(
      0deg,
      rgba(50, 70, 84, 0.8),
      rgba(50, 70, 84, 0.8)
    ),
    url(${(p) => p.background ?? defaultImage});
`;

export const SmallerTitle = styled.div`
  ${h3}
  color: var(--white)
`;

export const Title = styled.div`
  ${h2}
  color: var(--white)
`;

export const Paragrapgh = styled.div`
  ${body4}
  color: var(--white)
`;

export const ButtonOrange = styled.div`
  ${desktopOnly}
  ${actionLarge}
  cursor: pointer;
  color: var(--white);
  background: #ff974a;
  border-radius: 10px;
  padding: 12px 23px;
  margin-left: 50px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Input = styled.input`
  ${body4}
  background: #ffffff;
  border: 1px solid rgba(96, 98, 103, 0.3);
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  padding: 13px 50px 13px 15px;
  width: 70%;
  @media screen and (max-width: 959px) {
    border-radius: 10px;
    width: 100%;
    padding-left: 7px;
    &::-webkit-input-placeholder {
      font-size: 10px;
      min-width: 300px;
    }
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
    min-width: 300px;
  }
`;
// Label
export const InputButton = styled(ButtonOrange)`
  ${mobileOnly}
  ${actionLarge}
  margin-left: 0px;
  background: #ff974a;
  border-radius: 0px 10px 10px 0px;
  padding: 13px;
  white-space: nowrap;

  @media screen and (max-width: 1200px) {
    display: unset;
    padding: 13px 9px;
    font-size: 12px;
  }
  @media screen and (max-width: 959px) {
    border-radius: 10px;
    margin-top: 16px;
    text-align: center;
    padding: 13px;
    font-size: 16px;
  }
`;
