import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { capacityOfferDetails, cargoOfferDetails } from '../../lib/convert';
import { MyOffers } from '../../lib/types/offers';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { ManagePresetsDialog } from '../presets/ManagePresetsDialog';
import { CreateCapacityDialog } from './CreateCapacityDialog';
import { CreateCargoDialog } from './CreateCargoDialog';
import { OfferMap } from './OfferMap';

type Props = {
  data?: MyOffers;
};
export function OfferDashboard({ data }: Props) {
  const [, setSearchParams] = useSearchParams();
  return (
    <Stack style={{ flexGrow: 1 }}>
      <Row gap="20px">
        <CreateCargoDialog />
        <CreateCapacityDialog />
        <ManagePresetsDialog />
      </Row>
      {data && (
        <OfferMap
          onClick={({ type, id }) => {
            setSearchParams({ tab: type, ...(id && { id: `${id}` }) });
          }}
          cargoOffers={data?.cargos.map(cargoOfferDetails) ?? []}
          capacityOffers={data?.capacities.map(capacityOfferDetails) ?? []}
        />
      )}
    </Stack>
  );
}
