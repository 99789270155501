import React, { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

import { UserWithRoles } from '../../pages/app/CompanyMembers';
import { Columns, DataTable, SortOrder } from '../common/DataTable';
import { Body } from '../common/typography';
import { Checkbox } from '../form/Checkbox';
import { Center } from '../layout/Center';
import { AvatarNameTitle } from './AvatarNameTitle';

export const roleNames: Record<string, string> = {
  admin: 'Admin',
  dispatcher: 'Dispatcher',
  employee: 'Mitarbeiter',
  pending: 'Eingeladen',
  sent_by_company: 'Eingeladen',
  sent_by_user: 'Anfrage',
};

export const RolesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
  ${Body.body5}

  li {
    color: var(--white);
    background: var(--grey500);
    padding: 0.5rem;
    border-radius: 10px;

    &.employee {
      background: var(--success500);
    }

    &.dispatcher {
      background: var(--secondary500);
    }

    &.admin {
      background: var(--tertiary600);
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

type Props = {
  items: UserWithRoles[];
  renderActions: (item: UserWithRoles) => ReactNode;
  onCheckAdmin?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckDispatcher?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isAdmin: boolean;
};
export function MemberDataTable({
  items,
  renderActions,
  onCheckAdmin,
  onCheckDispatcher,
  isAdmin,
}: Props) {
  let columnConfig: Columns<UserWithRoles>;

  if (isAdmin) {
    columnConfig = useMemo(
      () => [
        {
          key: 'name',
          heading: 'Name',
          sortable: true,
          render: ({ user }: UserWithRoles) => <AvatarNameTitle user={user} />,
          value: ({ user }: UserWithRoles) =>
            `${user.last_name} ${user.first_name}`,
        },
        {
          key: 'roles',
          heading: 'Roles',
          sortable: true,
          render: ({ roles }: UserWithRoles) => (
            <div>
              <RolesList>
                {roles.map((role, idx) => (
                  <li key={idx} className={role}>
                    {roleNames[role]}
                  </li>
                ))}
              </RolesList>
            </div>
          ),
          value: ({ roles }: UserWithRoles) =>
            (roles.includes('admin') ? 4 : 0) +
            (roles.includes('dispatcher') ? 2 : 0) +
            (roles.includes('employee') ? 1 : 0),
        },
        {
          key: 'admin',
          heading: 'Admin',
          sortable: false,
          style: { textAlign: 'center' as const },
          render: ({ user, roles }: UserWithRoles) => (
            <Center>
              {roles.includes('employee') && (
                <Checkbox
                  data-id={user.id}
                  checked={roles.includes('admin')}
                  aria-label="Admin"
                  onChange={onCheckAdmin}
                  readOnly={typeof onCheckAdmin === 'undefined'}
                ></Checkbox>
              )}
            </Center>
          ),
        },
        {
          key: 'dispatcher',
          heading: 'Disponent',
          style: { textAlign: 'center' as const },
          sortable: false,
          value: ({ roles }: UserWithRoles) => roles?.includes('dispatcher'),
          render: ({ user, roles }: UserWithRoles) => (
            <Center>
              {roles.includes('employee') && (
                <Checkbox
                  data-id={user.id}
                  checked={roles.includes('dispatcher')}
                  aria-label="Dispatcher"
                  onChange={onCheckDispatcher}
                  readOnly={typeof onCheckAdmin === 'undefined'}
                ></Checkbox>
              )}
            </Center>
          ),
        },
        {
          key: 'actions',
          heading: 'Aktionen',
          style: { width: 0 },
          render: (item: UserWithRoles) => (
            <Actions>{renderActions(item)}</Actions>
          ),
        },
      ],
      [renderActions]
    );
  } else {
    columnConfig = useMemo(
      () => [
        {
          key: 'name',
          heading: 'Name',
          sortable: true,
          render: ({ user }: UserWithRoles) => <AvatarNameTitle user={user} />,
          value: ({ user }: UserWithRoles) =>
            `${user.last_name} ${user.first_name}`,
        },
        {
          key: 'roles',
          heading: 'Roles',
          sortable: true,
          render: ({ roles }: UserWithRoles) => (
            <div>
              <RolesList>
                {roles.map((role, idx) => (
                  <li key={idx} className={role}>
                    {roleNames[role]}
                  </li>
                ))}
              </RolesList>
            </div>
          ),
          value: ({ roles }: UserWithRoles) =>
            (roles.includes('admin') ? 4 : 0) +
            (roles.includes('dispatcher') ? 2 : 0) +
            (roles.includes('employee') ? 1 : 0),
        },
      ],
      [renderActions]
    );
  }

  const [sortOrder, setSortOrder] = useState<SortOrder>({
    column: 'name',
    asc: true,
  });

  return (
    <DataTable
      columns={columnConfig}
      items={items}
      sortOrder={sortOrder}
      onSort={setSortOrder}
    />
  );
}
