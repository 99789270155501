import React from 'react';

import { ConnectedContact } from '../../lib/types/connections';
import { TableTitle } from '../common/DataTable';
import { CheckmarkCircleIcon, RemoveIcon } from '../common/Icons';
import { StatusButton } from '../common/StatusButton';
import { useNotifications } from '../NotificationsProvider';
import { useApi } from '../UserState';
import { ConnectionsTable } from './ConnectionsTable';

type Props = {
  items: ConnectedContact[];
  onAction: () => void;
};
export function ReceivedContactRequestsTable({ items, onAction }: Props) {
  const api = useApi();
  const { refresh } = useNotifications();
  return (
    <>
      <TableTitle>Empfangen</TableTitle>
      <ConnectionsTable
        items={items}
        columns={['image', 'name', 'company', 'location']}
        renderActions={(item) => (
          <>
            <StatusButton
              variant="positive"
              onClick={async () => {
                await api.acceptConnection(item.connection.id);
                await refresh();
                onAction();
              }}
            >
              <CheckmarkCircleIcon />
              Annehmen
            </StatusButton>
            <StatusButton
              variant="neutral"
              onClick={async () => {
                await api.declineConnection(item.connection.id);
                await refresh();
                onAction();
              }}
            >
              <RemoveIcon />
              Ignorieren
            </StatusButton>
          </>
        )}
      />
    </>
  );
}
