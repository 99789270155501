import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

import { Btn } from './Btn';
import { MenuDotsIcon } from './Icons';
import { body5 } from './typography/Body';

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const Content = styled(DropdownMenu.Content)`
  ${body5}
  border-radius: 10px;
  padding: 8px 0;
  background: var(--white);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  min-width: 134px;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  &[data-side='top'] {
    animation-name: ${slideUp};
  }
  &[data-side='bottom'] {
    animation-name: ${slideDown};
  }
`;

export const Item = styled(DropdownMenu.Item)`
  padding: 12px;
  cursor: pointer;
  :hover {
    color: var(--tertiary500);
  }
`;

export function Menu({
  trigger,
  disabled,
  children,
}: {
  trigger: ReactNode;
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger disabled={disabled} asChild>
        {trigger}
      </DropdownMenu.Trigger>
      <Content>{children}</Content>
    </DropdownMenu.Root>
  );
}

export function KebabMenu({ children }: { children: ReactNode }) {
  return (
    <Menu
      trigger={
        <Btn
          appearance="transparent"
          size="tiny"
          style={{ alignSelf: 'flex-start' }}
        >
          <MenuDotsIcon />
        </Btn>
      }
    >
      {children}
    </Menu>
  );
}

export const Root = DropdownMenu.Root;
export const Trigger = DropdownMenu.Trigger;
export const Label = DropdownMenu.Label;
