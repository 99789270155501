import React from 'react';
import styled from 'styled-components';

import { CountryFlag } from '../common/CountryFlag';
import { Body5 } from '../common/typography/Body';

const WaypointBox = styled.div`
  display: flex;
  flex-flow: column;
`;

const CityCountryBox = styled.div`
  display: flex;
  div {
    margin-right: 0.5rem;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #9fa1a3;
  }
`;

const ZipAndFlagBox = styled.div`
  display: flex;
  justify-content: center;
  div {
    margin-right: 0.5rem;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #282b32;
  }
`;

type Props = {
  zip: string;
  place: string;
  country: string;
};

export function Waypoint({ zip, place, country }: Props) {
  return (
    <WaypointBox>
      <ZipAndFlagBox>
        <Body5>{zip}</Body5>
        <CountryFlag country={country} />
      </ZipAndFlagBox>
      <CityCountryBox>
        <Body5 color="var(--grey400)">{place},</Body5>
        <Body5> {country.toUpperCase()}</Body5>
      </CityCountryBox>
    </WaypointBox>
  );
}
