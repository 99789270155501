import { findLast } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Chat, ChatMessage } from '../../lib/types/chat';
import { useNotifications } from '../NotificationsProvider';
import { useApi, useUserData } from '../UserState';
import { MatchSubHeader } from './MatchSubHeader';
import { MessageArea } from './MessageArea';
import { MessageAreaHeader } from './MessageAreaHeader';
import { MessageBubble } from './MessageBubble';

const ChatView = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Messages = styled.div`
  flex-grow: 1;
  flex-basis: 35vh;
  @media screen and (min-width: 960px) {
    flex-basis: 0;
  }
  overflow-y: auto;
  padding: 12px 20px;
  margin: 4px;
`;

const NewMessageContainer = styled.div`
  padding: 0 20px 20px;
`;

type Props = {
  chat: Chat;
  refreshChats: () => void;
  setLoading: (loading: boolean) => void;
};

export function ChatDetails({ chat, refreshChats, setLoading }: Props) {
  const { chat_id } = chat;

  const user = useUserData();
  const api = useApi();
  const notifications = useNotifications();
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    const last = findLast(messages, (m) => m.message.id !== 0);
    if (last?.message.chat_id !== chat_id) {
      // Clear messages if chat changes
      api.getChatMessages(chat_id).then(setMessages);
    } else {
      if (notifications.messages) {
        if (last?.message.chat_id === chat_id) {
          const since = new Date(
            new Date(last.message.created_at).getTime() + 1
          );
          api.getChatMessages(chat_id, since.toISOString()).then((fetched) => {
            setMessages(mergeMessages(fetched, messages));
          });
        }
      }
    }
  }, [notifications, chat]);

  const sendMessage = useCallback(
    (message: string) => {
      setMessages((messages) => [
        ...(messages ?? []),
        {
          image: null,
          message: {
            id: 0,
            sender_id: user.id,
            chat_id: chat_id,
            created_at: new Date().toISOString(),
            message,
          },
        },
      ]);
      return api.sendMessage(chat_id, message);
    },
    [chat_id]
  );

  return (
    <>
      <MessageAreaHeader
        chat={chat}
        refreshChats={refreshChats}
        setLoading={setLoading}
      />
      <ChatView>
        <Messages>
          {chat?.match_id ? <MatchSubHeader match_id={chat.match_id} /> : null}
          {chat &&
            messages?.map((message, i) => (
              <MessageBubble
                key={i}
                chat={chat}
                user={user}
                chatMessage={message}
                isLast={i === messages.length - 1}
              />
            ))}
        </Messages>
        {/* send message will be rendered for new message */}
        {sendMessage && (
          <NewMessageContainer>
            <MessageArea onSend={sendMessage} />
          </NewMessageContainer>
        )}
      </ChatView>
    </>
  );
}

function mergeMessages(messages: ChatMessage[], old?: ChatMessage[]) {
  if (!old) return messages;
  const hasId = (m: ChatMessage) => {
    return m.message.id !== 0;
  };
  const oldWithId = old.filter((o) => hasId(o));
  const isSeen = (m: ChatMessage) =>
    oldWithId.some((o) => o.message.id === m.message.id);
  const unseen = messages.filter((m) => !isSeen(m));
  if (!unseen.length) return oldWithId;
  return oldWithId.concat(unseen);
}
