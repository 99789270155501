import React from 'react';
import styled from 'styled-components';

import { BtnLink } from '../common/Btn';
import { Body5 } from '../common/typography/Body';
import { Row } from '../layout/Row';

const Wrapper = styled.div`
  background-color: #324654;
  padding: 12px 24px;
`;

const Botttom = styled(BtnLink)`
  color: var(--tertiary500);
`;

export const Footer = () => {
  return (
    <Wrapper>
      <Row justify="spread">
        <Body5 color="white">2023 © Cargofaces</Body5>
        <Row>
          <Botttom size="tiny" appearance="transparent" to="/">
            Hilfe
          </Botttom>
          <Botttom
            size="tiny"
            appearance="transparent"
            to="/imprint"
            target="_blank"
          >
            Impressum
          </Botttom>
          <Botttom
            size="tiny"
            appearance="transparent"
            to="/privacypolicy"
            target="_blank"
          >
            Datenschutzerklärung
          </Botttom>
        </Row>
      </Row>
    </Wrapper>
  );
};
