import { Marker } from 'googlemaps-react-primitives';
import React from 'react';

import capacity from './capacity_pin.png';
import cargo from './cargo_pin.png';
import destination from './destination_pin.png';
import selectedCapacity from './selected_capacity_pin.png';
import selectedCargo from './selected_cargo_pin.png';
import waypoint from './waypoint_pin.png';

const pins = {
  capacity,
  cargo,
  waypoint,
  destination,
};

const selectedPins = {
  capacity: selectedCapacity,
  cargo: selectedCargo,
  waypoint,
  destination,
};

type Props = {
  type: keyof typeof pins;
  position: { lat: number; lng: number };
  selected?: boolean;
  onClick?: () => void;
};
export function MatchMarker({ type, position, selected, onClick }: Props) {
  return (
    <Marker
      zIndex={selected ? 1 : 0}
      onClick={onClick}
      position={position}
      icon={{
        url: selected ? selectedPins[type] : pins[type],
        scaledSize: { width: 83, height: 91 } as any,
        anchor: { x: 44, y: 52 } as any,
      }}
    />
  );
}
