import styled from 'styled-components';

import { inputLabel } from '../../common/typography/Special';

export const TextButton = styled.button`
  ${inputLabel}
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--tertiary500);
  background: none;
  border: none;
  cursor: pointer;
`;
