import React, { ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatDay } from '../../lib/convert';
import { CapacityOfferWithMatches } from '../../lib/types/offers';
import { CapacityOfferDetails } from '../../lib/types/ui';
import { TableBadge } from '../common/Badge';
import { Btn } from '../common/Btn';
import { CountryFlag } from '../common/CountryFlag';
import { Column, SortOrder } from '../common/DataTable';
import * as DropdownMenu from '../common/DropdownMenu';
import { FullArrowDownIcon } from '../common/Icons';
import { MultiSelectDataTable } from '../common/MultiSelectDataTable';
import { Props as ToastProps, Toast } from '../common/Toast';
import { Body5 } from '../common/typography/Body';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { useApi } from '../UserState';
import { CompactVisibilityDropdown } from './CompactVisibilityDropdown';
import { EditCapacityDialog } from './EditCapacityDialog';
import { SendEmailDialog } from './SendEmailDialog';

type Props = {
  items: CapacityOfferDetails[];
  invalidate: () => void;
  children?: ReactNode;
};
export function CapacityOfferTable({ items, invalidate, children }: Props) {
  const api = useApi();
  const [edit, setEdit] = useState<CapacityOfferWithMatches>();
  const [selection, setSelection] = useState<CapacityOfferDetails[]>([]);
  const [sendOffers, setSendOffers] = useState<number[]>();
  const [toast, setToast] = useState<ToastProps>();
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    column: 'name',
    asc: true,
  });

  const columns: Column<CapacityOfferDetails>[] = useMemo(
    () => [
      {
        key: 'name',
        heading: 'Name',
        sortable: true,
        render: ({ id, name, visible }) => (
          <Stack align="left">
            <div>{name}</div>
            <CompactVisibilityDropdown
              value={visible}
              onChange={async (value) => {
                await api.changeCapacityVisibility(id, value);
                invalidate();
              }}
            />
          </Stack>
        ),
      },
      {
        key: 'date',
        heading: 'Datum',
        render: ({ date }) => formatDay(date),
      },
      {
        key: 'location',
        heading: 'Standort',
        render: ({ zip_location, country_location }) => (
          <Stack>
            <Row gap="8px">
              <Body5>{zip_location}</Body5>
              <CountryFlag country={country_location} />
            </Row>
          </Stack>
        ),
      },
      {
        key: 'actions',
        heading: 'Aktionen',
        style: { width: 0 },
        render: ({ id, offer, matches }) => (
          <Row noWrap>
            <Btn
              as={Link}
              to={`/matching/results/${id}/capacity`}
              color="tertiary"
            >
              Zu den Matches
            </Btn>
            <DropdownMenu.KebabMenu>
              <DropdownMenu.Item onClick={() => setEdit(offer)}>
                Bearbeiten
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={async () => {
                  await api.deleteCapacities([id]);
                  invalidate();
                }}
              >
                Löschen
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => {
                  setSendOffers([id]);
                }}
              >
                Per E-Mail versenden
              </DropdownMenu.Item>
            </DropdownMenu.KebabMenu>
            {matches > 0 && <TableBadge>{matches}</TableBadge>}
          </Row>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Row>
        {children}
        <DropdownMenu.Menu
          disabled={!selection.length}
          trigger={
            <Btn
              appearance="outline"
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              Mehrfachauswahl <FullArrowDownIcon />
            </Btn>
          }
        >
          <DropdownMenu.Item
            onClick={async () => {
              await api.deleteCapacities(selection.map((item) => item.id));
              invalidate();
            }}
          >
            Löschen
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={() => setSendOffers(selection.map((item) => item.id))}
          >
            Per E-Mail versenden
          </DropdownMenu.Item>
        </DropdownMenu.Menu>
      </Row>
      <MultiSelectDataTable
        columns={columns}
        items={items}
        sortOrder={sortOrder}
        onSort={setSortOrder}
        selection={selection}
        onSelectionChange={setSelection}
      />
      {edit && (
        <EditCapacityDialog
          offer={edit}
          onClose={(modified) => {
            setEdit(undefined);
            if (modified) invalidate();
          }}
        />
      )}
      {sendOffers && (
        <SendEmailDialog
          offersAmount={sendOffers.length}
          onClick={async (includeCompanyContacts = false) => {
            const { error } = await api.informContactsAboutCapacity(
              sendOffers,
              includeCompanyContacts
            );
            setToast({
              title: error ? 'Fehler' : 'Versand erfolgreich',
              children: error ?? 'Deine Kontakte wurden benachrichtigt.',
            });
          }}
          onClose={() => setSendOffers(undefined)}
        />
      )}
      {toast && <Toast {...toast} />}
    </div>
  );
}
