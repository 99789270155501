import React, { ReactElement, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { UserProfileInput } from '../../lib/types/user';
import { Avatar } from '../common/Avatar';
import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { MessageBox } from '../common/MessageBox';
import { Body4, Body5 } from '../common/typography/Body';
import { Field } from '../form/Field';
import { Form } from '../form/Form';
import { Label } from '../form/Label';
import { Select } from '../form/Select';
import { TextArea } from '../form/TextArea';
import { TextField } from '../form/TextField';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { useApi, useUpdateUser, useUserData } from '../UserState';
import { Data, NameText, TextBox } from './setting/UserData';

type Props = {
  children?: ReactElement;
};

export const AboutUserModal = ({ children }: Props) => {
  const api = useApi();
  const user = useUserData();
  const updateUser = useUpdateUser();
  const [open, setOpen] = useState(false);
  const form = useForm();
  const trigger = children ?? <Btn>Übersicht bearbeiten</Btn>;
  const onSubmit: SubmitHandler<UserProfileInput> = async (profile) => {
    const { data } = await api.editUserProfile(profile);
    updateUser(data);
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen} trigger={trigger}>
      <Dialog.Header title="Übersicht bearbeiten" />
      <Dialog.Body>
        <Stack>
          <Body4 color="var(--primary500)">Beschreibung</Body4>
          <Body5 color="var(--neutral600)">
            Schreib ein paar Sätze über dich und klicken zum Schluss auf
            "Aktualisieren".
          </Body5>
          <Form {...form} onSubmit={onSubmit}>
            <Stack>
              <Field name="about_me">
                <TextArea defaultValue={user.about_me ?? ''} />
              </Field>
              <Row justify="right">
                <Btn appearance="transparent" onClick={() => setOpen(false)}>
                  Abbrechen
                </Btn>

                <Btn color="primary" type="submit">
                  Aktualisieren
                </Btn>
              </Row>
            </Stack>
          </Form>
        </Stack>
      </Dialog.Body>
    </Dialog.Root>
  );
};

const Input = styled.input`
  ::-webkit-file-upload-button {
    display: none;
  }
  display: none;
`;

export const UserDataModal = ({ children }: Props) => {
  const api = useApi();
  const user = useUserData();
  const updateUser = useUpdateUser();
  const [showError, setShowError] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const trigger = children ?? <Btn>Basisinfos bearbeiten</Btn>;

  const form = useForm<UserProfileInput>({
    defaultValues: { ...user, picture: null },
  });
  const image = form.watch('picture', null);

  const previewImage = useMemo(() => {
    if (showError) {
      setShowError(false);
    }
    let url = '';
    if (image instanceof FileList && image.length) {
      if (image[0].size < 1000000) {
        url = URL.createObjectURL(image[0]);
      } else setShowError(true);
    }
    return url;
  }, [image]);

  const onSubmit: SubmitHandler<UserProfileInput> = async (profile) => {
    const { data } = await api.editUserProfile(profile);
    updateUser(data);
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen} trigger={trigger}>
      <Dialog.Header title="Basisinfos bearbeiten" />
      <Dialog.Body>
        <Stack>
          {showError && (
            <MessageBox type="error">Maximale Dateigröße ist 1 MB</MessageBox>
          )}
          <Row style={{ maxWidth: '500px' }}>
            <Body4 color="var(--primary500)">Persönliche angaben</Body4>
            <Body5 color="var(--neutral600)">
              Hier kannst du deine persönlichen Daten bearbeiten. Wenn du fertig
              bist, klicke auf den Button "Aktualisieren", um die Änderungen zu
              übernehmen.
            </Body5>
          </Row>
          <Row justify="center">
            <Data>
              <Avatar
                name={`${user.first_name} ${user.last_name}`}
                image={previewImage || user.picture}
                size="64px"
              />
              <TextBox>
                <NameText>{`${user.first_name} ${user.last_name}`}</NameText>
                <Input
                  {...form.register('picture')}
                  type="file"
                  id="avatar"
                  accept="image/png, image/jpeg"
                />
                <Label htmlFor="avatar">Avatar ändern</Label>
              </TextBox>
            </Data>
          </Row>
          <Form {...form} onSubmit={onSubmit}>
            <Stack>
              <Field name="first_name" label="Vorname" required={true}>
                <TextField />
              </Field>
              <Field name="last_name" label="Nachname" required={true}>
                <TextField />
              </Field>
              <Field
                name="phone_number"
                label="Telefonnummer"
                options={{
                  pattern: {
                    value: /^([+0-9()-]\s*){6,}$/,
                    message: 'Bitte geben Sie eine gültige Telefonnummer an.',
                  },
                }}
              >
                <TextField type="tel" />
              </Field>
              <Field name="job_title" label="Stellenbezeichnung">
                <TextField />
              </Field>
              <Field name="country" label="Land">
                <Select>
                  <option>Deutschland</option>
                  <option>Österreich</option>
                  <option>Schweiz</option>
                </Select>
              </Field>
              <Field name="city" label="Stadt">
                <TextField />
              </Field>
              <Row justify="right">
                <Btn appearance="transparent" onClick={() => setOpen(false)}>
                  Abbrechen
                </Btn>
                <Btn color="primary" type="submit">
                  Aktualisieren
                </Btn>
              </Row>
            </Stack>
          </Form>
        </Stack>
      </Dialog.Body>
    </Dialog.Root>
  );
};
