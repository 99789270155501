import React, { useMemo } from 'react';

import { cargoOfferInputFromForm, cargoOfferToForm } from '../../lib/convert';
import { CargoOffer } from '../../lib/types/offers';
import { CargoFormValues } from '../../lib/types/ui';
import * as Dialog from '../common/Dialog';
import { useApi } from '../UserState';
import { CargoDialog } from './CargoDialog';

type Props = {
  offer: CargoOffer;
  onClose: (modified: boolean) => void;
};

export const EditCargoDialog = ({ offer, onClose }: Props) => {
  const api = useApi();

  const values = useMemo(() => cargoOfferToForm(offer), [offer]);

  const onSave = async (values: CargoFormValues) => {
    await api.updateCargo(offer.cargo.id, cargoOfferInputFromForm(values));
    // Close on next tick to give the CargoDialog the chance to change its state.
    setTimeout(() => onClose(true), 0);
  };

  return (
    <CargoDialog
      values={values}
      onSave={onSave}
      onCancel={() => onClose(false)}
      header={<Dialog.Header title="Überhang bearbeiten" />}
    />
  );
};
