import React from 'react';
import { useForm } from 'react-hook-form';

import { Btn } from '../../common/Btn';
import { HR } from '../../common/HR';
import { Body4 } from '../../common/typography/Body';
import { H6 } from '../../common/typography/Headings';
import { Checkbox } from '../../form/Checkbox';
import { CustomInput } from '../../form/CustomInput';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { TextArea } from '../../form/TextArea';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { Dots } from './Dots';

export type TermsAndMediaProps = {
  adsp: boolean;
  agb_link: string;
  agb_file: FileList;
  details: string;
  xing_link: string;
  linkedin_link: string;
  website_link: string;
};

type Props = {
  values?: Partial<TermsAndMediaProps>;
  next: (data: TermsAndMediaProps) => void;
  prev: (data: TermsAndMediaProps) => void;
};

export const TermsAndMedia = ({ values, next, prev }: Props) => {
  const form = useForm<TermsAndMediaProps>({ defaultValues: values });

  return (
    <Stack gap="24px">
      <H6>Angaben zur deinen AGBs</H6>
      <Body4 color="var(--grey500)">
        Mache hier Angaben zu deinen allgemeinen Geschäftsbedingungen. Du hast
        die Möglichkeit, die AGBs hochzuladen, auf sie zu verlinken oder sie
        direkt einzutragen. Sollte dein Unternehmen nach den ADSp arbeiten,
        kannst du dies auch angeben.
      </Body4>
      <Form {...form} onSubmit={next}>
        <Stack gap="24px">
          <Field name="agb_link" label="Link zu deinen AGBs">
            <TextField placeholder="https://musterspedition.de/AGB" />
          </Field>
          <Field name="agb_file" label="Upload-Richtlinie">
            <CustomInput />
          </Field>
          <Field name="adsp">
            <Checkbox>
              <Body4 color="var(--grey800)">
                Das Unternehmen handelt nach den ADSp
              </Body4>
            </Checkbox>
          </Field>
          <Field name="details" label="Manuelle Eigabe der AGBs">
            <TextArea placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur quis eget nunc gravida pretium ac. Facilisi sagittis, velit mattis enim vulputate pulvinar facilisi. Augue" />
          </Field>
          <div>
            <HR style={{ margin: '0px' }} />
          </div>
          <H6>Social Media & Webseite</H6>
          <Body4 color="var(--grey500)">
            Zuletzt kannst du noch Angaben zu deiner Webpräsenz & Social Media
            Kanälen machen.
          </Body4>

          <Field name="website_link" label="Website">
            <TextField placeholder="http://www.escargo.com/" />
          </Field>
          <Row>
            <Field name="linkedin_link" label="LINKEDIN" weight={4}>
              <TextField placeholder="http://www.linkedin.com/escargo" />
            </Field>
            <Field name="xing_link" label="XING" weight={4}>
              <TextField placeholder="http://www.xing.com/escargo" />
            </Field>
          </Row>
          <Row justify="spread">
            <Btn
              type="button"
              onClick={() => prev(form.getValues())}
              appearance="transparent"
            >
              Zurück
            </Btn>
            <Dots step={4} />
            <Btn type="submit">Weiter</Btn>
          </Row>
        </Stack>
      </Form>
    </Stack>
  );
};
