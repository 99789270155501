import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { Chat } from '../../lib/types/chat';
import { Avatar } from '../common/Avatar';
import { Btn } from '../common/Btn';
import { PinIcon, TranslationIcon, TrashIcon } from '../common/Icons';
import { Body5 } from '../common/typography/Body';
import { H6 } from '../common/typography/Headings';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { useApi, useUserData } from '../UserState';

const ChatHeader = styled(Row)<{ isMatch: boolean }>`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f4f6;
  padding: 8px;
  background: ${(p) =>
    p.isMatch ? 'var(--tertiary700)' : 'var(--neutral300)'};
  color: ${(p) => (p.isMatch ? 'white' : 'inherit')};
`;

const Header = styled.div`
  flex-grow: 1;
`;

function matchStatus(matchIs: string) {
  return matchIs === 'potential' || matchIs === 'negotiating';
}

type Props = {
  chat: Chat;
  refreshChats: () => void;
  setLoading?: (loading: boolean) => void;
};

export const MessageAreaHeader = ({
  chat,
  refreshChats,
  setLoading,
}: Props) => {
  const navigate = useNavigate();
  const api = useApi();
  const user = useUserData();

  const { match_first_accepted_by, match_status, match_id, pinned, chat_id } =
    chat;

  const pinChat = async (id: number) => {
    setLoading?.(true);
    await api.pinChat(id, true);
    await refreshChats();
    setLoading?.(false);
  };

  return (
    <>
      <ChatHeader isMatch={!!match_id}>
        <Header>
          <Row justify="spread">
            <Btn
              type="button"
              appearance="transparent"
              onClick={() => navigate(`/user/${chat.contact_id}`)}
            >
              <Row gap="8px">
                <Avatar
                  name={chat ? chat.contact_name : ''}
                  image={chat.contact_image}
                />
                <Stack gap="2px" align="left">
                  <H6 color={match_id ? 'var(--white)' : ''}>
                    {chat?.contact_name}
                  </H6>
                  <Body5 color={match_id ? 'var(--white)' : ''}>
                    {match_id ? 'Match' : 'Chat'}
                  </Body5>
                </Stack>
              </Row>
            </Btn>
            <Row>
              {!!match_id && (
                <Btn
                  color={
                    match_first_accepted_by === user.id ||
                    !matchStatus(match_status)
                      ? 'neutral'
                      : 'tertiary'
                  }
                  size="small"
                  onClick={() => api.acceptMatch(match_id, chat_id)}
                  disabled={
                    match_first_accepted_by === user.id ||
                    !matchStatus(match_status)
                  }
                >
                  Match akzeptieren
                </Btn>
              )}
              {!pinned && (
                <Btn
                  type="button"
                  appearance="transparent"
                  color="inherit"
                  onClick={() => pinChat(chat.chat_id)}
                >
                  <PinIcon />
                </Btn>
              )}
              <div data-tip="Übersetzung ist bald verfügbar." data-for="main">
                <TranslationIcon />
                <ReactTooltip
                  id="main"
                  backgroundColor="#0F1D30
  "
                />
              </div>
              <Btn
                type="button"
                appearance="transparent"
                color="inherit"
                onClick={async () => {
                  await api.deleteChat(chat.chat_id);
                  await refreshChats();
                  navigate('/chat');
                }}
              >
                <TrashIcon />
              </Btn>
            </Row>
          </Row>
        </Header>
      </ChatHeader>
    </>
  );
};
