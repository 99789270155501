import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { UserData } from '../../lib/publicApi';
import { DataTable } from '../common/DataTable';
import { AvatarNameTitle } from './AvatarNameTitle';

const Actions = styled.div`
  width: 300px;
  display: flex;
  margin-left: -8px;
  gap: 8px;
`;

const EMail = styled.a`
  color: var(--grey800);
  text-decoration: none;
`;

type Props = {
  items: UserData[];
  renderActions: (item: UserData) => ReactNode;
};
export function RequestsDataTable({ items, renderActions }: Props) {
  const columnConfig = useMemo(
    () => [
      {
        key: 'name',
        heading: 'Name',
        sortable: true,
        style: { width: 250 },
        render: (user: UserData) => <AvatarNameTitle user={user} />,
      },
      {
        key: 'email',
        heading: 'E-Mail',
        sortable: true,
        render: (user: UserData) => (
          <EMail href={`mailto:${user.email}`}>{user.email}</EMail>
        ),
      },
      {
        key: 'actions',
        heading: 'Aktionen',
        style: { width: 0 },
        render: (item: UserData) => <Actions>{renderActions(item)}</Actions>,
      },
    ],
    [renderActions]
  );
  return (
    <DataTable
      columns={columnConfig}
      items={items}
      sortOrder={{ column: 'name', asc: true }}
    />
  );
}
