import React, { useMemo, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { UserProfileInput } from '../../../lib/types/user';
import { Avatar } from '../../common/Avatar';
import { Btn } from '../../common/Btn';
import { Card, CardHeader } from '../../common/Card';
import { HR } from '../../common/HR';
import { LoadingCircle } from '../../common/LoadingCircle';
import { MessageBox } from '../../common/MessageBox';
import { Section } from '../../common/Section';
import { body4 } from '../../common/typography/Body';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { Select } from '../../form/Select';
import { TextArea } from '../../form/TextArea';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { useApi, useUpdateUser, useUserData } from '../../UserState';
import { DeleteAccountDialog } from './DeleteAccountDialog';

export const DataBox = styled.div`
  ${body4}
  border: 1px solid #dce5ee;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`;

export const Data = styled.div`
  display: flex;
`;
export const TextBox = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const NameText = styled.div``;
export const Label = styled.label`
  color: var(--tertiary600);
  cursor: pointer;
`;

export const Input = styled.input`
  ::-webkit-file-upload-button {
    display: none;
  }
  display: none;
`;

export function UserData() {
  const api = useApi();
  const user = useUserData();
  const updateUser = useUpdateUser();
  const form = useForm<UserProfileInput>({
    defaultValues: { ...user, picture: null },
  });
  const image = form.watch('picture', null);
  const previewImage = useMemo(
    () =>
      image instanceof FileList &&
      image.length &&
      URL.createObjectURL(image[0]),
    [image]
  );

  const scrollRef = useRef<null | HTMLDivElement>(null);
  const scrollToRef = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onSubmit: SubmitHandler<UserProfileInput> = async (profile) => {
    const { data } = await api.editUserProfile(profile);
    updateUser(data);
  };

  const resetForm = () => {
    scrollToRef();
    form.reset();
  };

  return form.formState.isSubmitting ? (
    <LoadingCircle />
  ) : (
    <>
      <Card>
        <Form {...form} onSubmit={onSubmit}>
          {form.formState.isSubmitSuccessful && !form.formState.isDirty && (
            <MessageBox type="success">
              Dein Profil wurde aktualisiert.
            </MessageBox>
          )}
          <CardHeader>Persönliche Informationen</CardHeader>
          <div ref={scrollRef}></div>
          Hier kannst du deine persönlichen Daten verändern und ein neues
          Profilbild hochladen.
          <Section title="">
            <Row align="top">
              <Field name="first_name" label="Vorname">
                <TextField />
              </Field>
              <Field name="last_name" label="Nachname">
                <TextField />
              </Field>
            </Row>
            <Row align="top">
              <Field
                name="phone_number"
                label="Telefonnummer"
                options={{
                  pattern: {
                    value: /^([+0-9()-]\s*){6,}$/,
                    message: 'Bitte geben Sie eine gültige Telefonnummer an.',
                  },
                }}
              >
                <TextField type="tel" />
              </Field>
            </Row>
            <DataBox>
              <Data>
                <Avatar
                  name={`${user.first_name} ${user.last_name}`}
                  image={previewImage || user.picture}
                  size="64px"
                />
                <TextBox>
                  <NameText>{`${user.first_name} ${user.last_name}`}</NameText>
                  <Input
                    {...form.register('picture')}
                    type="file"
                    id="avatar"
                    accept="image/png, image/jpeg"
                  />
                  <Label htmlFor="avatar">Avatar ändern</Label>
                </TextBox>
              </Data>
            </DataBox>
            <Row align="top">
              <Field name="country" label="Land">
                <Select>
                  <option>Deutschland</option>
                  <option>Österreich</option>
                  <option>Schweiz</option>
                </Select>
              </Field>
              <Field name="city" label="Stadt">
                <TextField />
              </Field>
            </Row>
            <Field name="job_title" label="Stellenbezeichnung">
              <TextField />
            </Field>
            <Field name="about_me" label="Über mich">
              <TextArea />
            </Field>
          </Section>
          <Row>
            <Btn
              color="primary"
              appearance="outline"
              type="button"
              onClick={resetForm}
            >
              Abbrechen
            </Btn>
            <Btn color="primary" type="submit">
              Aktualisieren
            </Btn>
          </Row>
          <HR />
        </Form>
        <DeleteAccountDialog>
          <Btn color="error" type="button">
            Konto löschen
          </Btn>
        </DeleteAccountDialog>
      </Card>
    </>
  );
}
