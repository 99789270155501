import React, { useEffect, useRef } from 'react';

import { MatchDetails } from '../../../lib/types/ui';
import { ForwardIcon } from '../../common/Icons';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { Card } from './Card';
import { Divider } from './Divider';
import { MatchSummary } from './MatchSummary';
import { MatchWaypoints } from './MatchWaypoints';
import { TextButton } from './TextButton';
import { UserInfo } from './UserInfo';

type Props = {
  details: MatchDetails;
  selected?: boolean;
  onClick?: () => any;
  onSelect?: () => any;
};

export function MatchCard({ selected, onClick, onSelect, details }: Props) {
  const { user, company } = details;

  const scrollRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (selected && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selected]);

  return (
    <Card aria-current={selected} onClick={onClick} ref={scrollRef}>
      <Stack gap="16px">
        <MatchSummary details={details} />
        <Divider />

        <MatchWaypoints details={details} />

        <Divider />

        <Row justify="spread">
          <UserInfo user={user} info={company.name}></UserInfo>
          <TextButton onClick={onSelect}>
            Siehe Details <ForwardIcon />
          </TextButton>
        </Row>
      </Stack>
    </Card>
  );
}
