import React, { useState } from 'react';
import styled from 'styled-components';

import { AddressBookSubNav } from '../../components/addressbook/AddressBookSubNav';
import { AddressBookTable } from '../../components/addressbook/AddressBookTable';
import { AppScreen } from '../../components/common/AppScreen';
import { SearchIcon } from '../../components/common/Icons';
import { PageTitle } from '../../components/common/PageTitle';
import addresses from '../../components/empty/addresses.svg';
import { EmptyState } from '../../components/empty/EmptyState';
import { InputWithIconRight } from '../../components/form/InputWithIconRight';
import { TextField } from '../../components/form/TextField';
import { ContactListItem } from '../../lib/types/contacts';
import { useApiCall } from '../../lib/useApiCall';

const TopControlsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
`;

type TopControlsProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  invalidate: () => void;
};
function TopControls({ filter, setFilter, invalidate }: TopControlsProps) {
  return (
    <TopControlsContainer>
      <InputWithIconRight>
        <TextField
          placeholder="Suche"
          aria-label="Adresse"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <SearchIcon />
      </InputWithIconRight>
    </TopControlsContainer>
  );
}

export function AddressBookEmpty() {
  return (
    <EmptyState
      image={addresses}
      imageStyle={{ height: 322, marginBottom: -15 }}
      title="Keine Adressbucheinträge"
    ></EmptyState>
  );
}

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function CompanyAddressBook() {
  const [filter, setFilter] = useState<string>('');
  const { data, invalidate } = useApiCall('getCompanyContacts');

  const filterFunc = ({ contact }: ContactListItem) => {
    const { company, email, name, firstname } = contact;
    const reg = new RegExp(filter, 'i');
    return (
      reg.test(company) || reg.test(email) || reg.test(firstname + ' ' + name)
    );
  };

  return (
    <AppScreen title="Firmenverteiler" HeaderNavi={<AddressBookSubNav />}>
      <HeaderBox>
        <PageTitle title="Firmenkontakte">
          Hier kannst du die geschäftlichen Kontakte von anderen Personen in
          deiner Firma sehen, die zusammen mit deinen eigenen Kontakten den
          Firmenverteiler bilden.
        </PageTitle>
        <TopControls
          filter={filter}
          setFilter={setFilter}
          invalidate={invalidate}
        />
      </HeaderBox>
      {data?.contacts.length === 0 ? (
        <AddressBookEmpty />
      ) : (
        <AddressBookTable
          columns={['name', 'company', 'email']}
          items={data?.contacts.filter((item) => filterFunc(item))}
        />
      )}
    </AppScreen>
  );
}
