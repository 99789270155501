import format from 'date-fns/format';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Chat } from '../../lib/types/chat';
import { Avatar } from '../common/Avatar';
import { Badge } from '../common/Badge';
import { Btn } from '../common/Btn';
import { PinIcon } from '../common/Icons';
import { body5, body6, body7 } from '../common/typography/Body';
import { useApi } from '../UserState';

const ChatLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  text-decoration: none;
  color: inherit;
  &.active {
    background-color: var(--neutral100);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  ${body5}
  font-weight: 700;
  color: var(--primary500);
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  margin-left: auto;
`;

const Time = styled.div`
  ${body7}
  color: var(--neutral500);
`;

const Company = styled.div`
  ${body6}
  line-height: 1;
  color: var(--neutral500);
`;

const Pin = styled.div`
  width: 24px;
  height: 24px;
  background: var(--grey100);
  border-radius: 8px;
  &:hover {
    background: var(--grey200);
  }
`;

type Props = {
  chat: Chat;
  active?: boolean;
  setLoading: (loading: boolean) => void;
  refreshChats: () => void;
};
export function ChatListItem({
  chat,
  active,
  refreshChats,
  setLoading,
}: Props) {
  const {
    chat_id: id,
    contact_name: name,
    contact_image: image,
    contact_company: company,
    latest_message_date: date,
    number_of_unread_messages: unread,
    chat_title: title,
    pinned,
  } = chat;
  const api = useApi();

  const unPinChat = async (id: number) => {
    setLoading(true);
    await api.pinChat(id, false);
    await refreshChats();
    setLoading(false);
  };

  return (
    <ChatLink to={`/chat/${id}`}>
      <Avatar name={name} image={image} />
      <Details>
        <Name>{title ?? name}</Name>
        <Company>{company}</Company>
      </Details>
      <Actions>
        <Time>{ago(new Date(date))}</Time>
        <div>{unread && !active ? <Badge>{unread}</Badge> : null}</div>
        <div>
          {pinned && (
            <Btn
              size="tiny"
              appearance="transparent"
              onClick={() => unPinChat(id)}
            >
              <Pin>
                <PinIcon />
              </Pin>
            </Btn>
          )}
        </div>
      </Actions>
    </ChatLink>
  );
}

function ago(date: Date) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const elapsed = Date.now() - date.getTime();

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + 's';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + 'm';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + 'h';
  } else {
    return format(date, 'dd.MM.');
  }
}
