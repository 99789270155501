import 'emoji-mart/css/emoji-mart.css';

import { emojiIndex } from 'emoji-mart';
import React, { useState } from 'react';
import styled from 'styled-components';

import { MessageInput } from '../chat/MessageInput';
import { Btn } from '../common/Btn';
import { EditIcon } from '../common/Icons';

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  background: var(--grey100);
  border: var(--grey300);
  border-radius: 10px;
`;

type Props = {
  content: string;
  onSend: (message: string) => any;
};
export function EditTextArea({ content, onSend }: Props) {
  const [message, setMessage] = useState(content);

  const send = () => {
    const text = message.trim();
    if (text.length) {
      onSend(text);
    }
  };

  return (
    <InputWrapper>
      <MessageInput
        value={message}
        onKeyPress={(ev) => {
          // Send on enter, shift-enter for new lines
          if (ev.code === 'Enter' && !ev.shiftKey) {
            ev.preventDefault();
            send();
          }
        }}
        onChange={(ev) => {
          const text = ev.target.value;
          setMessage(
            text.replaceAll(/:(\S+):/g, (m, name) => {
              const emojis: any = emojiIndex.search(name);
              return emojis.length ? emojis[0].native : m;
            })
          );
        }}
      />
      <Btn appearance="transparent" size="small" type="button" onClick={send}>
        <EditIcon />
      </Btn>
    </InputWrapper>
  );
}
