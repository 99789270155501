import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { ToastProvider, ToastViewport } from '../../components/common/Toast';
import { SharedOfferSuccessed } from '../../components/matching/SharedOfferSuccessed';
import { NotificationsProvider } from '../../components/NotificationsProvider';
import { Setting } from '../../components/profile/setting/Setting';
import { useOptionalUserData } from '../../components/UserState';
import { MocKUp } from '../mockup';
import { AddressBook } from './AddressBook';
import { Bookmarks } from './Bookmarks';
import { Chat } from './Chat';
import { Company } from './Company';
import { CompanyAddressBook } from './CompanyAddressBook';
import { Connections } from './Connections';
import { ContainerMatchHub } from './ContainerMatchHub';
import { CreateChat } from './CreateChat';
// import { CreateCompany } from './CreateCompany';
import { Dashboard } from './Dashboard';
import { EditInsurance } from './EditInsurance';
import { EditProfile } from './EditProfile';
import { MatchResults } from './MatchResults';
import { MyRequests } from './MyRequests';
import { Pending } from './Pending';
import { Profile } from './Profile';
import { Search } from './Search';
import { SideBar } from './SideBar';
import { UserProfile } from './UserProfile';

const Root = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: var(--neutral100);
`;

export function AppRoutes() {
  const user = useOptionalUserData();
  if (user === undefined) return null;
  if (user === null) return <MocKUp />;
  return (
    <NotificationsProvider>
      <ToastProvider duration={4000}>
        <Root>
          <SideBar />
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route
              path="/pending"
              element={<Navigate to="/connections/pending" />}
            />
            <Route path="/connections" element={<Connections />} />
            <Route path="/connections/pending" element={<Pending />} />
            <Route path="/connections/bookmarks" element={<Bookmarks />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/:id" element={<Chat />} />
            <Route path="/editprofile" element={<EditProfile />} />
            <Route path="/editinsurance" element={<EditInsurance />} />
            <Route path="/address">
              <Route path="" element={<AddressBook />} />
              <Route path="company" element={<CompanyAddressBook />} />
            </Route>
            <Route path="/search" element={<Search />} />
            <Route path="/user/:id" element={<UserProfile />} />
            <Route path="/user/:id/chat" element={<CreateChat />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/company/:id/*" element={<Company />} />
            <Route path="/my-requests/*" element={<MyRequests />} />
            <Route path="/settings/*" element={<Setting />} />
            <Route path="/matching">
              <Route path="" element={<ContainerMatchHub />} />
              <Route path="results/:id/:type" element={<MatchResults />} />
            </Route>
            <Route
              path="/shareoffer/:id/:type"
              element={<SharedOfferSuccessed />}
            />
            <Route path="/*" element={<Navigate to="/dashboard" />} />
          </Routes>
          <ToastViewport />
        </Root>
      </ToastProvider>
    </NotificationsProvider>
  );
}
