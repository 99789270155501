import styled from 'styled-components';

import { body5 } from './typography/Body';

type Status = 'neutral' | 'positive' | 'negative';

const styles = {
  neutral: {
    icon: 'var(--primary500)',
    text: 'var(--neutral600)',
    background: 'var(--neutral200)',
  },
  positive: {
    icon: 'var(--success700)',
    text: 'var(--success700)',
    background: 'var(--success100)',
  },
  negative: {
    icon: 'var(--error700)',
    text: 'var(--error700)',
    background: 'var(--error100)',
  },
};

export const StatusButton = styled.button<{ variant: Status }>`
  ${body5}
  color: ${(p) => styles[p.variant].text};
  background: ${(p) => styles[p.variant].background};
  white-space: nowrap;
  box-sizing: border-box;
  padding: 9px 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  & > svg {
    margin: 0 4px 0 -8px;
    width: 16px;
    height: 16px;
  }
  :disabled {
    cursor: default;
  }
`;
