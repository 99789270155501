import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Btn } from '../../common/Btn';
import * as Dialog from '../../common/Dialog';
import { TrashIcon } from '../../common/Icons';
import { Section } from '../../common/Section';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { useApi } from '../../UserState';

type Props = {
  children?: ReactElement;
};

const UnorderedList = styled.ul`
  li {
    margin-left: 12px;
  }
`;

export function DeleteAccountDialog({ children }: Props) {
  const [open, setOpen] = useState(false);
  const trigger = children ?? <Btn size="small">Konto löschen</Btn>;
  const api = useApi();
  const form = useForm({
    defaultValues: { confirmation: '' },
  });
  const navigate = useNavigate();

  const onSubmit = async () => {
    await api.deleteAccount();
    await api.logout();
    navigate('/');
  };

  return (
    <Dialog.Root trigger={trigger} open={open} onOpenChange={setOpen}>
      <Dialog.Header
        title="Profildaten löschen"
        icon={<TrashIcon fill="red" />}
      />
      <Dialog.Body>
        <Row>Warnung: Das Löschen kann nicht rückgängig gemacht werden.</Row>
        <Row>
          Durch das Klicken auf "Löschen" wird eine E-Mail an das Admin-Team
          geschickt, welches Deinen Account zeitnah restlos löscht. Das umfasst:
        </Row>
        <UnorderedList>
          <li>Alle Kontodaten</li>
          <li>Alle Kontakte und Nachrichtenverläufe</li>
          <li>Alle offenen Angebote</li>
        </UnorderedList>
        <Form {...form} onSubmit={onSubmit}>
          <Section title={''}>
            <Field
              name="confirmation"
              required
              label='Gib zur Bestätigung "CARGOFACES" ein'
              options={{
                pattern: {
                  value: /^CARGOFACES$/,
                  message: '"CARGOFACES" ohne Anführungszeichen.',
                },
              }}
            >
              <TextField />
            </Field>
          </Section>
          <Row>
            <Btn
              color="primary"
              appearance="outline"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Abbrechen
            </Btn>
            <Btn color="error" type="submit">
              Löschen
            </Btn>
          </Row>
        </Form>
      </Dialog.Body>
    </Dialog.Root>
  );
}
