import styled from 'styled-components';

const alignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  stretch: 'stretch',
};

export const Stack = styled.div<{
  gap?: string;
  align?: keyof typeof alignment;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => alignment[p.align ?? 'stretch']};
  & > * + * {
    margin-top: ${(p) => p.gap ?? '1em'};
  }
`;
