import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { GoogleMap } from 'googlemaps-react-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CapacityOfferDetails, CargoOfferDetails } from '../../lib/types/ui';
import styles from '../matching/map/mapStyles.json';
import { MatchCluster } from '../matching/map/MatchCluster';
import { MatchMarker } from '../matching/map/MatchMarker';

const DEFAULT_CENTER = { lat: 51.312801, lng: 9.481544 };

function renderLoadingStatus(status: Status) {
  return <h1>{status}</h1>;
}

type Props = {
  cargoOffers: CargoOfferDetails[];
  capacityOffers: CapacityOfferDetails[];
  onClick?: (target: { type: 'capacity' | 'cargo'; id?: number }) => void;
};
export function OfferMap({ cargoOffers, capacityOffers, onClick }: Props) {
  const empty = !cargoOffers.length && !capacityOffers.length;
  const navigate = useNavigate();

  return (
    <Wrapper
      apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
      libraries={['geometry']}
      render={renderLoadingStatus}
      language="de"
    >
      <GoogleMap
        style={{ flexGrow: 1, flexBasis: '50%' }}
        styles={styles}
        center={empty ? DEFAULT_CENTER : undefined}
        zoom={empty ? 5 : undefined}
        maxZoom={12}
        disableDefaultUI
        autoFit
      >
        <MatchCluster
          color="#1DABF2"
          onClick={() => onClick?.({ type: 'cargo' })}
        >
          {cargoOffers.map((offer) => (
            <MatchMarker
              key={offer.id}
              position={offer.pos}
              type="cargo"
              onClick={() =>
                navigate('/matching/results/' + offer.id + '/cargo')
              }
              // onClick={() => onClick?.({ type: 'cargo', id: offer.id })}
            />
          ))}
        </MatchCluster>
        <MatchCluster
          color={'#FF974A'}
          onClick={() => onClick?.({ type: 'capacity' })}
        >
          {capacityOffers.map((offer) => (
            <MatchMarker
              key={offer.id}
              position={offer.pos}
              type="capacity"
              onClick={() =>
                navigate('/matching/results/' + offer.id + '/capacity')
              }
              // onClick={to:{`/matching/results/${id}/capacity`}}
              // onClick={() => onClick?.({ type: 'capacity', id: offer.id })}
            />
          ))}
        </MatchCluster>
      </GoogleMap>
    </Wrapper>
  );
}
