import React, { ReactElement } from 'react';

import { Btn } from '../../common/Btn';
import { CardButton } from '../../common/CardButton';
import * as Dialog from '../../common/Dialog';
import { PlusCircleIcon } from '../../common/Icons';
import { Row } from '../../layout/Row';
import bookIcon from './book-icon.svg';
import emailIcon from './email-icon.svg';
import { EmailImport } from './EmailImport';
import addIcon from './import-icon.svg';
import { ManualInput } from './ManualInput';

type Props = {
  children?: ReactElement;
  onClose?: () => void;
};
export function AdressImportDialog({ children, onClose }: Props) {
  const trigger = children ?? (
    <Btn size="small">
      <PlusCircleIcon />
      Adressen hinzufügen
    </Btn>
  );
  return (
    <Dialog.Root
      trigger={trigger}
      onOpenChange={(open) => {
        if (!open) onClose?.();
      }}
    >
      <Dialog.Header title="Adressen hinzufügen" icon={addIcon} />
      <Dialog.Body>
        <Row
          justify="center"
          align="stretch"
          gap="48px"
          style={{ margin: '0 40px' }}
        >
          <EmailImport>
            <CardButton
              title="Via E-Mail importieren"
              icon={<img src={emailIcon} />}
            >
              Aus Outlook oder anderen E-Mail-Programmen hinzufügen.
            </CardButton>
          </EmailImport>
          <ManualInput>
            <CardButton title="Manuelle Eingabe" icon={<img src={bookIcon} />}>
              Füge Adressen manuell über ein Formular hinzu.
            </CardButton>
          </ManualInput>
        </Row>
      </Dialog.Body>
    </Dialog.Root>
  );
}
