import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Btn } from '../common/Btn';
import { CancelIcon } from '../common/Icons';
import {
  Backdrop,
  CloseButton,
  Dialog,
  DialogContent,
  DialogFrame,
  DialogHeader,
  ModalText,
  Title,
} from '../common/Modal';
import { Form } from '../form/Form';
import { SpreadButtonRow } from '../layout/SpreadButtonRow';

type Props = {
  close: () => void;
  onConfirm: () => void | Promise<void>;
};

export const ConfirmDisconnectModal = ({ close, onConfirm }: Props) => {
  const form = useForm();

  const onSubmit: SubmitHandler<{}> = async () => {
    const result = onConfirm();
    if (result instanceof Promise) {
      await result;
    }
    close();
  };

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            Kontakt entfernen?
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            <Title>Beschreibung</Title>
            <ModalText>
              Bitte bestätige, dass du diesen Kontakt entfernen möchtest.
            </ModalText>
            <SpreadButtonRow>
              <Btn color="error" type="submit">
                Entfernen
              </Btn>
              <Btn appearance="outline" onClick={close}>
                Abbrechen
              </Btn>
            </SpreadButtonRow>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};
