import React, { ReactNode } from 'react';

import { CommentsIcon } from '../../common/Icons';
import { SubtitleMedium } from '../../common/typography/Special';
import { Stack } from '../../layout/Stack';
import { Label } from './Label';

type Props = {
  children: ReactNode;
};
export function MatchComment({ children }: Props) {
  return (
    <Stack gap="4px">
      <Label>
        <CommentsIcon /> Kommentar
      </Label>
      <SubtitleMedium color="var(--grey400)" style={{ marginLeft: '27px' }}>
        {children}
      </SubtitleMedium>
    </Stack>
  );
}
