import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MatchDetails } from '../../../lib/types/ui';
import { Btn } from '../../common/Btn';
import {
  BackwardIcon,
  RoundedCancelIcon,
  RoundedCheckedIcon,
} from '../../common/Icons';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { useApi } from '../../UserState';
import { Card } from './Card';
import { Divider } from './Divider';
import { MatchAttributes } from './MatchAttributes';
import { MatchComment } from './MatchComment';
import { MatchSummary } from './MatchSummary';
import { MatchWaypoints } from './MatchWaypoints';
import { TextButton } from './TextButton';
import { UserInfo } from './UserInfo';

const Content = styled.div`
  padding: 24px 12px 0 12px;
`;

type Props = {
  details: MatchDetails;
  onBack: () => any;
  invalidate: () => any;
};

export function MatchDetailCard({ onBack, invalidate, details }: Props) {
  const { description, user, company, interested, matchId } = details;
  const navigate = useNavigate();
  const api = useApi();

  const accept = useCallback(async () => {
    if (!interested) {
      await api.interestedMatch(matchId);
      invalidate();
    }
    const res = await api.findChatMatch(matchId);
    navigate(`/chat/${res.chat.id}`);
  }, [interested, matchId, invalidate]);

  const reject = useCallback(async () => {
    await api.cancelMatch(matchId);
    invalidate();
    onBack();
  }, [matchId, invalidate]);

  return (
    <Card>
      <TextButton onClick={onBack}>
        <BackwardIcon /> Zurück zu allen Matches
      </TextButton>
      <Content>
        <Stack gap="16px">
          <MatchSummary details={details} />
          <Divider />

          <MatchWaypoints details={details} />

          <Divider />

          <MatchAttributes details={details} />

          <Divider />

          {description && (
            <>
              <MatchComment>{description}</MatchComment>
              <Divider />
            </>
          )}

          <Row justify="spread">
            <UserInfo user={user} info={company.name}></UserInfo>
            {/* <Rating rating={0} maxRating={5} /> */}
          </Row>

          <Row justify="stretch">
            <Btn color="success" size="medium" onClick={accept}>
              <RoundedCheckedIcon color="white" />
              {interested ? 'Chat' : 'Interessiert'}
            </Btn>

            <Btn color="neutral" size="medium" onClick={reject}>
              <RoundedCancelIcon color="white" />
              Nicht Interessiert
            </Btn>
          </Row>
        </Stack>
      </Content>
    </Card>
  );
}
