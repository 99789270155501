import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Paragraph = styled.div`
  white-space: break-spaces;
  max-width: 90%;
  margin: 16px;
`;

export const Imprint = () => {
  return (
    <Root>
      <h1 id="impressum">Impressum</h1>

      <h2 id="angabengem5tmg">Angaben gemäß § 5 TMG</h2>

      <h2 id="verantwortlichfrdenwebauftrittist">
        Verantwortlich für den Webauftritt ist:
      </h2>

      <Paragraph>Eligius GmbH Bremer Straße 18 21073 Hamburg</Paragraph>

      <Paragraph>
        Handelsregister: HRB 169031 Registergericht: Amtsgericht Hamburg
      </Paragraph>

      <Paragraph>
        <strong>Vertreten durch:</strong>
        Nils Löwe
      </Paragraph>

      <h2 id="kontakt">Kontakt</h2>

      <Paragraph>Telefon: [Telefonnummer] E-Mail: moin@cargofaces.de</Paragraph>

      <h2 id="rechtshinweise">Rechtshinweise</h2>

      <Paragraph>
        Die Informationen auf unseren Webseiten werden ständig aktualisiert und
        geprüft. Trotz aller Sorgfalt können sich aber zwischenzeitlich
        Informationen verändert haben. Eine Haftung oder Garantie für die
        Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten
        Informationen kann daher nicht übernommen werden.
      </Paragraph>

      <h2 id="haftung">Haftung</h2>

      <Paragraph>
        Eligius GmbH übernimmt keine Haftung oder Garantie für die Aktualität,
        Korrektheit, Vollständigkeit der zur Verfügung gestellten Informationen,
        sowie keine Gewähr für Verluste, die durch die Verwendung oder
        Verteilung verursacht werden oder mit der Verteilung oder Nutzung dieser
        Informationen in Zusammenhang stehen. Die Eligius GmbH behält sich das
        Recht vor, die zur Verfügung gestellten Informationen jederzeit zu
        ändern oder zu ergänzen.
      </Paragraph>

      <Paragraph>
        Texte, Bilder, Grafiken, Sounds, Animationen und Videos, sowie deren
        Anordnung auf den Eligius GmbH Webseiten unterliegen dem Schutz des
        Urheberrechtes und anderen Schutzgesetzen.
      </Paragraph>

      <Paragraph>
        Der Inhalt dieser Webseiten darf nicht zu kommerziellen Zwecken kopiert,
        verbreitet, verändert oder Dritten zugänglich gemacht werden. Alle auf
        den Webseiten gezeigten, genannten und mit diesen Seiten in Verbindung
        stehenden Marken- und Warenzeichen unterliegen uneingeschränkt den
        Bestimmungen des jeweils geltenden Kennzeichenrechtes und den
        Besitzrechten der jeweiligen Eigentümer.
      </Paragraph>

      <h2 id="redaktionellverantwortlich">Redaktionell verantwortlich</h2>

      <Paragraph>Eligius GmbH</Paragraph>

      <h2 id="verbraucherstreitbeilegunguniversalschlichtungsstelle">
        Verbraucherstreitbeilegung/Universalschlichtungsstelle
      </h2>

      <Paragraph>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </Paragraph>
    </Root>
  );
};
