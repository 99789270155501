import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Chat } from '../../lib/types/chat';
import { HR } from '../common/HR';
import { Switch } from '../common/Switch';
import { ChatListItem } from './ChatListItem';

const ChatListContainer = styled.div`
  flex: 1;
  position: relative;
  flex-direction: column;
  background: var(--white);
`;

const Outer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  background: var(--white);
`;

const Divider = styled(HR)`
  margin: 10px;
`;

const ChatListScrollView = styled.div`
  position: absolute;
  inset: 0;
  overflow-y: auto;
`;

type Props = {
  chats: Chat[] | undefined;
  activeChatId?: number;
  setLoading: (loading: boolean) => void;
  refreshChats: () => void;
};

export function ChatList({
  chats,
  activeChatId,
  refreshChats,
  setLoading,
}: Props) {
  const [onlyUnread, setOnlyUnread] = useState(false);

  const pinnedChats = useMemo(
    () =>
      chats
        ?.filter((chat) => chat.pinned)
        .map((chat) => (
          <ChatListItem
            key={chat.chat_id}
            chat={chat}
            refreshChats={refreshChats}
            setLoading={setLoading}
          />
        )),
    [chats]
  );

  const unPinnedChats = useMemo(
    () =>
      chats
        ?.filter((chat) => !chat.pinned)
        .map((chat) => (
          <ChatListItem
            key={chat.chat_id}
            chat={chat}
            active={chat.chat_id === activeChatId}
            refreshChats={refreshChats}
            setLoading={setLoading}
          />
        )),
    [chats]
  );

  const unreadChats = useMemo(
    () =>
      chats
        ?.filter((chat) => {
          return (
            chat.number_of_unread_messages || chat.chat_id === activeChatId
          );
        })
        .map((chat) => (
          <ChatListItem
            key={chat.chat_id}
            chat={chat}
            refreshChats={refreshChats}
            setLoading={setLoading}
          />
        )),
    [chats]
  );

  const divider =
    pinnedChats?.length && unPinnedChats?.length ? <Divider /> : null;

  return (
    <Outer>
      <Switch
        isChecked={onlyUnread}
        handleToggle={() => setOnlyUnread(!onlyUnread)}
      />
      <ChatListContainer>
        <ChatListScrollView>
          {onlyUnread ? (
            unreadChats
          ) : (
            <>
              {pinnedChats}
              {divider}
              {unPinnedChats}
            </>
          )}
        </ChatListScrollView>
      </ChatListContainer>
    </Outer>
  );
}
