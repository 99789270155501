import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { TotalUnreadBadge } from '../../components/chat/TotalUnreadBadge';
import { Avatar } from '../../components/common/Avatar';
import { Btn, BtnLink } from '../../components/common/Btn';
import { HR } from '../../components/common/HR';
import {
  AddIcon,
  BookIcon,
  BriefCaseIcon,
  BurgerIcon,
  ChatBubbleOutlineIcon,
  ContactsIcon,
  DashboardIcon,
  QuestionMarkIcon,
  TruckIcon,
} from '../../components/common/Icons';
import {
  Content,
  DarkBox,
  Header,
  ItemLabel,
  Logo,
  Nav,
  NavItem,
  QuestionMarkBox,
  SubMenu,
  SubMenuItem,
  WhiteButton,
  Wrapper,
} from '../../components/common/SideBar';
import { Body5 } from '../../components/common/typography/Body';
import { H6 } from '../../components/common/typography/Headings';
import { JoinCompanyDialog } from '../../components/company/createCompany/JoinCompanyDialog';
import { PendingApprovalsBadge } from '../../components/company/PendingApprovalsBadge';
import { PendingConnectionsBadge } from '../../components/connections/PendingConnectionsBadge';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { MatchCountBadge } from '../../components/matching/MatchCountBadge';
import { CreateOfferDialog } from '../../components/offer/CreateOfferDialog';
import { ManagePresetsDialog } from '../../components/presets/ManagePresetsDialog';
import { useUser } from '../../components/UserState';
import { ContactUsModal } from './ContactUsModal';

type FooterProps = {
  openModal: () => void;
};

const MenuFooter = ({ openModal }: FooterProps) => {
  return (
    <DarkBox>
      <QuestionMarkBox>
        <Btn size="tiny" appearance="transparent" onClick={openModal}>
          <QuestionMarkIcon />
        </Btn>
      </QuestionMarkBox>
      <div>
        <H6>Brauchst du Hilfe?</H6>
        <Body5>Wir sind für dich da.</Body5>
      </div>
      <WhiteButton onClick={openModal}>Kontakt</WhiteButton>
    </DarkBox>
  );
};

export function SideBar() {
  const {
    data: user,
    companyProfile,
    isAdmin,
    isDispatcher,
    hasPendingEmployeeRequests,
  } = useUser();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);

  return (
    <Wrapper className={collapsed ? 'collapsed' : ''}>
      {openEmailModal && (
        <ContactUsModal close={() => setOpenEmailModal(!openEmailModal)} />
      )}
      <Header>
        <Logo to="/" />
        <Btn
          size="tiny"
          onClick={() => setCollapsed(!collapsed)}
          appearance="transparent"
          color="neutral"
        >
          <BurgerIcon />
        </Btn>
      </Header>
      <Content>
        {!collapsed ? (
          <Stack>
            {user.selected_company_name ? (
              <BtnLink
                size="tiny"
                appearance="transparent"
                color="neutral"
                to={`company/${user.selected_company_id}/profile`}
              >
                <Row style={{ width: '100%' }} justify="left" noWrap={true}>
                  <Avatar
                    name={user.selected_company_name}
                    image={companyProfile?.logo}
                    size="40px"
                  />
                  <Body5 color="var(--white)">
                    {user.selected_company_name}
                  </Body5>
                </Row>
              </BtnLink>
            ) : (
              <>
                {!hasPendingEmployeeRequests ? (
                  <JoinCompanyDialog>
                    <WhiteButton size="small">Unternehmen finden</WhiteButton>
                  </JoinCompanyDialog>
                ) : (
                  <WhiteButton
                    size="small"
                    onClick={() => navigate('/my-requests')}
                  >
                    Meine Anfragen
                  </WhiteButton>
                )}
              </>
            )}
          </Stack>
        ) : user.selected_company_name ? (
          <Row justify="center">
            <Avatar
              name={user.selected_company_name}
              image={companyProfile?.logo}
              size="40px"
            />
          </Row>
        ) : (
          <>
            {!hasPendingEmployeeRequests ? (
              <JoinCompanyDialog>
                <WhiteButton size="small">
                  <AddIcon />
                </WhiteButton>
              </JoinCompanyDialog>
            ) : (
              <WhiteButton
                size="small"
                onClick={() => navigate('/my-requests')}
              >
                <AddIcon />
              </WhiteButton>
            )}
          </>
        )}
        <HR gap="16px" />
        <Nav>
          <NavItem to="/dashboard">
            <DashboardIcon />
            <ItemLabel>Dashboard</ItemLabel>
          </NavItem>

          <NavItem to="/connections">
            <ContactsIcon />
            <ItemLabel>Kontakte</ItemLabel>
            <PendingConnectionsBadge />
          </NavItem>

          <NavItem to="/chat">
            <ChatBubbleOutlineIcon />
            <ItemLabel>Nachrichten</ItemLabel>
            <TotalUnreadBadge />
          </NavItem>

          <NavItem to="/address">
            <BookIcon />
            <ItemLabel>Firmenverteiler</ItemLabel>
          </NavItem>

          {isDispatcher &&
            (collapsed ? (
              <NavItem to="/matching">
                <TruckIcon />
                <MatchCountBadge />
              </NavItem>
            ) : (
              <SubMenu
                icon={<TruckIcon />}
                title={
                  <Row noWrap gap="8px">
                    ContainerMatch <MatchCountBadge />
                  </Row>
                }
              >
                <SubMenuItem as={Link} to="/matching">
                  Meine Angebote
                </SubMenuItem>
                <ManagePresetsDialog>
                  <SubMenuItem>Meine Vorlagen</SubMenuItem>
                </ManagePresetsDialog>
                <CreateOfferDialog>
                  <SubMenuItem>Neues Angebot</SubMenuItem>
                </CreateOfferDialog>
              </SubMenu>
            ))}
          {isAdmin &&
            (collapsed ? (
              <NavItem to={`/company/${user.selected_company_id}/profile`}>
                <BriefCaseIcon />
                <PendingApprovalsBadge />
              </NavItem>
            ) : (
              <SubMenu
                icon={<BriefCaseIcon />}
                title="Unternehmen"
                Badge={PendingApprovalsBadge}
              >
                <SubMenuItem
                  as={Link}
                  to={`/company/${user.selected_company_id}/members`}
                >
                  Mitglieder verwalten
                  <PendingApprovalsBadge />
                </SubMenuItem>
                <SubMenuItem
                  as={Link}
                  to={`/company/${user.selected_company_id}/profile`}
                >
                  Profil bearbeiten
                </SubMenuItem>
              </SubMenu>
            ))}
        </Nav>
        <MenuFooter openModal={() => setOpenEmailModal(!openEmailModal)} />
      </Content>
    </Wrapper>
  );
}
