import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Body } from '../common/typography';

const Root = styled.label`
  display: flex;
  font-family: inherit;
  user-select: none;
  color: var(--primary500);
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

const Circle = styled.div`
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 12px;
  border: 7px solid transparent;
  outline: 1px solid var(--neutral500);
  ${Input}:focus-visible + & {
    /* outline: 2px solid var(--tertiary600); */
    box-shadow: 0 0 0px 3px var(--tertiary700);
  }
  ${Input}:checked + & {
    border-color: var(--primary500);
    outline-color: var(--primary500);
  }
`;

const Label = styled(Body.Body5)`
  align-self: center;
  flex-grow: 1;
`;

type Props = JSX.IntrinsicElements['input'];

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, ref) => (
    <Root>
      <Input type="radio" {...props} ref={ref} />
      <Circle />
      <Label>{children}</Label>
    </Root>
  )
);
