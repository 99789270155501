import React from 'react';

import { ConnectedContact } from '../../lib/types/connections';
import { Person } from './Person';

type Props = {
  connected: ConnectedContact;
};
export function ContactItem({ connected }: Props) {
  const { contact, companies } = connected;
  const company = companies.find((c) => c.id === contact.selected_company_id);
  return (
    <Person
      id={contact.id}
      name={`${contact.first_name} ${contact.last_name}`}
      info={company?.name}
      image={contact.picture}
    />
  );
}
