import * as Dialog from '@radix-ui/react-dialog';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { Row } from '../layout/Row';
import { Btn } from './Btn';
import { CloseIcon } from './Icons';
import { h4 } from './typography/Headings';

const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: rgba(15, 29, 48, 0.4);
  backdrop-filter: blur(5px);
`;

const StyledContent = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 90vw;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.25),
    0px 8px 16px -1px rgba(0, 0, 0, 0.25);
`;

const HeaderRow = styled(Row)`
  padding: 16px 20px 8px 40px;
`;

const Title = styled.div`
  ${h4};
  margin-right: auto;
`;

type HeaderProps = {
  icon?: string | ReactElement;
  title: string;
  children?: ReactNode;
};
export function Header({ icon, title, children }: HeaderProps) {
  return (
    <HeaderRow gap="16px">
      {icon && typeof icon === 'string' ? (
        <img src={icon} width="32" height="32" />
      ) : (
        icon
      )}
      <Title>{title}</Title>
      {children}
      <Dialog.Close asChild>
        <Btn appearance="transparent">
          <CloseIcon />
        </Btn>
      </Dialog.Close>
    </HeaderRow>
  );
}

export const Body = styled.div<{ height?: string }>`
  flex: ${(p) => p.height ?? 'auto'} 1 1;
  padding: 40px 40px 20px 40px;
  margin-bottom: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

type RootProps = {
  children: ReactNode;
  trigger?: ReactElement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};
export function Root({ trigger, children, ...rootProps }: RootProps) {
  return (
    <Dialog.Root {...rootProps}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Overlay />
        <div onClick={(ev) => ev.stopPropagation()}>
          <StyledContent>{children}</StyledContent>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export const Close = Dialog.Close;
