import React from 'react';
import styled from 'styled-components';

import { Headings } from '../common/typography';
import { SubtitleMedium } from '../common/typography/Special';
import EmptyChatLogo from './EmptyChatLogo.svg';

const EmptyChatBox = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
`;
const EmptyChatImage = styled.div`
  background-image: url(${EmptyChatLogo});
  width: 300px;
  height: 300px;
`;
const EmptyChatTitle = styled.div`
  ${Headings.h5}
  color: var(--primary500)
`;
const EmptyChatText = styled.div`
  ${SubtitleMedium}
  color: var(--grey500)
`;
export const EmptyChat = () => {
  return (
    <EmptyChatBox>
      <EmptyChatImage />
      <EmptyChatTitle>Keine Konversation</EmptyChatTitle>
      <EmptyChatText>
        Wähle eine Konversation aus oder geh zur Liste deiner Kontakte, um einen
        neuen Chat zu beginnen.
      </EmptyChatText>
    </EmptyChatBox>
  );
};
