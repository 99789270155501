import styled from 'styled-components';

import { Body } from '../common/typography';
import arrow from './SelectArrow.svg';

type Props = {
  /** No background, border only */
  ghost?: boolean;
  compact?: boolean;
};
export const Select = styled.select<Props>`
  ${Body.body4}
  padding: ${(p) => (p.compact ? '9px 36px 8px 18px' : '10px 36px 10px 12px')};
  color: var(--primary500);
  background: url(${arrow}) 100% / 24px no-repeat
    ${(p) => (p.ghost ? 'transparent' : 'var(--input-background)')};
  border: 1px solid var(--grey300);
  border-radius: 9.5px;
  cursor: pointer;
  appearance: none;
  ::-ms-expand {
    display: none; /* remove default arrow in IE 10 + 11 */
  }
  :focus,
  :hover {
    border-color: var(--primary500);
  }
  :focus {
    box-shadow: 0px 0px 0px 4px rgba(29, 171, 242, 0.2);
  }
`;
