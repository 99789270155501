import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { debounce } from '../../lib/debounce';
import { useKeyboard } from '../../lib/useKeyboard';
import { Btn } from './Btn';
import { BackwardIcon, ForwardIcon } from './Icons';

/**
 * for the padding-top aspect-ratio fallback (older browsers) to work, we need the aspect ratio as a number.
 */
const resolveDivision = (value: string | number): number => {
  if (typeof value === 'number') {
    return value;
  }
  if (/^\s*\d+(\.\d+)?\s*\/\s*\d+(\.\d+)?\s*$/.test(value)) {
    const [a, b] = value.split(/\s*\/\s*/);
    return +a / +b;
  }
  // if that string is not in the required format, return something nice anyway
  return 1;
};

const Container = styled.section<{
  aspectRatio?: number | string;
  mobileAspectRatio?: number | string;
  mediumAspectRatio?: number | string;
}>`
  flex: 1;
  position: relative;
  --aspect-ratio: ${(p) =>
    p.aspectRatio ? resolveDivision(p.aspectRatio) : '2'};

  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 720px) {
    --aspect-ratio: ${(p) =>
      p.mediumAspectRatio ? resolveDivision(p.mediumAspectRatio) : '1.333'};
  }

  @media screen and (max-width: 400px) {
    --aspect-ratio: ${(p) =>
      p.mobileAspectRatio ? resolveDivision(p.mobileAspectRatio) : '1'};
  }

  aspect-ratio: var(--aspect-ratio);
  @supports not (aspect-ratio: 1) {
    padding-top: calc(100% * 1 / var(--aspect-ratio));
  }

  .left,
  .right {
    padding: 10px;
  }

  * {
    box-sizing: border-box;
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: 0px;
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  * {
    -ms-overflow-style: none;
  }

  ol,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  > .left {
    position: absolute;
    margin-top: -28px;
    margin-left: -28px;
    top: 50%;
    left: 0%;
  }

  > .right {
    position: absolute;
    margin-top: -24px;
    margin-right: -24px;
    top: 50%;
    right: 0%;
  }

  @media screen and (max-width: 719px) {
    > .left,
    > .right {
      display: none;
    }
  }
`;

const Viewport = styled.ol`
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  left: 0.5rem;
  display: flex;
  overflow-x: auto;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
`;

const Snapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
  z-index: -1;
`;

const StyledSlide = styled.li`
  position: relative;
  flex: 0 0 100%;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline: none;
  }
  & img {
    max-width: 100%;
  }
`;

const CarouselNavigation = styled.aside`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;

  * {
    display: inline-block;
  }
`;

const Dots = styled.ol`
  li {
    a {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      background-color: var(--tertiary200);
      background-clip: content-box;
      border: 0.5rem solid transparent;
      border-radius: 50%;
      font-size: 0;
      transition: transform 0.1s;

      &.selected {
        background-color: var(--tertiary500);
      }
    }
  }
`;

type SlideProps = {
  id: string;
  children?: React.ReactNode;
};
export const Slide = ({ id, children }: SlideProps) => (
  <StyledSlide id={id} tabIndex={-1} className="slide">
    <Snapper />
    {children}
  </StyledSlide>
);

type Props = {
  labelledBy: string;
  aspectRatio?: number | string;
  mediumAspectRatio?: number | string;
  mobileAspectRatio?: number | string;
  children?: React.ReactNode;
};
export const Carousel = ({
  labelledBy,
  children,
  aspectRatio = '16 / 9',
  mediumAspectRatio = '6 / 5',
  mobileAspectRatio = '4 / 5',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const childrenArray = children instanceof Array ? children : [children];
  const left = (e?: KeyboardEvent) => {
    e?.preventDefault();
    if (currentSlide > 0) {
      const newSlide = currentSlide - 1;
      location.hash = `#${childrenArray[newSlide].props.id}`;
    }
  };
  const right = (e?: KeyboardEvent) => {
    e?.preventDefault();
    if (currentSlide < childrenArray.length - 1) {
      const newSlide = currentSlide + 1;
      location.hash = `#${childrenArray[newSlide].props.id}`;
    }
  };
  useKeyboard({
    ArrowLeft: left,
    ArrowRight: right,
  });

  useEffect(() => {
    // reset the URL hash when the Carousel gets unmounted.
    return () => {
      location.hash = '';
    };
  }, []);

  useEffect(() => {
    let viewport: HTMLDivElement | null = null;
    const onScroll = debounce(() => {
      const viewport = document.querySelector('.viewport');
      const slide = document.querySelector('.slide');
      if (viewport && slide instanceof HTMLElement) {
        setCurrentSlide((viewport.scrollLeft / slide.clientWidth) | 0);
      }
    }, 50);

    if (ref && ref.current) {
      viewport = ref.current.querySelector('.viewport');
      viewport?.addEventListener('scroll', onScroll, false);
    }
    return () => {
      viewport?.removeEventListener('scroll', onScroll, false);
    };
  }, [ref]);

  return (
    <Container
      aria-labelledby={labelledBy}
      aspectRatio={aspectRatio}
      mobileAspectRatio={mobileAspectRatio}
      mediumAspectRatio={mediumAspectRatio}
      ref={ref}
    >
      <Viewport role="list" className="viewport">
        {children}
      </Viewport>
      {currentSlide > 0 && (
        <Btn round size="medium" className="left" onClick={() => left()}>
          <BackwardIcon />
        </Btn>
      )}
      {currentSlide < childrenArray.length - 1 && (
        <Btn round className="right" size="medium" onClick={() => right()}>
          <ForwardIcon />
        </Btn>
      )}
      <CarouselNavigation>
        <Dots role="list">
          {childrenArray.map((item, idx) => (
            <li key={item.props.id}>
              <a
                href={`#${item.props.id}`}
                className={idx === currentSlide ? 'selected' : undefined}
                onClick={() => setCurrentSlide(idx)}
              >
                Go to Slide {idx}
              </a>
            </li>
          ))}
        </Dots>
      </CarouselNavigation>
    </Container>
  );
};
