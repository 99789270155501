import React from 'react';

import { EmptyState } from '../../empty/EmptyState';
import pending from '../../empty/pending.svg';

export function EmptyPending() {
  return (
    <EmptyState
      image={pending}
      imageStyle={{ height: 339 }}
      title="Keine ausstehenden Anfragen"
    >
      Wenn Sie eine Verbindung zu einer Person herstellen möchten, wird dies
      hier angezeigt.
    </EmptyState>
  );
}
