import React from 'react';

import { Card, CardHeader } from '../../common/Card';
import { Section } from '../../common/Section';

export function AboutCargo() {
  return (
    <Card>
      <CardHeader>Über die App</CardHeader>
      <Section title="Version">
        {import.meta.env.VITE_APP_VERSION} ({import.meta.env.VITE_APP_DATE})
      </Section>
    </Card>
  );
}
