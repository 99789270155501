import 'emoji-mart/css/emoji-mart.css';

import { emojiIndex, Picker } from 'emoji-mart';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useClickOutside } from '../../lib/useClickOutside';
import { MessageInput } from '../chat/MessageInput';
import { Btn } from '../common/Btn';
import { SendIcon, SmileyIcon } from '../common/Icons';
import { H6 } from '../common/typography/Headings';
import { UserAvatar } from '../common/UserAvatar';
import { Row } from '../layout/Row';
import { useUserData } from '../UserState';
import { Card } from './Card';
import { PictureButton } from './PictureButton';

const Root = styled(Card)`
  color: var(--neutral700);
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 10px 0;
`;

const Images = styled.div`
  max-height: 100px;
`;

const Image = styled.img`
  max-width: 100px;
  max-height: 100%;
  border-radius: 10px;
  margin-left: 10px;
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  & .emoji-mart {
    font-family: inherit;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
  & .emoji-mart-title-label {
    display: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--grey200);
  padding-top: 4px;
`;

type Props = {
  onSend: (message: string, image?: File) => any;
};
export function MessageArea({ onSend }: Props) {
  const user = useUserData();
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target?.result as string);
      };
      reader.onerror = () => {
        setFile(undefined);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(undefined);
    }
  }, [file]);

  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useClickOutside<HTMLDivElement>(() => {
    if (showPicker) setShowPicker(false);
  });
  const send = () => {
    const text = message.trim();
    if (text.length || file) {
      onSend(text, file);
      setMessage('');
      setFile(undefined);
    }
  };

  // Ref to focus the textarea when container is clicked
  const ref = useRef<HTMLTextAreaElement>(null);

  const previewFile = (ev: ChangeEvent<HTMLInputElement>) => {
    const { files } = ev.target;
    setFile(files?.[0]);
  };

  return (
    <Root
      onClick={() => {
        if (!showPicker) ref.current?.focus();
      }}
    >
      <H6 color="var(--grey800)">Beitrag erstellen</H6>
      <Wrapper>
        <UserAvatar size="40px" user={user} />
        <MessageInput
          ref={ref}
          value={message}
          onKeyPress={(ev) => {
            // Send on enter, shift-enter for new lines
            if (ev.code === 'Enter' && !ev.shiftKey) {
              ev.preventDefault();
              send();
            }
          }}
          placeholder="Was gibt's neues?"
          onChange={(ev) => {
            const text = ev.target.value;
            setMessage(
              text.replaceAll(/:(\S+):/g, (m, name) => {
                const emojis: any = emojiIndex.search(name);
                return emojis.length ? emojis[0].native : m;
              })
            );
          }}
        />
        {image && (
          <Images>
            <Image src={image} />
          </Images>
        )}
        {showPicker && (
          <PickerContainer ref={pickerRef}>
            <Picker
              native
              emoji=""
              onSelect={(emoji) => {
                if ('native' in emoji) {
                  setMessage((m) => m + emoji.native);
                }
              }}
            />
          </PickerContainer>
        )}
      </Wrapper>
      <Buttons>
        <Row gap="0">
          <PictureButton onChange={previewFile} />
          <Btn
            appearance="transparent"
            size="small"
            type="button"
            onClick={() => setShowPicker(true)}
          >
            <SmileyIcon />
          </Btn>
        </Row>
        <Btn appearance="transparent" size="small" type="button" onClick={send}>
          <SendIcon />
        </Btn>
      </Buttons>
    </Root>
  );
}
