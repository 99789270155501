import styled, { css } from 'styled-components';

import { body3, body4, body5 } from './typography/Body';

export const Dialog = styled.div<{ nested?: boolean }>`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${(p) =>
    !p.nested &&
    css`
      background: rgba(15, 29, 48, 0.4);
      backdrop-filter: blur(5px);
    `}
  overflow: scroll;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const DialogFrame = styled.div<{ width?: string }>`
  z-index: 1;
  flex-basis: ${(p) => p.width || '586px'};
  margin: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.25),
    0px 8px 16px -1px rgba(0, 0, 0, 0.25);
`;

export const DialogHeader = styled.div`
  ${body3}
  color: var(--primary500);
  background: var(--white);
  padding: 16px 36px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
`;

export const DialogContent = styled.div`
  background: var(--neutral100);
  padding: 16px 36px 36px 36px;
  border-radius: 0 0 8px 8px;
`;

export const Title = styled.div`
  ${body4}
  color: var(--primary500);
`;

export const ModalText = styled.div`
  ${body5}
  color: var(--neutral600);
  margin: 8px 0 24px 0;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background: inherit;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
`;
