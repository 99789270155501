import React from 'react';

import { useApiCall } from '../../lib/useApiCall';
import { LoadingCircle } from '../common/LoadingCircle';
import { CapacityPresetListItem, CargoPresetListItem } from './PresetListItem';

export function Presets() {
  const { data, invalidate } = useApiCall('getPresets');
  if (!data) return <LoadingCircle />;
  return (
    <div>
      {data.capacity_presets.map((p, i) => (
        <CapacityPresetListItem key={i} preset={p} invalidate={invalidate} />
      ))}
      {data.cargo_presets.map((p, i) => (
        <CargoPresetListItem key={i} preset={p} invalidate={invalidate} />
      ))}
    </div>
  );
}
