import React from 'react';
import styled from 'styled-components';

import { MatchDetails } from '../../../lib/types/ui';
import { CountryFlag } from '../../common/CountryFlag';
import { Body5 } from '../../common/typography/Body';
import { Row } from '../../layout/Row';
import destinationIcon from '../icons/destination.svg';
import loadUnloadIcon from '../icons/load_unload.svg';
import locationIcon from '../icons/location.svg';

export const Waypoints = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    position: relative;
    margin-top: 24px;
    ::before {
      content: '';
      display: block;
      position: absolute;
      left: 11px;
      top: -22px;
      width: 1px;
      height: 20px;
      background: var(--grey300);
    }
  }
`;

export type Props = {
  details: MatchDetails;
};

export function MatchWaypoints({ details }: Props) {
  const {
    zip_location,
    country_location,
    place_location,
    zip_load_unload,
    country_load_unload,
    place_load_unload,
    zip_destination,
    country_destination,
    place_destination,
  } = details;
  return (
    <Waypoints>
      {zip_location && (
        <Row>
          <img src={locationIcon} width="24" height="24" />
          <Body5>{zip_location}</Body5>
          <Body5 color="var(--grey400)">{place_location}</Body5>
          <CountryFlag country={country_location} />
        </Row>
      )}
      {zip_load_unload && country_load_unload && (
        <Row>
          <img src={loadUnloadIcon} width="24" height="24" />
          <Body5>{zip_load_unload}</Body5>
          <Body5 color="var(--grey400)">{place_load_unload}</Body5>
          <CountryFlag country={country_load_unload} />
        </Row>
      )}
      {zip_destination && country_destination && (
        <Row>
          <img src={destinationIcon} width="24" height="24" />
          <Body5>{zip_destination}</Body5>
          <Body5 color="var(--grey400)">{place_destination}</Body5>
          <CountryFlag country={country_destination} />
        </Row>
      )}
    </Waypoints>
  );
}
