import React, { useMemo } from 'react';

import {
  capacityOfferInputFromForm,
  capacityOfferToForm,
} from '../../lib/convert';
import { CapacityOffer } from '../../lib/types/offers';
import { CapacityFormValues } from '../../lib/types/ui';
import * as Dialog from '../common/Dialog';
import { useApi } from '../UserState';
import { CapacityDialog } from './CapacityDialog';

type Props = {
  offer: CapacityOffer;
  onClose: (modified: boolean) => void;
};

export const EditCapacityDialog = ({ offer, onClose }: Props) => {
  const api = useApi();

  const values = useMemo(() => capacityOfferToForm(offer), [offer]);

  const onSave = async (values: CapacityFormValues) => {
    await api.updateCapacity(
      offer.capacity.id,
      capacityOfferInputFromForm(values)
    );
    // Close on next tick to give the CapacityDialog the chance to change its state.
    setTimeout(() => onClose(true), 0);
  };

  return (
    <CapacityDialog
      values={values}
      onSave={onSave}
      onCancel={() => onClose(false)}
      header={<Dialog.Header title="Leerchassis bearbeiten" />}
    />
  );
};
