import React from 'react';

import { MatchDetails } from '../../lib/types/ui';
import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { ForwardIcon } from '../common/Icons';
import { Stack } from '../layout/Stack';
import { Divider } from '../matching/cards/Divider';
import { MatchAttributes } from '../matching/cards/MatchAttributes';
import { MatchComment } from '../matching/cards/MatchComment';
import { MatchSummary } from '../matching/cards/MatchSummary';
import { MatchWaypoints } from '../matching/cards/MatchWaypoints';
import { UserInfo } from '../matching/cards/UserInfo';

type Props = {
  details: MatchDetails;
};

export function MatchDetailModal({ details }: Props) {
  const trigger = (
    <Btn appearance="transparent" color="tertiary" size="small">
      Match Details anzeigen <ForwardIcon />
    </Btn>
  );

  return (
    <Dialog.Root trigger={trigger}>
      <Dialog.Header title="Details zum Match"></Dialog.Header>
      <Dialog.Body>
        <Stack gap="16px">
          <MatchSummary details={details} />
          <Divider />

          <MatchWaypoints details={details} />

          <Divider />

          <MatchAttributes details={details} />

          <Divider />
          {details.description && (
            <>
              <MatchComment>{details.description}</MatchComment>
              <Divider />
            </>
          )}
          <UserInfo user={details.user} info={details.company.name}></UserInfo>
        </Stack>
      </Dialog.Body>
    </Dialog.Root>
  );
}
