import React, { ReactNode } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

type Props<T> = UseFormReturn<T> & {
  children: ReactNode;
  onSubmit: SubmitHandler<T>;
};
export function Form<T>({ children, onSubmit, ...form }: Props<T>) {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
}
