import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const IconLink = styled(Link)<{ margin?: string }>`
  color: var(--primary500);
  text-decoration: none;
  padding: 20px;
  margin: ${(p) => p.margin ?? '0'};

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  :visited {
    color: var(--primary500);
  }

  :hover {
    color: var(--primary400);
  }
  :disabled {
    color: var(--neutral500);
  }
`;
