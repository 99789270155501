import styled, { css } from 'styled-components';

const manrope = css`
  font-family: Manrope, sans-serif;
  font-style: normal;
`;

export const display1 = css`
  ${manrope}
  font-weight: 600;
  font-size: 72px;
  line-height: 98px;
  letter-spacing: -1.5px;
`;

export const display2 = css`
  ${manrope}
  font-weight: 600;
  font-size: 56px;
  line-height: 76px;
  letter-spacing: -0.5px;
`;

export const h1 = css`
  ${manrope}
  font-weight: 600;
  font-size: 48px;
  line-height: 66px;
`;

export const h2 = css`
  ${manrope}
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 0.25px;
`;

export const h3 = css`
  ${manrope}
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.25px;
`;

export const h4 = css`
  ${manrope}
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
`;

export const h5 = css`
  ${manrope}
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.15px;
`;

export const h6 = css`
  ${manrope}
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

export const h7 = css`
  ${manrope}
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15px;
`;

export const Display1 = styled.h1<{
  color?: string;
}>`
  ${display1}
  color: ${(p) => p.color ?? ''};
`;
export const Display2 = styled.h2<{
  color?: string;
}>`
  ${display2}
  color: ${(p) => p.color ?? ''};
`;
export const H1 = styled.h1<{
  color?: string;
}>`
  ${h1}
  color: ${(p) => p.color ?? ''};
`;
export const H2 = styled.h2<{
  color?: string;
}>`
  ${h2}
  color: ${(p) => p.color ?? ''};
`;
export const H3 = styled.h3<{
  color?: string;
}>`
  ${h3}
  color: ${(p) => p.color ?? ''};
`;
export const H4 = styled.h4<{
  color?: string;
}>`
  ${h4}
  color: ${(p) => p.color ?? ''};
`;
export const H5 = styled.h5<{
  color?: string;
}>`
  ${h5}
  color: ${(p) => p.color ?? ''};
`;
export const H6 = styled.h6<{
  color?: string;
}>`
  ${h6}
  color: ${(p) => p.color ?? ''};
`;

export const H7 = styled.h6<{
  color?: string;
}>`
  ${h7}
  color: ${(p) => p.color ?? ''};
`;
