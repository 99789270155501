import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { AppScreen } from '../../components/common/AppScreen';
import { Btn } from '../../components/common/Btn';
import { Card, CardHeader } from '../../components/common/Card';
import {
  ChatBubbleIcon,
  CheckedIcon,
  EmailIcon,
  LocationIcon,
} from '../../components/common/Icons';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { StatusButton } from '../../components/common/StatusButton';
import { TextArea } from '../../components/form/TextArea';
import { SideBySide } from '../../components/layout/SideBySide';
import { Stack } from '../../components/layout/Stack';
import { Person, PersonStack } from '../../components/profile/Person';
import {
  ProfileHeader,
  ProfileHeaderInfo,
} from '../../components/profile/ProfileHeader';
import { useApi, useUserData } from '../../components/UserState';
import { int } from '../../lib/convert';
import { UserData } from '../../lib/publicApi';
import { useApiCall } from '../../lib/useApiCall';

export function UserProfile() {
  const api = useApi();
  const navigate = useNavigate();
  const params = useParams();
  const id = int(params.id);
  const user = useUserData();
  const { data, invalidate } = useApiCall('getUserProfile', id);
  const [loading, setLoading] = useState(false);
  const currentUser = user.id === id;
  const [newNote, setNote] = useState('');

  return !data || loading ? (
    <LoadingCircle />
  ) : (
    <AppScreen title="Benutzerprofil">
      <Stack gap="24px">
        <Card>
          <ProfileHeader
            title={`${data.user.first_name} ${data.user.last_name}`}
            subTitle={
              data.company_member_at.find(
                (company) => company[0].id === data.user.selected_company_id
              )?.[0].name ?? ''
            }
            id={
              data.company_member_at.find(
                (company) => company[0].id === data.user.selected_company_id
              )?.[0].id ?? ''
            }
            image={data.user.picture}
            buttons={
              !currentUser ? (
                <>
                  {data.connection_to_user === 'accepted' && (
                    <Btn
                      size="small"
                      color="neutral"
                      onClick={async () => {
                        const res = await api.checkChats(data.user.id);
                        if (!res.chat_id) {
                          navigate(`/user/${data.user.id}/chat`);
                        } else {
                          navigate(`/chat/${res.chat_id}`);
                        }
                      }}
                    >
                      <ChatBubbleIcon />
                      Chat
                    </Btn>
                  )}
                  {data.connection_to_user === 'possible' && (
                    <StatusButton
                      variant="neutral"
                      onClick={async () => {
                        await api.connect(data.user.id);
                        //TODO stay on page, update row
                        navigate('/connections/pending');
                      }}
                    >
                      <CheckedIcon />
                      Kontakt Hinzufügen
                    </StatusButton>
                  )}
                  {data.connection_to_user === 'pending' && (
                    <StatusButton variant="neutral" disabled>
                      <CheckedIcon />
                      Ausgestellt
                    </StatusButton>
                  )}
                  {data.connection_to_user === 'accepted' && (
                    <StatusButton variant="positive" disabled>
                      <CheckedIcon />
                      Akzeptiert
                    </StatusButton>
                  )}
                </>
              ) : null
            }
          >
            {data.user.city && (
              <ProfileHeaderInfo>
                <LocationIcon />
                {data.user.city}, {data.user.country}
              </ProfileHeaderInfo>
            )}
            <ProfileHeaderInfo>
              <EmailIcon />
              {data.user.email}
            </ProfileHeaderInfo>
          </ProfileHeader>
        </Card>
        <SideBySide gap="24px">
          <Stack>
            <Card>
              <CardHeader>Kontakte</CardHeader>
              <PersonStack gap="24px">
                {data.connected_with.map(
                  ({ id, first_name, last_name, picture }: UserData) => (
                    <Person
                      id={id}
                      key={id}
                      name={`${first_name} ${last_name}`}
                      image={picture}
                    />
                  )
                )}
              </PersonStack>
            </Card>
            {!currentUser && (
              <Card>
                <Stack gap="12px">
                  <CardHeader>Notizen</CardHeader>
                  <TextArea
                    value={newNote.length ? newNote : data.note.content}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Nutze die Profilanmerkungen, um dir Notizen zu den Personen zu machen. Die Personen sehen deine Anmerkungen nicht."
                  />
                  <Btn
                    color="primary"
                    type="button"
                    onClick={async () => {
                      setLoading(true);
                      await api.editNotes(data.note.person_id, newNote);
                      invalidate();
                      setLoading(false);
                    }}
                  >
                    Notiz speichern
                  </Btn>
                </Stack>
              </Card>
            )}
          </Stack>
          <Stack>
            <Card>
              <CardHeader>Übersicht</CardHeader>
              <div>{data.user.about_me}</div>
            </Card>
          </Stack>
        </SideBySide>
      </Stack>
    </AppScreen>
  );
}
