import * as Tabs from '@radix-ui/react-tabs';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { PresetsIcon } from '../common/Icons';
import { SubNavItem } from '../common/SubNav';
import { AddPresetDialog } from './AddPresetDialog';
import { ArchivedPresets } from './ArchivedPresets';
import { CompanyPresets } from './CompanyPresets';
import { Presets } from './Presets';

const TabsRoot = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
  min-height: 0; /* required to make overflow scrolling work */
`;
const TabsList = styled(Tabs.List)`
  display: flex;
  border-bottom: 1px solid var(--grey300);
  justify-content: center;
  padding: 0 20px;
`;
type Props = {
  children?: ReactElement;
};
export function ManagePresetsDialog({ children }: Props) {
  const [key, setKey] = useState(0);
  const trigger = children ?? (
    <Btn size="small" appearance="outline">
      <PresetsIcon />
      Vorlagen
    </Btn>
  );
  return (
    <Dialog.Root trigger={trigger}>
      <TabsRoot defaultValue="tab1" orientation="horizontal">
        <Dialog.Header icon={<PresetsIcon />} title="Vorlagen">
          <AddPresetDialog onCreate={() => setKey((key) => key + 1)} />
        </Dialog.Header>
        <TabsList aria-label="tabs example">
          <Tabs.Trigger value="tab1" asChild>
            <SubNavItem as="div">Meine Vorlagen</SubNavItem>
          </Tabs.Trigger>
          <Tabs.Trigger value="tab2" asChild>
            <SubNavItem as="div">Unternehmensvorlagen</SubNavItem>
          </Tabs.Trigger>
          <Tabs.Trigger value="tab3" asChild>
            <SubNavItem as="div">Archiv</SubNavItem>
          </Tabs.Trigger>
        </TabsList>
        <Dialog.Body height="90vh">
          <Tabs.Content value="tab1">
            <Presets key={key} />
          </Tabs.Content>
          <Tabs.Content value="tab2">
            <CompanyPresets />
          </Tabs.Content>
          <Tabs.Content value="tab3">
            <ArchivedPresets />
          </Tabs.Content>
        </Dialog.Body>
      </TabsRoot>
    </Dialog.Root>
  );
}
