import * as React from 'react';

import at from './Flags/AT.svg';
import be from './Flags/BE.svg';
import ch from './Flags/CH.svg';
import de from './Flags/DE.svg';
import nl from './Flags/NL.svg';

const flags: Record<string, string> = {
  at,
  be,
  ch,
  de,
  nl,
};

type Props = {
  country: string;
  size?: string;
};

export const CountryFlag = ({ country, size = '16px' }: Props) => {
  const icon = flags[country.toLowerCase()] ?? flags['de'];
  return <img src={icon} alt={country} width={size} height={size} />;
};
