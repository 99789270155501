import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { BtnLink } from '../../components/common/Btn';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { Body4 } from '../../components/common/typography/Body';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { blockEmail } from '../../lib/publicApi';
import CheckedIcon from './CheckedIcon.svg';

const BlockConfirmed = styled.div`
  background-image: url(${CheckedIcon});
  width: 80px;
  height: 80px;
`;

export function Blocklist() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('user_id');
  const blocked_email = searchParams.get('blocked_email');

  const [confirmed, setConfirmed] = React.useState<boolean | undefined>();
  useEffect(() => {
    if (token && userId && blocked_email) {
      blockEmail(token, userId, blocked_email).then(setConfirmed);
    } else {
      setConfirmed(false);
    }
  }, []);
  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="Blockieren der E-Mail-Adresse">
              <Stack gap="24px">
                <Row justify="center">
                  {confirmed ? (
                    <BlockConfirmed />
                  ) : confirmed === undefined ? (
                    <LoadingCircle />
                  ) : null}
                </Row>
                {confirmed !== undefined && (
                  <>
                    {confirmed ? (
                      <Body4>
                        Du wirst keine E-Mails mehr über Cargofaces von diesem
                        Benutzer bekommen!
                      </Body4>
                    ) : (
                      <Body4>
                        Etwas ist schief gelaufen. Bitte verwende genau den Link
                        aus der E-Mail. Bei Problemen wende Dich bitte an
                        unseren Support:{' '}
                        <span>
                          <a href={`mailto:support@cargofaces.com`}>
                            support@cargofaces.com
                          </a>
                        </span>
                        .
                      </Body4>
                    )}
                    <BtnLink to="/signup">Bei Cargofaces mitmachen?</BtnLink>
                  </>
                )}
              </Stack>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
