import React from 'react';

import { AppScreen } from '../../components/common/AppScreen';

export function EditProfile() {
  return (
    <AppScreen title="EditProfile">
      <h1>EditProfile</h1>
    </AppScreen>
  );
}
