import 'emoji-mart/css/emoji-mart.css';

import { emojiIndex, Picker } from 'emoji-mart';
import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { useClickOutside } from '../../lib/useClickOutside';
import { Btn } from '../common/Btn';
import { AttachmentIcon, SmileyIcon } from '../common/Icons';
import { MessageInput } from './MessageInput';

const Root = styled.div`
  background: var(--white);
  color: var(--neutral700);
  border-radius: 8px;

  position: relative;
  border: 1px solid var(--neutral300);
`;

const PickerContainer = styled.div`
  position: absolute;
  bottom: 100%;
  & .emoji-mart {
    font-family: inherit;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
  & .emoji-mart-title-label {
    display: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
`;

type Props = {
  onSend: (message: string) => any;
};
export function MessageArea({ onSend }: Props) {
  const [message, setMessage] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useClickOutside<HTMLDivElement>(() => {
    if (showPicker) setShowPicker(false);
  });
  const send = () => {
    const text = message.trim();
    if (text.length) {
      onSend(text);
      setMessage('');
    }
  };

  // Ref to focus the textarea when container is clicked
  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <Root
      onClick={() => {
        if (!showPicker) ref.current?.focus();
      }}
    >
      <MessageInput
        ref={ref}
        value={message}
        onKeyPress={(ev) => {
          // Send on enter, shift-enter for new lines
          if (ev.code === 'Enter' && !ev.shiftKey) {
            ev.preventDefault();
            send();
          }
        }}
        placeholder="Nachricht"
        onChange={(ev) => {
          const text = ev.target.value;
          setMessage(
            text.replaceAll(/:(\S+):/g, (m, name) => {
              const emojis: any = emojiIndex.search(name);
              return emojis.length ? emojis[0].native : m;
            })
          );
        }}
      />
      {showPicker && (
        <PickerContainer ref={pickerRef}>
          <Picker
            native
            emoji=""
            onSelect={(emoji) => {
              if ('native' in emoji) {
                setMessage((m) => m + emoji.native);
              }
            }}
          />
        </PickerContainer>
      )}
      <Buttons>
        <div data-tip="Chatanhänge sind bald verfügbar !" data-for="main">
          <AttachmentIcon />
          <ReactTooltip id="main" backgroundColor="#0F1D30" />
        </div>
        <Btn
          appearance="transparent"
          size="small"
          type="button"
          onClick={() => setShowPicker(true)}
        >
          <SmileyIcon />
        </Btn>
        <Btn
          size="small"
          type="button"
          onClick={send}
          style={{ borderRadius: 4 }}
        >
          Senden
        </Btn>
      </Buttons>
    </Root>
  );
}
