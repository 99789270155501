import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CapacityFormValues, CargoFormValues } from '../../lib/types/ui';
import { AdrIcon, CoolingIcon, TiltIcon, TrashIcon } from '../common/Icons';
import { InputLabel } from '../common/typography/Special';
import { InputButtonCard } from '../form/InputButtonCard';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';

export const RequirementsForm = () => {
  const { register } = useFormContext<CargoFormValues | CapacityFormValues>();
  return (
    <Stack>
      <InputLabel color="var(--grey600)">Attribute deines Überhangs</InputLabel>
      <Row>
        <InputButtonCard type="checkbox" {...register('requirements.ADR')}>
          <AdrIcon />
          ADR
        </InputButtonCard>
        <InputButtonCard type="checkbox" {...register('requirements.Waste')}>
          <TrashIcon />
          Abfall
        </InputButtonCard>

        <InputButtonCard type="checkbox" {...register('requirements.Dumping')}>
          <TiltIcon />
          Kippgut
        </InputButtonCard>

        <InputButtonCard type="checkbox" {...register('requirements.Cooling')}>
          <CoolingIcon />
          Kühler
        </InputButtonCard>
      </Row>
    </Stack>
  );
};
