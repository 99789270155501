import './index.css';
import './i18n';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './App';
import { UserStateProvider } from './components/UserState';

const dsn = import.meta.env.VITE_SENTRY_DSN;
if (dsn && dsn !== 'undefined') {
  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    release: import.meta.env.VITE_COMMIT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    /* beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    }, */
  });
}

ReactDOM.render(
  <React.StrictMode>
    <UserStateProvider>
      <Router>
        <App />
      </Router>
    </UserStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
