import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React, { ReactNode, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { SpreadButtonRow } from '../layout/SpreadButtonRow';
import { Btn } from './Btn';
import notice from './notice.svg';
import { body4 } from './typography/Body';
import { h6 } from './typography/Headings';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(AlertDialog.Overlay)`
  position: fixed;
  inset: 0;
  background: rgba(15, 29, 48, 0.4);
  backdrop-filter: blur(5px);
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
`;

const StyledContent = styled(AlertDialog.Content)`
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.25),
    0px 8px 16px -1px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 75vw;
  max-height: 85vh;
  padding: 25px;
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  &:focus {
    outline: 'none';
  }
`;

const StyledTitle = styled(AlertDialog.Title)`
  ${h6};
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ::before {
    display: block;
    content: '';
    width: 32px;
    height: 32px;
    background: url(${notice}) no-repeat;
    margin-right: 16px;
  }
`;

const StyledDescription = styled(AlertDialog.Description)`
  ${body4}
  color: var(--grey500);
  margin-bottom: 20px;
`;

type Props = {
  onConfirm: () => any;
  onClose: () => any;
  title: string;
  children: ReactNode;
  cancelAction?: string;
  confirmAction: string;
};
export function ConfirmDialog({
  title,
  children,
  cancelAction = 'Abbrechen',
  confirmAction = 'Bestätigen',
  onConfirm,
  onClose,
}: Props) {
  const [submitting, setSubmitting] = useState(false);
  return (
    <AlertDialog.AlertDialog
      defaultOpen
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <AlertDialog.Portal>
        <StyledOverlay />
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{children}</StyledDescription>
          <SpreadButtonRow>
            <Btn
              color="primary"
              disabled={submitting}
              onClick={async () => {
                // NOTE: We don't wrap the button inside a AlertDialogAction
                // so that the dialog does not close immediately.
                setSubmitting(true);
                try {
                  await onConfirm();
                } finally {
                  setSubmitting(false);
                }
                onClose();
              }}
            >
              {confirmAction}
            </Btn>
            <AlertDialog.AlertDialogCancel asChild>
              <Btn appearance="outline">{cancelAction}</Btn>
            </AlertDialog.AlertDialogCancel>
          </SpreadButtonRow>
        </StyledContent>
      </AlertDialog.Portal>
    </AlertDialog.AlertDialog>
  );
}
