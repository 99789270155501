import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useApiCall } from '../../lib/useApiCall';
import { AppScreen } from '../common/AppScreen';
import { LoadingCircle } from '../common/LoadingCircle';
import { PageTitle } from '../common/PageTitle';
import { Stack } from '../layout/Stack';
import { useNotifications } from '../NotificationsProvider';
import { ChatDetails } from './ChatDetails';
import { ChatList } from './ChatList';
import { EmptyChat } from './EmptyChat';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 24px;
  overflow: hidden;
  & > :first-child {
    flex: 0 0 270px;
  }
  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
`;

const Main = styled.div`
  flex-grow: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background: var(--white);
`;

type Props = {
  chatId?: number;
  sendMessage?: (message: string) => Promise<any>;
};

export function ChatScreen({ chatId }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { data, invalidate } = useApiCall('getChats');
  const { messages } = useNotifications();

  useEffect(invalidate, [messages]);

  const chat = useMemo(() => {
    return data?.find((c) => c.chat_id === chatId);
  }, [data, chatId]);

  if (loading) return <LoadingCircle />;

  return (
    <AppScreen title="Nachrichtencenter">
      <Stack style={{ flexGrow: 1 }}>
        <PageTitle
          title="Nachrichtencenter"
          subTitle="Hier findest du alle Nachrichten und Chats auf Cargofaces."
        />
        <Root>
          <ChatList
            chats={data}
            activeChatId={chatId}
            refreshChats={invalidate}
            setLoading={setLoading}
          />
          <Main>
            {chat ? (
              <ChatDetails
                chat={chat}
                refreshChats={invalidate}
                setLoading={setLoading}
              />
            ) : (
              <EmptyChat />
            )}
          </Main>
        </Root>
      </Stack>
    </AppScreen>
  );
}
