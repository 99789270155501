import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Btn } from '../../components/common/Btn';
import { TinyLink } from '../../components/common/Link';
import { AllCapsSmall } from '../../components/common/typography/Special';
import { Checkbox } from '../../components/form/Checkbox';
import { Field } from '../../components/form/Field';
import { Form } from '../../components/form/Form';
import { PasswordField } from '../../components/form/PasswordField';
import { TextField } from '../../components/form/TextField';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Stack } from '../../components/layout/Stack';
import { useAuth } from '../../components/UserState';
import { login, visitErrors } from '../../lib/publicApi';
import { useIsActiveRoute } from '../../lib/useIsActiveRoute';

const FieldLink = styled(TinyLink)`
  align-self: end;
  color: var(--tertiary600);
`;

const BoxFooter = styled.div`
  text-align: center;
  color: var(--neutral500);
`;

type Inputs = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export function Login() {
  const uid = window.localStorage.getItem('uid');
  const form = useForm<Inputs>({
    defaultValues: {
      email: uid ?? '',
      rememberMe: !!uid,
    },
  });
  const authenticate = useAuth();
  const navigate = useNavigate();
  const isLoginRoute = useIsActiveRoute('/login');
  const { search } = useLocation();
  const parameters = new URLSearchParams(search);
  const origin = parameters.get('origin');

  const onSubmit: SubmitHandler<Inputs> = async ({ rememberMe, ...data }) => {
    const res = await login(data);
    if ('auth' in res) {
      await authenticate(res.auth, () => res.data, rememberMe);
      if (origin) {
        navigate(origin);
      } else if (isLoginRoute) navigate('/dashboard');
    } else {
      visitErrors(data, res, form.setError);
    }
  };
  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="Anmelden">
              <Form {...form} onSubmit={onSubmit}>
                <Stack gap="24px">
                  <Stack>
                    <Field name="email" label="E-Mail">
                      <TextField
                        autoFocus
                        tabIndex={1}
                        placeholder="Deine E-Mail"
                        autoComplete="email"
                        type="email"
                      />
                    </Field>
                    <Stack gap="6px">
                      <FieldLink tabIndex={3} to="/forgotpassword">
                        Passwort vergessen
                      </FieldLink>
                      <Field name="password">
                        <PasswordField
                          tabIndex={2}
                          placeholder="Passwort"
                          autoComplete="current-password"
                        />
                      </Field>
                    </Stack>
                    <Field name="rememberMe">
                      <Checkbox>Angemeldet bleiben</Checkbox>
                    </Field>
                    <Btn color="primary" type="submit">
                      Weiter
                    </Btn>
                    <BoxFooter>
                      <AllCapsSmall>
                        Sie haben noch kein Konto?{' '}
                        <FieldLink to="/signup">
                          Hier ein Konto erstellen
                        </FieldLink>
                      </AllCapsSmall>
                    </BoxFooter>
                  </Stack>
                </Stack>
              </Form>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
