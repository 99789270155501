import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import styled from 'styled-components';

import { CountryFlag } from '../common/CountryFlag';
import { ArrowDownIcon } from '../common/Icons';
import { Row } from '../layout/Row';

const Trigger = styled(DropdownMenu.Trigger)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  height: 44px;
  background: var(--neutral100);
  cursor: pointer;
  z-index: 3;
  border: none;
  border-radius: 9.5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
`;

const Content = styled(DropdownMenu.Content)`
  border-radius: 20px;
  background: red;
  overflow: hidden;
  padding: 16px 0;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: -70px;
  width: 210px;
  cursor: pointer;
`;

const RadioItem = styled(DropdownMenu.RadioItem)`
  display: flex;
  align-items: center;
  gap: 19px;
  padding: 16px 24px;
  background: var(--white);
  &[aria-checked='true'] {
    background: var(--tertiary100);
  }
`;

type Props = {
  value: string | null | undefined;
  onChange: (value: string) => void;
};

export const CountrySelect = ({ value, onChange }: Props) => {
  const country = value || 'de';
  return (
    <DropdownMenu.Root>
      <Trigger>
        <CountryFlag country={country} /> <ArrowDownIcon />
      </Trigger>
      <Content>
        <DropdownMenu.RadioGroup value={country} onValueChange={onChange}>
          <RadioItem value="de">
            <Row>
              <CountryFlag country="de" />
              <div>Deutschland</div>
            </Row>
          </RadioItem>
          <RadioItem value="nl">
            <Row>
              <CountryFlag country="nl" />
              <div>Niederlande</div>
            </Row>
          </RadioItem>
          <RadioItem value="be">
            <Row>
              <CountryFlag country="be" />
              <div>Belgien</div>
            </Row>
          </RadioItem>
          <RadioItem value="at">
            <Row>
              <CountryFlag country="at" />
              <div>Österreich</div>
            </Row>
          </RadioItem>
          <RadioItem value="ch">
            <Row>
              <CountryFlag country="ch" />
              <div>Schweiz</div>
            </Row>
          </RadioItem>
        </DropdownMenu.RadioGroup>
      </Content>
    </DropdownMenu.Root>
  );
};
