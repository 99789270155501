import React from 'react';
import styled from 'styled-components';

import { desktopOnly } from './Consts';
import points from './images/points.svg';

const image1 = '/images/image1.jpg';
const image2 = '/images/image2.jpg';
const image3 = '/images/image3.jpg';
const image4 = '/images/image4.jpg';
const image5 = '/images/image5.jpg';
const image6 = '/images/image6.jpg';

const ImageSectionHead = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 32px auto;
  max-width: 100%;
`;

const ImageBox1 = styled.div`
  background-image: url(${image1});
  background-size: cover;
  background-position: right;
  width: 278px;
  height: 407px;
  border-radius: 10px;
  margin: 18px;
  margin-left: 100px;
  z-index: 1;
  @media screen and (max-width: 959px) {
    margin-left: 0px;
  }
`;

const ImageBox2 = styled.div`
  background-image: url(${image2});
  background-size: cover;
  background-position: center;
  width: 265px;
  height: 212px;
  border-radius: 10px;
  margin: 18px;
  z-index: 1;
  ${desktopOnly}
`;

const ImageBox3 = styled.div`
  background-image: url(${image3});
  background-size: cover;
  background-position: center;
  width: 377px;
  height: 283px;
  border-radius: 10px;
  margin: 18px;
  margin-right: 0;
  z-index: 1;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

const ImageBox4 = styled.div`
  background-image: url(${image4});
  background-size: cover;
  background-position: center;
  width: 377px;
  height: 283px;
  border-radius: 10px;
  margin: 18px;
  margin-left: 0;
  z-index: 1;
  ${desktopOnly}
`;

const ImageBox5 = styled.div`
  background-image: url(${image5});
  background-size: cover;
  background-position: right;
  width: 155px;
  height: 212px;
  border-radius: 10px;
  margin: 18px;
  z-index: 1;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

const ImageBox6 = styled.div`
  background-image: url(${image6});
  background-size: cover;
  background-position: center;
  width: 392px;
  height: 372px;
  border-radius: 10px;
  margin: 18px;
  z-index: 1;
  @media screen and (max-width: 959px) {
    transform: scale(0.5);
    margin: auto;
  }
`;

const Points1 = styled.div`
  background-image: url(${points});
  background-size: cover;
  width: 214px;
  height: 120px;
  position: absolute;
  top: 196px;
  left: 27%;
  z-index: 0;
  overflow: hidden;
  ${desktopOnly}
`;

const Points2 = styled.div`
  position: absolute;
  background-image: url(${points});
  background-size: cover;
  width: 214px;
  height: 120px;
  bottom: -10px;
  right: 0;
  z-index: -10;
  ${desktopOnly}
`;

const ImagePoint1 = styled.div`
  position: relative;
  padding-left: 32px;
  overflow: hidden;
  @media screen and (max-width: 959px) {
    padding: 0;
  }
`;

const Box1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
const Box2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const ImageSection = () => {
  return (
    <ImageSectionHead>
      <Box1>
        <ImageBox1 />
        <ImageBox2 />
        <ImageBox3 />
      </Box1>
      <Box2>
        <ImageBox4 />
        <ImageBox5 />
        <ImagePoint1>
          <ImageBox6 />
          <Points2 />
        </ImagePoint1>
      </Box2>
      <Points1 />
    </ImageSectionHead>
  );
};
