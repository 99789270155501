import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  capacityOfferInputFromForm,
  capacityPresetToForm,
  formatDate,
} from '../../lib/convert';
import { CapacityPreset } from '../../lib/types/preset';
import { CapacityFormValues } from '../../lib/types/ui';
import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { TruckIcon } from '../common/Icons';
import addIcon from '../offer/icons/SpecialAddIcon.svg';
import { ManagePresetsDialog } from '../presets/ManagePresetsDialog';
import { useApi } from '../UserState';
import { CapacityDialog } from './CapacityDialog';

type Props = {
  preset?: CapacityPreset;
  children?: ReactElement;
  onCreate?: () => void;
};

export const CreateCapacityDialog = ({ preset, children, onCreate }: Props) => {
  const navigate = useNavigate();
  const api = useApi();
  const values = useMemo(
    () => ({
      ...(preset && capacityPresetToForm(preset)),
      date_start: formatDate(new Date()),
    }),
    [preset]
  );

  const onSave = async (values: CapacityFormValues) => {
    const { capacity } = await api.createCapacity(
      capacityOfferInputFromForm(values)
    );
    onCreate?.();
    navigate(`/matching/results/${capacity.id}/capacity`);
  };

  const trigger = children ?? (
    <Btn size="small">
      <TruckIcon />
      Neues Leerchassis
    </Btn>
  );
  return (
    <CapacityDialog
      values={values}
      onSave={onSave}
      trigger={trigger}
      header={
        <Dialog.Header title="Neues Leerchassis" icon={addIcon}>
          <ManagePresetsDialog />
        </Dialog.Header>
      }
    />
  );
};
