import React from 'react';

import { EmptyState } from '../../empty/EmptyState';
import search from '../../empty/search.svg';

export function EmptySearch() {
  return (
    <EmptyState
      image={search}
      imageStyle={{ height: 382 }}
      title="Keine Ergebnisse"
    >
      Wir konnten keine Übereinstimmung für Ihre Suche finden. Bitte versuchen
      Sie einen anderen Suchbegriff oder entfernen Sie die Filter.
    </EmptyState>
  );
}
