import { TFunction } from 'i18next';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FullArrowDownIcon } from './Icons';
import { body3, body4 } from './typography/Body';
import { h6 } from './typography/Headings';

export const TableTitle = styled.div`
  ${body3}
`;

export const Table = styled.table`
  ${body4};
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
  thead {
    color: var(--white);
    td,
    th {
      background: var(--primary500);
      text-align: left;
      padding: 4px 16px;
      white-space: nowrap;
      ${h6}

      &:first-child {
        padding-left: 48px;
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
  tbody {
    tr {
      border-radius: 20px;
    }

    td {
      padding: 16px;
      position: relative;
      background: var(--white);
      color: var(--primary500);
      border-top: 1px solid var(--grey200);
      border-bottom: 1px solid var(--grey200);
      &:nth-child(1n + 3) {
        box-shadow: -13px 0 0 -12px var(--neutral300);
      }
      &:first-child {
        padding-left: 20px;
        padding-right: 0;
        border-left: 1px solid var(--grey200);
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-right: 1px solid var(--grey200);
        border-radius: 0 10px 10px 0;
      }
    }
  }
`;

const SortHeader = styled.button<{ sorted: boolean; asc: boolean }>`
  border: none;
  border-radius: 1px;
  outline-offset: 4px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background: inherit;
  cursor: pointer;

  svg {
    opacity: ${(p) => (p.sorted ? 1 : 0)};
    margin-left: 8px;
    transform: rotate(${(p) => (p.asc ? '180' : '0')}deg);
    transform-origin: 50% 50%;
  }
  :hover svg {
    opacity: 1;
  }
`;

function Heading({
  column,
  sort,
  onSort,
}: {
  column: Column<any>;
  sort?: SortOrder;
  onSort?: OnSort;
}) {
  if (!column.heading) return null;
  if (!column.sortable) {
    return <>{column.heading}</>;
  }
  const sorted = column.key === sort?.column;
  return (
    <SortHeader
      asc={!!sort?.asc}
      sorted={sorted}
      onClick={() => {
        if (onSort) onSort({ column: column.key, asc: !sort?.asc });
      }}
      role="button"
      tabIndex={0}
    >
      {column.heading}
      <FullArrowDownIcon />
    </SortHeader>
  );
}

export type Columns<T> = Column<T>[];

export type Column<T> = {
  key: string;
  heading?: string;
  sortable?: boolean;
  style?: CSSProperties;
  render?: (item: T, t: TFunction) => ReactNode;
  value?: (item: T) => any;
};

export type SortOrder = { column: string; asc: boolean };
export type OnSort = (order: SortOrder) => void;

export type Props<T> = {
  columns: Columns<T>;
  items: T[];
  sortOrder?: { column: string; asc: boolean };
  onSort?: OnSort;
};
export function DataTable<T>({ columns, items, sortOrder, onSort }: Props<T>) {
  const { t } = useTranslation();
  const [sortedItems, setSortedItems] = useState<Array<T> | null>(null);

  useEffect(() => {
    if (sortOrder && items?.length > 0 && columns?.length > 0) {
      const column = columns.find((col) => sortOrder.column === col.key);
      if (column) {
        const { value } = column;
        if (typeof value === 'function') {
          const newItems = items.slice(0);
          newItems.sort((a: T, b: T) => {
            const dir = sortOrder.asc ? 1 : -1;
            const aVal = value(a);
            const bVal = value(b);
            if (!!aVal && !bVal) return dir * 1;
            if (!aVal && !!bVal) return dir * -1;

            if (aVal < bVal) return dir * -1;
            if (aVal > bVal) return dir * 1;
            return 0;
          });
          setSortedItems(newItems);
        }
      }
    }
  }, [sortOrder, items, columns]);

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={col.key} style={col.style}>
              <Heading column={col} sort={sortOrder} onSort={onSort} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(sortedItems instanceof Array ? sortedItems : items)?.map(
          (item, i) => (
            <tr key={i}>
              {columns.map((col) => (
                <td key={col.key}>
                  {col.render ? col.render(item, t) : (item as any)[col.key]}
                </td>
              ))}
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
}
