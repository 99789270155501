import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { body5 } from './typography/Body';
import { allCapsSmall } from './typography/Special';

const link = css`
  text-decoration: none;
  color: var(--tertiary700);
`;

export const TinyLink = styled(Link)`
  ${link}
  ${allCapsSmall}
`;

export const WideMediumLink = styled(Link)`
  ${link}
  ${body5}
`;

export const TinyExternalLink = styled.a`
  ${link}
  ${allCapsSmall}
`;

export const TableLink = styled(Link)`
  text-decoration: none;
  color: var(--primary500);
`;
