import React, { ReactElement, useMemo } from 'react';

import {
  capacityPresetFromForm,
  capacityPresetToPresetForm,
} from '../../lib/convert';
import { CapacityPreset } from '../../lib/types/preset';
import { CapacityPresetFormValues } from '../../lib/types/ui';
import * as Dialog from '../common/Dialog';
import { CapacityDialog } from '../offer/CapacityDialog';
import addIcon from '../offer/icons/SpecialAddIcon.svg';
import { useApi } from '../UserState';

type Props = {
  edit?: CapacityPreset;
  onSave?: () => void;
  onClose?: () => void;
  children?: ReactElement;
};

export function CapacityPresetDialog({
  edit,
  onSave,
  onClose,
  children,
}: Props) {
  const api = useApi();

  const values = useMemo(
    () => ({ ...(edit && capacityPresetToPresetForm(edit)) }),
    [edit]
  );

  const handleSave = async (values: CapacityPresetFormValues) => {
    const Capacity = capacityPresetFromForm(values);
    if (edit) {
      await api.editCapacityPreset(Capacity, edit.id);
    } else {
      await api.addCapacityPreset(Capacity);
    }
    onSave?.();
    onClose?.();
  };

  return (
    <CapacityDialog
      preset
      header={<Dialog.Header title="Leerchassis Vorlage" icon={addIcon} />}
      values={values}
      onSave={handleSave}
      onCancel={onClose}
      trigger={children}
    />
  );
}
