import * as Popover from '@radix-ui/react-popover';
import React, { useRef, useState } from 'react';

import { formatDate, parseDate } from '../../lib/convert';
import { DatePicker } from '../calendar/DatePicker';
import { TextField } from './TextField';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

export function DateInput({ value, onChange, disabled = false }: Props) {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const date = value ? parseDate(value) : null;
  const text = date ? formatDate(date) : '';

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Anchor asChild>
        <TextField
          ref={inputRef}
          autoComplete="off"
          disabled={disabled}
          value={text}
          onFocus={() => setOpen(true)}
          onClick={() => setOpen(true)}
          onBlur={(ev) => {
            // close the picker on blur unless the calender itself was focused
            if (!contentRef.current?.contains(ev.relatedTarget)) {
              setOpen(false);
            }
          }}
          onChange={(ev) => {
            onChange?.(ev.target.value);
          }}
        />
      </Popover.Anchor>
      <Popover.Content
        ref={contentRef}
        align="start"
        onInteractOutside={(ev) => {
          // prevent the picker from closing if the input was focused
          if (inputRef.current?.contains(ev.currentTarget as any)) {
            ev.preventDefault();
          }
        }}
        onOpenAutoFocus={(ev) => {
          // keep the input field focused
          ev.preventDefault();
        }}
      >
        <DatePicker
          value={date ?? null}
          onChange={(date) => {
            // close the picker upon selection and re-focus the input field
            onChange?.(formatDate(date));
            inputRef.current?.focus();
            setOpen(false);
          }}
        />
      </Popover.Content>
    </Popover.Root>
  );
}
