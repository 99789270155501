import * as React from 'react';
import { forwardRef, useRef } from 'react';
import styled from 'styled-components';

import { CopyIcon } from '../common/Icons';
import { Body } from '../common/typography';

const Root = styled.div`
  box-sizing: border-box;
  border-radius: 9.5px;
  border: solid 1px var(--grey300);
  background-color: var(--input-background);
  display: flex;
  width: min(100%, 310px);
  &:focus-within {
    box-shadow: 0px 0px 0px 4px rgba(29, 171, 242, 0.2);
  }
`;

const Input = styled.input`
  ${Body.body6}
  box-sizing: border-box;
  border: none;
  border-radius: 9.5px 0 0 9.5px;
  background: transparent;
  color: var(--grey800);
  padding: 12px 6px;
  margin: 0;
  flex: 0 1 100%;
  width: 100%;
  ::placeholder {
    color: var(--grey500);
  }
  :focus {
    ::placeholder {
      color: var(--grey300);
    }
    position: relative;
  }

  @media screen and (min-width: 720px) {
    padding: 10px 12px;
    ${Body.body4}
  }
`;

const CopyButton = styled.button`
  box-sizing: border-box;
  flex: 0 0 48px;
  color: var(--white);
  background-color: var(--primary500);
  border: none;
  border-left: solid 1px var(--grey300);
  padding: 0 12px;
  border-radius: 0 9.5px 9.5px 0;
  cursor: pointer;
`;

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

type Props = JSX.IntrinsicElements['input'];

export const CopyField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const rootRef = useRef<HTMLDivElement>(null);
  return (
    <Root ref={rootRef}>
      <Input {...props} ref={ref} type="text" />
      <CopyButton
        type="button"
        onClick={() => {
          if (rootRef?.current) {
            const value = rootRef.current.querySelector('input')?.value;
            if (value) copyTextToClipboard(value);
          }
        }}
      >
        <CopyIcon />
      </CopyButton>
    </Root>
  );
});
