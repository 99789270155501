import React from 'react';
import styled from 'styled-components';

import { body1, body4 } from '../../components/common/typography/Body';
import { Center } from '../../components/layout/Center';
import QuotesIcon from './images/Quotes.svg';

const Text = styled.div`
  ${body1}
  line-height: 32px;
  color: var(--primary600);
  width: 60%;
  margin-top: 16px;
  @media screen and (max-width: 959px) {
    ${body4}
  }
`;

const Box = styled(Center)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const Quotation = () => {
  return (
    <Box direction="column">
      <img src={QuotesIcon} />
      <Text>
        “Wir machen die Logistik zu einer besseren Branche, indem wir Logistiker
        mit modernen Tools in die Lage versetzen, besser zu kommunizieren,
        zusammenzuarbeiten und gemeinsam das Datenpotenzial in der Lieferkette
        zu nutzen.”
      </Text>
    </Box>
  );
};
