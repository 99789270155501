import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Btn, BtnLink } from '../../components/common/Btn';
import { Card } from '../../components/common/Card';
import { ConfirmDialog } from '../../components/common/ConfirmDialog';
import {
  MinusCircleIcon,
  PlusCircleIcon,
  SearchIcon,
} from '../../components/common/Icons';
import {
  LoadingCircle,
  LoadingLayer,
} from '../../components/common/LoadingCircle';
import {
  ViewportCanShowDataTable,
  ViewportTooSmallForDataTable,
} from '../../components/common/MediaQueries';
import { PageTitle } from '../../components/common/PageTitle';
import { Headings, Special } from '../../components/common/typography';
import { AvatarNameTitle } from '../../components/company/AvatarNameTitle';
import { ConfirmDeleteCompanyMember } from '../../components/company/ConfirmDeleteCompanyMember';
import { CompanyInviteModal } from '../../components/company/invite-modal';
import {
  MemberDataTable,
  roleNames,
  RolesList,
} from '../../components/company/MemberDataTable';
import connections from '../../components/empty/connections.svg';
import { EmptyState } from '../../components/empty/EmptyState';
import { InputWithIconRight } from '../../components/form/InputWithIconRight';
import { TextField } from '../../components/form/TextField';
import { Stack } from '../../components/layout/Stack';
import { useApi, useRefetchUser, useUser } from '../../components/UserState';
import { UserData } from '../../lib/publicApi';
import { useApiCall } from '../../lib/useApiCall';

export function FilterResultsEmpty() {
  return (
    <EmptyState
      image={connections}
      imageStyle={{ height: 322, marginBottom: -15 }}
      title="Keine Mitarbeiter gefunden"
    >
      <BtnLink to="invite" size="large" type="button">
        <PlusCircleIcon />
        Mitarbeiter hinzufügen
      </BtnLink>
    </EmptyState>
  );
}

const SearchField = styled(InputWithIconRight)`
  flex: 0 1 200px;
`;

const Grow = styled.div`
  flex-grow: 1;
`;

const UserCard = styled(Card)`
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);

  h2 {
    ${Headings.h6}
    color: var(--grey800);
  }

  h2 + p {
    ${Special.SubtitleMedium}
    color: var(--grey500);
  }
`;

const MobileActions = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopControlsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
`;

type LayoutProps = {
  top?: React.ReactNode;
  children?: React.ReactNode;
};
function Layout({ top, children }: LayoutProps) {
  return (
    <Stack style={{ minHeight: '100%' }}>
      {top}
      <Grow>{children}</Grow>
    </Stack>
  );
}

type TopControlsProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  invalidate: () => void;
};
function TopControls({ filter, setFilter, invalidate }: TopControlsProps) {
  return (
    <>
      <TopControlsContainer>
        <SearchField>
          <TextField
            placeholder="Suche"
            aria-label="Adresse"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <SearchIcon />
        </SearchField>
        <BtnLink
          type="button"
          size="small"
          style={{ marginTop: '2px' }}
          to="invite"
        >
          <PlusCircleIcon />
          Mitarbeiter hinzufügen
        </BtnLink>
      </TopControlsContainer>
    </>
  );
}

export type UserWithRoles = {
  user: UserData;
  roles: Array<string>;
};

type Props = {
  companyID: number;
};

export function CompanyMembers({ companyID }: Props) {
  const [filter, setFilter] = useState('');
  const api = useApi();
  const navigate = useNavigate();
  const { data: user, isAdmin } = useUser();

  const refetchUser = useRefetchUser();
  const { data, invalidate } = useApiCall('getEmployees', companyID);
  const [flatData, setFlatData] = useState<UserWithRoles[]>([]);
  const [pendingDeletion, selectForDeletion] = useState<number>();
  const [confirmRoleChange, setConfirmRoleChange] = useState<
    [string, number] | null
  >(null);
  const [loading, setLoading] = useState(false);

  const filterFunc = ({ user }: UserWithRoles) => {
    const { first_name, last_name, job_title } = user;
    const regExp = new RegExp(filter, 'i');
    return [first_name, last_name, job_title].some((s) => s && regExp.test(s));
  };

  // aggregate the userdata into an array of users
  useEffect(() => {
    if (data) {
      const userMap: Record<number, UserWithRoles> = {};
      for (const [key, users] of Object.entries(data)) {
        const singularKey = key.endsWith('s') ? key.slice(0, -1) : key;
        for (const user of users) {
          if (userMap[user.id]) {
            userMap[user.id].roles.push(singularKey);
          } else {
            userMap[user.id] = { user, roles: [singularKey] };
          }
        }
      }
      setFlatData(Object.values(userMap));
    }
  }, [data]);

  const onConfirmDeletion = async () => {
    setLoading(true);
    const id = pendingDeletion;
    selectForDeletion(undefined);
    try {
      if (id) {
        await api.removeEmpolyee(companyID, id);
        invalidate();
      }
    } catch (err: any) {
      alert(err.toString());
    } finally {
      setLoading(false);
    }
  };

  const onConfirmRoleChange = async () => {
    if (confirmRoleChange === null) {
      return;
    }
    const [cmd, theUserID]: [string, number] = confirmRoleChange;
    setLoading(true);
    setConfirmRoleChange(null);
    try {
      switch (cmd) {
        case 'addAdmin':
          await api.addAdmin(companyID, theUserID);
          break;
        case 'removeAdmin':
          if (theUserID === user.id) {
            throw new Error(
              'Das Entziehen der Rechte des aktuellen Benutzers ist nicht zulässig.'
            );
          }
          await api.removeAdmin(companyID, theUserID);
          break;
        case 'addDispatcher':
          await api.addDispatcher(companyID, theUserID);
          break;
        case 'removeDispatcher':
          await api.removeDispatcher(companyID, theUserID);
          break;
        default:
          throw new Error('Operation nicht zulässig.');
      }
      invalidate();
      if (theUserID === user.id) {
        refetchUser();
      }
    } catch (err: any) {
      alert(err?.message || 'Unkown error');
    } finally {
      setLoading(false);
    }
  };

  const isPending = ({ roles }: { roles: string[] }): boolean => {
    return (
      roles.filter((r) => r === 'sent_by_user' || r === 'sent_by_company')
        .length > 0
    );
  };

  const onCheckAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(e.target.getAttribute('data-id') ?? '', 10);
    if (Number.isNaN(id)) {
      return;
    }
    if (e.target.checked) {
      setConfirmRoleChange(['addAdmin', id]);
    } else {
      setConfirmRoleChange(['removeAdmin', id]);
    }
  };

  const onCheckDispatcher = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(e.target.getAttribute('data-id') ?? '', 10);
    if (Number.isNaN(id)) {
      return;
    }
    if (e.target.checked) {
      setConfirmRoleChange(['addDispatcher', id]);
    } else {
      setConfirmRoleChange(['removeDispatcher', id]);
    }
  };

  const confirmRoleCaptions: Record<string, string> = {
    add: 'Berechtigung erteilen',
    rem: 'Berechtigung entziehen',
  };

  const confirmRoleText: Record<string, string> = {
    add: 'Du bist gerade dabei, diesem Mitarbeiter Berechtigungen zu erteilen. Wenn du dir sicher bist, klicke auf "OK", andernfalls klicke auf "Abbrechen".',
    rem: 'Du bist gerade dabei, diesem Mitarbeiter Berechtigungen zu entziehen. Wenn du dir sicher bist, klicke auf "OK", andernfalls klicke auf "Abbrechen".',
  };

  const filteredFlatData = useMemo(
    () => flatData.filter(filterFunc),
    [flatData, filter]
  );

  return (
    <>
      <Layout
        top={
          <HeaderBox>
            <PageTitle title="Mitglieder">
              Auf dieser Seite kannst du alle Mitglieder deines Unternehmens
              administrieren. Füge neue Mitarbeiter hinzu, bearbeite die Rollen
              der Mitglieder oder entferne Benutzer aus deinem Unternehmen.
              Hinweis: Alle Administratoren haben Zugriff auf diese Seite.
            </PageTitle>
            <TopControls
              filter={filter}
              setFilter={setFilter}
              invalidate={invalidate}
            />
          </HeaderBox>
        }
      >
        {filteredFlatData.length > 0 ? (
          <>
            <ViewportCanShowDataTable>
              <MemberDataTable
                onCheckAdmin={onCheckAdmin}
                onCheckDispatcher={onCheckDispatcher}
                items={filteredFlatData}
                renderActions={(item) => (
                  <>
                    {isPending(item) === false && isAdmin && (
                      <>
                        <Btn
                          color="primary"
                          size="small"
                          onClick={() => selectForDeletion(item.user.id)}
                        >
                          <MinusCircleIcon />
                          Mitglied entfernen
                        </Btn>
                      </>
                    )}
                  </>
                )}
                isAdmin={isAdmin}
              />
            </ViewportCanShowDataTable>
            <ViewportTooSmallForDataTable>
              <Stack gap=".5rem">
                {filteredFlatData.map(({ user, roles }) => (
                  <UserCard key={user.id}>
                    <Stack gap="1rem">
                      <AvatarNameTitle user={user} />
                      <RolesList>
                        {roles.map((role, idx) => (
                          <li key={idx} className={role}>
                            {roleNames[role]}
                          </li>
                        ))}
                      </RolesList>
                      <MobileActions>
                        {isPending({ roles }) && (
                          <Btn
                            size="small"
                            color="neutral"
                            onClick={() => selectForDeletion(user.id)}
                          >
                            <MinusCircleIcon />
                            Mitglied entfernen
                          </Btn>
                        )}
                      </MobileActions>
                    </Stack>
                  </UserCard>
                ))}
              </Stack>
            </ViewportTooSmallForDataTable>
          </>
        ) : (
          <FilterResultsEmpty />
        )}
      </Layout>
      {pendingDeletion && (
        <ConfirmDeleteCompanyMember
          onConfirm={onConfirmDeletion}
          onClose={() => selectForDeletion(undefined)}
        />
      )}
      {confirmRoleChange && (
        <ConfirmDialog
          confirmAction="OK"
          onConfirm={onConfirmRoleChange}
          onClose={() => setConfirmRoleChange(null)}
          title={
            confirmRoleCaptions[confirmRoleChange[0].slice(0, 3) || ''] || ''
          }
        >
          {confirmRoleText[confirmRoleChange[0].slice(0, 3) || ''] || ''}
        </ConfirmDialog>
      )}
      {loading && (
        <LoadingLayer>
          <LoadingCircle />
        </LoadingLayer>
      )}
      <Routes>
        <Route
          path="invite"
          element={
            <CompanyInviteModal
              companyID={companyID}
              close={() => {
                navigate(`/company/${companyID}/members`);
                invalidate();
              }}
            />
          }
        />
      </Routes>
    </>
  );
}
