import React from 'react';

import { UserData } from '../../../lib/publicApi';
import { Avatar } from '../../common/Avatar';
import { InputLabel, SubtitleSmall } from '../../common/typography/Special';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';

type Props = {
  user: UserData;
  info?: string | null;
};

export function UserInfo({ user, info }: Props) {
  return (
    <Row gap="8px">
      <Avatar
        name={`${user.first_name} ${user.last_name}`}
        image={user.picture}
        size="32px"
      />
      <Stack gap="0">
        <InputLabel>
          {user.first_name} {user.last_name}
        </InputLabel>
        <SubtitleSmall color="var(--grey400)">{info}</SubtitleSmall>
      </Stack>
    </Row>
  );
}
