import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { BtnLink } from '../../components/common/Btn';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { Body4 } from '../../components/common/typography/Body';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { confirmEmail } from '../../lib/publicApi';
import CheckedIcon from './CheckedIcon.svg';

const EmailConfirmed = styled.div`
  background-image: url(${CheckedIcon});
  width: 80px;
  height: 80px;
`;

export function ConfirmedAccount() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('confirmation_token');
  const [confirmed, setConfirmed] = React.useState<boolean | undefined>();
  useEffect(() => {
    if (token) {
      confirmEmail(token).then(setConfirmed);
    } else {
      // Already confirmed by the server
      setConfirmed(true);
    }
  }, []);
  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="Bestätigung der E-Mail-Adresse">
              <Stack gap="24px">
                <Row justify="center">
                  {confirmed ? (
                    <EmailConfirmed />
                  ) : confirmed === undefined ? (
                    <LoadingCircle />
                  ) : null}
                </Row>
                {confirmed !== undefined && (
                  <>
                    {confirmed ? (
                      <Body4>
                        Du hast erfolgreich dein Konto erstellt. Klicke auf
                        "Anmelden", um auf dein persönliches Dashboard zu
                        gelangen.
                      </Body4>
                    ) : (
                      <Body4>
                        Die E-Mail-Addresse konnte leider nicht bestätigt
                        werden.
                      </Body4>
                    )}
                    <BtnLink to="/login">Zurück zur Anmeldung</BtnLink>
                  </>
                )}
              </Stack>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
