import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { actionSmall } from '../common/typography/Special';
import { Row } from '../layout/Row';
import { Label } from './Label';

const Root = styled(Row)`
  border: 1px solid var(--neutral300);
  border-radius: 9.5px;
  padding: 7px;
  justify-content: space-between;
`;

const Input = styled.input`
  ::-webkit-file-upload-button {
    display: none;
  }
  :disabled {
    color: var(--neutral400);
    ::-webkit-file-upload-button {
      color: var(--neutral400);
    }
  }
`;

const CustomLabel = styled(Label)`
  background: var(--primary500);
  color: white;
  padding: 8px;
  ${actionSmall}
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: var(--primary400);
  }
`;

type Props = JSX.IntrinsicElements['input'];

export const CustomInput = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, ref) => (
    <Root>
      <Input id="input" type="file" {...props} ref={ref} />
      <CustomLabel htmlFor="input">Datei auswählen</CustomLabel>
    </Root>
  )
);
