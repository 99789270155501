import React from 'react';

import { AppScreen } from '../../components/common/AppScreen';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { PageTitle } from '../../components/common/PageTitle';
import { BookmarksTable } from '../../components/connections/BookmarksTable';
import { ConnectionsSubNav } from '../../components/connections/ConnectionsSubNav';
import { useApiCall } from '../../lib/useApiCall';

export function Bookmarks() {
  const { data, invalidate } = useApiCall('getBookmarks');

  return (
    <AppScreen HeaderNavi={<ConnectionsSubNav />}>
      <PageTitle title="Lesezeichen">
        Hier werden dir alle Kontakte angezeigt, die du mit einem Lesezeichen
        versehen hast.
      </PageTitle>
      {!data ? (
        <LoadingCircle />
      ) : (
        <BookmarksTable
          items={data}
          onAction={invalidate}
          columns={['image', 'name', 'company', 'location']}
        />
      )}
    </AppScreen>
  );
}
