import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Body } from '../common/typography';

const Root = styled.label`
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  user-select: none;
  color: var(--neutral500);
  cursor: pointer;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

const Svg = styled.svg`
  background: var(--white);
  border: 1px solid var(--neutral500);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 8px;
  ${Input}:focus-visible + & {
    outline: 2px solid var(--tertiary600);
  }
  ${Input}:checked + & {
    background: var(--primary500);
    border-color: transparent;
  }
  ${Input}:not(:checked) + & path {
    display: none;
  }
`;

const Label = styled.label`
  ${Body.body6}
  margin-left: 12px;
`;

type Props = JSX.IntrinsicElements['input'];

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, ref) => (
    <Root>
      <Input type="checkbox" {...props} ref={ref} />
      <Svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#FFF"
          d="M19.14 6.14c.19-.2.51-.2.7 0 .18.18.19.46.04.66l-.05.04L8.47 18.19l-5.36-5.36c-.2-.2-.2-.52 0-.71.18-.19.46-.2.66-.05l.04.04 4.64 4.64L19.09 6.1Z"
        />
      </Svg>
      {children && <Label htmlFor={props.id}>{children}</Label>}
    </Root>
  )
);
