import get from 'lodash/get';
import React, { cloneElement, ReactElement } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { subtitleSmall } from '../common/typography/Special';
import { Stack } from '../layout/Stack';
import { FieldFeedback } from './FieldFeedback';
import { Label } from './Label';

const LabelContainer = styled.div`
  display: grid;
  grid-template-areas: 'stack';
  > * {
    grid-area: stack;
  }

  label {
    place-self: center start;
  }
`;

const Hint = styled.div`
  place-self: center end;
  ${subtitleSmall}
  color: var(--grey500);
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  label?: string;
  children: ReactElement;
  required?: boolean | string;
  hint?: string;
  weight?: number;
  controlled?: boolean;
  options?: RegisterOptions<any>;
}

export function Field({
  name,
  label,
  hint,
  required,
  weight,
  controlled,
  options,
  children,
  ...rest
}: Props) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const requiredMessage =
    typeof required === 'string' ? required : 'Bitte füll das Feld aus.';

  const error = get(errors, name);

  return (
    <Stack gap="8px" style={{ flexGrow: weight ?? 1 }} {...rest}>
      {(label || hint) && (
        <LabelContainer>
          {label && <Label required={!!required}>{label}</Label>}
          {hint && <Hint>{hint}</Hint>}
        </LabelContainer>
      )}

      {controlled ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) =>
            cloneElement(children, {
              value,
              onChange,
            })
          }
        />
      ) : (
        cloneElement(children, {
          ...register(name, {
            ...options,
            required: required && requiredMessage,
          }),
        })
      )}
      {error && (
        <FieldFeedback type="error">
          {error.message || 'Ungültige Eingabe.'}
        </FieldFeedback>
      )}
    </Stack>
  );
}
