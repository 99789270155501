import axios, { AxiosInstance, AxiosResponse } from 'axios';

import {
  AuthHeaders,
  formData,
  getAuthHeaders,
  getPresentAuthHeaders,
  InputErrors,
} from './publicApi';
import { Chat, ChatMessage, CreateChatResponse } from './types/chat';
import {
  CompanyData,
  CompanyInput,
  CompanyPaymentInfo,
  CompanyPaymentInput,
  CompanyProfileResult,
  CompanyResponse,
  CompanySearchResponse,
  EmployeeRequestsResponse,
  EmployeesResponse,
  FlatCompany,
  InsuranceInput,
  InsuranceResult,
  UserCompaniesResponse,
} from './types/company';
import {
  Bookmark,
  BookmarksResponse,
  ConnectionsResult,
  SearchResult,
  SearchResultItem,
} from './types/connections';
import { ContactRecord, ContactsResponse } from './types/contacts';
import {
  CapacityDisableResponse,
  CapacityMatchesResponse,
  CargoDisableResponse,
  CargoMatchesResponse,
  MatchingPricesImportExport,
  MatchingResponse,
} from './types/matching';
import {
  CapacityOffer,
  CapacityOfferInput,
  CargoOffer,
  CargoOfferInput,
  MyOffers,
} from './types/offers';
import {
  CapacityPresetData,
  CargoPresetData,
  PresetsResults,
} from './types/preset';
import {
  ChangePasswordResponse,
  EditProfileResponse,
  Notifications,
  PasswordInput,
  ResetPasswordError,
  ResetPasswordSuccess,
  TimeLineResponse,
  UserProfileInput,
  UserProfileResponse,
} from './types/user';

export class Api {
  private axios: AxiosInstance;
  private auth: AuthHeaders | null;
  private invalidateUser: () => void;

  constructor(
    auth: AuthHeaders,
    invalidateUser: () => void,
    rememberMe?: boolean
  ) {
    this.auth = auth;
    this.invalidateUser = invalidateUser;
    if (!auth['access-token']) {
      throw new Error('Missing access token');
    }
    window.localStorage.setItem('auth', JSON.stringify(auth));

    if (typeof rememberMe === 'boolean') {
      // To remember a user we store their uid...
      if (rememberMe) {
        window.localStorage.setItem('uid', auth.uid);
      } else {
        window.localStorage.removeItem('uid');
      }
    } else {
      rememberMe = window.localStorage.getItem('remember') === 'true';
    }

    // We use `onstorage` rather than addEventListener to prevent memory leaks
    window.onstorage = ({ key, newValue }: StorageEvent) => {
      if (key === 'auth') {
        if (newValue === null) {
          // Another window was closed, but as we're still open we restore auth
          console.log('Restoring localStorage to our auth', this.auth);
          window.localStorage.setItem('auth', JSON.stringify(this.auth));
        }
        if (newValue === '') {
          // Logout was performed in another window
          console.log('Logout was performed in another window');
          this.clearAuth();
        } else if (newValue && this.auth) {
          // Another window updated the auth value so we update ours accordingly
          const auth = JSON.parse(newValue);
          if (auth?.uid === this.auth.uid) {
            this.auth = auth;
            console.log('Updated auth from localStorage', this.auth);
          } else {
            // Another user logged in, invalidate ours...
            console.log('Another user logged in, invalidate ...');
            this.clearAuth();
          }
        }
      }
    };

    if (!rememberMe) {
      // User does not want to be rembered so we remove the auth data from
      // localStorage upon unload. We temporarily write it to the sessionStorage
      // though, so it can survive page reloads.
      window.onbeforeunload = () => {
        if (this.auth) {
          console.log('Removing auth from localStorage');
          window.sessionStorage.setItem('auth', JSON.stringify(this.auth));
          window.localStorage.removeItem('auth');
        }
      };
    }

    this.axios = axios.create({
      baseURL: '/api',
    });

    // Intercept requests to add the auth headers
    this.axios.interceptors.request.use((config) => {
      Object.assign(config?.headers?.common ?? {}, this.auth);
      return config;
    });

    const updateToken = (response: AxiosResponse<any, any>) => {
      const headers = getPresentAuthHeaders(response.headers);
      if (headers['access-token']) {
        if (headers.uid && headers.uid != this.auth?.uid) {
          //TODO This happens upon login while already beeing logged in
          // with a different user. The Rails Devise auth apparently reads the
          // uid from the Rails session which ideally should not even exist.
          // This should be fixable by disabling Devise session altogether.
          // See https://github.com/Lionizers/CargoFaces-Backend/blob/3f78e9a463f94ec4eced3c9419db1d301e28d5bb/config/initializers/devise.rb#L99
          const message = `Fatal: uid mismatch. Expected ${this.auth?.uid} but got ${headers.uid}`;
          console.log(message);
          alert(message);
          window.location.reload();
          this.clearAuth();
          return Promise.reject(new Error(message));
        }
        if (this.auth) Object.assign(this.auth, headers);
        window.localStorage.setItem('auth', JSON.stringify(this.auth));
      }
    };

    // Intercept responses to update our auth data with possibly new tokens
    this.axios.interceptors.response.use(
      (response) => {
        updateToken(response);
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          // In case of a 401 error update the UI to reflect that the user
          // is no longer logged in.
          this.invalidateUser();
        } else {
          updateToken(error.response);
        }
        return Promise.reject(error);
      }
    );
  }

  private clearAuth() {
    this.auth = null;
    this.invalidateUser();
  }

  async logout() {
    await this.axios.delete('/auth/sign_out');
    // NOTE: The empty string has a special meaning to other windows - it lets
    // them know that a logout was performed rather than a window being closed.
    window.localStorage.setItem('auth', '');
    window.localStorage.setItem('uid', '');
    this.clearAuth();
  }

  ////////////////////////////////////////
  // User Actions Requests
  ///////////////////////////////////////

  async resetPassword(data: PasswordInput) {
    try {
      const res = await this.axios.put<ResetPasswordSuccess>(
        '/auth/password',
        formData(data)
      );
      return res.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { status, data, headers } = error.response ?? {};
        // In case of an validation error return the messages as well as the new
        // auth headers so the caller can update its state (for example the URL)
        if (status === 422) {
          return {
            errors: (data as any).errors,
            auth: getAuthHeaders(headers ?? {}),
          } as ResetPasswordError;
        }
        throw error;
      }
    }
  }

  async updatePassword(data: PasswordInput) {
    try {
      const res = await this.axios.put<ChangePasswordResponse>('/auth/', data);
      return res.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response ?? {};
        if (status === 422) {
          return data as InputErrors<PasswordInput>;
        }
        throw error;
      }
    }
  }

  async deleteAccount() {
    try {
      const res = await this.axios.put('/user/delete');
      return res;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response ?? {};
        if (status === 422) {
          return data;
        }
        throw error;
      }
    }
  }

  async getConnections() {
    const res = await this.axios.get<ConnectionsResult>(
      '/user_actions/status',
      {
        params: { connection_status: 'accepted' },
      }
    );
    return res.data.connections.map(([c]) => c);
  }

  async getPendingConnections() {
    const res = await this.axios.get<ConnectionsResult>(
      '/user_actions/status',
      {
        params: { connection_status: 'pending' },
      }
    );
    return res.data.connections.map(([c]) => c);
  }

  async search(q: string): Promise<SearchResultItem[]> {
    const res = await this.axios.post<SearchResult>(
      '/user_actions/search',
      formData({
        search: q,
      })
    );
    return res.data.users.map(([user, status]) => ({ user, status }));
  }

  async connect(id: number) {
    await this.axios.post('/user/connections', {
      connection: {
        receiver_id: id,
      },
      link_url: `${window.origin}/connections/pending`,
    });
  }

  async declineConnection(id: number) {
    await this.axios.delete(`/user/connections/${id}`);
  }

  async acceptConnection(id: number) {
    const res = await this.axios.put(`/user/connections/accept/${id}`);
    return res.data;
  }

  async getUserProfile(id: number) {
    const res = await this.axios.get<UserProfileResponse>(
      `/user/${id}/profile`
    );
    return res.data;
  }

  async editNotes(id: number, note: string) {
    const res = await this.axios.patch(`/user/notes`, {
      content: note,
      person_id: id,
    });
    return res.data;
  }

  async getBookmarks(): Promise<Bookmark[]> {
    const res = await this.axios.get<BookmarksResponse>('/user/bookmark');
    return res.data.bookmarks.map(([user, companies]) => ({ user, companies }));
  }

  async addBookmark(id: number) {
    const res = await this.axios.post(`/user/add_bookmark/${id}`);
    return res.data;
  }

  async removeBookmark(id: number) {
    const res = await this.axios.delete(`user/remove_bookmark/${id}`);
    return res.data;
  }

  async addFavorite(id: number) {
    const res = await this.axios.patch(`/user/connections/add_favorite/${id}`);
    return res.data;
  }

  async removeFavorite(id: number) {
    const res = await this.axios.patch(
      `/user/connections/remove_favorite/${id}`
    );
    return res.data;
  }

  // get companies where user is involved as -> admin || dispatcher || employee
  async getUserCompanies(): Promise<FlatCompany[]> {
    const res = await this.axios.get<UserCompaniesResponse>(
      '/user_actions/company'
    );
    return res.data.companies.map(([comp, ins, members]) => {
      const { company, company_address, logo } = comp;
      const { insurance, file } = ins;
      return {
        ...company_address,
        ...company,
        logo,
        insurance: {
          ...insurance,
          file,
        },
        members,
      };
    });
  }

  async getUserMatchingPrices() {
    try {
      const res = await this.axios.get(`/user/matching_prices`);

      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response ?? {};
        if (status === 422) {
          return data as InputErrors<MatchingResponse>;
        }
        throw error;
      }
    }
  }

  async setMatchingPriceCompanyMember(
    data: MatchingPricesImportExport,
    use_company_prices: boolean
  ) {
    try {
      const res = await this.axios.patch(`/user/matching_price`, {
        matching_prices: data,
        use_company_prices,
      });
      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response ?? {};
        if (status === 422) {
          return data;
        }
        throw error;
      }
    }
  }

  async setMatchingPriceCompany(id: number, data: MatchingPricesImportExport) {
    try {
      const res = await this.axios.patch(`/companies/${id}/matching_price`, {
        matching_prices: data,
      });
      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { status, data } = error.response ?? {};
        if (status === 422) {
          return data;
        }
        throw error;
      }
    }
  }

  async getEmployeeRequests() {
    const res = await this.axios.get<EmployeeRequestsResponse>(
      '/user/employee_requests'
    );
    return res.data;
  }

  /**
   * Accept invitation to join the company
   * @param id target user ID
   * @returns id || null => id of chat
   */
  async checkChats(id: number) {
    const res = await this.axios.get<{ chat_id: string | null }>(
      `/user/${id}/chat`
    );
    return res.data;
  }

  async pinChat(id: number, pin: boolean) {
    const res = await this.axios.patch(`/chats/${id}`, {
      pinned: pin,
    });
    return res.data;
  }

  async deleteChat(id: number) {
    await this.axios.delete(`/chats/${id}`);
  }

  async getNotifications() {
    const res = await this.axios.get<Notifications>('/user/notifications');
    return res.data;
  }

  ////////////////////////////////////////
  // Company Requests Section
  ///////////////////////////////////////

  async searchCompanies(search: string) {
    const res = await this.axios.post<CompanySearchResponse>(
      `/companies/search?search=${encodeURIComponent(search)}`
    );
    return res.data;
  }

  async requestToJoinCompany(companyId: number) {
    const res = await this.axios.post(`/companies/${companyId}/user_request`, {
      link_url: `${window.origin}/company/${companyId}/requests`,
    });
    return res.data;
  }

  async getCompanyProfile(id: number) {
    const res = await this.axios.get<CompanyProfileResult>(`/companies/${id}`);
    return res.data;
  }

  async createCompany(company: CompanyInput) {
    const res = await this.axios.post<CompanyResponse>(
      '/companies',
      formData(company)
    );
    return res.data;
  }

  async editCompany(id: number, company: CompanyData) {
    const res = await this.axios.patch<CompanyResponse>(
      `/companies/${id}`,
      formData(company)
    );
    return res.data;
  }

  async deleteCompany(id: number) {
    const res = await this.axios.delete(`/companies/${id}`);
    return res.data;
  }

  async getEmployees(companyID: number): Promise<EmployeesResponse> {
    const res = await this.axios.get(`companies/${companyID}/employee`);
    return res.data;
  }

  async addEmployee(companyID: number, selectedUser: number) {
    const res = await this.axios.post(`companies/${companyID}/employee`, {
      employee_id: selectedUser,
      link_url: `${window.origin}/my-requests`,
    });
    return res.data;
  }

  async removeEmpolyee(companyID: number, selectedUser: number) {
    const res = await this.axios.delete(`companies/${companyID}/employee`, {
      data: {
        employee_id: selectedUser,
      },
    });
    return res.data;
  }

  async addAdmin(companyID: number, selectedUser: number) {
    const res = await this.axios.post(`companies/${companyID}/admin`, {
      employee_id: selectedUser,
    });
    return res.data;
  }

  async removeAdmin(companyID: number, selectedUser: number) {
    const res = await this.axios.delete(`companies/${companyID}/admin`, {
      data: {
        employee_id: selectedUser,
      },
    });
    return res.data;
  }

  async addDispatcher(companyID: number, selectedUser: number) {
    const res = await this.axios.post(`companies/${companyID}/dispatcher`, {
      employee_id: selectedUser,
    });
    return res.data;
  }

  async removeDispatcher(companyID: number, selectedUser: number) {
    const res = await this.axios.delete(`companies/${companyID}/dispatcher`, {
      data: {
        employee_id: selectedUser,
      },
    });
    return res.data;
  }

  async getAddToCompanyRequest() {
    const res = await this.axios.get(`user/employee_requests`);
    return res.data;
  }

  /**
   * Accept invitation to join the company
   * user accept request from company
   * @param companyID company ID
   * @returns
   */
  async acceptAsUserToJoinCompany(companyID: number) {
    const res = await this.axios.patch(`companies/${companyID}/accept`, {
      link_url: `${window.origin}/company/${companyID}/members`,
    });
    return res.data;
  }

  /**
   * Leave company as member
   * @param companyID company ID
   * @returns
   */
  async leaveCompany(companyID: number) {
    const res = await this.axios.delete(`companies/${companyID}/leave`);
    return res;
  }

  /**
   * Accept request to join a company
   * admin accept request from user
   * @param userID user ID
   */

  async acceptAsCompanyToAddUser(userID: number) {
    const res = await this.axios.patch(
      `companies/user_request/${userID}/accept`,
      {
        link_url: `${window.origin}/dashboard`,
      }
    );
    return res.data;
  }

  async declineAddToCompanyRequest(companyID: number) {
    const res = await this.axios.patch(`companies/${companyID}/decline`);
    return res.data;
  }

  ////////////////////////////////////////
  // Company Payments Requests Section
  ///////////////////////////////////////

  async getCompanyPayment(companyID: number | null) {
    if (!companyID) {
      return null;
    }
    const res = await this.axios.get<{
      payment_info: CompanyPaymentInfo;
    } | null>(`/companies/${companyID}/payment_info`);
    return res.data?.payment_info;
  }

  async createPaymentInfo(
    companyId: number | undefined,
    payment_info: CompanyPaymentInput
  ) {
    const res = await this.axios.post(
      `companies/${companyId}/payment_info`,
      formData({ payment_info })
    );
    return res.data;
  }

  async updatePaymentInfo(
    companyId: number | undefined,
    payment_info: CompanyPaymentInput
  ) {
    const res = await this.axios.patch(
      `companies/${companyId}/payment_info`,
      formData({ payment_info })
    );
    return res.data;
  }

  ////////////////////////////////////////
  // Insurance Requests Section
  ///////////////////////////////////////

  async createInsurance(insurance: InsuranceInput) {
    const res = await this.axios.post<InsuranceResult>(
      '/insurances',
      formData(insurance)
    );
    return res.data;
  }

  async editInsurance(id: number, insurance: InsuranceInput) {
    const res = await this.axios.patch<InsuranceResult>(
      `/insurances/${id}`,
      formData(insurance)
    );
    return res.data;
  }

  async deleteInsurance(id: number) {
    const res = await this.axios.delete(`/insurances/${id}`);
    return res.data;
  }

  ////////////////////////////////////////
  // Chat Requests Section
  ///////////////////////////////////////

  async getChats(since?: Date) {
    //TODO support since
    const res = await this.axios.get<{ chats: Chat[] }>('/chats');
    return res.data.chats;
  }

  async createChat(participant: number, message: string) {
    const res = await this.axios.post<CreateChatResponse>(
      '/chats',
      formData({ participant, message })
    );
    return res.data;
  }

  //TODO startChat vs createChat – seems to be redundant
  async startChat(id: number, message: string) {
    const res = await this.axios.post(`/chats`, {
      participant: id,
      message,
    });
    return res.data;
  }

  async getChatMessages(chatId: number, since?: string) {
    const res = await this.axios.get<{ messages: ChatMessage[] | null }>(
      `/chats/${chatId}/messages`,
      { params: { since } }
    );
    return res.data.messages ?? [];
  }

  async sendMessage(chatId: number, message: string) {
    const res = await this.axios.post(
      `/chats/${chatId}/messages`,
      formData({
        message,
      })
    );
    return res.data;
  }

  //////////////////////////////////////////
  // Edit User Profile
  ///////////////////////////////////////

  async editUserProfile(profile: UserProfileInput) {
    const res = await this.axios.patch<EditProfileResponse>(
      '/auth',
      formData(profile)
    );
    return res.data;
  }

  //////////////////////////////////////////
  // Offers
  ///////////////////////////////////////

  async getAllCargosCapacities() {
    const res = await this.axios.get<MyOffers>(`/user/cargos_capacities`);
    return res.data;
  }

  async createCargo(cargo: CargoOfferInput) {
    const res = await this.axios.post<CargoOffer>('/matching/cargo', cargo);
    return res.data;
  }

  async updateCargo(id: number, cargo: CargoOfferInput) {
    const res = await this.axios.patch(`/matching/cargo/${id}`, cargo);
    return res.data;
  }

  /* async getCargos() {
    const res = await this.axios.get<Cargo[]>('/matching/cargo');
    return res.data;
  } */

  async changeCargoVisibility(id: number, to: string) {
    const res = await this.axios.patch<CapacityMatchesResponse>(
      `/matching/cargo/${id}/visible`,
      {
        visible: to,
      }
    );
    return res.data;
  }

  async deleteCargos(cargos: number[]) {
    const res = await this.axios.patch<CargoDisableResponse>(
      `/matching/disable/cargo`,
      {
        cargos,
      }
    );
    return res.data;
  }

  async createCapacity(capacity: CapacityOfferInput) {
    const res = await this.axios.post<CapacityOffer>(
      '/matching/capacity',
      capacity
    );
    return res.data;
  }

  async updateCapacity(id: number, capacity: CapacityOfferInput) {
    const res = await this.axios.patch(`/matching/capacity/${id}`, capacity);
    return res.data;
  }

  /* async getCapacities() {
    const res = await this.axios.get<Capacity[]>('/matching/capacity');
    return res.data;
  } */

  async changeCapacityVisibility(id: number, to: string) {
    const res = await this.axios.patch<CapacityMatchesResponse>(
      `/matching/capacity/${id}/visible`,
      {
        visible: to,
      }
    );
    return res.data;
  }

  async deleteCapacities(capacities: number[]) {
    const res = await this.axios.patch<CapacityDisableResponse>(
      `/matching/disable/capacity/`,
      {
        capacities,
      }
    );
    return res.data;
  }

  //////////////////////////////////////////
  // Matching
  ///////////////////////////////////////

  async isValidZipCode(code: string, country = 'de') {
    const res = await this.axios.get<{ valid: boolean }>(
      `/zipcode/${country}/${code}`
    );
    return res.data.valid;
  }

  async getMatch(id: number) {
    const res = await this.axios.get<MatchingResponse>(`/matching/match/${id}`);
    return res.data;
  }

  async cargoMatches(id: number) {
    const res = await this.axios.get<CargoMatchesResponse>(
      `/matching/cargo/${id}/matches`
    );
    return res.data;
  }
  async capacityMatches(id: number) {
    const res = await this.axios.get<CapacityMatchesResponse>(
      `/matching/capacity/${id}/matches`
    );
    return res.data;
  }

  async interestedMatch(id: number) {
    const res = await this.axios.patch(`/matching/match/${id}/interested`, {
      visible: 'everyone',
    });
    return res.data;
  }

  async cancelMatch(id: number) {
    const res = await this.axios.patch(`/matching/match/${id}/cancel`, {
      visible: 'everyone',
    });
    return res.data;
  }

  async findChatMatch(id: number) {
    const res = await this.axios.get<{
      chat: {
        created_at: string;
        id: number;
        match_id: number;
        updated_at: string;
      };
    }>(`/matching/match/${id}/chat`);
    return res.data;
  }

  async acceptMatch(id: number, chat: number) {
    const res = await this.axios.patch<{ id: number }>(
      `/matching/match/${id}/accept`,
      {
        link_to_conversation: `${window.origin}/chat/${chat}`,
      }
    );
    return res.data;
  }

  async informContactsAboutCapacity(
    ids: number[],
    includeCompanyContacts = false
  ) {
    try {
      await this.axios.put(`/matching/capacity/matches/inform_contacts`, {
        ids,
        includeCompanyContacts,
        signup_url: `${window.origin}/signup`,
        blocklist_url: `${window.origin}/blocklist`,
      });
      return { error: null };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { data } = error.response ?? {};
        return data as { error: string };
      }
      throw error;
    }
  }

  async informContactsAboutCargo(
    ids: number[],
    includeCompanyContacts = false
  ) {
    try {
      await this.axios.put(`/matching/cargo/matches/inform_contacts`, {
        ids,
        includeCompanyContacts,
        signup_url: `${window.origin}/signup`,
        blocklist_url: `${window.origin}/blocklist`,
      });
      return { error: null };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { data } = error.response ?? {};
        return data as { error: string };
      }
      throw error;
    }
  }

  ////////////////////////////////////////
  // Contacts
  ////////////////////////////////////////

  /**
   * get list of contacts
   * @returns contacts
   */
  async getContacts(): Promise<ContactsResponse> {
    const res = await this.axios.get<ContactsResponse>('/user/contacts');
    return res.data;
  }

  async getCompanyContacts(): Promise<ContactsResponse> {
    const res = await this.axios.get<ContactsResponse>('/company/contacts');
    return res.data;
  }

  /**
   * get single contact
   * @param id contact id
   * @returns
   */
  async getContact(id: number) {
    const res = await this.axios.get(`/user/contacts/${id}`);
    return res.data;
  }

  async addContact(contact: ContactRecord) {
    const res = await this.axios.post(`/user/contacts`, contact);
    return res.data;
  }

  async deleteContact(id: number) {
    const res = await this.axios.delete(`/user/contacts/${id}`);
    return res.data;
  }

  async updateContact(id: number, contact: ContactRecord) {
    const res = await this.axios.put(`/user/contacts/${id}`, contact);
    return res.data;
  }

  async sendInviteEmail(id: number) {
    const res = await this.axios.put(`/user/contacts/${id}/invite`, {
      signup_url: `${window.origin}/signup`,
      blocklist_url: `${window.origin}/blocklist`,
    });
    return res.data;
  }

  ////////////////////////////////////////
  // Timeline
  ////////////////////////////////////////

  async getTimelinePosts() {
    // gets only friends and system posts
    const res = await this.axios.get<TimeLineResponse>(`/user/timeline`, {
      params: { friends: 'true' },
    });
    return res.data.timeline;
  }

  async addTimelinePost(content: string, image?: File) {
    await this.axios.post(`/user/timeline`, formData({ content, image }));
  }

  async deleteTimelinePost(id: number) {
    await this.axios.delete(`/user/timeline/${id}`);
  }

  async editTimelinePost(id: number, content: string) {
    await this.axios.patch(`/user/timeline/${id}`, formData({ content }));
  }

  async likeTimelinePost(id: number) {}

  async getTimelinePostDetails(id: number) {
    const res = await this.axios.get(`/user/timeline/${id}`);
    return res.data;
  }

  async addTimelinePostComment(id: number, comment: string, image?: File) {
    await this.axios.post(
      `/user/timeline/${id}/comment`,
      formData({ comment, image })
    );
  }

  async deleteTimelinePostComment(comment_id: number) {
    await this.axios.delete(`/user/timeline/comment/${comment_id}`);
  }

  async editTimelinePostComment(id: number, comment: string) {
    await this.axios.patch(
      `/user/timeline/comment/${id}`,
      formData({ comment })
    );
  }

  async askForSupport(
    name: string,
    contact_email: string,
    title: string,
    content: string
  ) {
    const res = await this.axios.post(`/email/support`, {
      name,
      contact_email,
      title,
      content,
    });

    // 204
    return res.status;
  }

  // //////////////////////////////////////
  // Presets
  // //////////////////////////////////////

  async getPresets() {
    const res = await this.axios.get<PresetsResults>(`/user/presets`);
    return res.data;
  }

  async getCompanyPresets() {
    const res = await this.axios.get<PresetsResults>(`/company/presets`);
    return res.data;
  }

  async addCargoPreset(cargo_preset: CargoPresetData) {
    const res = await this.axios.post(`/matching/presets/cargo_presets`, {
      cargo_preset,
    });
    return res.data;
  }

  async addCapacityPreset(capacity_preset: CapacityPresetData) {
    const res = await this.axios.post(`/matching/presets/capacity_presets`, {
      capacity_preset,
    });
    return res.data;
  }

  async editCargoPreset(cargo_preset: CargoPresetData, id: number) {
    const res = await this.axios.patch<PresetsResults>(
      `/matching/presets/cargo_presets/${id}`,
      { cargo_preset }
    );
    return res.data;
  }

  async editCapacityPreset(capacity_preset: CapacityPresetData, id: number) {
    const res = await this.axios.patch<PresetsResults>(
      `/matching/presets/capacity_presets/${id}`,
      { capacity_preset }
    );
    return res.data;
  }

  async deleteCargoPreset(id: number) {
    const res = await this.axios.delete<PresetsResults>(
      `/matching/presets/cargo_presets/${id}`
    );
    return res.data;
  }

  async deleteCapacityPreset(id: number) {
    const res = await this.axios.delete<PresetsResults>(
      `/matching/presets/capacity_presets/${id}`
    );
    return res.data;
  }
}
