import React, { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CompanyWithLogo } from '../../lib/types/company';
import { DataTable, SortOrder } from '../common/DataTable';
import { CompanyAvatarNameTitle } from './AvatarNameTitle';

const Actions = styled.div`
  width: 300px;
  display: flex;
  margin-left: -8px;
  gap: 8px;
`;

const EMail = styled.a`
  color: var(--grey800);
  text-decoration: none;
`;

type Props = {
  items: CompanyWithLogo[];
  renderActions: (item: CompanyWithLogo) => ReactNode;
};
export function UserRequestsDataTable({ items, renderActions }: Props) {
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    column: 'name',
    asc: true,
  });
  const columnConfig = useMemo(
    () => [
      {
        key: 'name',
        heading: 'Name',
        sortable: true,
        style: { width: 250 },
        render: ({ company, logo }: CompanyWithLogo) => (
          <CompanyAvatarNameTitle company={company} logo={logo} />
        ),
        value: ({ company }: CompanyWithLogo) => company.name,
      },
      {
        key: 'email',
        heading: 'E-Mail',
        sortable: true,
        render: ({ company }: CompanyWithLogo) => (
          <EMail href={`mailto:${company.email}`}>{company.email}</EMail>
        ),
        value: ({ company }: CompanyWithLogo) => company.email,
      },
      {
        key: 'actions',
        heading: 'Aktionen',
        style: { width: 0 },
        render: (item: CompanyWithLogo) => (
          <Actions>{renderActions(item)}</Actions>
        ),
      },
    ],
    [renderActions]
  );
  return (
    <DataTable
      columns={columnConfig}
      items={items}
      sortOrder={sortOrder}
      onSort={setSortOrder}
    />
  );
}
