import styled from 'styled-components';

import coloredDetour from './ColoredDetour.svg';
import coloredExport from './ColoredExport.svg';
import coloredGate from './ColoredGate.svg';
import coloredImport from './ColoredImport.svg';
import coloredTruck from './ColoredTruck.svg';
import container20Heavy from './container20Heavy.svg';
import container20Light from './container20Light.svg';
import container40 from './container40.svg';
import container45 from './container45.svg';
import flatRack from './FlatRack.svg';
import highCube from './HighCube.svg';
import openTop from './OpenTop.svg';
import rearFlush from './RearFlush.svg';
import addicon from './SpecialAddIcon.svg';
import truck2x20 from './truck2x20.svg';
import truck20 from './truck20.svg';
import truck40 from './truck40.svg';
import truck45 from './truck45.svg';

const Icon = styled.div`
  background-position: center;
  background-repeat: no-repeat;
`;

export const Truck20 = styled(Icon)`
  background-image: url(${truck20});
  width: 37px;
  height: 23px;
`;

export const Truck2x20 = styled(Icon)`
  background-image: url(${truck2x20});
  width: 63px;
  height: 23px;
`;
export const Truck40 = styled(Icon)`
  background-image: url(${truck40});
  width: 63px;
  height: 23px;
`;

export const Truck45 = styled(Icon)`
  background-image: url(${truck45});
  width: 83px;
  height: 23px;
`;

export const Container20Light = styled(Icon)`
  background-image: url(${container20Light});
  width: 54px;
  height: 21px;
`;

export const Container20Heavy = styled(Icon)`
  background-image: url(${container20Heavy});
  width: 54px;
  height: 21px;
`;

export const Container40 = styled(Icon)`
  background-image: url(${container40});
  width: 66px;
  height: 21px;
`;

export const Container45 = styled(Icon)`
  background-image: url(${container45});
  width: 90px;
  height: 21px;
`;

export const HighCube = styled(Icon)`
  background-image: url(${highCube});
  width: 71px;
  height: 31px;
`;
export const OpenTop = styled(Icon)`
  background-image: url(${openTop});
  width: 61px;
  height: 41px;
`;
export const FlatRack = styled(Icon)`
  background-image: url(${flatRack});
  width: 71px;
  height: 31px;
`;
export const RearFlush = styled(Icon)`
  background-image: url(${rearFlush});
  width: 81px;
  height: 31px;
`;

export const ColoredGate = styled(Icon)`
  background-image: url(${coloredGate});
  height: 32px;
  width: 32px;
`;

export const ColoredImport = styled(Icon)`
  background-image: url(${coloredImport});
  height: 32px;
  width: 32px;
`;
export const ColoredExport = styled(Icon)`
  background-image: url(${coloredExport});
  height: 32px;
  width: 32px;
`;
export const ColoredDetour = styled(Icon)`
  background-image: url(${coloredDetour});
  height: 32px;
  width: 32px;
`;

export const ColoredTruck = styled(Icon)`
  background-image: url(${coloredTruck});
  height: 32px;
  width: 32px;
`;

export const AddSpecialIcon = styled(Icon)`
  background-image: url(${addicon});
  width: 32px;
  height: 32px;
`;
