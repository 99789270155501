import React, { ReactNode } from 'react';

import { SvgCluster } from './SvgCluster';

type Props = {
  color: string;
  children: ReactNode;
  onClick?: () => void;
};
export function MatchCluster({ color, children, onClick }: Props) {
  return (
    <SvgCluster
      onClusterClick={onClick}
      render={(count) => ({
        width: 48,
        height: 48,
        svg: `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <circle cx="24" cy="24" opacity="1" r="18" />
            <circle cx="24" cy="24" opacity=".3" r="24" />
          </svg>`,
        label: {
          text: String(count),
          color: 'white',
          fontFamily: 'Manrope',
          fontWeight: '700',
        },
      })}
    >
      {children}
    </SvgCluster>
  );
}
