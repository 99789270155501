import { useRef, useState } from 'react';

import { useInterval } from './useInterval';

export function usePolling<T>(fn: (prev?: T) => Promise<T>, interval = 2000) {
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<unknown>();
  const prevData = useRef<T | undefined>(undefined);
  const pollNow = async () => {
    try {
      const newData = await fn(prevData.current);
      if (newData !== prevData.current) {
        prevData.current = newData;
        setData(newData);
      }
    } catch (err) {
      setError(err);
    }
  };
  useInterval(pollNow, interval, true);
  return { data, setData, error, pollNow };
}
