import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { EncodedPolyline, GoogleMap } from 'googlemaps-react-primitives';
import React from 'react';

import {
  CargoOrCapacityOfferDetails,
  MatchDetails,
} from '../../../lib/types/ui';
import styles from './mapStyles.json';
import { MatchMarker } from './MatchMarker';

function renderLoadingStatus(status: Status) {
  return <h1>{status}</h1>;
}

type Props = {
  offer: CargoOrCapacityOfferDetails;
  matches: MatchDetails[];
  selected?: MatchDetails;
  setSelected: (details?: MatchDetails) => void;
  loadAgain: () => void;
  type: string;
};
export function MatchesMap({ offer, selected, setSelected, matches }: Props) {
  return (
    <Wrapper
      apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
      libraries={['geometry']}
      render={renderLoadingStatus}
      language="de"
    >
      <GoogleMap
        style={{ width: '100%', height: '100%' }}
        styles={styles}
        maxZoom={12}
        disableDefaultUI
        autoFit
        onClick={(ev) => {
          if (selected) setSelected(undefined);
        }}
      >
        <MatchMarker type={offer.offerType} position={offer.pos} />
        {/* <MatchCluster
          color={offer.offerType === 'cargo' ? '#FF974A' : '#1DABF2'}
        > */}
        {matches
          .filter((d) => d.matchId !== selected?.matchId)
          .map((details) => (
            <MatchMarker
              key={details.matchId}
              position={details.pos_location}
              type={details.type}
              onClick={() => setSelected(details)}
            />
          ))}
        {/* </MatchCluster> */}
        {selected?.polyline && (
          <EncodedPolyline
            locations={selected.polyline}
            strokeColor="#1DABF2"
          />
        )}
        {selected?.approaching_polyline && (
          <EncodedPolyline
            locations={selected.approaching_polyline}
            strokeColor="#FF974A"
          />
        )}
        {selected && (
          <MatchMarker
            position={selected.pos_location}
            type={selected.type}
            selected
          />
        )}
        {selected?.pos_load_unload && (
          <MatchMarker position={selected.pos_load_unload} type="waypoint" />
        )}
        {selected?.pos_destination && (
          <MatchMarker position={selected.pos_destination} type="destination" />
        )}
      </GoogleMap>
    </Wrapper>
  );
}
