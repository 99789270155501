import React from 'react';

import { capacityOfferDetails } from '../../lib/convert';
import { CapacityOfferWithMatches } from '../../lib/types/offers';
import { Row } from '../layout/Row';
import { ManagePresetsDialog } from '../presets/ManagePresetsDialog';
import { CapacityOfferTable } from './CapacityOfferTable';
import { CreateCapacityDialog } from './CreateCapacityDialog';

type Props = {
  capacities: CapacityOfferWithMatches[];
  invalidate: () => void;
};

export function MyCapacityOffers({ capacities, invalidate }: Props) {
  return (
    <CapacityOfferTable
      items={capacities.map(capacityOfferDetails)}
      invalidate={invalidate}
    >
      <Row gap="20px">
        <CreateCapacityDialog />
        <ManagePresetsDialog />
      </Row>
    </CapacityOfferTable>
  );
}
