import styled from 'styled-components';

import { H6 } from '../common/typography/Headings';
import { Stack } from '../layout/Stack';

export const TabContent = styled(Stack).attrs({ gap: '12px' })`
  text-align: center;
  max-width: 50ch;
  margin: auto;
  color: var(--grey500);
  ${H6} {
    color: var(--grey800);
  }
`;
