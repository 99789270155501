import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  cargoOfferInputFromForm,
  cargoPresetToForm,
  formatDate,
} from '../../lib/convert';
import { CargoPreset } from '../../lib/types/preset';
import { CargoFormValues } from '../../lib/types/ui';
import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { WarehouseIcon } from '../common/Icons';
import { CargoDialog } from '../offer/CargoDialog';
import addIcon from '../offer/icons/SpecialAddIcon.svg';
import { ManagePresetsDialog } from '../presets/ManagePresetsDialog';
import { useApi } from '../UserState';

type Props = {
  preset?: CargoPreset;
  children?: ReactElement;
  onCreate?: () => void;
};

export const CreateCargoDialog = ({ preset, children, onCreate }: Props) => {
  const navigate = useNavigate();
  const api = useApi();

  const values = useMemo(
    () => ({
      ...(preset && cargoPresetToForm(preset)),
      date_start: formatDate(new Date()),
    }),
    [preset]
  );

  const onSave = async (values: CargoFormValues) => {
    const { cargo } = await api.createCargo(cargoOfferInputFromForm(values));
    onCreate?.();
    navigate(`/matching/results/${cargo.id}/cargo`);
  };

  const trigger = children ?? (
    <Btn size="small">
      <WarehouseIcon />
      Neuer Überhang
    </Btn>
  );
  return (
    <CargoDialog
      values={values}
      onSave={onSave}
      trigger={trigger}
      header={
        <Dialog.Header title="Neuer Überhang" icon={addIcon}>
          <ManagePresetsDialog />
        </Dialog.Header>
      }
    />
  );
};
