import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Avatar } from '../common/Avatar';
import { body5, body6 } from '../common/typography/Body';
import { Stack } from '../layout/Stack';

type Side = 'left' | 'right';

const Root = styled.div<{ side: Side }>`
  display: flex;
  justify-content: ${(p) => (p.side === 'left' ? 'flex-start' : 'flex-end')};
  & + & {
    margin-top: 8px;
  }
`;

const Container = styled.div<{ side: Side }>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.side === 'left' ? 'flex-end' : 'flex-start')};
`;

const Message = styled.div<{ side: Side }>`
  display: flex;
  flex-direction: ${(p) => (p.side === 'left' ? 'row' : 'row-reverse')};
  align-items: center;
  gap: 8px;
`;

const Image = styled.img``;

const Text = styled.div<{ side: Side }>`
  ${body5}
  color: ${(p) => (p.side === 'left' ? 'var(--white)' : 'var(--primary500)')};
  background: ${(p) =>
    p.side === 'left' ? 'var(--primary500)' : 'var(--neutral200)'};
  padding: 6px 16px;
  border-radius: 20px;
  white-space: pre-wrap;
  word-break: break-all;
`;

const Time = styled.div`
  ${body6}
  color: var(--neutral500);
  margin-top: 4px;
`;

const SystemMessages = styled.div`
  ${body5}
  background: var(--tertiary700);
  color: var(--white);
  width: fit-content;
  padding: 0.5em 1em;
  border-radius: 10px;
`;
const SystemMessagesStack = styled(Stack)`
  width: 100%;
  align-items: center;
`;
type Props = {
  side?: Side;
  name?: string;
  avatar?: string | null;
  message?: string;
  image?: string | null;
  date: Date;
  system_message?: boolean;
};

export const ChatBubble = forwardRef<HTMLDivElement, Props>(
  (
    { side = 'right', avatar, name = '', image, message, date, system_message },
    ref
  ) => (
    <Root ref={ref} side={side}>
      {system_message ? (
        <SystemMessagesStack>
          <SystemMessages>{message}</SystemMessages>
          <Time>{format(date, isToday(date) ? 'HH:mm' : 'dd.MM. HH:mm')}</Time>
        </SystemMessagesStack>
      ) : (
        <Container side={side}>
          <Message side={side}>
            <Avatar size="24px" image={avatar} name={name} />
            {image ? <Image src={image} /> : <Text side={side}>{message}</Text>}
          </Message>
          <Time>{format(date, isToday(date) ? 'HH:mm' : 'dd.MM. HH:mm')}</Time>
        </Container>
      )}
    </Root>
  )
);
