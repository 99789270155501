import React from 'react';
import styled from 'styled-components';

import { UserData } from '../../lib/publicApi';
import { CompanyData } from '../../lib/types/company';
import { Avatar } from '../common/Avatar';
import { Headings, Special } from '../common/typography';

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  &:first-child {
    flex: 0 0 42px;
  }

  &:last-child {
    flex: 0 1 100%;
  }

  .full-name {
    ${Headings.h6}
    color: var(--primary800);
  }
  .sub-title {
    ${Special.subtitleMedium}
    color: var(--grey500);
  }
`;

type Props = {
  user: UserData;
  subTitle?: keyof UserData;
};

export function AvatarNameTitle({ user, subTitle = 'job_title' }: Props) {
  const { first_name, last_name, picture } = user;
  return (
    <Container>
      <Avatar image={picture} name={`${first_name} ${last_name}`} />
      <div>
        <div className="full-name">
          {first_name} {last_name}
        </div>
        <div className="sub-title">{user[subTitle]}</div>
      </div>
    </Container>
  );
}

type CompanyAvatarProps = {
  company: CompanyData;
  logo: string;
  subTitle?: keyof CompanyData;
};
export function CompanyAvatarNameTitle({
  company,
  logo,
  subTitle = 'website_link',
}: CompanyAvatarProps) {
  return (
    <Container>
      <Avatar image={logo} name={`${company.name}`} />
      <div>
        <div className="full-name">{company.name}</div>
        <div className="sub-title">{company[subTitle]}</div>
      </div>
    </Container>
  );
}
