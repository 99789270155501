import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { ConnectedContact } from '../../lib/types/connections';
import { Btn } from '../common/Btn';
import { ChatBubbleOutlineIcon, MinusCircleIcon } from '../common/Icons';
import { useApi } from '../UserState';
import { ConfirmDisconnectModal } from './ConfirmDisconnectModal';
import { ConnectionsTable } from './ConnectionsTable';
import { EmptyConnections } from './EmptyTables/EmptyConnections';

// CF-883: Temporarily disable favorites feature
/* function isFavorite({ contact, connection }: ConnectedContact) {
  const userIsPerson2 = contact.id === connection.sender_id;
  return userIsPerson2
    ? connection.favorite_for_person_2
    : connection.favorite_for_person_1;
} */

type Props = {
  items: ConnectedContact[];
  onAction: () => void;
};
export function MyConnectionsTable({ items, onAction }: Props) {
  const api = useApi();
  const [pendingContactDeletion, selectContactForDeletion] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  return items.length ? (
    <>
      <ConnectionsTable
        items={items}
        columns={['image', 'name', 'company', 'location']}
        renderActions={(item) => (
          <>
            <Btn
              title="Chat"
              appearance="transparent"
              onClick={() => navigate(`/user/${item.contact.id}/chat`)}
            >
              <ChatBubbleOutlineIcon />
            </Btn>
            {/* {isFavorite(item) ? (
              <Btn
                title="von Favoriten entfernen"
                appearance="transparent"
                onClick={async () => {
                  await api.removeFavorite(item.connection.id);
                  onAction();
                }}
              >
                <StarIcon />
              </Btn>
            ) : (
              <Btn
                title="zu Favoriten hinzufügen"
                appearance="transparent"
                onClick={async () => {
                  await api.addFavorite(item.connection.id);
                  onAction();
                }}
              >
                <StarOutlineIcon />
              </Btn>
            )} */}
            <Btn
              title="Kontakt löschen"
              appearance="transparent"
              onClick={() => selectContactForDeletion(item.connection.id)}
            >
              <MinusCircleIcon />
            </Btn>
          </>
        )}
      />
      {pendingContactDeletion !== null && (
        <ConfirmDisconnectModal
          onConfirm={async () => {
            await api.declineConnection(pendingContactDeletion);
            onAction();
          }}
          close={() => selectContactForDeletion(null)}
        />
      )}
    </>
  ) : (
    <EmptyConnections />
  );
}
