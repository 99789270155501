import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { AppScreen } from '../../components/common/AppScreen';
import { Avatar } from '../../components/common/Avatar';
import { Btn } from '../../components/common/Btn';
import { Columns, DataTable } from '../../components/common/DataTable';
import {
  BookmarkIcon,
  BookmarkOutlineIcon,
  ConnectionsIcon,
} from '../../components/common/Icons';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { EmptySearch } from '../../components/connections/EmptyTables/EmptySearch';
import { useApi } from '../../components/UserState';
import { Bookmark, SearchResultItem } from '../../lib/types/connections';
import { useApiCall, useApiResult } from '../../lib/useApiCall';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: -8px;
  gap: 8px;
`;

function isBookmark(bookmarks: Bookmark[], id: number) {
  return bookmarks.some(({ user }) => user.id === id);
}

function renderNode(searchResult: any[], columns: Columns<SearchResultItem>) {
  return !searchResult.length ? (
    <EmptySearch />
  ) : (
    <DataTable
      columns={columns}
      items={searchResult}
      sortOrder={{ column: 'name', asc: true }}
    />
  );
}

export function Search() {
  const q = new URL(window.location.href).searchParams.get('q') ?? '';
  const searchResult = useApiResult('search', q);
  const { data, invalidate } = useApiCall('getBookmarks');

  const api = useApi();
  const navigate = useNavigate();

  const columns: Columns<SearchResultItem> = [
    {
      key: 'Image',
      render: (item) => (
        <Avatar
          name={`${item.user.first_name} ${item.user.last_name}`}
          image={item.user.picture}
        />
      ),
      style: { width: 0 },
    },
    {
      key: 'name',
      heading: 'Name',
      sortable: true,
      value: ({ user }) => `${user.last_name}, ${user.first_name}`,
      render: (item) => (
        <Btn
          appearance="transparent"
          onClick={() => navigate(`/user/${encodeURIComponent(item.user.id)}`)}
        >
          {item.user.first_name} {item.user.last_name}
        </Btn>
      ),
    },
    {
      key: 'location',
      heading: 'Basisstandort',
      sortable: true,
      value: (item) => item.user.city,
      render: (item) => item.user.city,
    },
    {
      key: 'actions',
      heading: 'Aktionen',
      style: { width: 0 },
      render: (item) => (
        <Actions>
          {item.status === 'possible' && (
            <>
              {isBookmark(data ?? [], item.user.id) ? (
                <Btn
                  title="aus Lesezeichen entfernen"
                  appearance="transparent"
                  onClick={async () => {
                    await api.removeBookmark(item.user.id);
                    invalidate();
                  }}
                >
                  <BookmarkIcon />
                </Btn>
              ) : (
                <Btn
                  title="zu Lesezeichen hinzufügen"
                  appearance="transparent"
                  onClick={async () => {
                    await api.addBookmark(item.user.id);
                    invalidate();
                  }}
                >
                  <BookmarkOutlineIcon />
                </Btn>
              )}
              <Btn
                title="Kontaktanfrage senden"
                appearance="transparent"
                onClick={async () => {
                  await api.connect(item.user.id);
                  //TODO stay on page, update row
                  navigate('/connections/pending');
                }}
              >
                <ConnectionsIcon />
              </Btn>
            </>
          )}
        </Actions>
      ),
    },
  ];

  return (
    <AppScreen title="Suche">
      {!searchResult || !data ? (
        <LoadingCircle />
      ) : (
        renderNode(searchResult, columns)
      )}
    </AppScreen>
  );
}
