import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AddYourSelf } from './AddYourSelf';
import { DataPrivacy } from './DataPrivacy';
import { DoubleFooter } from './Footer';
import { ImageSection } from './ImageSection';
import { OverView } from './OverView';
import { Pricing } from './Pricing';
import { Quotation } from './Quotation';
import { Solution } from './Solution';
import { Welcome } from './Welcome';

const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MocKUp = () => {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      const id = hash.split('#').join('');
      document.getElementById(id)?.scrollIntoView();
    }
  }, [hash]);
  return (
    <Root>
      <Welcome />
      <OverView />
      <Solution />
      <Pricing />
      <DataPrivacy />
      <Quotation />
      <AddYourSelf />
      <ImageSection />
      <DoubleFooter />
    </Root>
  );
};
