import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import React, { useState } from 'react';
import styled from 'styled-components';

import { TimelineComment, TimelinePost } from '../../lib/types/user';
import { useApiCall } from '../../lib/useApiCall';
import { BtnLink } from '../common/Btn';
import { CargoFacesIcon } from '../common/Icons';
import { TableLink } from '../common/Link';
import { Body4, Body6 } from '../common/typography/Body';
import { UserAvatar } from '../common/UserAvatar';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { useApi, useUserData } from '../UserState';
import { Card } from './Card';
import { Comment } from './Comment';
import { CommentInput } from './CommentInput';
import { EditTextArea } from './EditTextArea';
import { PostMenu } from './PostMenu';

const Root = styled(Card)`
  flex: 1 1 300px;
  position: relative;
  word-break: break-all;
  padding: 0px;
`;

const UserInfo = styled.div<{ system_post: boolean }>`
  display: flex;
  gap: 16px;
  background: ${(p) => p.system_post && 'var(--secondary500)'};
  color: ${(p) => p.system_post && 'white'};
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 16px;
`;

const UserText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 45vh;
  border-radius: 10px;
`;

const CargoIconBox = styled.div`
  border-radius: 50%;
  background: white;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ServerText = styled(Body4)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--grey500);
  word-break: break-word;
  white-space: pre-wrap;
  & h3 {
    color: var(--primary800);
  }
  & a {
    color: var(--tertiary500);
    cursor: pointer;
    text-decoration: none;
  }
`;

type Props = {
  post: TimelinePost;
  onDelete: () => any;
  onEdit: (input: string) => any;
};

export function Post({ post, onDelete, onEdit }: Props) {
  const { user, timeline_post, image, timeline_comments } = post;
  const { id, content, created_at, system_post, edited_at } = timeline_post;
  const currentUser = useUserData();
  const api = useApi();
  const [expandComments, setExpandComments] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { data, invalidate } = useApiCall(
    expandComments ? 'getTimelinePostDetails' : false,
    id
  );

  const comments = data?.timeline_post?.timeline_comments ?? timeline_comments;
  const date = new Date(created_at);

  const handleOnEdit = () => {
    setIsEdit(true);
  };

  const editPost = (input: string) => {
    onEdit(input);
    setIsEdit(false);
  };

  return (
    <Root>
      {user && user.id === currentUser.id && (
        <PostMenu post={post} onDelete={onDelete} onEdit={handleOnEdit} />
      )}
      <Stack>
        <UserInfo system_post={system_post}>
          {user ? (
            <BtnLink
              size="tiny"
              appearance="transparent"
              color="neutral"
              to={`/user/${user.id}`}
            >
              <UserAvatar size="32px" user={user} />
            </BtnLink>
          ) : (
            <CargoIconBox>
              <CargoFacesIcon color="var(--secondary500)" />
            </CargoIconBox>
          )}
          <UserText>
            <Body4>
              {user ? (
                <TableLink to={`/user/${user.id}`}>
                  {`${user.first_name} ${user.last_name}`}
                </TableLink>
              ) : (
                'Cargofaces'
              )}
            </Body4>
            <Row justify="spread">
              <Body6 color="var(--grey400)">
                {format(date, isToday(date) ? 'HH:mm' : 'dd.MM. HH:mm')}
              </Body6>
              <Body6 color="var(--grey400)">
                {edited_at && '(bearbeitet)'}
              </Body6>
            </Row>
          </UserText>
        </UserInfo>
        {isEdit ? (
          <Stack style={{ padding: '16px', paddingTop: '0px' }}>
            <EditTextArea onSend={editPost} content={content} />
            {image && <Image src={image} />}
          </Stack>
        ) : (
          <Stack style={{ padding: '16px', paddingTop: '0px' }}>
            <ServerText dangerouslySetInnerHTML={{ __html: content }} />
            {image && <Image src={image} />}
            {/* user.id !== currentUser.id && (
            <Buttons>
            <IconButton>
            <LikeIcon /> Gefällt mir
            </IconButton>
            <IconButton>
            <CommentsIcon /> Kommentare
            </IconButton>
            </Buttons>
          ) */}
            {comments.map((c: TimelineComment, i: number) => (
              <Comment
                key={i}
                data={c}
                invalidate={() => {
                  setExpandComments(true);
                  invalidate();
                }}
              />
            ))}
            <CommentInput
              onSend={async (message, image) => {
                await api.addTimelinePostComment(id, message, image);
                setExpandComments(true);
                invalidate();
              }}
            />
          </Stack>
        )}
      </Stack>
    </Root>
  );
}
