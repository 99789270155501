import React from 'react';

import { UserData } from '../../lib/publicApi';
import { Avatar } from './Avatar';

type Props = {
  user: UserData;
  size?: string;
};

export function UserAvatar({ user, size }: Props) {
  const name = `${user.first_name} ${user.last_name}`;
  return <Avatar name={name} image={user.picture} size={size}></Avatar>;
}
