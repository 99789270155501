import React from 'react';
import styled from 'styled-components';

import { ResponsiveBtnLink } from '../../components/common/LandingHeader';
import { body4 } from '../../components/common/typography/Body';
import { h2, h5 } from '../../components/common/typography/Headings';
import { Stack } from '../../components/layout/Stack';
import checked from './images/check.svg';
import points from './images/points.svg';

const Main = styled.div`
  width: 50%;
  margin: 48px auto;
  position: relative;
  @media screen and (max-width: 960px) {
    width: 90%;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
`;

const Price = styled.div`
  background: #324654;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  border-radius: 8px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const IconTitle = styled.div`
  ${body4}
  color: white;
  white-space: nowrap;
`;

const Body = styled.div`
  color: white;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Five = styled.div`
  ${h2}
`;

const Month = styled.div`
  ${body4}
`;

const Feature = styled.div`
  padding: 20px;
  border-radius: 8px;
`;
const FTitle = styled.div`
  ${h5}
`;

const FText = styled.div`
  ${body4}
  color: #606267;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Paragrapgh = styled.div`
  ${body4}
  color: #606267;
  margin: auto;
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: center;
`;
const Title = styled.div`
  ${h2}
  color: #243748;
  margin: auto;
  text-align: center;
`;

const Points1 = styled.div`
  background-image: url(${points});
  background-size: cover;
  width: 214px;
  height: 120px;
  position: absolute;
  top: 170px;
  right: -65px;
  z-index: -1;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const Points2 = styled.div`
  background-image: url(${points});
  background-size: cover;
  width: 214px;
  height: 120px;
  position: absolute;
  top: 400px;
  left: -40px;
  z-index: -1;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const IconCon = styled.div`
  display: flex;
  margin: 8px auto;
  color: #243748;
`;

const UnderLineCon = styled.span`
  position: relative;
  width: fit-content;
  margin: auto;
`;

export const MeeagesBoxCon = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const UnderLine = styled.span`
  &:after {
    position: absolute;
    display: inline-block;
    bottom: 10px;
    z-index: -1;
    content: '';
    background: #ffcc92;
    width: 90%;
    height: 16px;
    margin: 0 auto;
    mix-blend-mode: normal;
    left: 0;
    /* optional animation */
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
`;

const EidtCenter = styled.div`
  height: auto;
`;

export const Pricing = () => {
  return (
    <Main id="price">
      <EidtCenter>
        <Stack>
          <Title>
            Was{' '}
            <UnderLineCon>
              <UnderLine>kostet</UnderLine>{' '}
            </UnderLineCon>
            mich das?
          </Title>
          <Paragrapgh>
            Während der Beta-Phase ist Cargofaces kostenlos für alle.
          </Paragrapgh>
          <Card>
            <Price>
              <Head>
                <IconTitle>
                  <b>Plattform</b>
                </IconTitle>
              </Head>
              <Body>
                <Five>freie</Five>
                <Month>Beta-Phase</Month>
              </Body>
            </Price>
            <Feature>
              <FTitle>Netzwerk, Chat, Matching</FTitle>
              <FText>
                Vernetze Dich über Cargofaces und nutze alle Vorteile von
                ContainerMatch und verwalte deine Kapazitäten und Überhänge in
                Cargofaces.
              </FText>
              <div>
                <IconCon>
                  <Icon src={checked} /> Matching-Algorithmus
                </IconCon>
                <IconCon>
                  <Icon src={checked} /> Business-Chat
                </IconCon>
                <IconCon>
                  <Icon src={checked} /> Gestalte mit!
                </IconCon>
              </div>
            </Feature>
          </Card>
          <Card>
            <Feature>
              <FTitle>Werde Gründungs-Mitglied</FTitle>
              <FText>
                Cargofaces ist gebaut, um das volle Potential digitaler
                Genossenschaften auszunutzen und demokratisch einen Wandel
                herbeizuführen.
              </FText>
              <div>
                <ResponsiveBtnLink color="secondary" to="/signup">
                  Jetzt registrieren
                </ResponsiveBtnLink>
              </div>
            </Feature>
            <Price>
              <Head>
                <IconTitle>
                  <b>Genossenschaft</b>
                </IconTitle>
              </Head>
              <Body>
                <Month>Coming</Month>
                <Month>soon</Month>
              </Body>
            </Price>
          </Card>
        </Stack>
      </EidtCenter>
      <Points1 />
      <Points2 />
    </Main>
  );
};
