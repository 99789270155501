import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import { AppHeader } from './AppHeader';
import { Footer } from './Footer';

const Main = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Content = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.main`
  flex: 1 1 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

type Props = {
  title?: string;
  children?: ReactNode;
  HeaderNavi?: ReactNode;
};
export function AppScreen({ title, children, HeaderNavi }: Props) {
  useEffect(() => {
    document.title = title ? `${title} – Cargofaces` : 'Cargofaces';
  }, [title]);

  return (
    <Main>
      <AppHeader HeaderNavi={HeaderNavi} />
      <Content>
        <Wrapper>{children}</Wrapper>
        <Footer />
      </Content>
    </Main>
  );
}
