import React from 'react';
import styled from 'styled-components';

import { Avatar, PresenceStatus } from '../common/Avatar';
import { TableLink } from '../common/Link';
import { body4, body5 } from '../common/typography/Body';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';

export const PersonStack = styled(Stack)`
  padding: 16px 0;
`;

const PersonName = styled.div`
  ${body4}
  color: var(--primary500);
`;

const PersonInfo = styled.div`
  ${body5}
  color: var(--neutral500);
`;

type Props = {
  id: number;
  name: string;
  info?: string | null;
  image?: string | null;
  presence?: PresenceStatus;
  to?: string;
};
export function Person({
  id,
  name,
  info,
  image,
  presence,
  to = `/user/${encodeURIComponent(id)}`,
}: Props) {
  return (
    <Row gap="12px">
      <Avatar size="32px" name={name} image={image} presence={presence} />
      <Stack gap="-6px">
        <PersonName>
          <TableLink to={to}>{name}</TableLink>
        </PersonName>
        {info && <PersonInfo>{info}</PersonInfo>}
      </Stack>
    </Row>
  );
}
