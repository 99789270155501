import React from 'react';
import { useParams } from 'react-router-dom';

import { ChatScreen } from '../../components/chat/ChatScreen';

export function Chat() {
  const { id } = useParams();
  const chatId = typeof id === 'string' ? parseInt(id) : undefined;
  return <ChatScreen chatId={chatId} />;
}
