import React from 'react';
import styled from 'styled-components';

import { body4 } from '../../components/common/typography/Body';
import { h2, h3, h5 } from '../../components/common/typography/Headings';
import { subtitleLarge } from '../../components/common/typography/Special';
import HighFive from './images/highfive.svg';
import Network from './images/network.svg';
import PadLock from './images/padlock.svg';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px auto;
  max-width: 100%;
`;
export const Title = styled.div`
  ${h2}
  display: flex;
  justify-content: center;
  color: #243748;
  @media screen and (max-width: 959px) {
    max-width: 90%;
    margin: auto;
    white-space: nowrap;
    ${h3}
  }
`;

const SubTitle = styled.div`
  ${subtitleLarge}
  max-width: 470px;
  text-align: center;
  align-self: center;
  color: #606267;
  margin-top: 16px;
  margin-bottom: 72px;
  @media screen and (max-width: 959px) {
    max-width: 90%;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 40px;
  }
`;

const Cards = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and (max-width: 959px) {
    max-width: 90%;
    margin: auto;
  }
`;

const CardItem = styled.div`
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #324654;
  width: 263px;
  padding: 24px;
  margin: 20px 20px;
`;

const CardTitle = styled.div`
  ${h5}
  margin: 16px auto;
  color: var(--white);
`;

const CardText = styled.div`
  ${body4}
  color: var(--white)
`;

export const TitleCon = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;

export const UnderLine = styled(Title)`
  &:after {
    position: absolute;
    display: inline-block;
    bottom: 10px;
    z-index: -1;
    content: '';
    background: #ffcc92;

    width: 100%;
    height: 16px;
    margin: 0 auto;
    /* optional animation */
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
`;

const Card = ({ title, text, icon }: any) => {
  return (
    <CardItem>
      <img src={icon} />
      <CardTitle>{title}</CardTitle>
      <CardText>{text}</CardText>
    </CardItem>
  );
};

export const OverView = () => {
  return (
    <Main id="overview">
      <TitleCon>
        <UnderLine>
          Logistik?
          <br />
          People‘s Business!
        </UnderLine>
      </TitleCon>
      <SubTitle>
        <p>
          Wir sorgen für mehr Vertrauen und Menschlichkeit in der Logistik,
          indem wir alle Beteiligten der Supply Chain mit einbeziehen.
        </p>
        <p>
          Cargofaces bietet dir eine persönliche Plattform und nützliche Tools
          für ein smartes und innovatives Arbeiten in der Logistik.
        </p>
      </SubTitle>
      <Cards>
        <Card
          icon={HighFive}
          title="In Kontakt bleiben"
          text="Profile und Chats für Verlader, Disponenten, Fahrer und Lagerpersonal."
        />
        <Card
          icon={Network}
          title="Das Netzwerk stärken"
          text="Ein Ort für alle Logistiker, um sich auszutauschen und zusammen zu arbeiten."
        />
        <Card
          icon={PadLock}
          title="Informationen erhalten"
          text="Gemeinsames Datenpotenzial durch offene Schnittstellen schaffen."
        />
      </Cards>
    </Main>
  );
};
