import React, { useMemo } from 'react';
import styled from 'styled-components';

import { AppScreen } from '../../components/common/AppScreen';
import { Card, CardHeader } from '../../components/common/Card';
import {
  EditIcon,
  EmailIcon,
  LocationIcon,
  PersonOutline,
} from '../../components/common/Icons';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { SideBySide } from '../../components/layout/SideBySide';
import { Stack } from '../../components/layout/Stack';
import { ContactItem } from '../../components/profile/ContactItem';
import { PersonStack } from '../../components/profile/Person';
import {
  ProfileHeader,
  ProfileHeaderInfo,
} from '../../components/profile/ProfileHeader';
import {
  AboutUserModal,
  UserDataModal,
} from '../../components/profile/ProfileModals';
import { useUserData } from '../../components/UserState';
import { useApiResult } from '../../lib/useApiCall';

export const IconBox = styled.div`
  display: flex;
  justify-content: flex-end;
  color: var(--primary500);
`;

export function Profile() {
  const user = useUserData();
  const connections = useApiResult('getConnections');
  const companies = useApiResult('getUserCompanies');

  const companyName = useMemo(() => {
    const selected = companies?.find(
      (com) => com.id === user.selected_company_id
    );
    return selected?.name;
  }, [companies]);

  return (
    <AppScreen title="Mein Profil">
      <Stack gap="24px">
        {companies && connections ? (
          <>
            <Card>
              <IconBox>
                <UserDataModal>
                  <EditIcon />
                </UserDataModal>
              </IconBox>
              <ProfileHeader
                title={`${user.first_name} ${user.last_name}`}
                subTitle={companyName}
                image={user.picture}
              >
                {user.city ? (
                  <ProfileHeaderInfo>
                    <LocationIcon />
                    {user.city}
                  </ProfileHeaderInfo>
                ) : null}
                {user.email ? (
                  <ProfileHeaderInfo>
                    <EmailIcon />
                    {user.email}
                  </ProfileHeaderInfo>
                ) : null}
                {user.job_title ? (
                  <ProfileHeaderInfo>
                    <PersonOutline />
                    {user.job_title}
                  </ProfileHeaderInfo>
                ) : null}
              </ProfileHeader>
            </Card>
            <SideBySide sideBarWidth="270px" gap="24px" equalHeight>
              <Card>
                <CardHeader>Kontakte </CardHeader>
                <PersonStack gap="24px">
                  {connections?.map((connected) => (
                    <ContactItem
                      key={connected.contact.id}
                      connected={connected}
                    />
                  ))}
                </PersonStack>
              </Card>
              <Card>
                <CardHeader>
                  Über mich
                  <AboutUserModal>
                    <EditIcon />
                  </AboutUserModal>
                </CardHeader>
                <div>{user.about_me}</div>
              </Card>
            </SideBySide>
          </>
        ) : (
          <LoadingCircle />
        )}
      </Stack>
    </AppScreen>
  );
}
