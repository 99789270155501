import * as ToastPrimitive from '@radix-ui/react-toast';
import React, { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

const VIEWPORT_PADDING = '25px';

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}))` },
  to: { transform: 'translateX(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}))` },
});

const StyledViewport = styled(ToastPrimitive.Viewport)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${VIEWPORT_PADDING};
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

const StyledToast = styled(ToastPrimitive.Root)`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;
  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: ${hide} 100ms ease-in forwards;
    }
    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe='end'] {
      animation: ${swipeOut} 100ms ease-out forwards;
    }
  }
`;

const StyledTitle = styled(ToastPrimitive.Title)`
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--primary500);
`;

const StyledDescription = styled(ToastPrimitive.Description)`
  grid-area: description;
  margin: 0;
  color: var(--grey500);
`;

export const ToastProvider = ToastPrimitive.Provider;
export const ToastViewport = StyledViewport;

export type Props = {
  title: string;
  children: ReactNode;
};

export function Toast({ title, children }: Props) {
  return (
    <StyledToast>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{children}</StyledDescription>
    </StyledToast>
  );
}
