import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Stack } from '../layout/Stack';
import { H6 } from './typography/Headings';

const Container = styled.section`
  margin: 24px 0;
`;

type Props = {
  title: string;
  children?: ReactNode;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};
export function Section({ title, children, headingLevel = 'h3' }: Props) {
  return (
    <Container>
      <Stack gap="2rem">
        <H6 as={headingLevel} color="var(--primary500)">
          {title}
        </H6>
        {children}
      </Stack>
    </Container>
  );
}
