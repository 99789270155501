import React from 'react';
import styled from 'styled-components';

import { body4 } from '../../components/common/typography/Body';
import { h5 } from '../../components/common/typography/Headings';
import { Row } from '../../components/layout/Row';
import APIIcon from './images/api.svg';
import Cloud from './images/cloud.svg';
import Germany from './images/germany.svg';
import Visible from './images/visible.svg';
import { FirstSide, MainTitle, SecondSide, SubTitle } from './Welcome';

const Territory = '/images/background5.jpg';

const Background = styled.div<{
  background?: string;
}>`
  background-attachment: fixed;
  background: linear-gradient(
      0deg,
      rgba(50, 70, 84, 0.8),
      rgba(50, 70, 84, 0.8)
    ),
    url(${Territory});
  padding-bottom: 30px;
  background-size: cover;
`;

const MainCard = styled.div`
  background: rgba(20, 29, 40, 0.6);
  padding: 20px 32px;
  color: var(--white);
  max-width: 250px;
  min-height: 170px;
  margin: 24px;
  border-radius: 10px;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  fill: var(--white);
`;

const CardTitle = styled.div`
  ${h5}
  margin: 16px auto;
`;

const CardText = styled.div`
  ${body4}
`;

const EditedRow = styled(Row)`
  justify-content: center;
`;

const EditFirstSide = styled(FirstSide)`
  text-align: center;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  width: fit-content;
`;

const Card = ({ title, text, icon }: any) => {
  return (
    <MainCard>
      <Icon src={icon} />
      <CardTitle>{title}</CardTitle>
      <CardText>{text}</CardText>
    </MainCard>
  );
};

export const DataPrivacy = () => {
  return (
    <Background id="privacy">
      <EditFirstSide>
        <MainTitle>Dein Profil - Deine Daten</MainTitle>
        <SubTitle>
          Cargofaces wurde von Grund auf entwickelt, um deine Privatsphäre zu
          respektieren und Vertrauen in der Supply Chain zu schaffen.
        </SubTitle>
      </EditFirstSide>
      <SecondSide>
        <EditedRow>
          <Card
            icon={Germany}
            title="Hosting in Deutschland"
            text="Cargofaces wird DSGVO-konform betrieben."
          />
          <Card
            icon={APIIcon}
            title="Offene API"
            text="Cargofaces glaubt an Open Data und bietet dir eine kostenlose API. "
          />
        </EditedRow>
        <EditedRow>
          <Card
            icon={Cloud}
            title="Datenschutz"
            text="Deine Daten werden niemals an Dritte weitergegeben."
          />
          <Card
            icon={Visible}
            title="Sichtbarkeitsoptionen"
            text="Du hast die Kontrolle darüber, wer dein Profil und deine Angebote sieht."
          />
        </EditedRow>
      </SecondSide>
    </Background>
  );
};
