import styled from 'styled-components';

/**
 * Only display on small viewports
 */
export const SmallViewport = styled.div`
  @media screen and (min-width: 400px) {
    display: none;
  }
`;

/**
 * Only display on small viewports
 */
export const ViewportTooSmallForDataTable = styled.div`
  @media screen and (min-width: 940px) {
    display: none;
  }
`;

/**
 * Only display on small viewports
 */
export const ViewportCanShowDataTable = styled.div`
  @media screen and (max-width: 939px) {
    display: none;
  }
`;

/**
 * Only display on medium or large viewports
 */
export const MediumOrLargeViewport = styled.div`
  @media screen and (max-width: 399px) {
    display: none;
  }
`;
