import { debounce } from 'lodash';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  CompanyResponse,
  CompanySearchResponse,
} from '../../../lib/types/company';
import { useKeyboard } from '../../../lib/useKeyboard';
import { Avatar } from '../../common/Avatar';
import { Btn } from '../../common/Btn';
import * as Dialog from '../../common/Dialog';
import { AddIcon, SearchIcon } from '../../common/Icons';
import {
  MediumOrLargeViewport,
  SmallViewport,
} from '../../common/MediaQueries';
import { Headings } from '../../common/typography';
import { body3, body6 } from '../../common/typography/Body';
import { H3, H7 } from '../../common/typography/Headings';
import { InputWithIconRight } from '../../form/InputWithIconRight';
import { TextField } from '../../form/TextField';
import { Center } from '../../layout/Center';
import { useApi, useRefetchUser } from '../../UserState';
import { CreateCompanyDialog } from './CreateCompanyDialog';
import magnify from './magnify.svg';

type Props = {
  children?: ReactElement;
};

const ResultList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  min-height: 260px;

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;

    &:first-child {
      flex: 0 0 42px;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }

    .company {
      flex: 0 1 100%;
    }

    .actions {
      flex: 0 0 auto;
    }
  }
`;

const ResultPlaceholder = styled.div`
  min-height: 180px;
`;

const SuccessLogo = styled(Center)`
  background: var(--grey200);
  border-radius: 20px;
  padding: 1rem;

  h2 {
    ${Headings.h4}
    color: var(--primary500);
  }

  p {
    ${Dialog.Body.body4}
    color: var(--primary400);
  }

  @media screen and (min-width: 720px) {
    h2 {
      ${Headings.h3}
    }

    p {
      ${body3}
    }
  }
`;

const CreateNew = styled.div`
  ${body6};
  text-align: center;
`;

const TriggerButton = styled(H7)`
  color: var(--tertiary500);
  cursor: pointer;
`;

export const JoinCompanyDialog = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const trigger = children ?? <Btn size="small">Unternehmen finden</Btn>;
  const navigate = useNavigate();
  useKeyboard({
    Escape: () => {
      closeHandler();
    },
  });
  const [query, setQuery] = useState('');
  const [requestedCompany, setRequestedCompany] =
    useState<CompanyResponse | null>(null);

  const refetchUser = useRefetchUser();

  const api = useApi();

  const closeHandler = () => {
    setOpen(!open);
    refetchUser();
  };

  const [results, setResults] = useState<CompanySearchResponse | null>(null);

  const refreshResults = debounce((query: string) => {
    if (query === '') {
      setResults(null);
      return;
    }
    api.searchCompanies(query).then((data) => setResults(data));
  }, 100);

  const requestJoin = async (item: CompanyResponse) => {
    await api.requestToJoinCompany(item.company.id);
    setRequestedCompany(item);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    refreshResults(e.target.value);
  };
  return (
    <Dialog.Root trigger={trigger} open={open} onOpenChange={closeHandler}>
      <Dialog.Header title="" />
      <Dialog.Body>
        {requestedCompany === null && (
          <Center direction="column" height="auto" gap="1.5rem">
            <img src={magnify} alt="" width="" height="" />
            <H3 id="createJoinTitle">
              Finde dein Unternehmen oder erstelle ein neues
            </H3>

            <InputWithIconRight style={{ width: '100%' }}>
              <TextField
                placeholder="Gib deinen Unternehmensnamen ein"
                value={query}
                onChange={changeHandler}
              />
              <SearchIcon />
            </InputWithIconRight>

            {results !== null && (
              <ResultList>
                {results.companies
                  .slice(0, 4)
                  .map(({ logo, company, company_address }) => (
                    <li key={company.id}>
                      <Avatar name={company.name ?? ''} image={logo} />
                      <div className="company">{company.name}</div>
                      <div className="actions">
                        <SmallViewport>
                          <Btn
                            round
                            appearance="outline"
                            type="button"
                            size="small"
                            onClick={() =>
                              requestJoin({
                                company,
                                logo,
                                address: company_address,
                              })
                            }
                          >
                            <AddIcon />
                          </Btn>
                        </SmallViewport>
                        <MediumOrLargeViewport>
                          <Btn
                            color="primary"
                            type="button"
                            onClick={() =>
                              requestJoin({
                                company,
                                logo,
                                address: company_address,
                              })
                            }
                          >
                            Beitritt beantragen
                          </Btn>
                        </MediumOrLargeViewport>
                      </div>
                    </li>
                  ))}
              </ResultList>
            )}
            {results === null && <ResultPlaceholder />}
            <CreateNew>
              <H7>Dein Unternehmen ist nicht dabei? </H7>
              <CreateCompanyDialog onClose={() => setOpen(false)}>
                <TriggerButton>Erstelle hier ein neues</TriggerButton>
              </CreateCompanyDialog>
            </CreateNew>
          </Center>
        )}
        {requestedCompany && (
          <Center direction="column" gap="1.5rem">
            <SuccessLogo
              direction="column"
              gap="1rem"
              role="img"
              aria-labelledby="companyName companyLocation"
            >
              <Avatar
                size="100px"
                name={requestedCompany.company.name ?? ''}
                image={requestedCompany.logo}
              />
              <h2 id="companyName">{requestedCompany.company.name}</h2>
              {requestedCompany.address.city && (
                <p id="companyLocation">{requestedCompany.address.city}</p>
              )}
            </SuccessLogo>
            <h1 id="createJoinTitle">
              Deine Mitgliedsanfrage wurde versendet.
            </h1>
            <p>
              Wir benachrichtigen dich, sobald ein Eigentümer deine Anfrage
              akzeptiert hat.
            </p>
            <Btn
              color="primary"
              type="button"
              onClick={() => {
                navigate('/dashboard');
                closeHandler();
              }}
            >
              Zum Dashboard
            </Btn>
          </Center>
        )}
      </Dialog.Body>
    </Dialog.Root>
  );
};
