import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { formatDate } from '../../lib/convert';
import {
  CompanyAddressData,
  CompanyData,
  CompanyFormValues,
  InsuranceData,
  InsuranceInput,
} from '../../lib/types/company';
import { Avatar } from '../common/Avatar';
import { Btn } from '../common/Btn';
import { CancelIcon } from '../common/Icons';
import { MessageBox } from '../common/MessageBox';
import {
  Backdrop,
  CloseButton,
  Dialog,
  DialogContent,
  DialogFrame,
  DialogHeader,
  ModalText,
  Title,
} from '../common/Modal';
import { Field } from '../form/Field';
import { FileInput } from '../form/FileInput';
import { Form } from '../form/Form';
import { Label } from '../form/Label';
import { Select } from '../form/Select';
import { TextArea } from '../form/TextArea';
import { TextField } from '../form/TextField';
import { Center } from '../layout/Center';
import { SpreadButtonRow } from '../layout/SpreadButtonRow';
import { Stack } from '../layout/Stack';
import { Data, Input, NameText, TextBox } from '../profile/setting/UserData';
import { useApi } from '../UserState';

type Props = {
  close: () => void;
  id: number;
  company?: CompanyData;
  logo?: string;
  company_address?: CompanyAddressData;
  insurance?: InsuranceData | null;
  file?: string | null;
};

export const AGBModal = ({ id, company, close }: Props) => {
  const api = useApi();
  const form = useForm({ defaultValues: { agb: company?.agb } });
  const onSubmit: SubmitHandler<CompanyData> = async ({ agb }) => {
    await api.editCompany(id, { agb });
    close();
  };

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            AGB bearbeiten
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            <Title>Angaben zur AGB</Title>
            <ModalText>
              Um deine Allgemeinen Geschäftsbedingungen zu aktualisieren, kannst
              du hier einen neuen Text einfügen. Klicke danach auf
              "Aktualisieren".
            </ModalText>
            <Field name="agb">
              <TextArea required />
            </Field>
            <SpreadButtonRow>
              <Btn appearance="transparent" onClick={close}>
                Abbrechen
              </Btn>
              <Btn color="primary" type="submit">
                Aktualisieren
              </Btn>
            </SpreadButtonRow>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};

export const DescriptionModal = ({ id, company, close }: Props) => {
  const api = useApi();
  const form = useForm();

  const onSubmit: SubmitHandler<CompanyData> = async ({ description }) => {
    await api.editCompany(id, { description });
    close();
  };

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            Übersicht bearbeiten
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            <Title>Beschreibung</Title>
            <ModalText>
              Hier kannst du deine Unternehmensbeschreibung ändern. Klicke
              danach auf "Aktualisieren".
            </ModalText>
            <Field name="description">
              <TextArea />
            </Field>
            <SpreadButtonRow>
              <Btn appearance="transparent" onClick={close}>
                Abbrechen
              </Btn>
              <Btn color="primary" type="submit">
                Aktualisieren
              </Btn>
            </SpreadButtonRow>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};

export const AddressModal = ({
  id,
  company: { employees, founding_date } = {},
  company_address,
  close,
}: Props) => {
  const api = useApi();

  const form = useForm({
    defaultValues: {
      company_address,
      employees,
      founding_date: founding_date ? formatDate(founding_date) : '',
    },
  });

  const onSubmit: SubmitHandler<CompanyData> = async (profile) => {
    await api.editCompany(id, profile);
    close();
  };

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            Basisinfos bearbeiten
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            <Title>Angaben zum Unternehmen</Title>
            <ModalText>
              Hier kannst du Basisinformationen zu deinem Unternehmen
              bearbeiten. Drücke auf "Aktualisieren", um deine Eingaben zu
              speichern.
            </ModalText>
            <Stack gap="16px">
              <Field name="employees" label="Größe des Unternehmens" weight={4}>
                <Select>
                  <option value="25">1-25</option>
                  <option value="50">25-50</option>
                  <option value="100">50 - 100</option>
                </Select>
              </Field>
              <Field
                name="founding_date"
                label="Gründungsdatum"
                weight={4}
                options={{
                  pattern: {
                    value: /\d\d\.\d\d\.\d\d\d\d/,
                    message:
                      'Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
                  },
                }}
              >
                <TextField placeholder="TT.MM.JJJJ" />
              </Field>

              <Field
                name="company_address.country"
                label="Land"
                required={true}
              >
                <Select>
                  <option>Deutschland</option>
                  <option>Österreich</option>
                  <option>Schweiz</option>
                </Select>
              </Field>

              <Field name="company_address.city" label="Stadt" required>
                <TextField />
              </Field>

              <Field name="company_address.street" label="straße" required>
                <TextField />
              </Field>

              <Field name="company_address.zip_code" label="PLZ" required>
                <TextField />
              </Field>
            </Stack>
            <SpreadButtonRow>
              <Btn appearance="transparent" onClick={close}>
                Abbrechen
              </Btn>
              <Btn color="primary" type="submit">
                Aktualisieren
              </Btn>
            </SpreadButtonRow>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};

export const GeneralInformationModal = ({
  id,
  company,
  logo,
  close,
}: Props) => {
  const api = useApi();

  const form = useForm<CompanyFormValues>({
    defaultValues: company,
  });
  const newLogo = form.watch('logo');
  const [showError, setShowError] = useState<boolean>(false);

  const previewImage = useMemo(() => {
    let url = null;
    if (showError) {
      setShowError(false);
    }
    if (newLogo instanceof FileList && newLogo.length) {
      if (newLogo[0].size < 1000000) {
        url = URL.createObjectURL(newLogo[0]);
      } else setShowError(true);
    }
    return url;
  }, [newLogo]);

  const onSubmit: SubmitHandler<CompanyData> = async (profile) => {
    await api.editCompany(id, profile);
    close();
  };

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            Infos zum Unternehmen bearbeiten
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            {showError && (
              <MessageBox type="error">Maximale Dateigröße ist 1 MB</MessageBox>
            )}
            <Title>Unternehmensinfos bearbeiten</Title>
            <ModalText>
              Hier kannst du deine Unternehmensdaten bearbeiten. Klicke auf
              "Aktualisieren", um die Einträge zu speichern.
            </ModalText>
            <Stack gap="16px">
              <Center>
                <Data>
                  <Avatar
                    name={company?.name ?? ''}
                    image={previewImage || logo}
                    size="64px"
                  />
                  <TextBox>
                    <NameText>{company?.name}</NameText>
                    <Input
                      {...form.register('logo')}
                      type="file"
                      id="avatar"
                      accept="image/png, image/jpeg"
                    />
                    <Label htmlFor="avatar">Logo ändern</Label>
                  </TextBox>
                </Data>
              </Center>
              <Field name="name" label="Name der Firma">
                <TextField placeholder="Der Name der Firma" type="text" />
              </Field>
              <Field name="information" label="Unternehmenstyp">
                <TextField
                  placeholder="Frachtverteiler"
                  type="text"
                  maxLength={75}
                />
              </Field>
              <Field
                name="email"
                label="E-mail"
                options={{
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                    message: 'Bitte trage eine gültige E-Mail-Adresse ein.',
                  },
                }}
              >
                <TextField placeholder="es-cargo@cargo.com/" />
              </Field>
              <Field name="website_link" label="Website">
                <TextField placeholder="http://www.escargo.com/" />
              </Field>
              <Field name="xing_link" label="XING">
                <TextField placeholder="http://www.xing.com/escargo" />
              </Field>
              <Field name="linkedin_link" label="LINKEDIN">
                <TextField placeholder="http://www.linkedin.com/escargo" />
              </Field>
            </Stack>
            <SpreadButtonRow>
              <Btn appearance="transparent" onClick={close}>
                Abbrechen
              </Btn>
              <Btn color="primary" type="submit">
                Aktualisieren
              </Btn>
            </SpreadButtonRow>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};

export const InsuranceModal = ({ id, insurance, file, close }: Props) => {
  const api = useApi();
  const {
    insurance_number,
    insurance_name,
    insurance_company_name,
    expiration_date,
  } = insurance ?? {};
  const form = useForm<InsuranceData>({
    defaultValues: {
      insurance_number,
      insurance_name,
      insurance_company_name,
      expiration_date: expiration_date ? formatDate(expiration_date) : '',
    },
  });

  const onSubmit: SubmitHandler<InsuranceData> = async (newInsurance) => {
    if (insurance) editinsurance(newInsurance);
    else createInsurance(newInsurance);
  };

  async function editinsurance(newInsurance: InsuranceData) {
    if (!insurance?.id) throw new Error('missing id');
    await api.editInsurance(insurance.id, newInsurance);
    close();
  }

  async function createInsurance(newInsurance: InsuranceData & InsuranceInput) {
    await api.createInsurance({ ...newInsurance, company_id: id });
    close();
  }

  return (
    <Form {...form} onSubmit={onSubmit}>
      <Dialog>
        <Backdrop />
        <DialogFrame>
          <DialogHeader>
            Versicherungsdaten bearbeiten
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </DialogHeader>
          <DialogContent>
            <Title>Angaben zur Versicherung</Title>
            <ModalText>
              Hier kannst du deine Angaben zu der Unternehmensversicherung
              ändern. Klicke auf "Aktualisieren", um deine Eingabe zu speichern.
            </ModalText>
            <Field name="file" label="Upload-Richtlinie">
              <FileInput accept="application/pdf" />
            </Field>
            <Stack gap="16px">
              <Field
                name="insurance_company_name"
                label="Name des Versicherungsunternehmens"
                required
              >
                <TextField />
              </Field>
              <Field
                name="insurance_name"
                label="Art der Versicherung"
                required
              >
                <TextField placeholder="Haftpflichtversicherung" />
              </Field>
              <Field
                name="insurance_number"
                label="Policennummer"
                weight={4}
                required
              >
                <TextField placeholder="12663534" />
              </Field>
              <Field
                name="expiration_date"
                label="Gültig bis"
                required
                options={{
                  pattern: {
                    value: /\d\d\.\d\d\.\d\d\d\d/,
                    message:
                      'Bitte geben Sie das Datum im Format TT.MM.JJJJ ein.',
                  },
                }}
              >
                <TextField placeholder="TT.MM.JJJJ" />
              </Field>
            </Stack>
            <SpreadButtonRow>
              <Btn appearance="transparent" onClick={close}>
                Abbrechen
              </Btn>
              <Btn color="primary" type="submit">
                Aktualisieren
              </Btn>
            </SpreadButtonRow>
          </DialogContent>
        </DialogFrame>
      </Dialog>
    </Form>
  );
};
