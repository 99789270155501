import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppScreen } from '../../components/common/AppScreen';
import { Card } from '../../components/common/Card';
import { PageTitle } from '../../components/common/PageTitle';
import { SubNavItem } from '../../components/common/SubNav';
import { Stack } from '../../components/layout/Stack';
import { MyCapacityOffers } from '../../components/offer/MyCapacityOffers';
import { MyCargoOffers } from '../../components/offer/MyCargoOffers';
import { OfferDashboard } from '../../components/offer/OfferDashboard';
import { useApiCall } from '../../lib/useApiCall';

const LayoutCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 0;
`;

const TabsList = styled(Tabs.List)`
  display: flex;
  min-height: 66px;
  border-bottom: 1px solid var(--grey300);
`;

const TabsRoot = styled(Tabs.Root)`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const TabsContent = styled(Tabs.Content)`
  flex-direction: column;
  padding: 16px;
  display: none;
  &[data-state='active'] {
    display: flex;
    flex: 1 1 0;
  }
`;

export function ContainerMatchHub() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? 'dashboard';
  const { data, invalidate } = useApiCall('getAllCargosCapacities');
  return (
    <TabsRoot
      value={tab}
      onValueChange={(tab) => {
        setSearchParams({ tab });
      }}
      orientation="horizontal"
    >
      <AppScreen title="ContainerMatch Dashboard">
        <Stack style={{ flexGrow: 1 }}>
          <PageTitle
            title="ContainerMatch"
            subTitle="Hier kannst du Überhänge & Leerchassis verwalten."
          />
          <LayoutCard>
            <TabsList>
              <Tabs.Trigger value="dashboard" asChild>
                <SubNavItem as="div">Karte</SubNavItem>
              </Tabs.Trigger>
              <Tabs.Trigger value="capacity" asChild>
                <SubNavItem as="div">Leerchassis</SubNavItem>
              </Tabs.Trigger>
              <Tabs.Trigger value="cargo" asChild>
                <SubNavItem as="div">Überhänge</SubNavItem>
              </Tabs.Trigger>
            </TabsList>
            {data && (
              <>
                <TabsContent value="dashboard">
                  <OfferDashboard data={data} />
                </TabsContent>
                <TabsContent value="capacity">
                  <MyCapacityOffers
                    capacities={data.capacities}
                    invalidate={invalidate}
                  />
                </TabsContent>
                <TabsContent value="cargo">
                  <MyCargoOffers cargos={data.cargos} invalidate={invalidate} />
                </TabsContent>
              </>
            )}
          </LayoutCard>
        </Stack>
      </AppScreen>
    </TabsRoot>
  );
}
