import React, { forwardRef, ReactElement } from 'react';
import styled from 'styled-components';

import { Stack } from '../layout/Stack';
import BlueArrow from './BlueArrow.svg';
import { Body5 } from './typography/Body';
import { H4 } from './typography/Headings';

const Root = styled.button`
  max-width: 248px;
  flex-basis: 248px;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 24px 12px 48px 12px;
  position: relative;
  ::before,
  ::after {
    position: absolute;
    opacity: 0;
  }
  ::after {
    content: url(${BlueArrow});
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
  }
  ::before {
    content: '';
    bottom: 0px;
    width: 100px;
    height: 4px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--tertiary500);
    border-radius: 3px 3px 0 0;
  }
  &:hover {
    border-color: var(--tertiary500);
    transition: border-color 0.5s;
    ::before,
    ::after {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
`;

type Props = JSX.IntrinsicElements['button'] & {
  icon: ReactElement;
  title: string;
};

export const CardButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, title, children, ...props }: Props, ref) => {
    return (
      <Root {...props} ref={ref}>
        <Stack gap="8px" align="center">
          {icon}
          <H4 color="var(--primary500)">{title}</H4>
          <Body5 color="var(--grey500)">{children}</Body5>
        </Stack>
      </Root>
    );
  }
);
