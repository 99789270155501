import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ContactRecord } from '../../../lib/types/contacts';
import { Btn } from '../../common/Btn';
import * as Dialog from '../../common/Dialog';
import { LoadingCircle } from '../../common/LoadingCircle';
import { Checkbox } from '../../form/Checkbox';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { SpreadButtonRow } from '../../layout/SpreadButtonRow';
import { Stack } from '../../layout/Stack';
import { useApi } from '../../UserState';
import importIcon from './import-icon.svg';

type Props = {
  children: ReactElement;
};

export function ManualInput({ children }: Props) {
  const api = useApi();
  const [more, setMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<ContactRecord>();
  const onSubmit: SubmitHandler<ContactRecord> = async (
    data: ContactRecord
  ) => {
    setLoading(true);
    await api.addContact(data);
    setLoading(false);
    if (more) {
      form.reset();
      form.setFocus('firstname');
    } else {
      setOpen(false);
    }
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <Dialog.Root trigger={children} open={open} onOpenChange={setOpen}>
      <Dialog.Header
        title="Adresse manuell eingeben"
        icon={<img src={importIcon} alt="" />}
      ></Dialog.Header>
      <Dialog.Body>
        <Form {...form} onSubmit={onSubmit}>
          <Stack gap=".5rem">
            <Row align="top">
              <Field name="firstname" label="Vorname" required>
                <TextField placeholder="Vorname" autoFocus />
              </Field>
              <Field name="name" label="Nachname" required>
                <TextField placeholder="Nachname" />
              </Field>
            </Row>
            <Field
              name="email"
              label="E-Mail"
              required
              options={{
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                  message: 'Bitte trage eine gültige E-Mail-Adresse ein.',
                },
              }}
            >
              <TextField placeholder="Kontakt E-Mail" inputMode="email" />
            </Field>
            <Field name="phone" label="Telefonnummer">
              <TextField placeholder="+49 XXX XXX XXXX" />
            </Field>
            <Field name="company" label="Firmenname">
              <TextField placeholder="Firmenname" />
            </Field>
          </Stack>
          <SpreadButtonRow>
            <Dialog.Close asChild>
              <Btn appearance="transparent" type="button" onClick={() => {}}>
                Zurück
              </Btn>
            </Dialog.Close>
            <Row>
              <Checkbox
                id="more"
                checked={more}
                onChange={() => setMore(!more)}
              >
                Weitere Adresse anlegen
              </Checkbox>
              <Btn color="primary">Speichern</Btn>
            </Row>
          </SpreadButtonRow>
        </Form>
      </Dialog.Body>
    </Dialog.Root>
  );
}
