import React, { useState } from 'react';
import styled from 'styled-components';

import { AddressBookSubNav } from '../../components/addressbook/AddressBookSubNav';
import { AddressBookTable } from '../../components/addressbook/AddressBookTable';
import { AdressImportDialog } from '../../components/addressbook/importmodal';
import { AppScreen } from '../../components/common/AppScreen';
import { Btn } from '../../components/common/Btn';
import { ConfirmDialog } from '../../components/common/ConfirmDialog';
import { IconLink } from '../../components/common/IconLink';
import {
  EmailIcon,
  PlusCircleIcon,
  SearchIcon,
  SendIcon,
  TrashIcon,
} from '../../components/common/Icons';
import { MessageBox } from '../../components/common/MessageBox';
import { PageTitle } from '../../components/common/PageTitle';
import addresses from '../../components/empty/addresses.svg';
import { EmptyState } from '../../components/empty/EmptyState';
import { InputWithIconRight } from '../../components/form/InputWithIconRight';
import { TextField } from '../../components/form/TextField';
import { useApi } from '../../components/UserState';
import { ContactListItem } from '../../lib/types/contacts';
import { useApiCall } from '../../lib/useApiCall';

const SearchField = styled(InputWithIconRight)`
  flex: 0 1 200px;
`;

const TopControlsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
`;

type TopControlsProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  invalidate: () => void;
};
function TopControls({ filter, setFilter, invalidate }: TopControlsProps) {
  return (
    <TopControlsContainer>
      <SearchField>
        <TextField
          placeholder="Suche"
          aria-label="Adresse"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <SearchIcon />
      </SearchField>
      <AdressImportDialog onClose={invalidate} />
    </TopControlsContainer>
  );
}

export function AddressBookEmpty() {
  return (
    <EmptyState
      image={addresses}
      imageStyle={{ height: 322, marginBottom: -15 }}
      title="Keine Adressbucheinträge"
    />
  );
}

const HiddenOnSmallScreens = styled.span`
  @media (max-width: 899px) {
    width: 1px;
    height: 0;
    position: absolute;
    font-size: 0;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function AddressBook() {
  const [filter, setFilter] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const api = useApi();
  const { data, invalidate } = useApiCall('getContacts');
  const [pendingContactDeletion, setPendingContactDeletion] =
    useState<number>();

  const filterFunc = ({ contact }: ContactListItem) => {
    const { company, email, name, firstname } = contact;
    const reg = new RegExp(filter, 'i');
    return (
      reg.test(company) || reg.test(email) || reg.test(firstname + ' ' + name)
    );
  };

  const sendConnectionRequest = async (id: number) => {
    await api.connect(id);
    invalidate();
  };

  const sendInvite = async (id: number) => {
    await api.sendInviteEmail(id);
    invalidate();
    setSuccess(true);
  };

  return (
    <AppScreen title="Firmenverteiler" HeaderNavi={<AddressBookSubNav />}>
      <HeaderBox>
        <PageTitle title="Meine Kontakte">
          Über den Firmenverteiler können Angebote an Personen verschickt
          werden, die selber noch nicht bei Cargofaces registriert sind. Hier
          kannst du die E-Mail-Addressen deiner geschäftlichen Kontakte
          verwalten und diese so zum Firmenverteiler hinzufügen.
        </PageTitle>
        <TopControls
          filter={filter}
          setFilter={setFilter}
          invalidate={invalidate}
        />
      </HeaderBox>

      {success && <MessageBox type="success">Einladung versandt!</MessageBox>}
      {data?.contacts.length === 0 ? (
        <AddressBookEmpty />
      ) : (
        <>
          <AddressBookTable
            columns={['name', 'company', 'email']}
            items={data?.contacts.filter((item) => filterFunc(item))}
            renderActions={({ contact, user, status }: ContactListItem) => (
              <>
                <IconLink
                  href={`mailto:${contact.email}`}
                  as="a"
                  margin="-10px"
                >
                  <EmailIcon />
                </IconLink>
                <Btn
                  size="small"
                  appearance="transparent"
                  onClick={() => setPendingContactDeletion(contact.id)}
                >
                  <TrashIcon />
                </Btn>
                {status == 'contact' && (
                  <Btn
                    size="small"
                    color="success"
                    style={{ width: '100%' }}
                    disabled={true}
                  >
                    <PlusCircleIcon />
                    <HiddenOnSmallScreens>
                      Ihr seid Kontakte
                    </HiddenOnSmallScreens>
                  </Btn>
                )}
                {status == 'requested' && (
                  <Btn
                    size="small"
                    color="success"
                    style={{ width: '100%' }}
                    disabled={true}
                  >
                    <PlusCircleIcon />
                    <HiddenOnSmallScreens>
                      Kontaktanfrage gesendet
                    </HiddenOnSmallScreens>
                  </Btn>
                )}
                {status == 'request' && user && (
                  <Btn
                    size="small"
                    style={{ width: '100%' }}
                    color="success"
                    onClick={() => sendConnectionRequest(user.id)} // user is the platform user with same email as the contact
                  >
                    <PlusCircleIcon />
                    <HiddenOnSmallScreens>
                      Kontaktanfrage Senden
                    </HiddenOnSmallScreens>
                  </Btn>
                )}
                {status == 'invited' && (
                  <Btn
                    size="small"
                    style={{ width: '100%' }}
                    color="neutral"
                    disabled={true}
                  >
                    <SendIcon />
                    <HiddenOnSmallScreens>
                      Einladung gesendet
                    </HiddenOnSmallScreens>
                  </Btn>
                )}
                {status == 'invite' && (
                  <Btn
                    size="small"
                    style={{ width: '100%' }}
                    color="neutral"
                    onClick={() => {
                      if (contact.id) sendInvite(contact.id);
                    }}
                  >
                    <SendIcon />
                    <HiddenOnSmallScreens>
                      Zu Cargofaces Einladen
                    </HiddenOnSmallScreens>
                  </Btn>
                )}
              </>
            )}
          />
          {pendingContactDeletion && (
            <ConfirmDialog
              title="Addresse löschen?"
              confirmAction="Löschen"
              onClose={() => setPendingContactDeletion(undefined)}
              onConfirm={async () => {
                await api.deleteContact(pendingContactDeletion);
                invalidate();
              }}
            >
              Bitte bestätige, dass du diese Addresse löschen möchtest.
            </ConfirmDialog>
          )}
        </>
      )}
    </AppScreen>
  );
}
