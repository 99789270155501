import styled, { css } from 'styled-components';

const nunito = css`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
`;

export const body1 = css`
  ${nunito}
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.5px;
`;

export const body2 = css`
  ${nunito}
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const body3 = css`
  ${nunito}
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const body4 = css`
  ${nunito}
  font-size: 16px;
  line-height: 22px;
`;

export const body5 = css`
  ${nunito}
  font-size: 14px;
  line-height: 19px;
`;

export const body6 = css`
  ${nunito}
  font-size: 12px;
  line-height: 16px;
`;

export const body7 = css`
  ${nunito}
  font-size: 10px;
  line-height: 12px;
`;

export const Body1 = styled.div<{
  color?: string;
}>`
  ${body1}
  color: ${(p) => p.color ?? ''};
`;
export const Body2 = styled.div<{
  color?: string;
}>`
  ${body2}
  color: ${(p) => p.color ?? ''};
`;
export const Body3 = styled.div<{
  color?: string;
}>`
  ${body3}
  color: ${(p) => p.color ?? ''};
`;

export const Body4 = styled.div<{
  color?: string;
}>`
  ${body4}
  color: ${(p) => p.color ?? ''};
`;
export const Body5 = styled.div<{
  color?: string;
}>`
  ${body5}
  color: ${(p) => p.color ?? ''};
`;
export const Body6 = styled.div<{
  color?: string;
}>`
  ${body6}
  color: ${(p) => p.color ?? ''};
`;
