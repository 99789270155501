import React from 'react';
import styled from 'styled-components';

import { subtitleMedium } from './typography/Special';

const SwitchSlider = styled.div`
  position: relative;
  width: 40px;
  height: 18px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 35px;
    top: 50%;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${SwitchSlider} {
    background: green;

    &:before {
      transform: translate(22px, -50%);
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-top: 12px;
  padding-left: 12px;
`;

const LabelText = styled.span`
  ${subtitleMedium}
`;

type Props = {
  isChecked: boolean;
  handleToggle: () => void;
};

export function Switch({ isChecked, handleToggle }: Props) {
  return (
    <Label>
      <LabelText>Nur ungelesene</LabelText>
      <Input checked={isChecked} type="checkbox" onChange={handleToggle} />
      <SwitchSlider />
    </Label>
  );
}
