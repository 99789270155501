import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CapacityFormValues,
  CapacityPresetFormValues,
} from '../../lib/types/ui';
import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { ChevronDownIcon, ChevronUpIcon } from '../common/Icons';
import { SubtitleMedium } from '../common/typography/Special';
import { DateInput } from '../form/DateInput';
import { Field } from '../form/Field';
import { Form } from '../form/Form';
import { InputButtonCard } from '../form/InputButtonCard';
import { RadioButton } from '../form/RadioButton';
import { TextField } from '../form/TextField';
import { ZipAndCountryField } from '../form/ZipAndCountryField';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { Truck2x20, Truck20, Truck40, Truck45 } from './icons/icons';
import { RequirementsForm } from './RequirementsForm';

type Props = {
  trigger?: ReactElement;
  header: ReactElement;
  onCancel?: () => void;
} & (
  | {
      preset: true;
      values: Partial<CapacityPresetFormValues>;
      onSave?: (values: CapacityPresetFormValues) => Promise<void>;
    }
  | {
      preset?: false;
      values: Partial<CapacityFormValues>;
      onSave?: (values: CapacityFormValues) => Promise<void>;
    }
);
export const CapacityDialog = ({
  trigger,
  header,
  values,
  preset,
  onSave,
  onCancel,
}: Props) => {
  const [open, setOpen] = useState(!trigger);
  const [moreOptions, setShowMoreOptions] = useState<boolean>(false);

  const form = useForm<typeof values>({
    defaultValues: values,
  });

  const onSubmit = async (values: any) => {
    await onSave?.(values);
    setOpen(false);
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      form.reset();
      onCancel?.();
    }
  };

  const size = form.watch('size');

  return (
    <Dialog.Root trigger={trigger} open={open} onOpenChange={onOpenChange}>
      {header}
      <Dialog.Body>
        <Form {...form} onSubmit={onSubmit}>
          <Stack gap="24px">
            {preset && (
              <Field name="name" label="Titel der Vorlage" required>
                <TextField placeholder="Kennzeichen / Bezeichung / ..." />
              </Field>
            )}
            <ZipAndCountryField
              zipFieldName="zip_location"
              countryFieldName="country_location"
              label="Wo steht dein Leerchassis?"
              required={!preset}
            />

            <Field
              name="size"
              label="Wie groß ist dein Leerchassis?"
              required={!preset}
            >
              <Stack gap="14px">
                <Row>
                  <InputButtonCard {...form.register('size')} value="1x20ft">
                    <Truck20 />1 x 20’
                  </InputButtonCard>
                  <InputButtonCard
                    {...form.register('size')}
                    value="2x20ft_semi_trailer"
                  >
                    <Truck2x20 />2 x 20’ Hängerzug
                  </InputButtonCard>

                  <InputButtonCard {...form.register('size')} value="1x40ft">
                    <Truck40 />1 x 40’
                  </InputButtonCard>
                  <InputButtonCard {...form.register('size')} value="1x45ft">
                    <Truck45 />1 x 45’
                  </InputButtonCard>
                </Row>
                {size === '1x20ft' && (
                  <Row gap="32px">
                    <RadioButton
                      {...form.register('lightOrHeavy')}
                      value="1x20ft"
                    >
                      <SubtitleMedium color="var(--grey800)">
                        Weniger als 10.000 kg
                      </SubtitleMedium>
                    </RadioButton>
                    <RadioButton
                      {...form.register('lightOrHeavy')}
                      value="1x20ft_heavy"
                    >
                      <SubtitleMedium color="var(--grey800)">
                        Mehr als 10.000 kg
                      </SubtitleMedium>
                    </RadioButton>
                  </Row>
                )}
              </Stack>
            </Field>
            {!preset && (
              <Field
                name="date_start"
                controlled
                label="Wann ist dein Leerchassis verfügbar?"
              >
                <DateInput />
              </Field>
            )}
            <Row>
              <Btn
                size="small"
                type="button"
                appearance="transparent"
                color="tertiary"
                onClick={() => setShowMoreOptions(!moreOptions)}
              >
                {!moreOptions ? (
                  <>
                    MEHR OPTIONEN <ChevronDownIcon />
                  </>
                ) : (
                  <>
                    WENIGER OPTIONEN <ChevronUpIcon />
                  </>
                )}
              </Btn>
            </Row>

            {moreOptions && (
              <>
                <Row>
                  <Field
                    name={preset ? 'offer_name' : 'name'}
                    label="Titel des Angebots"
                  >
                    <TextField placeholder="20’ EX Leipzig" />
                  </Field>
                  <ZipAndCountryField
                    zipFieldName="zip_destination"
                    countryFieldName="country_destination"
                    label="Dein Wunschziel"
                  />
                </Row>
                <Field name="description" label="Kommentar">
                  <TextField placeholder="Spezielle Abfertigung notwendig" />
                </Field>
                <RequirementsForm />
              </>
            )}
            <Btn type="submit">Speichern</Btn>
          </Stack>
        </Form>
      </Dialog.Body>
    </Dialog.Root>
  );
};
