import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { body4 } from '../common/typography/Body';
import { h4 } from '../common/typography/Headings';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    display: block;
    max-width: 100%;
    max-height: 40vmin;
  }

  a,
  button {
    margin-top: 1.5rem;
  }
`;

const Title = styled.div`
  ${h4}
  color: var(--primary500);
  margin-top: 24px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  ${body4}
  color: var(--neutral500);
  max-width: 300px;
`;

type Props = {
  image: string;
  imageStyle?: CSSProperties;
  title: string;
  children?: ReactNode;
};
export function EmptyState({ image, imageStyle, title, children }: Props) {
  return (
    <Root>
      <div>
        <img src={image} style={imageStyle} />
      </div>
      <Title>{title}</Title>
      <Text>{children}</Text>
    </Root>
  );
}
