import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { actionLarge, actionMedium, actionSmall } from './typography/Special';

/**
 * New button component to later replace the olds ones.
 * Will be renamed to Button once replacement is done.
 */

type Props = {
  size: 'tiny' | 'small' | 'medium' | 'large';
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'error'
    | 'success'
    | 'neutral'
    | 'inherit';
  appearance: 'solid' | 'outline' | 'transparent';
  round?: boolean;
};

const base = css`
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: all 0.15s ease;
  border: 1px solid;
  line-height: 24px;
  :not(:disabled) {
    cursor: pointer;
    :hover {
      --color: var(--hover);
    }
    :active {
      --color: var(--active);
    }
  }
`;

const color = {
  background: {
    solid: 'var(--color)',
    outline: 'var(--white)',
    transparent: 'transparent',
  },
  text: {
    solid: 'var(--white)',
    outline: 'var(--color)',
    transparent: 'var(--color)',
  },
  border: {
    solid: 'var(--color)',
    outline: 'var(--color)',
    transparent: 'transparent',
  },
} as const;

const font = {
  large: actionLarge,
  medium: actionMedium,
  small: css`
    ${actionSmall};
    line-height: 24px;
  `,
  tiny: css`
    ${actionSmall}
    line-height: 24px;
  `,
};

const paddingX = {
  large: '24px',
  medium: '24px',
  small: '22px',
  tiny: 0,
};

const paddingY = {
  large: '15px',
  medium: '11px',
  small: '7px',
  tiny: 0,
};

const button = css<Props>`
  ${base}
  ${(p) => font[p.size]};
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  --color: ${(p) =>
    p.color === 'inherit' ? 'inherit' : `var(--${p.color}500)`};
  --hover: ${(p) => `var(--${p.color}400)`};
  --active: ${(p) => `var(--${p.color}800)`};
  color: ${(p) => color.text[p.appearance]};
  background: ${(p) => color.background[p.appearance]};
  border-color: ${(p) => color.border[p.appearance]};
  border-radius: ${(p) =>
    p.round ? '50%' : p.size === 'tiny' ? '8px' : '10px'};
  padding: ${(p) =>
    p.round || p.appearance === 'transparent'
      ? paddingY[p.size]
      : `${paddingY[p.size]} ${paddingX[p.size]}`};
  :disabled {
    opacity: 0.4;
  }
`;

const defaults = (props: Props) => ({
  size: props.size || 'medium',
  color: props.color || 'primary',
  appearance: props.appearance || 'solid',
});

export const Btn = styled.button.attrs(defaults)`
  ${button}
`;

export const BtnLink = styled(Link).attrs(defaults)`
  ${button}
  text-decoration: none;
`;
