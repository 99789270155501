import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { TransportType } from '../../lib/types/offers';
import { CargoFormValues, CargoPresetFormValues } from '../../lib/types/ui';
import { Btn } from '../common/Btn';
import * as Dialog from '../common/Dialog';
import { ChevronDownIcon, ChevronUpIcon } from '../common/Icons';
import { H6, H7 } from '../common/typography/Headings';
import { Checkbox } from '../form/Checkbox';
import { DateInput } from '../form/DateInput';
import { Field } from '../form/Field';
import { Form } from '../form/Form';
import { InputButtonCard } from '../form/InputButtonCard';
import { TextField } from '../form/TextField';
import { ZipAndCountryField } from '../form/ZipAndCountryField';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import coloreddetour from '../offer/icons/ColoredDetour.svg';
import coloredexport from '../offer/icons/ColoredExport.svg';
import coloredimport from '../offer/icons/ColoredImport.svg';
import { AttributesForm } from './AttributesForm';
import {
  Container20Heavy,
  Container20Light,
  Container40,
  Container45,
} from './icons/icons';
import { RequirementsForm } from './RequirementsForm';

const ColoredImport = styled.div`
  background-image: url(${coloredimport});
  height: 32px;
  width: 32px;
`;
const ColoredExport = styled.div`
  background-image: url(${coloredexport});
  height: 32px;
  width: 32px;
`;
const ColoredDetour = styled.div`
  background-image: url(${coloreddetour});
  height: 32px;
  width: 32px;
`;

type Props = {
  trigger?: ReactElement;
  header: ReactElement;
  onCancel?: () => void;
} & (
  | {
      preset: true;
      values: Partial<CargoPresetFormValues>;
      onSave?: (values: CargoPresetFormValues) => Promise<void>;
    }
  | {
      preset?: false;
      values: Partial<CargoFormValues>;
      onSave?: (values: CargoFormValues) => Promise<void>;
    }
);

export const CargoDialog = ({
  trigger,
  header,
  values,
  preset,
  onSave,
  onCancel,
}: Props) => {
  const [open, setOpen] = useState(!trigger);
  const [moreOptions, setShowMoreOptions] = useState<boolean>(false);

  const form = useForm<typeof values>({
    defaultValues: values,
  });

  const transportType = form.watch('transport_type');

  const onSubmit = async (values: any) => {
    await onSave?.(values);
    setOpen(false);
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      form.reset();
      onCancel?.();
    }
  };

  return (
    <Dialog.Root trigger={trigger} open={open} onOpenChange={onOpenChange}>
      {header}
      <Dialog.Body>
        <Form {...form} onSubmit={onSubmit}>
          <Stack gap="24px">
            <Row gap="40px" justify="spread">
              <InputButtonCard
                {...form.register('transport_type')}
                value="import"
              >
                <ColoredImport />
                <H6>Import</H6>
              </InputButtonCard>
              <InputButtonCard
                {...form.register('transport_type')}
                value="export"
              >
                <ColoredExport />
                <H6>Export</H6>
              </InputButtonCard>
              <InputButtonCard
                {...form.register('transport_type')}
                value="relocation"
              >
                <ColoredDetour />
                <H6>Umfuhr</H6>
              </InputButtonCard>
            </Row>
            {transportType && (
              <>
                {preset && (
                  <Field name="name" label="Titel der Vorlage" required>
                    <TextField placeholder="20’ EX Leipzig" />
                  </Field>
                )}
                <Row justify="spread" align="top">
                  <ZipAndCountryField
                    zipFieldName="zip_location"
                    countryFieldName="country_location"
                    label="Aufnahme"
                    required={!preset}
                  />
                  {transportType !== 'relocation' && (
                    <ZipAndCountryField
                      zipFieldName="zip_load_unload"
                      countryFieldName="country_load_unload"
                      label="Gestellung"
                      required={!preset}
                    />
                  )}

                  <ZipAndCountryField
                    zipFieldName="zip_destination"
                    countryFieldName="country_destination"
                    label="Rückgabe"
                    required={!preset}
                  />
                </Row>
                <Field
                  name="size"
                  label="Wie groß ist dein Container?"
                  required={!preset}
                >
                  <Stack gap="14px">
                    <Row justify="spread">
                      <InputButtonCard
                        {...form.register('size')}
                        value="container_20ft"
                      >
                        <Container20Light />
                        20′ leicht
                      </InputButtonCard>
                      <InputButtonCard
                        {...form.register('size')}
                        value="container_20ft_heavy"
                      >
                        <Container20Heavy />
                        20′ schwer
                      </InputButtonCard>

                      <InputButtonCard
                        {...form.register('size')}
                        value="container_40ft"
                      >
                        <Container40 />
                        40′
                      </InputButtonCard>
                      <InputButtonCard
                        {...form.register('size')}
                        value="container_45ft"
                      >
                        <Container45 />
                        45′
                      </InputButtonCard>
                    </Row>
                  </Stack>
                </Field>
                <Row>
                  {!preset && (
                    <Field
                      name="date_start"
                      controlled
                      label={
                        transportType === 'relocation'
                          ? 'Wann ist dein Überhang verfügbar?'
                          : 'Wann ist das Gestellungsdatum?'
                      }
                    >
                      <DateInput />
                    </Field>
                  )}
                  <Field
                    name="attributes.shippingCompany"
                    label="Wie heißt die Reederei?"
                    required={!preset && transportType !== 'relocation'}
                  >
                    <TextField placeholder="CMA CGM" />
                  </Field>
                </Row>
                <Row>
                  <Btn
                    size="small"
                    type="button"
                    appearance="transparent"
                    color="tertiary"
                    onClick={() => setShowMoreOptions(!moreOptions)}
                  >
                    {!moreOptions ? (
                      <>
                        MEHR OPTIONEN <ChevronDownIcon />
                      </>
                    ) : (
                      <>
                        WENIGER OPTIONEN <ChevronUpIcon />
                      </>
                    )}
                  </Btn>
                </Row>

                {moreOptions && (
                  <>
                    <Row>
                      <Start transportType={transportType} />
                      <Destination transportType={transportType} />
                    </Row>
                    <Row>
                      <H7 color="var(--grey800)">Braucht Zollabfertigung</H7>
                      <Checkbox
                        {...form.register('attributes.customsClearance')}
                      />
                    </Row>
                    <Field
                      name={preset ? 'offer_name' : 'name'}
                      label="Titel des Angebots"
                    >
                      <TextField placeholder="20’ EX Leipzig" />
                    </Field>

                    <Field name="description" label="Kommentar">
                      <TextField placeholder="Spezielle Abfertigung notwendig" />
                    </Field>
                    <AttributesForm />
                    <RequirementsForm />
                  </>
                )}
              </>
            )}
            {transportType && <Btn type="submit">Speichern</Btn>}
          </Stack>
        </Form>
      </Dialog.Body>
    </Dialog.Root>
  );
};

function Start({ transportType }: { transportType: TransportType }) {
  if (transportType === 'import') {
    return (
      <Field name="attributes.terminal" label="Startterminal">
        <TextField placeholder="Terminalname" />
      </Field>
    );
  }
  if (transportType === 'export') {
    return (
      <Field name="attributes.depot" label="Startdepot">
        <TextField placeholder="Depotname" />
      </Field>
    );
  }
  return (
    <Field name="attributes.depot" label="Depot">
      <TextField placeholder="Depotname" />
    </Field>
  );
}

function Destination({ transportType }: { transportType: TransportType }) {
  if (transportType === 'import') {
    return (
      <Field name="attributes.depot" label="Zieldepot">
        <TextField placeholder="Depotname" />
      </Field>
    );
  }
  if (transportType === 'export') {
    return (
      <Field name="attributes.terminal" label="Zielterminal">
        <TextField placeholder="Terminalname" />
      </Field>
    );
  }
  return (
    <Field name="attributes.terminal" label="Terminal">
      <TextField placeholder="Terminalname" />
    </Field>
  );
}
