import React from 'react';
import styled from 'styled-components';

import { LoadingCircle } from '../common/LoadingCircle';
import { Body1, Body4 } from '../common/typography/Body';
import { H6 } from '../common/typography/Headings';
import { Stack } from '../layout/Stack';
import PortImage from './Port.png';

const Card = styled(Stack)`
  background: #fff;
  border-radius: 12px;
  width: 90%;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const LoadingBox = styled.div`
  height: 100px;
`;

export const LoadingMatches = () => {
  return (
    <Card>
      <img src={PortImage} width={300} height={150} />
      <LoadingBox>
        <LoadingCircle />
      </LoadingBox>
      <H6 color="var(--grey800)">Erste Ergebnisse</H6>
      <Body1>Matches laden ...</Body1>
      <Body4 color="var(--grey500)">
        Deine ersten Quick-Matches werden geladen und in kürze angezeigt.
      </Body4>
    </Card>
  );
};
