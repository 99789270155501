import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { visitErrors } from '../../../lib/publicApi';
import { PasswordInput } from '../../../lib/types/user';
import { Btn } from '../../common/Btn';
import { Card, CardHeader } from '../../common/Card';
import { MessageBox } from '../../common/MessageBox';
import { Section } from '../../common/Section';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { SpreadButtonRow } from '../../layout/SpreadButtonRow';
import { useApi } from '../../UserState';

export function ChangePassword() {
  const api = useApi();
  const form = useForm<PasswordInput>();
  const password = form.watch('password', '');

  const [success, setSuccess] = useState(false);

  const onSubmit: SubmitHandler<PasswordInput> = async (data) => {
    const res = await api.updatePassword(data);
    if (res && 'errors' in res) {
      setSuccess(false);
      visitErrors(data, res, form.setError);
    } else {
      setSuccess(true);
      form.reset();
    }
  };

  return (
    <Form {...form} onSubmit={onSubmit}>
      {success && !form.formState.isDirty ? (
        <MessageBox type="success">
          Dein Passwort wurde aktualisiert.
        </MessageBox>
      ) : null}
      <Card>
        <CardHeader>Passwort</CardHeader>
        Um dein aktuelles Passwort zu ändern, gib hier ein neues Passwort ein
        und klicke auf "Passwort aktualisieren"
        <Section title="">
          <Row align="top">
            <Field name="current_password" label="Aktuelles Passwort">
              <TextField type="password" autoComplete="off" />
            </Field>
          </Row>
          <Row align="top">
            <Field name="password" label="Neues Passwort">
              <TextField type="password" autoComplete="off" />
            </Field>
          </Row>
          <Row>
            <Field
              name="password_confirmation"
              label="Passwort bestätigen"
              options={{
                validate: (value) =>
                  value === password || 'Die Passwörter stimmen nicht überein.',
              }}
            >
              <TextField type="password" autoComplete="off" />
            </Field>
          </Row>
        </Section>
      </Card>
      <SpreadButtonRow>
        <Btn type="submit">Passwort aktualisieren</Btn>
      </SpreadButtonRow>
    </Form>
  );
}
