import React, { useState } from 'react';

import { Btn } from '../../components/common/Btn';
import {
  Card,
  CardContent,
  CardContentTitle,
  CardHeader,
} from '../../components/common/Card';
import {
  EditIcon,
  EmailIcon,
  LinkedInIcon,
  LocationIcon,
  WebsiteIcon,
  XingIcon,
} from '../../components/common/Icons';
import {
  AddressModal,
  AGBModal,
  DescriptionModal,
  GeneralInformationModal,
  InsuranceModal,
} from '../../components/company/CompanyModals';
import { Row } from '../../components/layout/Row';
import { SideBySide } from '../../components/layout/SideBySide';
import { Stack } from '../../components/layout/Stack';
import { Person, PersonStack } from '../../components/profile/Person';
import {
  ProfileHeader,
  ProfileHeaderInfo,
} from '../../components/profile/ProfileHeader';
import { formatDate } from '../../lib/convert';
import { UserData } from '../../lib/publicApi';
import { CompanyProfileResult } from '../../lib/types/company';
import { IconBox } from './Profile';

export function CompanyProfile({
  company,
  insurance,
  logo,
  company_address,
  employees,
  file,
  isAdmin,
  invalidate,
}: CompanyProfileResult & {
  isAdmin: boolean;
  invalidate: () => void;
}) {
  const [showAGBModal, setShowAGBModal] = useState(false);
  const [showDescriptionModal, setshowDescriptionModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showGeneralModal, setShowGeneralModal] = useState(false);
  const [showInsuranceModal, setShowInsuranceModal] = useState(false);

  function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      {showAGBModal && (
        <AGBModal
          id={company.id}
          company={company}
          close={() => {
            setShowAGBModal(false);
            invalidate();
          }}
        />
      )}
      {showDescriptionModal && (
        <DescriptionModal
          id={company.id}
          company={company}
          close={() => {
            setshowDescriptionModal(false);
            invalidate();
          }}
        />
      )}
      {showAddressModal && (
        <AddressModal
          id={company.id}
          company={company}
          company_address={company_address}
          close={() => {
            setShowAddressModal(false);
            invalidate();
          }}
        />
      )}
      {showGeneralModal && (
        <GeneralInformationModal
          id={company.id}
          company={company}
          close={() => {
            setShowGeneralModal(false);
            invalidate();
          }}
        />
      )}
      {showInsuranceModal && (
        <InsuranceModal
          insurance={insurance}
          file={file}
          id={company.id}
          close={() => {
            setShowInsuranceModal(false);
            invalidate();
          }}
        />
      )}
      <Stack gap="24px">
        <Card>
          {isAdmin && (
            <IconBox>
              <Btn
                size="tiny"
                appearance="transparent"
                onClick={() => setShowGeneralModal(true)}
              >
                <EditIcon />
              </Btn>
            </IconBox>
          )}
          <ProfileHeader
            title={company.name}
            subTitle={company.information}
            image={logo}
          >
            <ProfileHeaderInfo>
              {company_address && (
                <>
                  <LocationIcon />
                  {company_address.city}, {company_address.country}
                </>
              )}
            </ProfileHeaderInfo>
            {company.email && (
              <ProfileHeaderInfo>
                <EmailIcon />
                {company.email}
              </ProfileHeaderInfo>
            )}
            <Row justify="spread">
              {company.website_link && (
                <Btn
                  size="tiny"
                  appearance="transparent"
                  onClick={() =>
                    window.open(`${company.website_link}`, '_blank')
                  }
                >
                  <ProfileHeaderInfo>
                    <WebsiteIcon />
                  </ProfileHeaderInfo>
                </Btn>
              )}
              {company.xing_link && (
                <Btn
                  size="tiny"
                  appearance="transparent"
                  onClick={() => window.open(`${company.xing_link}`, '_blank')}
                >
                  <ProfileHeaderInfo>
                    <XingIcon />
                  </ProfileHeaderInfo>
                </Btn>
              )}
              {company.linkedin_link && (
                <Btn
                  size="tiny"
                  appearance="transparent"
                  onClick={() =>
                    window.open(`${company.linkedin_link}`, '_blank')
                  }
                >
                  <ProfileHeaderInfo>
                    <LinkedInIcon />
                  </ProfileHeaderInfo>
                </Btn>
              )}
            </Row>
          </ProfileHeader>
        </Card>
        <SideBySide sideBarWidth="270px" gap="24px" equalHeight>
          <Stack gap="24px">
            <Card>
              <CardHeader>Team</CardHeader>
              <PersonStack gap="24px">
                {employees.map(
                  ({ id, first_name, last_name, picture }: UserData) => (
                    <Person
                      id={id}
                      key={id}
                      name={`${first_name} ${last_name}`}
                      image={picture}
                    />
                  )
                )}
              </PersonStack>
            </Card>
          </Stack>
          <Stack gap="24px">
            <Card>
              <CardHeader>
                Infos zum Unternehmen
                {isAdmin && (
                  <Btn
                    size="tiny"
                    appearance="transparent"
                    onClick={() => setShowAddressModal(true)}
                  >
                    <EditIcon />
                  </Btn>
                )}
              </CardHeader>
              <Row justify="spread">
                <Stack gap="16px">
                  <CardContentTitle>Mitarbeiter</CardContentTitle>
                  <CardContent>{company.employees ?? '\u00A0'}</CardContent>
                </Stack>
                <Stack gap="16px">
                  <CardContentTitle>Gründungsdatum</CardContentTitle>
                  <CardContent>
                    {company.founding_date
                      ? formatDate(company.founding_date)
                      : '\u00A0'}
                  </CardContent>
                </Stack>
                <Stack gap="16px">
                  <CardContentTitle>Vollständige Adresse</CardContentTitle>
                  {company_address ? (
                    <>
                      <CardContent>
                        {`${company_address.street} ${company_address.zip_code}
                        ${company_address.city} `}
                      </CardContent>
                    </>
                  ) : (
                    <CardContent>Nicht angegeben</CardContent>
                  )}
                </Stack>
              </Row>
            </Card>
            <Card>
              <CardHeader>
                Versicherungsdaten
                {isAdmin && (
                  <Btn
                    size="tiny"
                    appearance="transparent"
                    onClick={() => setShowInsuranceModal(true)}
                  >
                    <EditIcon />
                  </Btn>
                )}
              </CardHeader>
              {insurance && !insurance.submit_later && (
                <Stack gap="24px">
                  <Row justify="spread">
                    <Stack gap="16px">
                      <CardContentTitle>Versicherer</CardContentTitle>
                      <CardContent>
                        {insurance.insurance_company_name}
                      </CardContent>
                    </Stack>
                    <Stack gap="16px">
                      <CardContentTitle>Policen-Nummer</CardContentTitle>
                      <CardContent>{insurance.insurance_number}</CardContent>
                    </Stack>
                    <Stack gap="16px">
                      <CardContentTitle>Versicherung Art</CardContentTitle>
                      <CardContent>{insurance.insurance_name}</CardContent>
                    </Stack>
                    <Stack gap="16px">
                      <CardContentTitle>Verfallsdatum</CardContentTitle>
                      <CardContent>
                        {insurance.expiration_date
                          ?.split('-')
                          .reverse()
                          .join('.')}
                      </CardContent>
                    </Stack>
                  </Row>
                  <span>
                    {file && (
                      <Btn
                        size="tiny"
                        appearance="outline"
                        onClick={() => downloadURI(`${file}`, 'Versicherung')}
                      >
                        Versicherung Datei als PDF Anzeigen
                      </Btn>
                    )}
                  </span>
                </Stack>
              )}
            </Card>

            <Card>
              <CardHeader>
                Übersicht
                {isAdmin && (
                  <Btn
                    size="tiny"
                    appearance="transparent"
                    onClick={() => setshowDescriptionModal(true)}
                  >
                    <EditIcon />
                  </Btn>
                )}
              </CardHeader>
              <div>{company.description}</div>
            </Card>

            {company.agb && (
              <Card>
                <CardHeader>
                  AGB
                  {isAdmin && (
                    <Btn
                      size="tiny"
                      appearance="transparent"
                      onClick={() => setShowAGBModal(true)}
                    >
                      <EditIcon />
                    </Btn>
                  )}
                </CardHeader>
                <Row>
                  <div>{company.agb}</div>
                </Row>
              </Card>
            )}
          </Stack>
        </SideBySide>
      </Stack>
    </>
  );
}
