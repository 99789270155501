import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../landing/CargofacesLogo.svg';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { BtnLink } from './Btn';
import { h6, h7 } from './typography/Headings';
import { actionSmall } from './typography/Special';

export const Root = styled(Row)`
  padding: 12px 24px;
  color: white;
`;

export const Links = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 24px;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const CargofacesLogo = styled.div`
  width: 222px;
  height: 24px;
  background-image: url(${Logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const HeaderLink = styled(Link)`
  ${h6}
  color: var(--white);
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    color: var(--secondary300);
  }
  @media screen and (max-width: 1300px) {
    ${h7}
  }
  @media screen and (max-width: 1075px) {
    ${h6}
  }
`;

export const ResponsiveBtnLink = styled(BtnLink)`
  @media screen and (max-width: 1300px) {
    ${actionSmall}
    padding: 7px 12px;
  }
`;

export const LoginButton = styled(ResponsiveBtnLink)`
  color: white;
  background: transparent;
  border-color: white;
`;

export const Buttons = styled.div`
  > * + * {
    margin-left: 24px;
  }
`;

export const MobilView = styled.div`
  display: none;
  @media screen and (max-width: 1075px) {
    display: unset;
    width: 100%;
  }
`;
export const DesktopView = styled.div`
  width: 100%;
  @media screen and (max-width: 1075px) {
    display: none;
  }
`;

export const SideMenuWrapper = styled(Stack)`
  background: var(--primary500);
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  padding: 12px 24px;
  z-index: 4;
`;
