import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { actionSmall } from '../common/typography/Special';

const Root = styled.label`
  position: relative;
  flex: 150px 1 1;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

const Card = styled.div`
  ${actionSmall}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid var(--grey300);
  border-radius: 10px;
  color: var(--grey500);
  &:hover {
    cursor: pointer;
  }

  ${Input}:checked + & {
    border-color: var(--tertiary500);
    background: var(--tertiary100);
  }
`;

type Props = JSX.IntrinsicElements['input'];
export const InputButtonCard = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, ref) => (
    <Root>
      <Input type="radio" {...props} ref={ref} />
      <Card>{children}</Card>
    </Root>
  )
);
