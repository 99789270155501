import React from 'react';
import styled from 'styled-components';

import { ResponsiveBtnLink } from '../../components/common/LandingHeader';
import { h3 } from '../../components/common/typography/Headings';
import { Stack } from '../../components/layout/Stack';
import { Background, Paragrapgh, Title } from './Consts';

const shelfs = '/images/background4.jpg';

const Main = styled.div`
  width: 50%;
  text-align: center;
  white-space: break-spaces;
  margin: 100px auto;
  @media screen and (max-width: 959px) {
    width: 70%;
  }
  @media screen and (max-width: 400px) {
    width: 90%;
  }
`;

const SPTitle = styled(Title)`
  ${h3}
`;

const Box = styled.div`
  width: 100%;
  margin: 32px auto;
`;

export const MeeagesBoxCon = styled.div`
  width: 93%;
  margin: auto;
  margin-top: 8px;
`;

export const AddYourSelf = () => {
  return (
    <Background background={shelfs}>
      <Main>
        <Stack gap="32px">
          <SPTitle>Keine Macht den Daten-Kraken</SPTitle>
          <Paragrapgh>
            Cargofaces ist gebaut, um das volle Potential digitaler
            Genossenschaften auszunutzen und demokratisch einen Wandel
            herbeizuführen.
          </Paragrapgh>
          <Paragrapgh>
            Sei dabei wenn es losgeht! Die Plattform ist bereits nutzbar,
            aktuell arbeiten wir an der Gründung der Genossenschaft.
          </Paragrapgh>
        </Stack>
        <Box>
          <ResponsiveBtnLink color="secondary" to="/signup">
            Jetzt registrieren
          </ResponsiveBtnLink>
        </Box>
      </Main>
    </Background>
  );
};
