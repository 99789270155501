import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Btn } from '../../common/Btn';
import { CountryFlag } from '../../common/CountryFlag';
import { HR } from '../../common/HR';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { Select } from '../../form/Select';
import { TextArea } from '../../form/TextArea';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { Dots } from './Dots';

export type LocationAndDescriptionProps = {
  city: string;
  country: string;
  description: string;
  employees: string;
  founding_date: string;
  information: string;
  street: string;
  zip_code: string;
};

const InactiveTextInput = styled(Row)`
  position: relative;
`;
const FlagContainer = styled.div`
  position: absolute;
  top: 43px;
  right: 10px;
`;

type Props = {
  values?: Partial<LocationAndDescriptionProps>;
  next: (data: LocationAndDescriptionProps) => void;
  prev: (data: LocationAndDescriptionProps) => void;
};

export const LocationAndDescription = ({ values, next, prev }: Props) => {
  const form = useForm<LocationAndDescriptionProps>({ defaultValues: values });

  return (
    <Form {...form} onSubmit={next}>
      <Stack gap="24px">
        <Field name="information" label="Bezeichnung deines Unternehmens">
          <TextField placeholder="Mustermann Spedition GmbH" />
        </Field>
        <Field name="description" label="Kurzbeschreibung">
          <TextArea placeholder="Beschreibe hier in wenigen Worten/Sätzen, was dein Unternehmen macht, damit andere Nutzer/innen von Cargofaces dich besser finden." />
        </Field>
        <Row>
          <Field name="employees" label="Größe des Unternehmens" weight={4}>
            <Select>
              <option value="25">1-25</option>
              <option value="50">25-50</option>
              <option value="100">50 - 100</option>
            </Select>
          </Field>
          <Field name="founding_date" label="Gründungsdatum">
            <TextField placeholder="18.03.1999" />
          </Field>
        </Row>
        <HR style={{ marginBottom: '0px' }} />
        <Row align="top">
          <InactiveTextInput>
            <FlagContainer>
              <CountryFlag country="de" />
            </FlagContainer>
            <Field name="country" label="Land">
              <TextField value="Deutschland" readOnly />
            </Field>
          </InactiveTextInput>

          <Field name="city" label="Stadt" required>
            <TextField placeholder="z.B. Hamburg" />
          </Field>
        </Row>
        <Row align="top">
          <Field
            name="street"
            label="Straße und Hausnummer"
            weight={4}
            required
          >
            <TextField placeholder="z.B. Wanderweg 73, Etage 3" />
          </Field>
          <Field name="zip_code" label="Postleitzahl" weight={2} required>
            <TextField placeholder="z.B. 24093" />
          </Field>
        </Row>

        <Row justify="spread">
          <Btn
            type="button"
            onClick={() => prev(form.getValues())}
            appearance="transparent"
          >
            Zurück
          </Btn>
          <Dots step={2} />

          <Btn type="submit">Weiter</Btn>
        </Row>
      </Stack>
    </Form>
  );
};
