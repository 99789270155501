import styled from 'styled-components';

export const HR = styled.hr<{
  gap?: string;
}>`
  border: 0;
  height: 0;
  border-bottom: 1px solid var(--neutral300);
  margin: ${(p) => p.gap ?? '32px'} 0;
`;
