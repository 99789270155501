import * as React from 'react';
import styled from 'styled-components';

import { body5 } from './typography/Body';

const Root = styled.footer`
  ${body5}
  color: var(--neutral500);
  background-color: #fff;
  border: solid 1px #ffffff00;

  width: 100%;
  height: 46px;

  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  box-sizing: border-box;
  padding: 0 2em;
`;

const Links = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * + * {
    margin-left: 2em;
  }
  a {
    color: var(--tertiary600);
    text-decoration: none;
  }
`;

const year = new Date().getFullYear();

export function Footer() {
  return (
    <Root>
      <div>Copyright ©{year}</div>
      <Links>
        <a href="/privacypolicy" target="_blank">
          Datenschutzerklärung
        </a>
        <a href="/imprint" target="_blank">
          Impressum
        </a>
      </Links>
    </Root>
  );
}
