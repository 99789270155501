import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Bookmark } from '../../lib/types/connections';
import { Avatar } from '../common/Avatar';
import { Btn } from '../common/Btn';
import { Column, DataTable } from '../common/DataTable';
import { BookmarkIcon } from '../common/Icons';
import { TableLink } from '../common/Link';
import { useApi } from '../UserState';
import { EmptyBookmarks } from './EmptyTables/EmptyBookmarks';

const Actions = styled.div`
  display: flex;
  margin-left: -8px;
  gap: 8px;
`;
const presets: Record<string, Column<Bookmark>> = {
  image: {
    key: 'Image',
    render: ({ user }) => (
      <Avatar
        name={`${user.first_name} ${user.last_name}`}
        image={user.picture}
      />
    ),
    style: { width: 0 },
  },
  name: {
    key: 'name',
    heading: 'Name',
    sortable: true,
    value: ({ user }) => `${user.last_name}, ${user.first_name}`,
    render: ({ user }) => (
      <TableLink to={`/user/${encodeURIComponent(user.id)}`}>
        {user.first_name} {user.last_name}
      </TableLink>
    ),
  },
  company: {
    key: 'company',
    heading: 'Firma',
    render: ({ user, companies }) => {
      const company = companies.find((c) => c.id === user.selected_company_id);
      return company ? (
        <TableLink to={`/company/${encodeURIComponent(company.id)}`}>
          {company.name}
        </TableLink>
      ) : null;
    },
  },
  location: {
    key: 'location',
    heading: 'Basisstandort',
    sortable: true,
    value: ({ user }) => user.city,
    render: ({ user }) => user.city,
  },
};

type Props = {
  items: Bookmark[];
  columns: Array<keyof typeof presets>;
  onAction: () => void;
};

export function BookmarksTable({ items, columns, onAction }: Props) {
  const api = useApi();
  const columnsConfig = useMemo(
    () => [
      ...columns.map((col) => presets[col]),
      {
        key: 'actions',
        heading: 'Aktionen',
        style: { width: 0 },
        render: ({ user }) => (
          <Actions>
            <Btn
              title="aus Lesezeichen entfernen"
              appearance="transparent"
              onClick={async () => {
                await api.removeBookmark(user.id);
                onAction();
              }}
            >
              <BookmarkIcon />
            </Btn>
          </Actions>
        ),
      },
    ],
    [...columns, onAction]
  );

  return items.length ? (
    <DataTable
      columns={columnsConfig}
      items={items}
      sortOrder={{ column: 'name', asc: true }}
    />
  ) : (
    <EmptyBookmarks />
  );
}
