import React from 'react';

import { Chip } from '../common/Chip';
import { AdrIcon, CoolingIcon, TiltIcon, TrashIcon } from '../common/Icons';

type Props = {
  values: string[];
};
export function Requirements({ values }: Props) {
  return (
    <>
      {values.includes('ADR') && (
        <Chip>
          <AdrIcon />
        </Chip>
      )}
      {values.includes('Cooling') && (
        <Chip>
          <CoolingIcon />
        </Chip>
      )}
      {values.includes('Dumping') && (
        <Chip>
          <TiltIcon />
        </Chip>
      )}
      {values.includes('Waste') && (
        <Chip>
          <TrashIcon />
        </Chip>
      )}
    </>
  );
}
