import 'emoji-mart/css/emoji-mart.css';

import { emojiIndex, Picker } from 'emoji-mart';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useClickOutside } from '../../lib/useClickOutside';
import { MessageInput } from '../chat/MessageInput';
import { Btn } from '../common/Btn';
import { SendIcon, SmileyIcon } from '../common/Icons';
import { UserAvatar } from '../common/UserAvatar';
import { useUserData } from '../UserState';
import { PictureButton } from './PictureButton';

const Root = styled.div`
  color: var(--neutral700);
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  background: var(--grey100);
  border: var(--grey300);
  border-radius: 10px;
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  & .emoji-mart {
    font-family: inherit;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
  & .emoji-mart-title-label {
    display: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ButtonStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Images = styled.div`
  max-height: 100px;
`;

const Image = styled.img`
  max-width: 100px;
  max-height: 100%;
  border-radius: 10px;
  margin-left: 10px;
`;

type Props = {
  onSend: (message: string, image?: File) => any;
};
export function CommentInput({ onSend }: Props) {
  const [message, setMessage] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useClickOutside<HTMLDivElement>(() => {
    if (showPicker) setShowPicker(false);
  });
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target?.result as string);
      };
      reader.onerror = () => {
        setFile(undefined);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(undefined);
    }
  }, [file]);

  const send = () => {
    const text = message.trim();
    if (text.length || file) {
      onSend(text, file);
      setMessage('');
      setFile(undefined);
    }
  };

  const previewFile = (ev: ChangeEvent<HTMLInputElement>) => {
    const { files } = ev.target;
    setFile(files?.[0]);
  };

  const user = useUserData();
  return (
    <Root>
      <UserAvatar size="32px" user={user} />
      <InputWrapper>
        <MessageInput
          value={message}
          onKeyPress={(ev) => {
            // Send on enter, shift-enter for new lines
            if (ev.code === 'Enter' && !ev.shiftKey) {
              ev.preventDefault();
              send();
            }
          }}
          placeholder="Kommentieren?"
          onChange={(ev) => {
            const text = ev.target.value;
            setMessage(
              text.replaceAll(/:(\S+):/g, (m, name) => {
                const emojis: any = emojiIndex.search(name);
                return emojis.length ? emojis[0].native : m;
              })
            );
          }}
        />
        {image && (
          <Images>
            <Image src={image} />
          </Images>
        )}
        <Buttons>
          <ButtonStack>
            <PictureButton onChange={previewFile} />
            <Btn
              appearance="transparent"
              size="tiny"
              type="button"
              onClick={() => setShowPicker(true)}
            >
              <SmileyIcon size="16px" />
            </Btn>
          </ButtonStack>
          <Btn
            appearance="transparent"
            size="small"
            type="button"
            onClick={send}
          >
            <SendIcon />
          </Btn>
        </Buttons>
      </InputWrapper>
      {showPicker && (
        <PickerContainer ref={pickerRef}>
          <Picker
            native
            emoji=""
            onSelect={(emoji) => {
              if ('native' in emoji) {
                setMessage((m) => m + emoji.native);
              }
            }}
          />
        </PickerContainer>
      )}
    </Root>
  );
}
