import React, { useMemo, useRef } from 'react';

import { Checkbox } from '../form/Checkbox';
import { Column, DataTable, Props as DataTableProps } from './DataTable';

export type SortOrder = { column: string; asc: boolean };
export type OnSort = (order: SortOrder) => void;

export interface Props<T> extends DataTableProps<T> {
  selection: T[];
  onSelectionChange: (selection: T[]) => void;
}
export function MultiSelectDataTable<T>({
  selection,
  onSelectionChange,
  columns,
  ...props
}: Props<T>) {
  const selectionRef = useRef<T[]>([]);
  selectionRef.current = selection;
  const columnsWithCheckbox = useMemo(() => {
    const checkBoxCol: Column<T> = {
      key: '_selected',
      render(item) {
        return (
          <Checkbox
            checked={selectionRef.current.includes(item)}
            onChange={(ev) => {
              const without = selectionRef.current.filter((i) => i !== item);
              onSelectionChange(
                ev.target.checked ? [...without, item] : without
              );
            }}
          />
        );
      },
    };
    return [checkBoxCol, ...columns];
  }, [columns]);
  return <DataTable {...props} columns={columnsWithCheckbox} />;
}
