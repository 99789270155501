import React, { ReactElement } from 'react';

import { Btn } from '../../common/Btn';
import { Carousel, Slide } from '../../common/Carousel';
import * as Dialog from '../../common/Dialog';
import { CopyField } from '../../form/CopyField';
import { Center } from '../../layout/Center';
import { Stack } from '../../layout/Stack';
import importIcon from './import-icon.svg';
import OutlookContacts from './Outlook_Contacts.gif';
import OutlookSend from './Outlook_Send.gif';
import OutlookSendMail from './Outlook_Send_Mail.gif';

type Props = {
  children: ReactElement;
};
export function EmailImport({ children }: Props) {
  const importEmail =
    import.meta.env.VITE_CONTACT_IMPORT_EMAIL.toString() ||
    'import@app.cargofaces.com';
  return (
    <Dialog.Root trigger={children}>
      <Dialog.Header
        title="Adressen via E-Mail importieren"
        icon={<img src={importIcon} alt="" />}
      ></Dialog.Header>
      <Dialog.Body height="80vh" style={{ paddingTop: '20px' }}>
        <Carousel labelledBy="emailImportTitle">
          <Slide id="slide_1">
            <Center height="auto" direction="column" gap="1rem">
              <p>
                Du kannst deine Kontakte aus Outlook bequem per E-Mail in
                Cargofaces importieren. Dazu sendest du eine Mail mit Kontakten
                im vCard Format an deinen User-Account innerhalb Cargofaces.
                Navigiere hierzu in deinem Outlook auf die Kontakte-Seite.
              </p>
              <img
                src={OutlookContacts}
                alt="Klick auf die Kontakte-Schaltfläche in Outlook"
                width="230"
                height="131"
              />
            </Center>
          </Slide>
          <Slide id="slide_2">
            <Center height="auto" direction="column" gap="1rem">
              <p>
                Markiere alle Kontakte für den Import. Klicke mit der rechten
                Maustaste einen Eintrag an und wähle “Kontakte weiterleiten”
                &gt; “Als Visitenkarte”. Ein neues E-Mail Fenster öffnet sich.
              </p>
              <img src={OutlookSend} alt="" />
            </Center>
          </Slide>
          <Slide id="slide_3">
            <Center height="auto" direction="column" gap="1rem">
              <p>
                Alle Kontakte sollten in dieser E-Mail angehängt sein. Trage die
                E-Mail Adresse {importEmail} als Empfänger ein und klicke
                “Senden”. Deine Kontakte werden importiert.
              </p>
              <img src={OutlookSendMail} alt="" />
            </Center>
          </Slide>
          <Slide id="slide_4">
            <Stack gap="2rem">
              <p>
                Jetzt bist du dran!
                <br />
                Öffne dein Outlook und sende deine Kontakte an deinen Cargofaces
                Account!
              </p>
              <Center height="auto" gap="1rem" direction="column">
                <CopyField value={importEmail} readOnly />
                <p>Füge diese E-Mail in das Empfängerfeld ein.</p>
                <Dialog.Close asChild>
                  <Btn color="primary" type="button">
                    Abschließen
                  </Btn>
                </Dialog.Close>
              </Center>
            </Stack>
          </Slide>
        </Carousel>
      </Dialog.Body>
    </Dialog.Root>
  );
}
