import React, { ReactNode } from 'react';

import { SubNav, SubNavItem } from '../common/SubNav';
import { PendingConnectionsBadge } from './PendingConnectionsBadge';

type Props = {
  children?: ReactNode;
};
export function ConnectionsSubNav({ children }: Props) {
  return (
    <SubNav>
      <SubNavItem to="/connections" end>
        Kontakte
      </SubNavItem>
      <SubNavItem to="/connections/pending">
        Anfragen <PendingConnectionsBadge />
      </SubNavItem>
      <SubNavItem to="/connections/bookmarks">Lesezeichen</SubNavItem>
      {children}
    </SubNav>
  );
}
