import React from 'react';
import styled from 'styled-components';

import { Btn, BtnLink } from '../common/Btn';
import { Carousel, Slide } from '../common/Carousel';
import { Body } from '../common/typography';
import { H2 } from '../common/typography/Headings';
import { Center } from '../layout/Center';
import CargoMatch200 from './CargoMatch200.png';
import CargoMatch250 from './CargoMatch250.png';
import CargoMatch400 from './CargoMatch400.png';
import CargoMatch500 from './CargoMatch500.png';
import FinalOnboard200 from './Final_Onboard200.png';
import FinalOnboard250 from './Final_Onboard250.png';
import FinalOnboard400 from './Final_Onboard400.png';
import FinalOnboard500 from './Final_Onboard500.png';
import Welcome200 from './Welcome.png';
import Welcome250 from './Welcome250.png';
import Welcome400 from './Welcome400.png';
import Welcome500 from './Welcome500.png';
import YourNetwork200 from './Your_Network200.png';
import YourNetwork250 from './Your_Network250.png';
import YourNetwork400 from './Your_Network400.png';
import YourNetwork500 from './Your_Network500.png';

const OrderedList = styled.ol`
  list-style: none;
  counter-reset: ol-counter;
  ${Body.body6}
  color: var(--primary500);

  li {
    position: relative;
    counter-increment: ol-counter;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  @media screen and (min-width: 720px) {
    ${Body.body5}

    li::before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: -50px;
      top: 50%;
      margin-top: -16px;
      padding: 8px;
      width: 20px;
      text-align: center;
      max-width: 20px;
      height: 20px;
      border-radius: 50px;
      content: counter(ol-counter);
      border: 1px solid currentColor;
    }
  }
`;

type Props = {
  close: () => void;
};
export function OnboardingCarousel({ close }: Props) {
  return (
    <>
      <Center direction="column" height="auto">
        <Carousel
          labelledBy="onboardingTitle"
          aspectRatio={9 / 8}
          mediumAspectRatio={7 / 8}
          mobileAspectRatio={0.55}
        >
          <Slide id="slide_1">
            <Center height="auto" direction="column" gap="1rem">
              <picture>
                <source
                  media="(min-width: 720px)"
                  srcSet={`${Welcome250} 1x, ${Welcome500} 2x`}
                />
                <source
                  media="(max-width: 719px)"
                  srcSet={`${Welcome200} 1x, ${Welcome400} 2x`}
                />
                <img src={Welcome200} alt="" />
                {/* while a missing alt text is an error, an empty alt-tag is valid. 
                    It means: the image is purely presentational
                  */}
              </picture>

              <H2>Moin! Schön, dass du da bist.</H2>
              <p>
                Und willkommen auf Cargofaces! Wir bieten dir eine persönliche
                Plattform und nützliche Tools für ein smartes und innovatives
                Arbeiten in der Logistik. Starte direkt durch, indem du dich mit
                Kollegen vernetzt und deinem Unternehmen beitrittst.
              </p>
            </Center>
          </Slide>
          <Slide id="slide_2">
            <Center height="auto" direction="column" gap="1rem">
              <picture>
                <source
                  media="(min-width: 720px)"
                  srcSet={`${YourNetwork250} 1x, ${YourNetwork500} 2x`}
                />
                <source
                  media="(max-width: 719px)"
                  srcSet={`${YourNetwork200} 1x, ${YourNetwork400} 2x`}
                />
                <img src={YourNetwork200} alt="" />
              </picture>
              <H2>Deine Kontakte, dein Netzwerk.</H2>
              <p>
                Vernetze dich mit Kollegen, Kunden und Transportunternehmern.
                Erweitere dein Netzwerk und importiere dein Adressbuch, um deine
                Reichweite zu erhöhen.
              </p>
              <p>
                Cargofaces hilft dir dabei und bietet nützliche Tools für deinen
                täglichen Arbeitsalltag.
              </p>
            </Center>
          </Slide>
          <Slide id="slide_3">
            <Center height="auto" direction="column" gap="1rem">
              <picture>
                <source
                  media="(min-width: 720px)"
                  srcSet={`${CargoMatch250} 1x, ${CargoMatch500} 2x`}
                />
                <source
                  media="(max-width: 719px)"
                  srcSet={`${CargoMatch200} 1x, ${CargoMatch400} 2x`}
                />
                <img src={CargoMatch200} alt="" />
              </picture>
              <H2>Jetzt neu: ContainerMatch</H2>
              <p>
                Pflege freie Kapazitäten und Überhänge im Containerverkehr in
                Cargofaces und finde passende Angebote von anderen Usern. Nutze
                dein Adressbuch, um alle deine Kontakte zu erreichen. Du
                entscheidest, mit wem deine Angebote geteilt werden.
              </p>
            </Center>
          </Slide>
          <Slide id="slide_4">
            <Center height="auto" gap="1rem" direction="column">
              <picture>
                <source
                  media="(min-width: 720px)"
                  srcSet={`${FinalOnboard250} 1x, ${FinalOnboard500} 2x`}
                />
                <source
                  media="(max-width: 719px)"
                  srcSet={`${FinalOnboard200} 1x, ${FinalOnboard400} 2x`}
                />
                <img src={FinalOnboard200} alt="" />
              </picture>
              <H2>Jetzt bist Du dran!</H2>
              <p>Mache Cargofaces zu deinem Tool. Starte jetzt durch:</p>
              <OrderedList role="list">
                <li>
                  <BtnLink to="/my-requests/find">
                    Mein Unternehmen finden
                  </BtnLink>
                </li>
                <li>
                  <BtnLink to="/connections">Kontakte hinzufügen</BtnLink>
                </li>
              </OrderedList>
              <Btn appearance="transparent" type="button" onClick={close}>
                Zum Dashboard
              </Btn>
            </Center>
          </Slide>
        </Carousel>
      </Center>
    </>
  );
}
