import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children?: React.ReactNode;
};
export function Portal({ children }: Props) {
  const container = React.useMemo(() => {
    const div = document.createElement('div');
    div.setAttribute('class', 'portal');
    return div;
  }, []);
  useEffect(() => {
    if (container) {
      document.body.insertBefore(container, document.body.firstElementChild);
    }
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);
  return createPortal(children, container);
}
