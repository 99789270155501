import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Btn } from '../../common/Btn';
import { Body4 } from '../../common/typography/Body';
import { CustomInput } from '../../form/CustomInput';
import { DateInput } from '../../form/DateInput';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { RadioButton } from '../../form/RadioButton';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { Dots } from './Dots';

export type InsuranceProps = {
  validInsurance?: 'yes' | 'no' | 'later';
  insurance_company_name?: string;
  insurance_name?: string;
  insurance_number?: string;
  expiration_date?: string;
  file?: FileList;
};

const FieldsBox = styled(Stack)`
  background: #f8f8f8;
  width: 90%;
  margin: auto;
  border-radius: 20px;
  padding: 12px;
  pag: 12px;
`;

type Props = {
  values?: Partial<InsuranceProps>;
  next: (data: InsuranceProps) => void;
  prev: (data: InsuranceProps) => void;
};

export const Insurance = ({ next, prev, values }: Props) => {
  const form = useForm<InsuranceProps>({ defaultValues: values });
  const validInsurance = form.watch('validInsurance');

  return (
    <Stack gap="24px">
      <Body4 color="var(--grey500)">
        Um bei Cargofaces einen hohen Standard an Sicherheit und Vertrauen zu
        schaffen, kannst du hier Details & Nachweise zu deiner Versicherung
        hinterlegen. Diese werden auf deiner Unternehmensseite veröffentlicht.
      </Body4>
      <Body4 color="var(--grey500)">
        Solltest du diese Dokumente nicht direkt zur Hand haben, kannst du sie
        innerhalb von 7 Tagen nachreichen.
      </Body4>
      <Form {...form} onSubmit={next}>
        <Stack gap="16px">
          <RadioButton {...form.register('validInsurance')} value="yes">
            Das unternehmen ist derzeit nach EU-Recht versichert
          </RadioButton>
          <FieldsBox>
            <Field
              name="insurance_company_name"
              label="Name des Versicherungunternehmens"
              required={validInsurance === 'yes'}
            >
              <TextField
                placeholder="Name des Versicherungunternehmens"
                disabled={validInsurance !== 'yes'}
              />
            </Field>
            <Field
              name="insurance_name"
              label="Art der Versicherung"
              required={validInsurance === 'yes'}
            >
              <TextField
                placeholder="Haftpflichtversicherung"
                disabled={validInsurance !== 'yes'}
              />
            </Field>
            <Row>
              <Field
                name="insurance_number"
                label="Policennummer"
                weight={4}
                required={validInsurance === 'yes'}
              >
                <TextField
                  placeholder="12663534"
                  disabled={validInsurance !== 'yes'}
                />
              </Field>
              <Field
                name="expiration_date"
                controlled
                label="Gültig bis"
                required={validInsurance === 'yes'}
              >
                <DateInput disabled={validInsurance !== 'yes'} />
              </Field>
            </Row>
            <Field name="file" label="Upload-Richtlinie">
              <CustomInput disabled={validInsurance !== 'yes'} />
            </Field>
          </FieldsBox>
          <Row justify="center">
            <Body4 color="var(--grey800)">ODER</Body4>
          </Row>
          <RadioButton {...form.register('validInsurance')} value="later">
            Ich reiche diese Daten nach.
          </RadioButton>
          <RadioButton {...form.register('validInsurance')} value="no">
            Das Unternehmen ist nicht versichert oder die Versicherung ist
            veraltet.
          </RadioButton>
          <Row justify="spread">
            <Btn
              type="button"
              onClick={() => prev(form.getValues())}
              appearance="transparent"
            >
              Zurück
            </Btn>
            <Dots step={3} />
            <Btn type="submit" disabled={validInsurance === undefined}>
              Weiter
            </Btn>
          </Row>
        </Stack>
      </Form>
    </Stack>
  );
};
