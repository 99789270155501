import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { body4 } from '../common/typography/Body';

const Container = styled.div`
  display: grid;
  flex: 1;
  ::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }
  & > textarea {
    resize: none;
    overflow: hidden;
    :focus {
      outline: none;
    }
    ::placeholder {
      opacity: 0.5;
    }
  }
  & > textarea,
  ::after {
    ${body4}
    border: none;
    border-radius: 8px;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    padding: 10px 12px;
    max-height: 12em;
    overflow-y: auto;
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
`;

type Props = JSX.IntrinsicElements['textarea'];
export const MessageInput = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const [value, setValue] = useState(props.value ?? props.defaultValue);
    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    return (
      <Container data-replicated-value={value}>
        <textarea
          rows={1}
          {...props}
          ref={ref}
          onInput={(e: any) => {
            setValue(e.target.value);
            if (props.onInput) props.onInput(e);
          }}
        />
      </Container>
    );
  }
);
