import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useZipValidation } from '../../lib/useZipValidation';
import { Body } from '../common/typography';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { CountrySelect } from './CountrySelect';
import { FieldFeedback } from './FieldFeedback';
import { Label } from './Label';

const Root = styled(Row)`
  box-sizing: border-box;
  border-radius: 9.5px;
  border: solid 1px var(--grey300);
  background-color: var(--input-background);
  display: flex;
  gap: 1px;
  &:focus-within {
    box-shadow: 0px 0px 0px 4px rgba(29, 171, 242, 0.2);
  }
`;

const ZipInput = styled.input`
  ${Body.body4}
  border: none;
  border-radius: 9.5px 0 0 9.5px;
  background: transparent;
  color: var(--grey800);
  padding: 10px 12px;
  margin: 0;
  flex: 1;
  width: 100px;
  ::placeholder {
    color: var(--grey500);
  }
  :focus {
    ::placeholder {
      color: var(--grey300);
    }
    position: relative;
  }
  z-index: 0;
`;

type Props = {
  zipFieldName: string;
  countryFieldName: string;
  label: string;
  required?: boolean;
};

export function ZipAndCountryField({
  zipFieldName,
  countryFieldName,
  label,
  required = false,
}: Props) {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const country = watch(countryFieldName);
  const validate = useZipValidation({ country, required });

  return (
    <Stack gap="8px" style={{ flexGrow: 1 }}>
      <Label required={required}>{label}</Label>
      <Root noWrap gap="0px">
        <ZipInput
          placeholder="Postleitzahl"
          {...register(zipFieldName, {
            validate,
          })}
        />
        <Controller
          control={control}
          name={countryFieldName}
          shouldUnregister
          render={({ field: { value, onChange } }) => (
            <CountrySelect value={value} onChange={onChange} />
          )}
        />
      </Root>
      {errors[zipFieldName] && (
        <FieldFeedback type="error">
          {errors[zipFieldName]?.message ?? 'Ungültige Eingabe.'}
        </FieldFeedback>
      )}
    </Stack>
  );
}
