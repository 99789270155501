import React, { ReactNode, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Row } from '../layout/Row';
import { Person } from '../profile/Person';
import { useApi, useUserData } from '../UserState';
import { Btn, BtnLink } from './Btn';
import { HR } from './HR';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LogoutIcon,
  SearchIcon,
  SettingsIcon,
  UserInCircleIcon,
} from './Icons';
import { body4 } from './typography/Body';

const Header = styled.header`
  padding: 0 16px;
  background: var(--white);
  border-bottom: 1px solid var(--neutral300);
`;

const DropDownContainer = styled(Row)`
  position: relative;
`;

const DropDownBox = styled.div`
  background: var(--white);
  padding-top: 16px;
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.12);
  z-index: 3;
`;

const DropDownItem = styled(Row)`
  margin: 8px;
`;

const SearchContainer = styled.div`
  background: var(--white);
  min-width: 50px;
  max-width: 250px;
  flex: 0px 1 1;
  height: 48px;
  padding: 0 16px;
  margin: 8px 0;
  border: 1px solid var(--grey300);
  border-radius: 10px;
  display: flex;
  align-items: center;
  :focus-within {
    border-color: var(--primary400);
  }
`;

const Search = styled.input`
  ${body4}
  border: none;
  background: transparent;
  color: var(--primary500);
  ::placeholder {
    color: var(--grey500);
  }
  :focus-visible {
    outline: none;
  }
`;

type SearchInput = {
  q: string;
};
type Props = {
  HeaderNavi?: ReactNode;
};

export function AppHeader({ HeaderNavi }: Props) {
  const user = useUserData();
  const api = useApi();
  const [extandOptions, setExtandOptions] = useState<boolean>(false);
  const userName = `${user.first_name} ${user.last_name}`;

  // Set up form and read default value from URL
  const { register, handleSubmit } = useForm<SearchInput>({
    defaultValues: {
      q: new URL(window.location.href).searchParams.get('q') ?? '',
    },
  });

  // Navigate to the search page on submit
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<SearchInput> = ({ q }) => {
    if (q?.trim().length) navigate(`/search?q=${encodeURIComponent(q)}`);
  };

  return (
    <Header>
      <Row gap="16px" justify="spread" align="center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SearchContainer>
            <Search placeholder="Personensuche" {...register('q')} />
            <SearchIcon />
          </SearchContainer>
        </form>
        {HeaderNavi}
        <Row>
          <DropDownContainer>
            <Person
              id={user.id}
              name={userName}
              info={user.job_title}
              image={user.picture}
              to="/profile"
            />
            <Btn
              size="tiny"
              appearance="transparent"
              onClick={() => setExtandOptions(!extandOptions)}
            >
              {extandOptions ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Btn>
            {extandOptions && (
              <DropDownBox>
                <DropDownItem>
                  <BtnLink size="tiny" appearance="transparent" to="/profile">
                    <UserInCircleIcon />
                    Mein Profil
                  </BtnLink>
                </DropDownItem>
                <DropDownItem>
                  <BtnLink size="tiny" appearance="transparent" to="/settings">
                    <SettingsIcon />
                    Einstellungen
                  </BtnLink>
                </DropDownItem>
                <HR gap="0px" />
                <DropDownItem>
                  <Btn
                    size="tiny"
                    appearance="transparent"
                    color="tertiary"
                    onClick={async () => {
                      await api.logout();
                      navigate('/');
                    }}
                  >
                    <LogoutIcon />
                    Abmelden
                  </Btn>
                </DropDownItem>
              </DropDownBox>
            )}
          </DropDownContainer>
        </Row>
      </Row>
    </Header>
  );
}
