import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CapacityFormValues, CargoFormValues } from '../../lib/types/ui';
import { InputLabel } from '../common/typography/Special';
import { InputButtonCard } from '../form/InputButtonCard';
import { Row } from '../layout/Row';
import { Stack } from '../layout/Stack';
import { FlatRack, HighCube, OpenTop, RearFlush } from './icons/icons';

export const AttributesForm = () => {
  const { register } = useFormContext<CargoFormValues | CapacityFormValues>();
  return (
    <Stack>
      <InputLabel color="var(--grey600)">Art der Verladung</InputLabel>
      <Row justify="spread">
        <InputButtonCard type="checkbox" {...register('attributes.highCube')}>
          <HighCube />
          High Cube
        </InputButtonCard>
        <InputButtonCard type="checkbox" {...register('attributes.flatRack')}>
          <FlatRack />
          Flat Rack
        </InputButtonCard>
        <InputButtonCard type="checkbox" {...register('attributes.openTop')}>
          <OpenTop />
          Open Top
        </InputButtonCard>
        <InputButtonCard type="checkbox" {...register('attributes.rearFlush')}>
          <RearFlush />
          Rückwandbündig
        </InputButtonCard>
      </Row>
    </Stack>
  );
};
