import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { AppScreen } from '../../components/common/AppScreen';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { SubNav, SubNavItem } from '../../components/common/SubNav';
import { PendingApprovalsBadge } from '../../components/company/PendingApprovalsBadge';
import { useUserData } from '../../components/UserState';
import { int } from '../../lib/convert';
import { UserData } from '../../lib/publicApi';
import { useApiCall } from '../../lib/useApiCall';
import { CompanyMembers } from './CompanyMembers';
import { CompanyProfile } from './CompanyProfile';
import { CompanyRequests } from './CompanyRequests';

export function Company() {
  const params = useParams();
  const id = int(params.id);
  const user = useUserData();
  const { data, invalidate } = useApiCall('getCompanyProfile', id);
  const location = useLocation();
  const title = location.pathname.endsWith('requests')
    ? 'Anfragen'
    : location.pathname.endsWith('members')
    ? 'Mitglieder'
    : 'Profil';

  if (!data) {
    return <LoadingCircle />;
  }
  const { company, insurance, logo, company_address, employees, admins, file } =
    data;

  const isAdmin = !!admins?.some((admin: UserData) => admin.id === user.id);

  return (
    <AppScreen
      title={title}
      HeaderNavi={
        <SubNav>
          <SubNavItem to={`/company/${id}/profile`}>Profil</SubNavItem>
          {isAdmin && (
            <SubNavItem to={`/company/${id}/requests`}>
              Anfragen <PendingApprovalsBadge />
            </SubNavItem>
          )}
          {isAdmin && (
            <SubNavItem to={`/company/${id}/members`}>Mitglieder</SubNavItem>
          )}
        </SubNav>
      }
    >
      <Routes>
        <Route index element={<Navigate to="profile" replace />} />
        <Route
          path="profile"
          element={
            <CompanyProfile
              company={company}
              insurance={insurance}
              logo={logo}
              company_address={company_address}
              employees={employees}
              file={file}
              isAdmin={isAdmin}
              invalidate={() => invalidate()}
            />
          }
        />
        <Route path="members/*" element={<CompanyMembers companyID={+id} />} />
        <Route
          path="requests/*"
          element={<CompanyRequests companyID={+id} />}
        />
      </Routes>
    </AppScreen>
  );
}
