import styled from 'styled-components';

import { body3, body4, body5 } from './typography/Body';

export const Card = styled.div`
  ${body4}
  background: var(--white);
  color: var(--neutral500);
  border-radius: 20px;
  box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
  padding: 12px 16px 16px 16px;
`;

export const CardHeader = styled.h2`
  ${body3}
  color: var(--primary500);
  border-bottom: 1px solid var(--neutral300);
  padding: 8px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const IconHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CardContentTitle = styled.div`
  ${body5}
  color: #324654;
`;
export const CardContent = styled.div`
  ${body5}
`;

export const InnerCard = styled(Card)`
  background: var(--grey100);
`;
