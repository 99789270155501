import React, { useState } from 'react';
import styled from 'styled-components';

import { AppScreen } from '../../components/common/AppScreen';
import { SearchIcon } from '../../components/common/Icons';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { PageTitle } from '../../components/common/PageTitle';
import { ConnectionsSubNav } from '../../components/connections/ConnectionsSubNav';
import { MyConnectionsTable } from '../../components/connections/MyConnectionsTable';
import { InputWithIconRight } from '../../components/form/InputWithIconRight';
import { TextField } from '../../components/form/TextField';
import { ConnectedContact } from '../../lib/types/connections';
import { useApiCall } from '../../lib/useApiCall';

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
`;

const TopControlsContainer = styled.div`
  flex: 0 0 200px;
  align-self: flex-end;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
`;

type TopControlsProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  invalidate: () => void;
};

function TopControls({ filter, setFilter }: TopControlsProps) {
  return (
    <TopControlsContainer>
      <InputWithIconRight>
        <TextField
          placeholder="In Kontakten suchen"
          aria-label="In Kontakten suchen"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <SearchIcon />
      </InputWithIconRight>
    </TopControlsContainer>
  );
}

export function Connections() {
  const { data, invalidate } = useApiCall('getConnections');
  const [filter, setFilter] = useState<string>('');

  const filterFunc = ({ contact }: ConnectedContact) => {
    const { email, first_name, last_name } = contact;
    const reg = new RegExp(filter, 'i');

    // TODO: maybe add company aswell but company does not seem to be a part of contact/data - Sebastian
    return reg.test(email) || reg.test(first_name + ' ' + last_name);
  };

  return (
    <AppScreen title="Kontakte" HeaderNavi={<ConnectionsSubNav />}>
      <HeaderBox>
        <PageTitle title="Kontakte">
          Hier kannst du deine Kontakte auf Cargofaces sehen und verwalten sowie
          Chats starten.
        </PageTitle>
        <TopControls
          filter={filter}
          setFilter={setFilter}
          invalidate={invalidate}
        />
      </HeaderBox>
      {!data ? (
        <LoadingCircle />
      ) : (
        <MyConnectionsTable
          items={data?.filter((item) => filterFunc(item))}
          onAction={invalidate}
        />
      )}
    </AppScreen>
  );
}
