import styled, { css } from 'styled-components';

const alignment = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  baseline: 'baseline',
  stretch: 'stretch',
};

const justification = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  spread: 'space-between',
  stretch: 'flex-start',
};

export const Row = styled.div<{
  gap?: string;
  align?: keyof typeof alignment;
  justify?: keyof typeof justification;
  noWrap?: boolean;
}>`
  display: flex;
  align-items: ${(p) => alignment[p.align ?? 'center']};
  justify-content: ${(p) => justification[p.justify ?? 'left']};
  flex-wrap: ${(p) => (p.noWrap === true ? 'no-wrap' : 'wrap')};
  gap: ${(p) => p.gap ?? '16px'};
  ${(p) =>
    p.justify === 'stretch' &&
    css`
      & > * {
        flex-grow: 1;
      }
    `}
`;
