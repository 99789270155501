import 'react-calendar/dist/Calendar.less';
import './Calendar.less';

import React from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

import { BackwardIcon, ForwardIcon } from '../common/Icons';

const Root = styled.div`
  border-radius: 8px;
  border: 1px solid #eeedf0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 8px;
`;

type Props = {
  value: Date | null;
  onChange: (date: Date) => void;
};

export function DatePicker({ value, onChange }: Props) {
  return (
    <Root>
      <Calendar
        locale="de"
        value={value}
        minDate={new Date()}
        onChange={onChange}
        prevLabel={<BackwardIcon />}
        prev2Label={null}
        nextLabel={<ForwardIcon />}
        next2Label={null}
      />
    </Root>
  );
}
