import React from 'react';

import { splitAttributes } from '../../../lib/convert';
import { MatchDetails } from '../../../lib/types/ui';
import { Chip } from '../../common/Chip';
import {
  FlagIcon,
  LocationIcon,
  TruckIcon,
  WarehouseIcon,
} from '../../common/Icons';
import { Body5 } from '../../common/typography/Body';
import { Row } from '../../layout/Row';
import { Requirements } from '../../offer/Requirements';
import { Label } from './Label';

export type Props = {
  details: MatchDetails;
};

export function MatchAttributes({ details }: Props) {
  const { attributes, requirements } = details;
  const { loadingTypes, terminal, depot, shippingCompany, customs } =
    splitAttributes(attributes);

  const isExport =
    details.type === 'cargo' && details.transport_type === 'export';

  return (
    <>
      {loadingTypes.length > 0 && (
        <Row justify="spread">
          <Label>
            <TruckIcon /> Art der Verladung
          </Label>
          {loadingTypes.map((type) => (
            <Chip key={type}>{type}</Chip>
          ))}
        </Row>
      )}
      {requirements.length > 0 && (
        <Row justify="spread">
          <Label>
            <FlagIcon /> Lastanforderungen
          </Label>
          <Requirements values={requirements} />
        </Row>
      )}

      {terminal && (
        <Row justify="spread">
          <Label>
            <LocationIcon />
            {isExport ? 'Startterminal' : 'Zielterminal'}
          </Label>
          <Body5 color="var(--tertiary500)">{terminal}</Body5>
        </Row>
      )}
      {depot && (
        <Row justify="spread">
          <Label>
            <LocationIcon />
            {isExport ? 'Zielterminal' : 'Ziel Depot'}
          </Label>
          <Body5 color="var(--tertiary500)">{depot}</Body5>
        </Row>
      )}
      {shippingCompany && (
        <Row justify="spread">
          <Label>
            <WarehouseIcon /> Reederei
          </Label>
          <Body5 color="var(--tertiary500)">{shippingCompany}</Body5>
        </Row>
      )}

      {customs && (
        <Row justify="spread">
          <Label>
            <WarehouseIcon /> Zollabfertigung benötigt
          </Label>
          <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 1.5v16c0 .83-.67 1.5-1.5 1.5h-16A1.5 1.5 0 0 1 0 17.5v-16C0 .67.67 0 1.5 0h16c.83 0 1.5.67 1.5 1.5Zm-3.81 4.31a.5.5 0 0 0-.71 0L7.5 12.8 4.52 9.81l-.05-.04a.5.5 0 0 0-.66.75l3.69 3.69 7.69-7.69.04-.05a.5.5 0 0 0-.04-.66Z"
              fill="var(--tertiary500)"
            />
          </svg>
        </Row>
      )}
    </>
  );
}
