import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { AppScreen } from '../../common/AppScreen';
import { PageTitle } from '../../common/PageTitle';
import { SideBySide } from '../../layout/SideBySide';
import { Stack } from '../../layout/Stack';
import { AboutCargo } from './AboutCargo';
import { Billing } from './Billing';
import { ChangePassword } from './ChangePassword';
import { LeaveCompany } from './LeaveCompany';
import { MatchingSettings } from './MatchingSettings';
import { WizardSteps } from './Steps';
import { UserData } from './UserData';

export function Setting() {
  return (
    <AppScreen title="Einstellungen">
      <Stack>
        <PageTitle title="Einstellungen">
          Ändere deine Cargofaces-Einstellungen und Informationen hier.
        </PageTitle>
        <SideBySide sideBarWidth="270px" gap="24px">
          <WizardSteps />
          <Routes>
            <Route index element={<Navigate to="personal" />} />
            <Route path="personal" element={<UserData />} />
            <Route path="password" element={<ChangePassword />} />
            <Route path="billing" element={<Billing />} />
            <Route path="matching" element={<MatchingSettings />} />
            <Route path="leaveCompany" element={<LeaveCompany />} />
            <Route path="app" element={<AboutCargo />} />
          </Routes>
        </SideBySide>
      </Stack>
    </AppScreen>
  );
}
