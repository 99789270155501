import React from 'react';

import { Body4 } from '../common/typography/Body';
import { H6 } from '../common/typography/Headings';
import { Row } from '../layout/Row';
import archived from './archived.svg';
import { TabContent } from './TabContent';

export function ArchivedPresets() {
  return (
    <TabContent>
      <Row justify="center">
        <img src={archived} width="250" height="250" />
      </Row>
      <H6>Das Archiv ist demnächst verfügbar!</H6>
      <Body4>
        Bald kannst du Vorlagen, die du nicht so oft brauchst hier archivieren.
        Wir werden dir Bescheid geben, wenn dieses Feature freigeschaltet ist.
      </Body4>
    </TabContent>
  );
}
