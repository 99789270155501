import axios, { AxiosResponse } from 'axios';

export type UserData = {
  id: number;
  uid: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  nickname: string | null;
  phone_number: string | null;
  picture: string | null;
  city: string | null;
  job_title: string | null;
  about_me: string | null;
  selected_company_id: number | null;
  accepted_toc_at: string | null;
  provider: string;
  allow_password_change: boolean;
  country: string;
  selected_company_name: string | null;
  created_at: string | null;
};

export type ErrorMessages = {
  errors: string[];
};

export type InputErrors<Input> = {
  errors: { [key in keyof Input]?: string[] };
};

export function visitErrors<Input extends object>(
  input: Input,
  res: InputErrors<Input>,
  cb: (field: keyof Input, error: { message: string }) => void
) {
  Object.keys(input).forEach((field) => {
    const messages = (res.errors as any)[field];
    if (messages) {
      cb(field as keyof Input, { message: messages.join(' ') });
    }
  });
}

export type AuthHeaders = {
  uid: string;
  client: string;
  'access-token': string;
};

export function getAuthHeaders(obj: Record<string, string>): AuthHeaders {
  return {
    uid: obj.uid,
    client: obj.client,
    'access-token': obj['access-token'],
  };
}

export function getPresentAuthHeaders(
  obj: Record<string, string>
): Partial<AuthHeaders> {
  const names = ['uid', 'client', 'access-token'];
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([k, v]) => names.includes(k.toLowerCase()) && !!v
    )
  );
}

function append(form: FormData, key: string, value: unknown) {
  if (typeof value === 'string') {
    form.append(key, value);
  } else if (value instanceof Date) {
    form.append(key, value.toISOString());
  } else if (value instanceof FileList && value.length) {
    form.append(key, value[0]);
  } else if (value instanceof File) {
    form.append(key, value);
  } else if (typeof value === 'number' || typeof value === 'boolean') {
    form.append(key, value.toString());
  } else if (value === null || value === undefined) {
    // skip value
  } else if (typeof value === 'object') {
    Object.entries(value).forEach(([prop, val]) => {
      append(form, `${key}[${prop}]`, val);
    });
  } else {
    throw new Error(
      `Property '${key}' has an unsupported type: ${typeof value}`
    );
  }
}
export function formData(data: object) {
  const form = new FormData();
  Object.entries(data).forEach(([key, value]) => append(form, key, value));
  return form;
}

async function postFormData<T, E>(url: string, data: object) {
  try {
    const res = await axios.post<T>(url, formData(data));
    return res;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response as AxiosResponse<E>;
    }
    throw error;
  }
}

export async function validateToken(auth: AuthHeaders) {
  type ValidateTokenResponse =
    | {
        success: true;
        data: UserData;
      }
    | {
        success: false;
        errors: string[];
      };

  const { data, headers } = await axios.get<ValidateTokenResponse>(
    '/api/auth/validate_token',
    {
      params: auth,
      validateStatus: (status) => status < 500,
    }
  );
  if (!data.success) {
    return null;
  }
  return {
    data: data.data,
    auth: getPresentAuthHeaders(headers),
  };
}

type Credentials = { email: string; password: string };
export async function login(credentials: Credentials) {
  const { data, headers } = await postFormData<
    { data: UserData },
    ErrorMessages
  >('/api/auth/sign_in', credentials);
  if ('errors' in data)
    return {
      errors: {
        email: data.errors,
      },
    };
  return {
    ...data,
    auth: getAuthHeaders(headers),
  };
}

type SignUpData = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
};

type SignUpResponse = {
  data: UserData;
};
export async function signUp(data: SignUpData) {
  const res = await postFormData<SignUpResponse, InputErrors<SignUpData>>(
    '/api/auth',
    {
      ...data,
      confirm_success_url: `${window.origin}/email-verified`,
    }
  );
  return res.data;
}

export async function confirmEmail(confirmation_token: string) {
  try {
    await axios.get('/api/auth/confirmation', {
      params: {
        confirmation_token,
      },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function blockEmail(
  token: string,
  user_id: string,
  blocked_email: string
) {
  try {
    const data = { token, user_id, blocked_email };
    const res = await postFormData('/api/public/mailing_blocklists', data);
    if (res.status == 204) return true;
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

type RecoverPasswordSuccess = {
  success: true;
};
type RecoverPasswordError = {
  success: false;
  errors: string[];
};

export async function recoverPassword(data: {
  email: string;
  redirect_url: string;
}) {
  const res = await postFormData<RecoverPasswordSuccess, RecoverPasswordError>(
    '/api/auth/password',
    data
  );
  return res.data;
}

export type PasswordInputWitToken = {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
};

type ResetPasswordResponse = {
  data: UserData;
  message: string;
};

export async function resetPasswordWithToken(data: PasswordInputWitToken) {
  const res = await axios.put<ResetPasswordResponse>(
    '/api/auth/password',
    formData(data)
  );
  return {
    auth: getAuthHeaders(res.headers),
    user: res.data.data,
  };
}
