import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { PictureIcon } from '../common/Icons';

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  cursor: pointer;
  input {
    display: none;
  }
`;

type Props = JSX.IntrinsicElements['input'];

export const PictureButton = forwardRef<HTMLInputElement, Props>(
  ({ children, ...props }, ref) => (
    <Label>
      <input type="file" {...props} ref={ref} accept="image/*" />
      <PictureIcon size="16px" />
    </Label>
  )
);
