import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import React, { useState } from 'react';
import styled from 'styled-components';

import { TimelineComment } from '../../lib/types/user';
import { Body4, Body6 } from '../common/typography/Body';
import { UserAvatar } from '../common/UserAvatar';
import { Row } from '../layout/Row';
import { useApi } from '../UserState';
import { EditTextArea } from './EditTextArea';
import { PostMenu } from './PostMenu';

const Root = styled.div`
  display: flex;
  gap: 16px;
  position: relative;
`;

const Bubble = styled.div`
  background: var(--grey100);
  border: var(--grey300);
  border-radius: 10px;
  padding: 8px 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 45vh;
  border-radius: 10px;
`;

type Props = {
  data: TimelineComment;
  invalidate: () => void;
};

export function Comment({ data, invalidate }: Props) {
  const {
    user,
    image,
    comment: { comment, created_at, id, edited_at },
  } = data;

  const api = useApi();
  const [isEdit, setIsEdit] = useState(false);

  const onDelete = async () => {
    await api.deleteTimelinePostComment(id);
    invalidate();
  };

  const handleOnEdit = () => {
    setIsEdit(true);
  };

  const editPost = async (input: string) => {
    await api.editTimelinePostComment(id, input);
    setIsEdit(false);
    invalidate();
  };

  const date = new Date(created_at);
  return (
    <Root>
      <UserAvatar size="32px" user={user} />
      <Wrapper>
        <Bubble>
          <Row>
            <Body4 color="var(--grey800)">
              {user.first_name} {user.last_name}
            </Body4>
            <PostMenu post={data} onDelete={onDelete} onEdit={handleOnEdit} />
          </Row>
          {isEdit ? (
            <>
              <EditTextArea onSend={editPost} content={comment} />
              {image && <Image src={image} />}
            </>
          ) : (
            <>
              <Body4 color="var(--grey500)">{comment}</Body4>
              {image && <Image src={image} />}
            </>
          )}
        </Bubble>
        <Row justify="spread">
          <Body6 color="var(--grey400)">
            {format(date, isToday(date) ? 'HH:mm' : 'dd.MM. HH:mm')}
          </Body6>
          <Body6 color="var(--grey400)">{edited_at && '(bearbeitet)'}</Body6>
        </Row>
      </Wrapper>
    </Root>
  );
}
