import { addDays } from 'date-fns';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';

import { CompanyPaymentInfo, CompanyPaymentInput } from './types/company';
import {
  CapacityMatchesResponse,
  CargoMatchesResponse,
  MatchingCapacity,
  MatchingCargo,
  MatchingPricesImportExport,
  MatchingResponse,
  MatchSettingForm,
} from './types/matching';
import {
  Attribute,
  CapacityOffer,
  CapacityOfferInput,
  CapacityOfferWithMatches,
  CargoOffer,
  CargoOfferInput,
  CargoOfferWithMatches,
} from './types/offers';
import {
  CapacityPreset,
  CapacityPresetData,
  CargoPreset,
  CargoPresetData,
} from './types/preset';
import {
  CapacityFormValues,
  CapacityOfferDetails,
  CapacityPresetFormValues,
  CargoFormValues,
  CargoOfferDetails,
  CargoOrCapacityOfferDetails,
  CargoPresetFormValues,
  CompanyPaymentFormValues,
  FormAttributes,
  FormRequirements,
  MatchDetails,
} from './types/ui';

export function int(s?: string | null) {
  if (!s) return 0;
  const i = parseInt(s);
  return isNaN(i) ? 0 : i;
}

export function parseDate(value: Date | string) {
  if (value instanceof Date) return value;
  if (value.includes(':')) return new Date(value);
  const pattern = value.includes('-') ? 'yyyy-MM-dd' : 'dd.MM.yyyy';
  const parsed = parse(value, pattern, new Date());
  return isValid(parsed) ? parsed : undefined;
}

export function formatDate(value: Date | string) {
  const date = parseDate(value);
  return date ? format(date, 'dd.MM.yyyy') : '';
}

export function formatShortDate(value: Date | string) {
  const date = parseDate(value);
  return date ? format(date, 'dd.MM.yy') : '';
}

export function formatDay(value: Date | string) {
  const date = parseDate(value);
  return date
    ? date.toLocaleDateString('de', {
        month: 'short',
        day: 'numeric',
      })
    : '';
}

const attributeNames: Record<keyof FormAttributes, string> = {
  openTop: 'Open-Top',
  flatRack: 'Flat-Rack',
  highCube: 'High-Cube',
  rearFlush: 'Rear-Flush',
  customsClearance: 'Customs-Clearance',
  shippingCompany: 'Shipping-Company',
  depot: 'Depot',
  terminal: 'Terminal',
} as const;

function attributesFromForm(values?: FormAttributes): Attribute[] {
  const res = [];
  if (values) {
    let prop: keyof FormAttributes;
    for (prop in values) {
      const value = values[prop];
      const name = attributeNames[prop];
      if (name) {
        if (value === true) {
          res.push({ name, value: name });
        } else if (typeof value === 'string' && value.length > 0) {
          res.push({ name, value });
        }
      } else {
        console.log('Unsupported attribute', prop);
      }
    }
  }
  return res;
}

function attributesToForm(attributes: Attribute[] | null): FormAttributes {
  const attr = (name: string) =>
    attributes?.find((a) => a.name === name)?.value;

  return {
    openTop: !!attr('Open-Top'),
    flatRack: !!attr('Flat-Rack'),
    highCube: !!attr('High-Cube'),
    rearFlush: !!attr('Rear-Flush'),
    customsClearance: !!attr('Customs-Clearance'),
    shippingCompany: attr('Shipping-Company'),
    depot: attr('Depot'),
    terminal: attr('Terminal'),
  };
}

function requirementsToForm(requirements: string[] | null): FormRequirements {
  return {
    ADR: !!requirements?.includes('ADR'),
    Cooling: !!requirements?.includes('Cooling'),
    Waste: !!requirements?.includes('Waste'),
    Dumping: !!requirements?.includes('Dumping'),
  };
}

function requirementsFromForm(values?: FormRequirements): string[] {
  const res = [];
  if (values) {
    let name: keyof FormRequirements;
    for (name in values) {
      const value = values[name];
      if (value === true) {
        res.push(name);
      }
    }
  }
  return res;
}

export function cargoPresetToForm(preset: CargoPreset): CargoFormValues {
  const {
    offer_name,
    transport_type,
    description,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
    zip_load_unload,
    country_load_unload,
    cargo_units: [unit],
  } = preset;
  return {
    name: offer_name ?? '',
    size: unit.size,
    transport_type,
    description: description ?? '',
    zip_location: zip_location ?? '',
    country_location: country_location ?? 'de',
    zip_destination: zip_destination ?? '',
    country_destination: country_destination ?? 'de',
    zip_load_unload: zip_load_unload ?? undefined,
    country_load_unload: country_load_unload ?? 'de',
    date_start: '',
    attributes: attributesToForm(unit.attributes),
    requirements: requirementsToForm(unit.requirements),
  };
}

export function cargoPresetToPresetForm(
  preset: CargoPreset
): CargoPresetFormValues {
  return {
    ...cargoPresetToForm(preset),
    name: preset.preset_name ?? '',
    offer_name: preset.offer_name ?? '',
  };
}

export function capacityPresetToPresetForm(
  preset: CapacityPreset
): CapacityPresetFormValues {
  return {
    ...capacityPresetToForm(preset),
    name: preset.preset_name,
    offer_name: preset.offer_name,
  };
}

export function cargoOfferToForm({
  cargo,
  cargo_units: [unit],
}: CargoOffer): CargoFormValues {
  const {
    name,
    transport_type,
    description,
    date_start,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
    zip_load_unload,
    country_load_unload,
  } = cargo;
  return {
    name,
    transport_type,
    description,
    size: unit.size,
    date_start,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
    zip_load_unload: zip_load_unload ?? undefined,
    country_load_unload: country_load_unload ?? undefined,
    attributes: attributesToForm(unit.cargo_attributes),
    requirements: requirementsToForm(unit.cargo_requirements),
  };
}
export function capacityOfferToForm(offer: CapacityOffer): CapacityFormValues {
  const { capacity, attribute_capabilities, requirement_capabilities } = offer;
  const {
    name,
    size,
    description,
    date_start,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
  } = capacity;
  return {
    name,
    size,
    lightOrHeavy: size === '1x20ft' || size === '1x20ft_heavy' ? size : null,
    description,
    date_start,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
    attributes: attributesToForm(attribute_capabilities),
    requirements: requirementsToForm(requirement_capabilities),
  };
}

export function capacityPresetToForm(
  preset: CapacityPreset
): CapacityFormValues {
  const {
    offer_name,
    size,
    attribute_capabilities,
    requirement_capabilities,
    description,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
  } = preset;
  return {
    name: offer_name ?? '',
    size,
    lightOrHeavy: size === '1x20ft' || size === '1x20ft_heavy' ? size : null,
    description,
    zip_location: zip_location ?? '',
    country_location: country_location ?? 'de',
    zip_destination: zip_destination ?? '',
    country_destination: country_destination ?? 'de',
    date_start: '',
    attributes: attributesToForm(attribute_capabilities),
    requirements: requirementsToForm(requirement_capabilities),
  };
}

export function cargoOfferInputFromForm(
  values: CargoFormValues
): CargoOfferInput {
  const {
    transport_type,
    size,
    attributes,
    requirements,
    country_location,
    country_destination,
    zip_load_unload,
    country_load_unload,
    ...cargo
  } = values;
  const relocation = transport_type === 'relocation';
  return {
    cargo: {
      ...cargo,
      date_end: endDate(cargo.date_start),
      transport_type,
      country_location: country_location || 'de',
      country_destination: country_destination || 'de',
      zip_load_unload: relocation ? null : zip_load_unload,
      country_load_unload: relocation ? null : country_load_unload || 'de',
    },
    cargo_units: [
      {
        size,

        attributes: attributesFromForm(attributes),
        requirements: requirementsFromForm(requirements),
      },
    ],
  };
}

export function cargoPresetFromForm(
  values: CargoPresetFormValues
): CargoPresetData {
  const {
    name,
    size,
    transport_type,
    attributes,
    requirements,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
    zip_load_unload,
    country_load_unload,
    ...cargo
  } = values;
  const relocation = transport_type === 'relocation';
  return {
    preset_name: name,
    ...cargo,
    transport_type,
    zip_location: zip_location || null,
    country_location: zip_location ? country_location || 'de' : null,
    zip_destination: zip_destination || null,
    country_destination: zip_destination ? country_destination || 'de' : null,
    zip_load_unload: relocation ? null : zip_load_unload || null,
    country_load_unload: relocation
      ? null
      : zip_load_unload
      ? country_load_unload || 'de'
      : null,
    cargo_units: [
      {
        size,
        attributes: attributesFromForm(attributes),
        requirements: requirementsFromForm(requirements),
      },
    ],
  };
}

export function capacityOfferInputFromForm(
  values: CapacityFormValues
): CapacityOfferInput {
  const {
    size,
    lightOrHeavy,
    date_start,
    attributes,
    requirements,
    country_location,
    zip_destination,
    country_destination,
    ...capacity
  } = values;
  return {
    capacity: {
      size: size === '1x20ft' ? lightOrHeavy || size : size,
      ...capacity,
      date_start,
      date_end: endDate(date_start),
      country_location: country_location || 'de',
      zip_destination: zip_destination || null,
      country_destination: zip_destination ? country_destination || 'de' : null,
      attributes: attributesFromForm(attributes),
      requirements: requirementsFromForm(requirements),
    },
  };
}

export function capacityPresetFromForm(
  values: CapacityPresetFormValues
): CapacityPresetData {
  const {
    name,
    attributes,
    requirements,
    zip_location,
    country_location,
    zip_destination,
    country_destination,
    ...capacity
  } = values;
  return {
    preset_name: name,
    ...capacity,
    zip_location: zip_location || null,
    country_location: zip_location ? country_location || 'de' : null,
    zip_destination: zip_destination || null,
    country_destination: zip_destination ? country_destination || 'de' : null,
    attribute_capabilities: attributesFromForm(attributes),
    requirement_capabilities: requirementsFromForm(requirements),
  };
}

export function cargoOfferDetails(
  offer: CargoOfferWithMatches
): CargoOfferDetails {
  const {
    amount_of_matches,
    cargo: {
      id,
      name,
      date_start,
      zip_location,
      country_location,
      zip_destination,
      country_destination,
      transport_type,
      visible,
    },
    cargo_units: [{ size, cargo_attributes, cargo_requirements }],
    location,
  } = offer;
  return {
    offerType: 'cargo',
    id,
    name,
    date: date_start,
    zip_location,
    country_location,
    pos: { lat: location.latitude, lng: location.longitude },
    zip_destination,
    country_destination,
    size,
    visible,
    type: transport_type,
    attributes: cargo_attributes,
    requirements: cargo_requirements,
    matches: amount_of_matches,
    offer,
  };
}

export function capacityOfferDetails(
  offer: CapacityOfferWithMatches
): CapacityOfferDetails {
  const {
    amount_of_matches,
    capacity: {
      id,
      name,
      date_start,
      zip_location,
      country_location,
      size,
      visible,
    },
    location,
    attribute_capabilities,
    requirement_capabilities,
  } = offer;
  return {
    offerType: 'capacity',
    id,
    name,
    date: date_start,
    zip_location,
    country_location,
    pos: { lat: location.latitude, lng: location.longitude },
    size,
    visible,
    attributes: attribute_capabilities,
    requirements: requirement_capabilities,
    matches: amount_of_matches,
    offer,
  };
}

/**
 * Type-guard to check if a match is a MatchingCargo.
 */
function isCargo(match: unknown): match is MatchingCargo {
  return !!match && typeof match === 'object' && 'cargo' in match;
}

export function matchResponseToMatchDetails(
  match: MatchingResponse
): MatchDetails {
  return {
    type: 'cargo',
    matchId: match.match.id,
    user: match.other_user,
    company: match.other_users_company,
    requirements: match.cargo.cargo_units[0].cargo_requirements,
    attributes: match.cargo.cargo_units[0].cargo_attributes,
    size: match.cargo.cargo_units[0].size,
    created_at: match.match.created_at,
    zip_location: match.cargo.location.zipcode,
    country_location: match.cargo.location.country,
    place_location: match.cargo.location.title,
    pos_location: {
      lat: match.cargo.location.latitude,
      lng: match.cargo.location.longitude,
    },
    zip_load_unload: match.cargo.load_unload?.zipcode ?? undefined,
    country_load_unload: match.cargo.load_unload?.country ?? undefined,
    place_load_unload: match.cargo.load_unload?.title,
    pos_load_unload: match.cargo.load_unload
      ? {
          lat: match.cargo.load_unload.latitude,
          lng: match.cargo.load_unload.longitude,
        }
      : undefined,
    zip_destination: match.cargo.destination.zipcode,
    country_destination: match.cargo.destination.country,
    place_destination: match.cargo.destination.title,
    pos_destination: {
      lat: match.cargo.destination.latitude,
      lng: match.cargo.destination.longitude,
    },
    date_start: match.cargo.cargo.date_start,
    date_end: match.cargo.cargo.date_end,
    name: match.cargo.cargo.name,
    description: match.cargo.cargo.description,
    visible: match.cargo.cargo.visible,
    interested: match.match.capacity_interested,
    distance: match.cargo.cargo.matching_route?.distance?.text,
    polyline: match.cargo.cargo.matching_route?.polyline,
    transport_type: match.cargo.cargo.transport_type,
    capacity_size: match.capacity.capacity.size,
  };
}

/**
 * Utility function to turn a `MatchingCargo` or `MatchingCapacity` into MatchDetails.
 */
export function matchDetails(match: MatchingCargo | MatchingCapacity) {
  return isCargo(match)
    ? matchingCargoDetails(match)
    : matchingCapacityDetails(match);
}

function matchingCargoDetails({
  cargo,
  match,
  other_user,
  other_users_company,
}: MatchingCargo): MatchDetails {
  const [first] = cargo.cargo_units;
  const {
    cargo: {
      created_at,
      transport_type,
      zip_location,
      country_location,
      zip_load_unload,
      country_load_unload,
      zip_destination,
      country_destination,
      date_start,
      date_end,
      name,
      description,
      visible,
      matching_route,
    },
    location,
    destination,
    load_unload,
  } = cargo;

  return {
    type: 'cargo',
    matchId: match.id,
    user: other_user,
    company: other_users_company,
    requirements: first.cargo_requirements,
    attributes: first.cargo_attributes,
    size: first.size,
    created_at,
    transport_type,
    zip_location,
    country_location,
    place_location: location.title,
    pos_location: {
      lat: location.latitude,
      lng: location.longitude,
    },
    zip_load_unload: zip_load_unload ?? undefined,
    country_load_unload: country_load_unload ?? undefined,
    place_load_unload: load_unload?.title,
    pos_load_unload: load_unload
      ? {
          lat: load_unload.latitude,
          lng: load_unload.longitude,
        }
      : undefined,
    zip_destination,
    country_destination,
    place_destination: destination.title,
    pos_destination: { lat: destination.latitude, lng: destination.longitude },
    date_start,
    date_end,
    name,
    description,
    visible,
    interested: match.capacity_interested,
    distance:
      matching_route.distance && match.approaching_route.distance
        ? `${matching_route.distance.text} (+${match.approaching_route.distance.text} Anfahrt)`
        : 'Entfernung konnte nicht berechnet werden',
    polyline: matching_route.polyline,
    approaching_polyline: match.approaching_route.polyline,
  };
}

function matchingCapacityDetails({
  capacity,
  match,
  other_user,
  other_users_company,
}: MatchingCapacity): MatchDetails {
  const {
    attribute_capabilities: attributes,
    requirement_capabilities: requirements,
    capacity: {
      created_at,
      zip_location,
      country_location,
      zip_destination,
      country_destination,
      date_start,
      date_end,
      name,
      visible,
      size,
      description,
    },
    location,
    destination,
  } = capacity;
  return {
    type: 'capacity',
    matchId: match.id,
    user: other_user,
    company: other_users_company,
    attributes,
    requirements,
    size,
    created_at,
    zip_location,
    country_location,
    place_location: location.title,
    pos_location: {
      lat: location.latitude,
      lng: location.longitude,
    },
    zip_destination: zip_destination ?? undefined,
    country_destination: (zip_destination && country_destination) || undefined,
    place_destination: destination?.title,
    pos_destination: destination
      ? { lat: destination.latitude, lng: destination.longitude }
      : undefined,
    date_start,
    date_end,
    name,
    description,
    visible,
    interested: match.cargo_interested,
    distance: match.approaching_route.distance
      ? match.approaching_route.distance.text
      : 'Entfernung konnte nicht berechnet werden.',
    approaching_polyline: match.approaching_route.polyline,
  };
}

export function splitAttributes(attrs: Attribute[]) {
  const types = ['Open-Top', 'Flat-Rack', 'High-Cube', 'Rear-Flush'];
  const getAttr = (name: string) => attrs.find((a) => a.name === name)?.value;
  return {
    loadingTypes: attrs
      .filter((a) => types.includes(a.name))
      .map((a) => a.value),
    customs: getAttr('Customs-Clearance') && true,
    terminal: getAttr('Terminal'),
    depot: getAttr('Depot'),
    shippingCompany: getAttr('Shipping-Company'),
  };
}

/**
 * Type-guard to check if a match is a CargoMatchesResponse.
 */
function isCargoMatchesResponse(
  response: unknown
): response is CargoMatchesResponse {
  return !!response && typeof response === 'object' && 'cargo' in response;
}

export function getOfferFromMatchResponse(
  response: CargoMatchesResponse | CapacityMatchesResponse
): CargoOrCapacityOfferDetails {
  if (isCargoMatchesResponse(response)) {
    return cargoOfferDetails(response.cargo);
  } else {
    return capacityOfferDetails(response.capacity);
  }
}

export function paymentInfoFromForm(
  {
    choose_email,
    payment_email,
    service,
    tax_number,
  }: CompanyPaymentFormValues,
  company_email?: string
): CompanyPaymentInput {
  return {
    tax_number,
    va_tax_id: tax_number,
    payment_email:
      choose_email === 'company_email' && company_email
        ? company_email
        : payment_email,
    service,
    currency: 'EUR',
  };
}

export function paymentInfoToForm(
  data: CompanyPaymentInfo | null,
  company_email?: string
): CompanyPaymentFormValues {
  if (data) {
    const { payment_email, tax_number, service } = data;
    return {
      choose_email: payment_email === company_email ? 'company_email' : 'other',
      payment_email: payment_email === company_email ? '' : payment_email,
      tax_number,
      service,
    };
  } else {
    return {
      choose_email: 'company_email',
      payment_email: '',
      tax_number: '',
      service: '',
    };
  }
}

export function matchingPricesToForm(
  cm_matching_prices: MatchingPricesImportExport,
  use_company_prices: boolean,
  company_matching_prices: any
): MatchSettingForm {
  return {
    companyMember: cm_matching_prices,
    use_company_prices: company_matching_prices ? use_company_prices : false,
    company: company_matching_prices,
  };
}

function endDate(start: string) {
  const d = parseDate(start);
  return d ? addDays(d, 2).toISOString() : start;
}
