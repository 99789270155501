import React from 'react';

import { Card, CardHeader } from '../../common/Card';

export function MatchingSettings() {
  return (
    <Card>
      <CardHeader>ContainerMatch</CardHeader>
      <p>
        {' '}
        Hier gibt es in Kürze die Einstellungen um Preise pro Kilometer für
        Container festzulegen. Diese können von Administatoren unternehmensweit
        eigestellt werden und Disponenten können individuelle Preise festlegen.
      </p>
      <br />
      <p>
        Du wirst über die Timeline informiert, sobald diese Funktion zur
        Verfügung steht.
      </p>
    </Card>
  );
}
