import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { debounce } from '../../../lib/debounce';
import { UserData } from '../../../lib/publicApi';
import { SearchResultItem } from '../../../lib/types/connections';
import { useApiCall } from '../../../lib/useApiCall';
import { useKeyboard } from '../../../lib/useKeyboard';
import { Avatar } from '../../common/Avatar';
import { Btn } from '../../common/Btn';
import { AddIcon, CancelIcon, SearchIcon } from '../../common/Icons';
import { LoadingCircle, LoadingLayer } from '../../common/LoadingCircle';
import {
  MediumOrLargeViewport,
  SmallViewport,
} from '../../common/MediaQueries';
import { MessageBox } from '../../common/MessageBox';
import {
  Backdrop,
  CloseButton,
  Dialog,
  DialogContent,
  DialogFrame,
  DialogHeader,
} from '../../common/Modal';
import { Portal } from '../../common/Portal';
import { Body, Headings } from '../../common/typography';
import { InputWithIconRight } from '../../form/InputWithIconRight';
import { TextField } from '../../form/TextField';
import { Center } from '../../layout/Center';
import { useApi } from '../../UserState';
import magnify from '../createCompany/magnify.svg';

type Props = {
  companyID: number;
  close: () => void;
};

const Frame = styled(DialogFrame)`
  border-radius: 20px;
`;

const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ResultContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Header = styled(DialogHeader)`
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 720px) {
    border-radius: 10px 10px 0 0;
    padding: 16px;
  }
`;

const Content = styled(DialogContent)`
  background: var(--white);
  border-radius: 0 0 20px 20px;
  min-height: 500px;

  h1 {
    ${Headings.h3}
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  p {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    color: var(--grey500);
  }

  @media screen and (max-width: 720px) {
    padding: 16px;
    border-radius: 0 0 20px 20px;

    p {
      ${Body.body6}
    }

    h1 {
      ${Headings.h4}
    }
  }
`;

const ResultList = styled.ul`
  position: absolute;
  background: var(--white);
  top: 0.25rem;
  left: 0;
  z-index: 10;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);

  list-style: none;
  margin: 0;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  max-height: 300px;
  overflow: auto;

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0.25rem;
    border-radius: 0.5rem;
    &.selected {
      background: var(--tertiary200);
    }

    &:first-child {
      flex: 0 0 42px;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }

    .company {
      flex: 0 1 100%;
    }

    .actions {
      flex: 0 0 auto;
    }
  }
`;

export function CompanyInviteModal({ companyID, close }: Props) {
  const resultRef = useRef<HTMLUListElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [query, setQuery] = useState('');
  const api = useApi();
  const [results, setResults] = useState<SearchResultItem[] | null>(null);
  const { data: employees, invalidate } = useApiCall('getEmployees', companyID);
  const [employeeIDs, setEmployeeIDs] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [addedUser, setAddedUser] = useState<UserData | null>(null);

  useEffect(() => {
    if (employees) {
      const ids: number[] = [];
      for (const users of Object.values(employees)) {
        for (const user of users) {
          ids.push(user.id);
        }
      }
      setEmployeeIDs(ids);
    }
    setSelectedIndex(-1);
  }, [employees]);

  const filteredResults = useMemo(
    () =>
      results
        ? results.filter((item) => employeeIDs.includes(item.user.id) === false)
        : null,
    [employeeIDs, results]
  );

  useKeyboard({ Escape: close });

  const refreshResults = debounce((query: string) => {
    if (query === '') {
      setResults(null);
      return;
    }
    api.search(query).then((data) => {
      setResults(data);
    });
  }, 100);

  const addEmployee = async (user: UserData) => {
    const id = user.id;
    try {
      setLoading(true);
      await api.addEmployee(companyID, id);
      setResults(results?.filter((item) => item.user?.id !== id) ?? null);
      setAddedUser(user);
      invalidate();
    } catch (err: any) {
      setError(err.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAddedUser(null);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [employeeIDs]);

  const scrollToSelectedElement = (block: boolean) => {
    const selected = resultRef.current?.querySelector('.selected');
    if (selected) {
      selected.scrollIntoView(block || true);
    }
  };

  const keyboardHandler = (e: React.KeyboardEvent) => {
    if (loading || !filteredResults || filteredResults.length === 0) {
      return;
    }
    if (e.key === 'ArrowUp') {
      if (selectedIndex <= 0) {
        setSelectedIndex(filteredResults.length - 1);
      } else {
        setSelectedIndex(selectedIndex - 1);
      }
      scrollToSelectedElement(true);
    }
    if (e.key === 'ArrowDown') {
      if (selectedIndex >= filteredResults.length - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
      scrollToSelectedElement(false);
    }
    if (e.key === 'Enter') {
      const selected = document.querySelector('.selected');
      selected?.querySelector<HTMLButtonElement>('button')?.click();
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    refreshResults(e.target.value);
  };
  return (
    <Portal>
      <Dialog aria-hidden={!!(loading || error)}>
        <Backdrop tabIndex={0} onClick={close} />
        <Frame width="720px" role="dialog" aria-labelledby="inviteTitle">
          <Header>
            {addedUser && (
              <MessageBox type="success" style={{ marginRight: '70px' }}>
                {`${addedUser.first_name} ${addedUser.last_name} wurde zu deinem Unternehmen eingeladen.`}
              </MessageBox>
            )}
            <CloseButton onClick={close}>
              <CancelIcon />
            </CloseButton>
          </Header>
          <Content>
            {employees && (
              <Center direction="column" height="auto" gap="1.5rem">
                <img src={magnify} alt="" width="" height="" />
                <h1 id="inviteTitle">Zum Unternehmen einladen</h1>
                <FieldWrapper onKeyDown={keyboardHandler}>
                  <label htmlFor="searchEmployee">Mitarbeiter suchen</label>
                  <InputWithIconRight style={{ width: '100%' }}>
                    <TextField
                      id="searchEmployee"
                      placeholder="Name suchen"
                      value={query}
                      onChange={changeHandler}
                      autoComplete="off"
                    />
                    <SearchIcon />
                  </InputWithIconRight>
                  {filteredResults !== null && (
                    <ResultContainer>
                      {filteredResults.length ? (
                        <ResultList ref={resultRef}>
                          {filteredResults.map(({ user }, idx) => (
                            <li
                              key={user.id}
                              className={
                                idx === selectedIndex ? 'selected' : undefined
                              }
                            >
                              <Avatar
                                name={`${user.first_name} ${user.last_name}`}
                                image={user.picture}
                              />
                              <div className="company">{`${user.first_name} ${user.last_name}`}</div>
                              <div className="actions">
                                <SmallViewport>
                                  <Btn
                                    round
                                    appearance="outline"
                                    type="button"
                                    size="small"
                                    onClick={() => addEmployee(user)}
                                  >
                                    <AddIcon />
                                  </Btn>
                                </SmallViewport>
                                <MediumOrLargeViewport>
                                  <Btn
                                    color="primary"
                                    type="button"
                                    onClick={() => addEmployee(user)}
                                  >
                                    Hinzufügen
                                  </Btn>
                                </MediumOrLargeViewport>
                              </div>
                            </li>
                          ))}
                        </ResultList>
                      ) : (
                        <ResultList ref={resultRef}>
                          <p>
                            Kein Benutzer mit diesem Namen gefunden, der noch
                            nicht Mitglied deiner Firma ist.
                          </p>
                        </ResultList>
                      )}
                    </ResultContainer>
                  )}
                </FieldWrapper>
                <p>
                  Füge neue Mitarbeiter zu deinem Unternehmen hinzu.
                  Nutzer/innen von Cargofaces kannst du via Name finden und
                  direkt einladen.
                </p>
              </Center>
            )}
          </Content>
        </Frame>
      </Dialog>
      {loading && (
        <LoadingLayer>
          <LoadingCircle />
        </LoadingLayer>
      )}
    </Portal>
  );
}
