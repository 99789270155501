import React, { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

import { formatShortDate } from '../../lib/convert';
import { ConnectedContact } from '../../lib/types/connections';
import { Avatar } from '../common/Avatar';
import { Column, DataTable, SortOrder } from '../common/DataTable';
import { TableLink } from '../common/Link';

const presets: Record<string, Column<ConnectedContact>> = {
  image: {
    key: 'Image',
    render: (item) => (
      <Avatar
        name={`${item.contact.first_name} ${item.contact.last_name}`}
        image={item.contact.picture}
      />
    ),
    style: { width: 0 },
  },
  name: {
    key: 'name',
    heading: 'Name',
    sortable: true,
    value: ({ contact }) =>
      `${contact.first_name} ${contact.last_name}`.toLowerCase(),
    render: ({ contact }) => (
      <TableLink to={`/user/${encodeURIComponent(contact.id)}`}>
        {contact.first_name} {contact.last_name}
      </TableLink>
    ),
  },
  company: {
    key: 'company',
    heading: 'Unternehmen',
    sortable: true,
    value: ({ contact, companies }) => {
      const company = companies.find(
        (c) => c.id === contact.selected_company_id
      );
      return company?.name;
    },
    render: ({ contact, companies }) => {
      const company = companies.find(
        (c) => c.id === contact.selected_company_id
      );
      return company ? (
        <TableLink to={`/company/${encodeURIComponent(company.id)}`}>
          {company.name}
        </TableLink>
      ) : null;
    },
  },
  location: {
    key: 'location',
    heading: 'Basisstandort',
    sortable: true,
    value: (item) => item.contact.city,
    render: (item) => item.contact.city,
  },
  dateSent: {
    key: 'date',
    heading: 'Datum gesendet',
    sortable: true,
    value: (item) => formatShortDate(item.connection.created_at),
    render: (item) => formatShortDate(item.connection.created_at),
  },
};

const Actions = styled.div`
  display: flex;
  margin-left: -8px;
  gap: 8px;
`;

type Props = {
  items: any;
  columns: Array<keyof typeof presets>;
  renderActions: (item: ConnectedContact) => ReactNode;
};
export function ConnectionsTable({ items, columns, renderActions }: Props) {
  const columnConfig = useMemo(
    () => [
      ...columns.map((col) => presets[col]),

      {
        key: 'actions',
        heading: 'Aktionen',
        style: { width: 0 },
        render: (item) => <Actions>{renderActions(item)}</Actions>,
      },
    ],
    [...columns, renderActions]
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    column: 'name',
    asc: true,
  });

  return (
    <DataTable
      columns={columnConfig}
      items={items}
      sortOrder={sortOrder}
      onSort={setSortOrder}
    />
  );
}
