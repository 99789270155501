import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { actionMedium } from './typography/Special';

/**
 * Wrapper around SubNavItem(s) for the top app-header navigation
 * that stretches the items to 66px height.
 */
export const SubNav = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  & > * {
    min-height: 66px;
  }
`;

export const SubNavItem = styled(NavLink)`
  ${actionMedium}
  display: flex;
  gap: 0.5em;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 1px;
  border: none;
  background: transparent;
  color: var(--primary500);
  :hover {
    color: var(--tertiary600);
  }
  transition: color 300ms ease;
  position: relative;
  &.active,
  &[data-state='active'] {
    color: var(--tertiary600);
    ::after {
      content: '';
      display: block;
      background: var(--tertiary600);
      border-radius: 3px 3px 0 0;
      width: 30px;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
