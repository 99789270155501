import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Avatar } from '../../common/Avatar';
import { Btn } from '../../common/Btn';
import { H3, H6 } from '../../common/typography/Headings';
import { Field } from '../../form/Field';
import { Form } from '../../form/Form';
import { Label } from '../../form/Label';
import { TextField } from '../../form/TextField';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { Input } from '../../profile/setting/UserData';
import CameraLogo from '../icons/Camera.svg';
import CircledHouse from '../icons/CompanyInsideCircle.svg';
import { Dots } from './Dots';

const LogosBox = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  right: -20px;
  bottom: -15px;
  cursor: pointer;
`;

export type BasicInfoPrpos = {
  logo: FileList;
  name: string;
  phone: string;
  email: string;
};

type Props = {
  values?: Partial<BasicInfoPrpos>;
  next: (data: BasicInfoPrpos) => void;
};

export const BasicInfo = ({ values, next }: Props) => {
  const [showError, setShowError] = useState<boolean>(false);
  const form = useForm<BasicInfoPrpos>({ defaultValues: values });
  const newLogo = form.watch('logo');
  const previewImage = useMemo(() => {
    let url = null;
    if (showError) {
      setShowError(false);
    }
    if (newLogo instanceof FileList && newLogo.length) {
      if (newLogo[0].size < 1000000) {
        url = URL.createObjectURL(newLogo[0]);
      } else setShowError(true);
    }
    return url;
  }, [newLogo]);

  return (
    <Form {...form} onSubmit={next}>
      <Stack gap="24px">
        <Row justify="center">
          <LogosBox>
            <Avatar name="" image={previewImage ?? CircledHouse} size="64px" />
            <Input
              {...form.register('logo')}
              type="file"
              id="avatar"
              accept="image/png, image/jpeg"
            />
            <Label htmlFor="avatar">
              <Image src={CameraLogo} />
            </Label>
          </LogosBox>
        </Row>
        <Stack align="center">
          <H3 color="var(--grey800)">Dein Unternehmen</H3>
          <H6 color="var(--grey800)">Herzlich Willkommen auf Cargofaces!</H6>
          <H6 color="var(--grey500)">
            Erstelle hier dein Unternehmen, um Kollegen hinzuzufügen und Tools
            wie ContainerMatch zu nutzen. Starten wir mit ein paar Details und
            schon bald wirst du alle Vorteile von Cargofaces genießen können.
          </H6>
        </Stack>
        <Field name="name" label="Name des Unternehmens" required>
          <TextField placeholder="Mustermann Spedition GmbH" />
        </Field>

        <Row>
          <Field name="email" label="Primäre E-Mail" required>
            <TextField type="email" placeholder="moin@cargofaces.com" />
          </Field>
          <Field name="phone" label="Telefonummer" required>
            <TextField type="tel" placeholder="+49 XXX XXX XXXX" />
          </Field>
        </Row>
        <Row justify="spread">
          <Btn type="button" appearance="transparent" disabled color="neutral">
            Zurück
          </Btn>
          <Dots step={1} />
          <Btn type="submit">Weiter</Btn>
        </Row>
      </Stack>
    </Form>
  );
};
