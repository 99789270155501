import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppScreen } from '../../components/common/AppScreen';
import { Btn } from '../../components/common/Btn';
import { Card } from '../../components/common/Card';
import {
  CheckmarkCircleIcon,
  CrossCircleIcon,
  MinusCircleIcon,
} from '../../components/common/Icons';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import {
  ViewportCanShowDataTable,
  ViewportTooSmallForDataTable,
} from '../../components/common/MediaQueries';
import { WhiteButton } from '../../components/common/SideBar';
import { Headings, Special } from '../../components/common/typography';
import { H5 } from '../../components/common/typography/Headings';
import { SubtitleLarge } from '../../components/common/typography/Special';
import { CompanyAvatarNameTitle } from '../../components/company/AvatarNameTitle';
import { JoinCompanyDialog } from '../../components/company/createCompany/JoinCompanyDialog';
import { UserRequestsDataTable } from '../../components/company/UserRequestsDataTable';
import addresses from '../../components/empty/addresses.svg';
import { EmptyState } from '../../components/empty/EmptyState';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import {
  useApi,
  useRefetchUser,
  useUserData,
  useUserState,
} from '../../components/UserState';
import { UserData } from '../../lib/publicApi';
import { CompanyWithLogo } from '../../lib/types/company';
import { useApiCall } from '../../lib/useApiCall';

const Grow = styled.div`
  flex-grow: 1;
`;

const UserCard = styled(Card)`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);

  h2 {
    ${Headings.h6}
    color: var(--grey800);
  }

  h2 + p {
    ${Special.SubtitleMedium}
    color: var(--grey500);
  }
`;

const MobileActions = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

type LayoutProps = {
  top?: React.ReactNode;
  children?: React.ReactNode;
};
function Layout({ top, children }: LayoutProps) {
  return (
    <Stack style={{ minHeight: '100%' }}>
      {top}
      <Grow>{children}</Grow>
    </Stack>
  );
}

function Empty() {
  return (
    <EmptyState
      image={addresses}
      imageStyle={{ height: 322, marginBottom: -15 }}
      title="Keine Anfragen"
    >
      Finde dein Unternehmen über die Schaltfläche oben
    </EmptyState>
  );
}

export type UserWithRoles = {
  user: UserData;
  roles: Array<string>;
};

type MobileCardProps = {
  companyWithLogo: CompanyWithLogo;
  actions?: React.ReactNode;
};

function MobileCard({ companyWithLogo, actions }: MobileCardProps) {
  return (
    <UserCard key={companyWithLogo.company.id}>
      <Stack gap="1.5rem">
        <CompanyAvatarNameTitle
          company={companyWithLogo.company}
          logo={companyWithLogo.logo}
        />
        <MobileActions>{actions}</MobileActions>
      </Stack>
    </UserCard>
  );
}

export function MyRequests() {
  const [filter, setFilter] = useState<string>('');
  const user = useUserData();
  const api = useApi();
  const [, setUser] = useUserState();
  const navigate = useNavigate();
  const { data, invalidate } = useApiCall('getEmployeeRequests');
  const filterFunc = ({ company }: CompanyWithLogo) => {
    const regExp = new RegExp(filter, 'i');
    return company.name && regExp.test(company.name);
  };
  const [loading, setLoading] = useState(false);
  const refetchUser = useRefetchUser();

  const requestsByCompanyFiltered =
    data?.sent_by_company?.filter(filterFunc) ?? [];
  const requestsByUserFiltered = data?.sent_by_user?.filter(filterFunc) ?? [];

  /**
   * Accept invitation to join company
   * @param id user id
   */
  const acceptUser = async (id: number, name?: string) => {
    setLoading(true);
    await api.acceptAsUserToJoinCompany(id);
    invalidate();
    const data: UserData = {
      ...user,
      selected_company_id: id,
      selected_company_name: name ?? '',
    };
    setUser({ api, data });
    navigate('/dashboard');
  };

  /**
   * Decline User who requested to join company
   * @param id
   */
  const cancelRequest = async (companyID: number) => {
    setLoading(true);
    await api.declineAddToCompanyRequest(companyID);
    invalidate();
    setLoading(false);
    refetchUser();
  };

  /**
   * Actions for requests sent by company
   * @param company
   * @returns
   */
  const companyActions = ({ company }: CompanyWithLogo) => (
    <>
      <Btn
        size="small"
        color="neutral"
        onClick={() => cancelRequest(company.id)}
      >
        <MinusCircleIcon />
        Ignorieren
      </Btn>
      <Btn
        size="small"
        color="success"
        onClick={() => acceptUser(company.id, company.name)}
      >
        <CheckmarkCircleIcon />
        Akzeptieren
      </Btn>
    </>
  );

  /**
   * Actions for requests sent by user
   * @param company
   * @returns
   */
  const userActions = ({ company }: CompanyWithLogo) => (
    <>
      <Btn size="small" color="error" onClick={() => cancelRequest(company.id)}>
        <CrossCircleIcon />
        Abbrechen
      </Btn>
    </>
  );

  const isEmpty =
    data?.sent_by_company?.length === 0 && data?.sent_by_user?.length === 0;

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <AppScreen title="Meine Anfragen">
      <Layout>
        {isEmpty && <Empty />}
        {data && !isEmpty && (
          <Stack gap="2rem">
            <Stack
              as="section"
              aria-labelledby="companyRequestsHeadline"
              gap="1rem"
            >
              <H5 as="h2">Gesendet</H5>
              <ViewportCanShowDataTable>
                <UserRequestsDataTable
                  items={requestsByUserFiltered}
                  renderActions={userActions}
                />
              </ViewportCanShowDataTable>
              <ViewportTooSmallForDataTable>
                <Stack gap=".5rem">
                  {requestsByUserFiltered.map((item) => (
                    <MobileCard
                      key={item.company.id}
                      companyWithLogo={item}
                      actions={userActions(item)}
                    />
                  ))}
                </Stack>
              </ViewportTooSmallForDataTable>
            </Stack>
            <Stack
              as="section"
              aria-labelledby="userRequestsHeadline"
              gap="1rem"
            >
              <H5 id="userRequestsHeadline" as="h2">
                Empfangen
              </H5>

              <ViewportCanShowDataTable>
                <UserRequestsDataTable
                  items={requestsByCompanyFiltered}
                  renderActions={companyActions}
                />
              </ViewportCanShowDataTable>
              <ViewportTooSmallForDataTable>
                {requestsByCompanyFiltered.map((item) => (
                  <MobileCard
                    key={item.company.id}
                    companyWithLogo={item}
                    actions={companyActions(item)}
                  />
                ))}
              </ViewportTooSmallForDataTable>
            </Stack>
          </Stack>
        )}
        <Row>
          <SubtitleLarge>Nicht die richtige Firma dabei?</SubtitleLarge>
          <JoinCompanyDialog>
            <WhiteButton size="small">Unternehmen finden</WhiteButton>
          </JoinCompanyDialog>
        </Row>
      </Layout>
    </AppScreen>
  );
}
