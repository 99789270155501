import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

import { Body } from '../common/typography';

/**
 * Auto-sizing textarea, see:
 * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 */
const Container = styled.div`
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  min-height: 200px;
  ::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) ' ';

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  & > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;

    ::placeholder {
      color: var(--grey500);
    }

    :focus {
      box-shadow: 0px 0px 0px 4px rgba(29, 171, 242, 0.2);
      ::placeholder {
        color: var(--grey300);
      }
    }

    :hover,
    :focus {
      border-color: var(--primary500);
    }

    &[disabled] {
      background: var(--grey100);
      :hover {
        border-color: var(--grey300);
      }
    }
  }
  & > textarea,
  ::after {
    ${Body.body4}
    border-radius: 9.5px;
    border: 1px solid var(--grey300);
    background-color: var(--input-background);
    color: var(--grey800);
    padding: 10px 12px;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
`;

type Props = JSX.IntrinsicElements['textarea'];
export const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const [value, setValue] = useState(props.value ?? props.defaultValue);
  return (
    <Container data-replicated-value={value}>
      <textarea
        {...props}
        ref={ref}
        onInput={(e: any) => {
          setValue(e.target.value);
          if (props.onInput) props.onInput(e);
        }}
      />
    </Container>
  );
});
