import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Btn, BtnLink } from '../../components/common/Btn';
import { Card } from '../../components/common/Card';
import {
  CheckmarkCircleIcon,
  CrossCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  SearchIcon,
} from '../../components/common/Icons';
import {
  LoadingCircle,
  LoadingLayer,
} from '../../components/common/LoadingCircle';
import {
  ViewportCanShowDataTable,
  ViewportTooSmallForDataTable,
} from '../../components/common/MediaQueries';
import { Headings, Special } from '../../components/common/typography';
import { H5 } from '../../components/common/typography/Headings';
import { AvatarNameTitle } from '../../components/company/AvatarNameTitle';
import { ConfirmDeleteCompanyMember } from '../../components/company/ConfirmDeleteCompanyMember';
import { CompanyInviteModal } from '../../components/company/invite-modal';
import { RequestsDataTable } from '../../components/company/RequestsDataTable';
import { EmptyState } from '../../components/empty/EmptyState';
import pending from '../../components/empty/pending.svg';
import { InputWithIconRight } from '../../components/form/InputWithIconRight';
import { TextField } from '../../components/form/TextField';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { useApi } from '../../components/UserState';
import { UserData } from '../../lib/publicApi';
import { useApiCall } from '../../lib/useApiCall';

const TopRow = styled(Row)`
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const SearchField = styled(InputWithIconRight)`
  flex: 0 1 300px;
`;

const Grow = styled.div`
  flex-grow: 1;
`;

const UserCard = styled(Card)`
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);

  h2 {
    ${Headings.h6}
    color: var(--grey800);
  }

  h2 + p {
    ${Special.SubtitleMedium}
    color: var(--grey500);
  }
`;

const MobileActions = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

type LayoutProps = {
  top?: React.ReactNode;
  children?: React.ReactNode;
};
function Layout({ top, children }: LayoutProps) {
  return (
    <Stack style={{ minHeight: '100%' }}>
      {top}
      <Grow>{children}</Grow>
    </Stack>
  );
}

type TopControlsProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  invalidate: () => void;
};
function TopControls({ filter, setFilter, invalidate }: TopControlsProps) {
  return (
    <>
      <TopRow gap="1.5rem" align="top">
        <SearchField>
          <TextField
            placeholder="Suche"
            aria-label="Adresse"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <SearchIcon />
        </SearchField>
        <BtnLink
          type="button"
          size="small"
          style={{ marginTop: '2px' }}
          to="invite"
        >
          <PlusCircleIcon />
          Mitarbeiter hinzufügen
        </BtnLink>
      </TopRow>
    </>
  );
}

function Empty() {
  return (
    <EmptyState
      image={pending}
      imageStyle={{ height: 322, marginBottom: -15 }}
      title="Keine neuen Anfragen"
    >
      Füge neue Mitglieder über die Schaltfläche oben hinzu
    </EmptyState>
  );
}

export type UserWithRoles = {
  user: UserData;
  roles: Array<string>;
};

type MobileCardProps = {
  user: UserData;
  actions?: React.ReactNode;
};
function MobileCard({ user, actions }: MobileCardProps) {
  return (
    <UserCard key={user.id}>
      <Stack gap="1.5rem">
        <AvatarNameTitle user={user} />
        <MobileActions>{actions}</MobileActions>
      </Stack>
    </UserCard>
  );
}

type Props = {
  companyID: number;
};

export function CompanyRequests({ companyID }: Props) {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const { data, invalidate } = useApiCall('getEmployees', companyID);
  const [pendingDeletion, selectForDeletion] = useState<number>();
  const filterFunc = (user: UserData) => {
    const { first_name, last_name, job_title } = user;
    const regExp = new RegExp(filter, 'i');
    return [first_name, last_name, job_title].some((s) => s && regExp.test(s));
  };

  const requestsByCompanyFiltered =
    data?.sent_by_company?.filter(filterFunc) ?? [];
  const requestsByUserFiltered = data?.sent_by_user?.filter(filterFunc) ?? [];

  /**
   * Accept user who requested to join company
   * @param id user id
   */
  const acceptUser = async (id: number) => {
    setLoading(true);
    try {
      await api.acceptAsCompanyToAddUser(id);
      invalidate();
    } catch (err: any) {
      alert(err.message || 'Unknown error.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Decline User who requested to join company
   * @param id
   */
  const declineUser = async (id: number) => {
    setLoading(true);
    try {
      await api.removeEmpolyee(companyID, id);
      invalidate();
    } catch (err: any) {
      alert(err.message || 'Unknown error.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Cancel invitation to join company
   * @param id
   */
  const cancelRequest = async (id: number) => {
    setLoading(true);
    try {
      await api.removeEmpolyee(companyID, id);
      invalidate();
    } catch (err: any) {
      alert(err.message || 'Unknown error.');
    } finally {
      setLoading(false);
    }
  };

  const userActions = (user: UserData) => (
    <>
      <Btn size="small" color="neutral" onClick={() => declineUser(user.id)}>
        <MinusCircleIcon />
        Ignorieren
      </Btn>
      <Btn size="small" color="success" onClick={() => acceptUser(user.id)}>
        <CheckmarkCircleIcon />
        Akzeptieren
      </Btn>
    </>
  );

  const isEmpty =
    data?.sent_by_company?.length === 0 && data?.sent_by_user?.length === 0;

  const companyActions = (user: UserData) => (
    <>
      <Btn size="small" color="error" onClick={() => cancelRequest(user.id)}>
        <CrossCircleIcon />
        Abbrechen
      </Btn>
    </>
  );
  return (
    <>
      <Layout
        top={
          <TopControls
            filter={filter}
            setFilter={setFilter}
            invalidate={invalidate}
          />
        }
      >
        {isEmpty && <Empty />}
        {data && !isEmpty && (
          <Stack gap="2rem">
            <Stack
              as="section"
              aria-labelledby="companyRequestsHeadline"
              gap="1rem"
            >
              <H5 as="h2">Gesendet</H5>
              <ViewportCanShowDataTable>
                <RequestsDataTable
                  items={requestsByCompanyFiltered}
                  renderActions={companyActions}
                />
              </ViewportCanShowDataTable>
              <ViewportTooSmallForDataTable>
                <Stack gap=".5rem">
                  {requestsByCompanyFiltered.map((user) => (
                    <MobileCard
                      key={user.id}
                      user={user}
                      actions={companyActions(user)}
                    />
                  ))}
                </Stack>
              </ViewportTooSmallForDataTable>
            </Stack>
            <Stack
              as="section"
              aria-labelledby="userRequestsHeadline"
              gap="1rem"
            >
              <H5 id="userRequestsHeadline" as="h2">
                Empfangen
              </H5>
              <ViewportCanShowDataTable>
                <RequestsDataTable
                  items={requestsByUserFiltered}
                  renderActions={userActions}
                />
              </ViewportCanShowDataTable>
              <ViewportTooSmallForDataTable>
                {requestsByUserFiltered.map((user) => (
                  <MobileCard
                    key={user.id}
                    user={user}
                    actions={userActions(user)}
                  />
                ))}
              </ViewportTooSmallForDataTable>
            </Stack>
          </Stack>
        )}
      </Layout>
      {pendingDeletion && (
        <ConfirmDeleteCompanyMember
          onConfirm={async () => {
            if (pendingDeletion) {
              await api.removeEmpolyee(companyID, pendingDeletion);
              invalidate();
            }
          }}
          onClose={() => selectForDeletion(undefined)}
        />
      )}
      {loading && (
        <LoadingLayer>
          <LoadingCircle />
        </LoadingLayer>
      )}
      <Routes>
        <Route
          path="invite"
          element={
            <CompanyInviteModal
              companyID={companyID}
              close={() => {
                navigate(`..`);
                invalidate();
              }}
            />
          }
        />
      </Routes>
    </>
  );
}
