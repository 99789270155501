import React, { useLayoutEffect, useRef } from 'react';

import { UserData } from '../../lib/publicApi';
import { Chat, ChatMessage } from '../../lib/types/chat';
import { ChatBubble } from './ChatBubble';

type Props = {
  chat: Chat;
  chatMessage: ChatMessage;
  user: UserData;
  isLast: boolean;
};

export function MessageBubble({ chat, chatMessage, user, isLast }: Props) {
  // const { sender_id, message, created_at, system_message } = chatMessage;
  const {
    image,
    message: { created_at, message: messageText, sender_id, system_message },
  } = chatMessage;

  const isSender = sender_id === user.id;
  const userName = `${user.first_name} ${user.last_name}`;
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (isLast) {
      ref.current?.scrollIntoView();
    }
  }, [isLast]);
  return (
    <ChatBubble
      ref={ref}
      image={image}
      name={isSender ? userName : chat.contact_name}
      avatar={isSender ? user.picture : null}
      side={isSender ? 'right' : 'left'}
      message={messageText}
      date={new Date(created_at)}
      system_message={system_message}
    />
  );
}
