import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDay } from '../../../lib/convert';
import { MatchDetails } from '../../../lib/types/ui';
import { Chip } from '../../common/Chip';
import { CalendarIcon, RouteIcon } from '../../common/Icons';
import { H6 } from '../../common/typography/Headings';
import { Row } from '../../layout/Row';
import { Stack } from '../../layout/Stack';
import { SizeIcon } from '../../offer/SizeIcon';

export type Props = {
  details: MatchDetails;
};

export function MatchSummary({ details }: Props) {
  const { name, size, date_start, distance } = details;
  const { t } = useTranslation();
  return (
    <Stack gap="8px">
      <H6>{name}</H6>
      <Row gap="8px">
        <Chip>
          <RouteIcon />
          {distance}
        </Chip>
        <Chip>
          <SizeIcon size={size} /> {t(size)}
        </Chip>
        <Chip>
          <CalendarIcon /> {formatDay(date_start)}
        </Chip>
      </Row>
    </Stack>
  );
}
