import React, { ReactNode, useMemo, useState } from 'react';

import { ContactListItem } from '../../lib/types/contacts';
import { Column, DataTable, SortOrder } from '../common/DataTable';
import { Row } from '../layout/Row';

const presets: Record<string, Column<ContactListItem>> = {
  name: {
    key: 'name',
    heading: 'Name',
    sortable: true,
    value: ({ contact }) => `${contact.name}, ${contact.firstname}`,
    render: ({ contact }) => (
      <div>
        {contact.firstname} {contact.name}
      </div>
    ),
  },
  company: {
    key: 'company',
    heading: 'Unternehmen',
    render: ({ contact }) => {
      return <div>{contact.company}</div>;
    },
  },
  email: {
    key: 'email',
    heading: 'E-Mail',
    render: ({ contact }) => {
      return <div>{contact.email}</div>;
    },
  },
};

type Props = {
  items: any;
  columns: Array<keyof typeof presets>;
  renderActions?: (item: ContactListItem) => ReactNode;
};
export function AddressBookTable({ items, columns, renderActions }: Props) {
  const columnConfig = useMemo(() => {
    const config = columns.map((col) => presets[col]);
    if (renderActions) {
      config.push({
        key: 'actions',
        heading: 'Aktionen',
        style: { width: 0 },
        render: (item) => <Row noWrap>{renderActions(item)}</Row>,
      });
    }
    return config;
  }, [...columns, renderActions]);
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    column: 'name',
    asc: true,
  });
  if (!items) return null;
  return (
    <DataTable
      columns={columnConfig}
      items={items}
      sortOrder={sortOrder}
      onSort={setSortOrder}
    />
  );
}
