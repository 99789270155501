import styled from 'styled-components';

import { SubtitleMedium } from '../../common/typography/Special';

export const Label = styled(SubtitleMedium)`
  color: var(--grey600);
  svg {
    color: var(--grey400);
  }
`;
