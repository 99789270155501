import React from 'react';

import container20 from './icons/container20Chip.svg';
import container40 from './icons/container40Chip.svg';
import container45 from './icons/container45Chip.svg';
import truck2x20 from './icons/truck2x20Chip.svg';
import truck20 from './icons/truck20Chip.svg';
import truck40 from './icons/truck40Chip.svg';
import truck45 from './icons/truck45Chip.svg';

const icons: any = {
  '1x20ft': () => <img src={truck20} width="23" height="14" />,
  '1x20ft_heavy': () => <img src={truck20} width="23" height="14" />,
  '2x20ft_semi_trailer': () => <img src={truck2x20} width="40" height="14" />,
  '1x40ft': () => <img src={truck40} width="38" height="14" />,
  '1x45ft': () => <img src={truck45} width="50" height="14" />,
  container_20ft: () => <img src={container20} width="19" height="12" />,
  container_20ft_heavy: () => <img src={container20} width="19" height="12" />,
  container_40ft: () => <img src={container40} width="38" height="12" />,
  container_45ft: () => <img src={container45} width="52" height="12" />,
};

type Props = { size: string };
export function SizeIcon({ size }: Props) {
  const Icon = icons[size] ?? icons['1x20ft'];
  return <Icon />;
}
