import React from 'react';
import styled from 'styled-components';

import { Row } from '../../layout/Row';

const Dot = styled.div<{ selected: boolean }>`
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: ${(p) =>
    p.selected ? 'var(--tertiary500)' : 'var(--tertiary200)'};
`;

type Props = {
  step: number;
};

export const Dots = ({ step }: Props) => {
  return (
    <Row>
      <Dot selected={step === 1} />
      <Dot selected={step === 2} />
      <Dot selected={step === 3} />
      <Dot selected={step === 4} />
    </Row>
  );
};
