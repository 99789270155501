import { useLocation, useResolvedPath } from 'react-router-dom';

export function useIsActiveRoute(path: string, end?: boolean) {
  const location = useLocation();
  const resolvedPath = useResolvedPath(path);

  const locationPathname = location.pathname.toLowerCase();
  const toPathname = resolvedPath.pathname.toLowerCase();

  return (
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/')
  );
}
