import styled from 'styled-components';

export const InputWithIconRight = styled.div`
  position: relative;

  input {
    width: 100%;
    box-sizing: border-box;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
