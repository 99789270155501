import React from 'react';

import connections from '../../empty/connections.svg';
import { EmptyState } from '../../empty/EmptyState';

export function EmptyConnections() {
  return (
    <EmptyState
      image={connections}
      imageStyle={{ height: 373 }}
      title="Keine Kontakte"
    >
      Suchen Sie nach Kontakten oder fügen Sie sie aus dem Adressbuch hinzu.
    </EmptyState>
  );
}
