import React from 'react';

import { cargoOfferDetails } from '../../lib/convert';
import { CargoOfferWithMatches } from '../../lib/types/offers';
import { Row } from '../layout/Row';
import { ManagePresetsDialog } from '../presets/ManagePresetsDialog';
import { CargoOfferTable } from './CargoOfferTable';
import { CreateCargoDialog } from './CreateCargoDialog';

type Props = {
  cargos: CargoOfferWithMatches[];
  invalidate: () => void;
};
export function MyCargoOffers({ cargos, invalidate }: Props) {
  return (
    <CargoOfferTable
      items={cargos.map(cargoOfferDetails)}
      invalidate={invalidate}
    >
      <Row gap="20px">
        <CreateCargoDialog />
        <ManagePresetsDialog />
      </Row>
    </CargoOfferTable>
  );
}
