import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Btn } from '../../components/common/Btn';
import { Body4 } from '../../components/common/typography/Body';
import { Field } from '../../components/form/Field';
import { Form } from '../../components/form/Form';
import { PasswordField } from '../../components/form/PasswordField';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Stack } from '../../components/layout/Stack';
import { useAuth } from '../../components/UserState';
import {
  getAuthHeaders,
  resetPasswordWithToken,
  UserData,
  visitErrors,
} from '../../lib/publicApi';
import { PasswordInput } from '../../lib/types/user';

export function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const form = useForm<PasswordInput>();
  const password = form.watch('password', '');

  const authenticate = useAuth();

  const onSubmit: SubmitHandler<PasswordInput> = async (data) => {
    const params = Object.fromEntries(searchParams);
    let user: UserData | void;
    if (params.reset_password_token) {
      const res = await resetPasswordWithToken({
        ...data,
        reset_password_token: params.reset_password_token,
      });
      user = await authenticate(res.auth, () => res.user);
    } else {
      user = await authenticate(getAuthHeaders(params), async (api) => {
        const res = await api.resetPassword(data);
        if (res && 'errors' in res) {
          visitErrors(data, res, form.setError);
          setSearchParams(res.auth, { replace: true });
        } else {
          return res;
        }
      });
    }

    if (user) {
      console.log('Password reset, user logged in');
      navigate('/dashboard');
    } else {
      console.log('Reset failed, try again');
    }
  };

  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="Passwort zurücksetzen">
              <Form {...form} onSubmit={onSubmit}>
                <Stack gap="24px">
                  <Body4>
                    Um dein Passwort zurück zu setzen, gib hier dein neues
                    Passwort ein und wiederhole es.
                  </Body4>

                  <Field
                    name="password"
                    label="Neues Passwort"
                    required
                    options={{
                      validate: (value) =>
                        value.length >= 6 ||
                        'Das Passwort ist zu kurz. (Mindestens 6 Zeichen)',
                    }}
                  >
                    <PasswordField
                      placeholder="Passwort"
                      autoComplete="new-password"
                    />
                  </Field>
                  <Field
                    name="password_confirmation"
                    label="Passwort wiederholen"
                    required
                    options={{
                      validate: (value) =>
                        value === password ||
                        'Die Passwörter stimmen nicht überein.',
                    }}
                  >
                    <PasswordField
                      placeholder="Passwort wiederholen"
                      autoComplete="new-password"
                    />
                  </Field>
                  <Btn color="primary" type="submit">
                    Passwort zurücksetzen
                  </Btn>
                </Stack>
              </Form>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
