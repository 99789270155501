import React, { ReactNode } from 'react';

import { SubNav, SubNavItem } from '../common/SubNav';

type Props = {
  children?: ReactNode;
};
export function AddressBookSubNav({ children }: Props) {
  return (
    <SubNav>
      <SubNavItem to="/address" end>
        Meine Kontakte
      </SubNavItem>
      <SubNavItem to="/address/company">Firmenkontakte</SubNavItem>
      {children}
    </SubNav>
  );
}
