import React from 'react';
import styled from 'styled-components';

import { body4 } from '../../components/common/typography/Body';
import { display2, h3, h4 } from '../../components/common/typography/Headings';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { desktopOnly, mobileOnly, MobileViewFirstSide } from './Consts';

const defaultImage = '/images/port.jpg';

const background = '/images/mainImage.jpg';

const Dashboard = '/images/dashboard1.png';

const Contacts = '/images/contactcard.png';

const ManWithIPod = '/images/manwithipad.jpg';

const Landing = styled.div<{
  background?: string;
}>`
  min-height: 100%;
  width: 100%;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  background-attachment: fixed;
  background: linear-gradient(
      0deg,
      rgba(50, 70, 84, 0.8),
      rgba(50, 70, 84, 0.8)
    ),
    url(${(p) => p.background ?? defaultImage});
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DoubleSide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FirstSide = styled.div`
  ${MobileViewFirstSide}
  flex-grow: 1;
  max-width: 50%;
  margin-top: 80px;
`;

export const SecondSide = styled.div`
  flex-grow: 1;
`;
const InnerBox = styled.div`
  height: 100%;
  margin: auto;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  @media screen and (max-width: 959px) {
    width: 70%;
  }

  @media screen and (max-width: 400px) {
    width: unset;
    margin: auto;
    text-align: center;
  }
`;

export const MainTitle = styled.div`
  ${display2}
  color: var(--white);
  @media screen and (max-width: 1350px) {
    ${h3}
  }

  @media screen and (max-width: 960px) {
    ${h3}
  }
`;

export const MediumTitle = styled.div`
  ${display2}
  color: var(--white);
  @media screen and (max-width: 1350px) {
    ${h4}
  }

  @media screen and (max-width: 960px) {
    ${h4}
  }
`;

export const SubTitle = styled.div`
  ${body4}
  color: var(--white);
  margin: 24px auto;
`;

const ContactImage = styled.div`
  background-image: url(${Contacts});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  height: 244px;
  width: 204px;
  border-radius: 12px;
  transform: rotate(-18deg);
  filter: drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.25))
    drop-shadow(-30px 0px 25px rgba(36, 55, 72, 0.25));
  border-radius: 12px;
  transform: rotate(-18.02deg);
  position: absolute;
  left: 90px;
  top: 100px;
  bottom: -25.76%;
  @media screen and (max-width: 960px) {
    transform: inherit;
    left: -150px;
    top: 40px;
  }
`;

const DashboardImage = styled.div`
  background-image: url(${Dashboard});
  background-size: cover;
  width: 800px;
  height: 470px;
  filter: drop-shadow(-30px 0px 25px rgba(36, 55, 72, 0.25))
    drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.25));
  border-radius: 11px;
  transform: rotate(-18deg);
  position: absolute;
  left: 400px;
  top: 80px;
  z-index: 2;
  @media screen and (max-width: 960px) {
    transform: inherit;
    left: 40px;
    top: 40px;
  }
`;

const ManWithIpodImage = styled.div`
  background-image: url(${ManWithIPod});
  background-size: cover;
  width: 240px;
  height: 166px;
  filter: drop-shadow(-30px 0px 25px rgba(36, 55, 72, 0.25))
    drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  transform: rotate(-18deg);
  border: solid white 6px;
  position: absolute;
  left: 130px;
  top: 370px;
  z-index: 2;
`;

const ImagesBox = styled.div`
  ${desktopOnly}
  height: 100%;
  position: relative;
`;

const ImagesBoxMobile = styled.div`
  display: none;
  ${mobileOnly}
  position: relative;
`;

const ClippedDiv = styled.div`
  ${desktopOnly}
  background: var(--white);
  width: 100%;
  height: 600px;
  -webkit-clip-path: polygon(0 52%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 52%, 100% 0, 100% 100%, 0% 100%);
`;

export const Welcome = () => {
  return (
    <Landing background={background}>
      <LandingHeader />
      <DoubleSide>
        <FirstSide>
          <InnerBox>
            <MainTitle>Cargofaces - Das Gesicht der Logistik</MainTitle>
            <SubTitle>
              Wir machen die Logistik zu einer besseren Branche, indem wir
              Logistiker mit modernen Tools in die Lage versetzen, besser zu
              kommunizieren, zusammenzuarbeiten und gemeinsam das Datenpotenzial
              in der Lieferkette zu nutzen.
            </SubTitle>
            <SubTitle>
              <b>Keine Macht den Daten-Kraken</b>
              <p>
                Cargofaces ist gebaut, um das volle Potential digitaler
                Genossenschaften auszunutzen und demokratisch einen Wandel
                herbeizuführen.
              </p>
            </SubTitle>
            <SubTitle>
              Sei dabei wenn es losgeht! Die Plattform ist bereits nutzbar,
              aktuell arbeiten wir an der Gründung der Genossenschaft.
            </SubTitle>
          </InnerBox>
        </FirstSide>
        <SecondSide>
          <ImagesBox>
            <ContactImage />
            <DashboardImage />
            <ManWithIpodImage />
          </ImagesBox>
          <ImagesBoxMobile>
            <ContactImage />
            <DashboardImage />
          </ImagesBoxMobile>
        </SecondSide>
      </DoubleSide>
      <ClippedDiv />
    </Landing>
  );
};
