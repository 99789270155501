import React, { useEffect } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';

import { AppScreen } from '../../components/common/AppScreen';
import { Btn } from '../../components/common/Btn';
import { ContactsIcon, UserInCircleIcon } from '../../components/common/Icons';
import { PageTitle } from '../../components/common/PageTitle';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';
import { CreateCapacityDialog } from '../../components/offer/CreateCapacityDialog';
import { CreateCargoDialog } from '../../components/offer/CreateCargoDialog';
import { OnboardingModal } from '../../components/onboarding';
import { Timeline } from '../../components/timeline/Timeline';
import { useUser, useUserData } from '../../components/UserState';
import { useLocalStorage } from '../../lib/useLocalStorage';

export function Dashboard() {
  const [onboardingShown, setOnboardingShown] = useLocalStorage<boolean>(
    'onboardingShown',
    false
  );
  const navigate = useNavigate();
  const user = useUserData();
  useEffect(() => {
    if (!onboardingShown && user && user.created_at) {
      const DAY = 864e5; // one day in milliseconds
      const accountIsYoungerThanTwoWeeks =
        (Date.now() - new Date(user.created_at).getTime()) / DAY < 14;
      if (accountIsYoungerThanTwoWeeks) {
        setOnboardingShown(true);
        navigate('./onboarding');
      }
    }
  }, [user, onboardingShown]);
  return (
    <AppScreen title="Dashboard">
      <Routes>
        <Route index element={<NavAndTimeline />} />
        <Route
          path="onboarding"
          element={
            <div>
              <OnboardingModal close={() => navigate('/dashboard')} />
            </div>
          }
        />
      </Routes>
    </AppScreen>
  );
}

function NavAndTimeline() {
  const { data, isDispatcher } = useUser();
  return (
    <Stack>
      <PageTitle
        title={`Hi ${data.first_name},`}
        subTitle="hier ist deine Timeline."
      />
      <Row>
        {isDispatcher && <CreateCapacityDialog />}
        {isDispatcher && <CreateCargoDialog />}
        <Btn size="small" appearance="outline" as={Link} to="/profile">
          <UserInCircleIcon />
          Mein Profil
        </Btn>
        <Btn size="small" appearance="outline" as={Link} to="/connections">
          <ContactsIcon />
          Meine Kontakte
        </Btn>
      </Row>
      <Timeline />
    </Stack>
  );
}
