import { Toast, ToastProps } from '@radix-ui/react-toast';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppHeader } from '../../components/common/AppHeader';
import { Btn } from '../../components/common/Btn';
import { Footer } from '../../components/common/Footer';
import { H4 } from '../../components/common/typography/Headings';
import { SubtitleSmall } from '../../components/common/typography/Special';
import { Row } from '../../components/layout/Row';
import { MatchCard } from '../../components/matching/cards/MatchCard';
import { MatchDetailCard } from '../../components/matching/cards/MatchDetailCard';
import { LoadingMatches } from '../../components/matching/LoadingMatches';
import { MatchesMap } from '../../components/matching/map/MatchesMap';
import { SendEmailDialog } from '../../components/offer/SendEmailDialog';
import { useApi } from '../../components/UserState';
import {
  getOfferFromMatchResponse,
  int,
  matchDetails,
} from '../../lib/convert';
import { MatchDetails } from '../../lib/types/ui';
import { useApiCall } from '../../lib/useApiCall';

const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
`;

const Overflow = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
  }
`;

const Detail = styled.div`
  padding-bottom: 16px;
`;

const ResultList = styled.ul`
  list-style: none;
  & > * {
    margin-bottom: 16px;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Matches = styled.div`
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
  flex: 454px 1 0;
  gap: 16px;
`;

export const MatchResults = () => {
  const params = useParams();
  const id = int(params.id);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const type = params.type!;
  const api = useApi();
  const { data, invalidate, setData } = useApiCall(
    type === 'cargo' ? 'cargoMatches' : 'capacityMatches',
    id
  );
  const offer = data && getOfferFromMatchResponse(data);

  const changeVisibilty = useCallback(
    async (to: string) => {
      if (type === 'capacity') {
        await api.changeCapacityVisibility(id, to);
      } else {
        await api.changeCargoVisibility(id, to);
      }
      invalidate();
    },
    [id, type]
  );

  const [selected, setSelected] = useState<MatchDetails>();
  const [details, setDetails] = useState<MatchDetails>();
  const [sendOffers, setSendOffers] = useState<number[]>();
  const [toast, setToast] = useState<ToastProps>();
  const matches =
    data?.potential_and_interested_matches.matches.map(matchDetails) ?? [];

  return (
    <Main>
      <AppHeader />
      {!(data && offer) ? (
        <LoadingMatches />
      ) : (
        <Layout>
          <Matches>
            <Row justify="spread">
              <H4 color="var(--primary500)">Matches</H4>
              {offer.visible !== 'everyone' ? (
                <Btn
                  color="tertiary"
                  onClick={async () => {
                    setData(undefined);
                    await changeVisibilty('everyone');
                  }}
                >
                  Angebot veröffentlichen
                </Btn>
              ) : (
                <Btn
                  onClick={() => {
                    setSendOffers([offer.id]);
                  }}
                >
                  Angebot per E-Mail versenden
                </Btn>
              )}
            </Row>
            <SubtitleSmall color="var(--grey500)">
              {matches.length || 'Keine'}{' '}
              {matches.length === 1 ? 'Ergebniss' : 'Ergebnisse'} gefunden.
              {offer.visible !== 'everyone' &&
                'Veröffentliche das Angebot, um es für alle Nutzer der Plattform sichtbar zu machen.'}
            </SubtitleSmall>
            <Overflow>
              {details ? (
                <Detail>
                  <MatchDetailCard
                    details={details}
                    onBack={() => setDetails(undefined)}
                    invalidate={invalidate}
                  />
                </Detail>
              ) : (
                <ResultList role="list">
                  {matches.map((details) => {
                    const { matchId } = details;
                    return (
                      <MatchCard
                        key={matchId}
                        details={details}
                        selected={selected?.matchId === matchId}
                        onClick={() => setSelected(details)}
                        onSelect={() => setDetails(details)}
                      />
                    );
                  })}
                </ResultList>
              )}
            </Overflow>
          </Matches>
          <MatchesMap
            offer={offer}
            matches={matches}
            selected={selected}
            setSelected={setSelected}
            loadAgain={() => invalidate()}
            type={type}
          />
        </Layout>
      )}
      {sendOffers && (
        <SendEmailDialog
          offersAmount={sendOffers.length}
          onClick={async (includeCompanyContacts = false) => {
            const { error } =
              type === 'capacity'
                ? await api.informContactsAboutCapacity(
                    sendOffers,
                    includeCompanyContacts
                  )
                : await api.informContactsAboutCargo(
                    sendOffers,
                    includeCompanyContacts
                  );
            setToast({
              title: error ? 'Fehler' : 'Versand erfolgreich',
              children: error ?? 'Deine Kontakte wurden benachrichtigt.',
            });
          }}
          onClose={() => setSendOffers(undefined)}
        />
      )}

      {toast && <Toast {...toast} />}
      <Footer />
    </Main>
  );
};
