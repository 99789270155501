import React, { createContext, ReactNode, useContext } from 'react';

import { Notifications } from '../lib/types/user';
import { usePolling } from '../lib/usePolling';
import { useApi } from './UserState';

const EMPTY = {
  company_requests: 0,
  friend_requests: 0,
  messages: 0,
  matches: 0,
  async refresh() {},
};

type NotificationsState = Notifications & {
  refresh: () => Promise<void>;
};

const NotificationsContext = createContext<NotificationsState>(EMPTY);

type Props = {
  children: ReactNode;
};
export function NotificationsProvider({ children }: Props) {
  const api = useApi();
  const { data, pollNow } = usePolling<Notifications | undefined>(
    async (prev) => {
      const notifications = await api.getNotifications();
      if (JSON.stringify(notifications) === JSON.stringify(prev)) return prev;
      return notifications;
    },
    2000
  );
  return (
    <NotificationsContext.Provider
      value={data ? { ...data, refresh: pollNow } : EMPTY}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => useContext(NotificationsContext);
