import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Center } from '../layout/Center';

export const LoadingLayer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000;
  display: grid;
  place-items: center;
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

const Svg = styled.svg`
  width: 80px;
  height: 80px;
  margin: auto;
  animation: ${rotate} 2s linear infinite;
  transform-origin: center center;
  & > circle {
    stroke-width: 3;
    fill: none;
    stroke: var(--neutral200);
  }
  & > circle:last-child {
    stroke: var(--primary500);
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    stroke-linecap: round;
    animation: ${dash} 3s ease-in-out infinite;
  }
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export function LoadingCircle() {
  return (
    <Root>
      <Center>
        <Svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" />
          <circle cx="50" cy="50" r="20" />
        </Svg>
      </Center>
    </Root>
  );
}
