import React from 'react';

import { AppScreen } from '../../components/common/AppScreen';

export function EditInsurance() {
  return (
    <AppScreen title="EditInsurance">
      <h1>EditInsurance</h1>
    </AppScreen>
  );
}
