import styled from 'styled-components';

import { h2, h4 } from '../common/typography/Headings';
import dots from './dots.svg';

const defaultImage = '/images/port.jpg';
export const Hero = styled.div<{
  background?: string;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      0deg,
      rgba(50, 70, 84, 0.8),
      rgba(50, 70, 84, 0.8)
    ),
    url(${(p) => p.background ?? defaultImage});
  background-size: cover;
`;

export const HeroContent = styled.div<{
  background?: string;
}>`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 70px 24px 32px;
`;

export const HeroColumn = styled.div`
  flex-grow: 1;
  max-width: 395px;
  margin-bottom: 32px;
`;

export const HeroText = styled.div`
  ${h2}
  @media screen and (max-width: 1024px) {
    ${h4}
  }
  color: var(--white);
  position: relative;
  ::before {
    content: '';
    background-image: url(${dots});
    width: 80px;
    height: 80px;
    background-size: 12px 12px;
    position: absolute;
    top: -30px;
    left: -40px;
  }
`;
