import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Btn } from '../../components/common/Btn';
import { Body4 } from '../../components/common/typography/Body';
import { Field } from '../../components/form/Field';
import { Form } from '../../components/form/Form';
import { TextField } from '../../components/form/TextField';
import { ActionBox } from '../../components/landing/ActionBox';
import { Hero, HeroColumn, HeroContent } from '../../components/landing/Hero';
import { LandingHeader } from '../../components/landing/LandingHeader';
import { Page } from '../../components/landing/Page';
import { Stack } from '../../components/layout/Stack';
import { recoverPassword } from '../../lib/publicApi';

type Inputs = {
  email: string;
};
export function ForgotPassword() {
  const navigate = useNavigate();
  const form = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const res = await recoverPassword({
      ...data,
      redirect_url: `${window.origin}/reset-password`,
    });
    if (res.success === false) {
      const [message] = res.errors;
      form.setError('email', { message });
    } else {
      navigate('/registerdone'); // TODO add own page
    }
  };

  return (
    <Page>
      <Hero>
        <LandingHeader />
        <HeroContent>
          <HeroColumn>
            <ActionBox title="Passwort vergessen">
              <Form {...form} onSubmit={onSubmit}>
                <Stack gap="24px">
                  <Body4>
                    Wenn du dein Passwort vergessen hast, gib hier deine E-Mail
                    Adresse ein. Wir schicken dir eine Mail mit einem Link, um
                    dein Passwort zurückzusetzen.
                  </Body4>
                  <Field name="email" label="E-Mail">
                    <TextField
                      placeholder="Deine E-Mail"
                      autoComplete="email"
                      type="email"
                    />
                  </Field>
                  <Btn color="primary" type="submit">
                    E-Mail senden
                  </Btn>
                </Stack>
              </Form>
            </ActionBox>
          </HeroColumn>
        </HeroContent>
      </Hero>
    </Page>
  );
}
