import React from 'react';

import { Attribute } from '../../lib/types/offers';
import { Chip } from '../common/Chip';

const labels: Record<string, string> = {
  'Open-Top': 'Open Top',
  'Flat-Rack': 'Flat Rack',
  'High-Cube': 'High Cube',
  'Rear-Flush': 'Rückwandbündig',
};

type Props = {
  values: Attribute[];
};
export function Attributes({ values }: Props) {
  return (
    <>
      {values
        .filter((v) => !!labels[v.name])
        .map((v) => {
          return <Chip key={v.name}>{labels[v.name]}</Chip>;
        })}
    </>
  );
}
