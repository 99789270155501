import React from 'react';
import styled from 'styled-components';

import { MatchDetails } from '../../lib/types/ui';
import { Waypoint } from './Waypoint';

export const Waypoints = styled.div`
  display: flex;
  justify-content: space-between;
`;

export type Props = {
  details: MatchDetails;
};

export function MatchChatWaypoints({ details }: Props) {
  const {
    zip_location,
    country_location,
    place_location,
    zip_load_unload,
    country_load_unload,
    place_load_unload,
    zip_destination,
    country_destination,
    place_destination,
  } = details;
  return (
    <Waypoints>
      {zip_location && (
        <>
          <Waypoint
            zip={zip_location}
            place={place_location}
            country={country_location}
          />

          <div>⟶</div>
        </>
      )}
      {zip_load_unload && country_load_unload && place_load_unload && (
        <>
          <Waypoint
            zip={zip_load_unload}
            place={place_load_unload}
            country={country_load_unload}
          />
          <div>⟶</div>
        </>
      )}
      {zip_destination && country_destination && place_destination && (
        <Waypoint
          zip={zip_destination}
          place={place_destination}
          country={country_destination}
        />
      )}
    </Waypoints>
  );
}
