import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import * as Dialog from '../../common/Dialog';
import { useApi, useUpdateUser, useUserData } from '../../UserState';
import { BasicInfo, BasicInfoPrpos } from './BasicInfo';
import { Insurance, InsuranceProps } from './Insurance';
import {
  LocationAndDescription,
  LocationAndDescriptionProps,
} from './LocationAndDescription';
import { TermsAndMedia, TermsAndMediaProps } from './TermsAndMedia';

const Body = styled(Dialog.Body)`
  padding: 0px 40px 20px;
`;

type Props = {
  children?: ReactElement;
  onClose?: () => void;
};

type Values = {
  basicInfo: BasicInfoPrpos;
  locationAndDescription: LocationAndDescriptionProps;
  insurance: InsuranceProps;
  termsAndMedia: TermsAndMediaProps;
};

const title = (step: number) => {
  if (step === 2 || step === 4) {
    return 'Wichtige Details';
  }
  if (step === 3) {
    return 'Versicherungsdetails';
  }
  return '';
};

export const CreateCompanyDialog = ({ children, onClose }: Props) => {
  const [values, setValues] = useState<Partial<Values>>({});
  const [step, setStep] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const api = useApi();

  const user = useUserData();
  const setUser = useUpdateUser();

  //const [optionalUser, setUser] = useUserState();
  //const data: UserData = optionalUser!.data;

  async function callAddCompany({
    basicInfo,
    locationAndDescription,
    termsAndMedia,
    insurance,
  }: Values) {
    const { logo, name, email, phone } = basicInfo;
    const {
      city,
      country,
      description,
      employees,
      founding_date,
      information,
      street,
      zip_code,
    } = locationAndDescription;
    const { validInsurance } = insurance;

    const {
      adsp,
      agb_link,
      agb_file,
      details,
      xing_link,
      linkedin_link,
      website_link,
    } = termsAndMedia;

    const companyData = {
      paying: true,
      company_address: {
        country,
        city,
        street,
        zip_code,
      },
      description,
      employees,
      founding_date,
      information,
      logo: logo?.[0],
      xing_link,
      linkedin_link,
      website_link,
      agb: details,
      adsp,
      agb_link,
      agb_file: agb_file?.[0],
      name,
      email,
      phone,
    };
    const { company } = await api.createCompany(companyData);
    await api.addDispatcher(company.id, user.id);

    setUser({
      ...user,
      selected_company_id: company.id,
      selected_company_name: company.name ?? null,
    });

    if (validInsurance === 'later') {
      await api.createInsurance({
        company_id: company.id,
        submit_later: true,
      });
    }
    if (validInsurance === 'yes') {
      const {
        insurance_company_name,
        insurance_number,
        expiration_date,
        insurance_name,
        file,
      } = insurance;
      await api.createInsurance({
        company_id: company.id,
        insurance_company_name,
        insurance_name,
        insurance_number,
        expiration_date,
        file: file?.[0],
      });
    }
    setOpen(false);
    onClose?.();
  }

  return (
    <Dialog.Root
      open={open}
      trigger={children}
      onOpenChange={() => {
        if (open) {
          setOpen(false);
          onClose?.();
        } else setOpen(true);
      }}
    >
      <Dialog.Header title={title(step)} />
      <Body>
        {step === 1 && (
          <BasicInfo
            values={values.basicInfo}
            next={(basicInfo) => {
              setValues({ ...values, basicInfo });
              setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <LocationAndDescription
            values={values.locationAndDescription}
            next={(locationAndDescription) => {
              setValues({ ...values, locationAndDescription });
              setStep(3);
            }}
            prev={(locationAndDescription) => {
              setValues({ ...values, locationAndDescription });
              setStep(1);
            }}
          />
        )}
        {step === 3 && (
          <Insurance
            values={values.insurance}
            next={(insurance) => {
              setValues({ ...values, insurance });
              setStep(4);
            }}
            prev={(insurance) => {
              setValues({ ...values, insurance });
              setStep(2);
            }}
          />
        )}
        {step === 4 && (
          <TermsAndMedia
            values={values.termsAndMedia}
            next={async (termsAndMedia) => {
              try {
                await callAddCompany({ ...values, termsAndMedia } as Values);
              } catch (err) {
                console.log(err);
              }
            }}
            prev={(termsAndMedia) => {
              setValues({ ...values, termsAndMedia });
              setStep(3);
            }}
          />
        )}
      </Body>
    </Dialog.Root>
  );
};
