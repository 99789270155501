import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppRoutes } from './pages/app';
import { MocKUp } from './pages/mockup/index';
import { Blocklist } from './pages/public/Blocklist';
import { ConfirmedAccount } from './pages/public/ConfirmedAccount';
import { ForgotPassword } from './pages/public/ForgotPassword';
import { Imprint } from './pages/public/Imprint';
import { Login } from './pages/public/Login';
import { RegisterDone } from './pages/public/RegisterDone';
import { ResetPassword } from './pages/public/ResetPassword';
import { Signup } from './pages/public/Signup';

export function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/email-verified" element={<ConfirmedAccount />} />
      <Route path="/registerdone" element={<RegisterDone />} />
      <Route path="/imprint" element={<Imprint />} />
      <Route path="/blocklist" element={<Blocklist />} />
      <Route path="/public" element={<MocKUp />} />
      <Route path="*" element={<AppRoutes />} />
    </Routes>
  );
}
