import React from 'react';
import styled from 'styled-components';

import { CargofacesLogo } from '../../components/common/LandingHeader';
import { Footer } from '../../components/landing/Footer';
import { HeaderLinks } from '../../components/landing/LandingHeader';
import { Row } from '../../components/layout/Row';
import { Stack } from '../../components/layout/Stack';

const Wrapper = styled(Stack)`
  background-color: #324654;
  width: 100%;
`;

const Box = styled(Stack)`
  width: 90%;
  margin: auto;
  padding: 24px;
`;

export const DoubleFooter = () => {
  return (
    <Wrapper>
      <Box>
        <Row>
          <CargofacesLogo />
          <HeaderLinks />
        </Row>
        <Footer />
      </Box>
    </Wrapper>
  );
};
