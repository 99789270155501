import styled from 'styled-components';

import { body6 } from './typography/Body';

export const Chip = styled.div`
  ${body6}
  display: inline-flex;
  gap: 8px;
  align-items: center;
  background: var(--tertiary100);
  color: var(--tertiary500);
  border-radius: 8px;
  padding: 4px 8px;
  min-height: 16px;
  white-space: nowrap;
  [aria-current='true'] & {
    background-color: var(--white);
  }
  svg {
    width: 16px;
    height: 16px;
    margin-left: -4px;
    margin-right: -4px;
  }
`;
