import { useEffect } from 'react';

export function useKeyboard(
  controls: Record<string, (evt?: KeyboardEvent) => void>
): void {
  useEffect(() => {
    const onKey = (evt: KeyboardEvent) => {
      if (typeof controls[evt.key] === 'function') {
        controls[evt.key](evt);
      }
    };
    if (controls) {
      window.addEventListener('keydown', onKey);
    }
    return () => window.removeEventListener('keydown', onKey);
  }, [controls]);
}
