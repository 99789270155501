import styled from 'styled-components';

//TODO Consider using regular Row
export const SpreadButtonRow = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;

  gap: 24px;
  justify-content: space-between;

  > * {
    flex: 0 1 auto;
  }

  @media (max-width: 720px) {
    > * {
      flex: 1 0 auto;
      justify-self: center;
    }
  }
`;
