import React, { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { MessageArea } from '../../components/chat/MessageArea';
import { AppScreen } from '../../components/common/AppScreen';
import { LoadingCircle } from '../../components/common/LoadingCircle';
import { PageTitle } from '../../components/common/PageTitle';
import { Stack } from '../../components/layout/Stack';
import { useApi } from '../../components/UserState';
import { int } from '../../lib/convert';
import { useApiResult } from '../../lib/useApiCall';

export function CreateChat() {
  const api = useApi();
  const params = useParams();
  const navigate = useNavigate();
  const userId = int(params.id);

  const contact = useApiResult('getUserProfile', userId);
  const chats = useApiResult('getChats');

  const chat = useMemo(
    () =>
      chats?.find(
        (chat) => chat.contact_id === userId && chat.match_id === null
      ),
    [chats, userId]
  );

  if (chat) {
    return <Navigate to={`/chat/${chat.chat_id}`} replace />;
  } else if (contact) {
    if (contact.connection_to_user !== 'accepted') {
      return <Navigate to={'/connections'} replace />;
    }
    const sendMessage = async (message: string) => {
      const { chat } = await api.createChat(userId, message);
      navigate(`/chat/${chat.id}`);
    };
    return (
      <AppScreen title="Neuer Chat">
        <Stack style={{ flexGrow: 1 }}>
          <PageTitle
            title="Neuer Chat"
            subTitle={`mit ${contact.user.first_name} ${contact.user.last_name}`}
          />
          <MessageArea onSend={sendMessage} />
        </Stack>
      </AppScreen>
    );
  }
  return <LoadingCircle />;
}
