import Lottie from 'lottie-react';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import { Btn } from '../common/Btn';
import { CardButton } from '../common/CardButton';
import * as Dialog from '../common/Dialog';
import { Row } from '../layout/Row';
import addIcon from '../offer/icons/SpecialAddIcon.svg';
import Truck from '../offer/icons/truck.json';
import WareHouse from '../offer/icons/warehouse.json';
import { CreateCapacityDialog } from './CreateCapacityDialog';
import { CreateCargoDialog } from './CreateCargoDialog';

const Animation = styled(Lottie).attrs({ loop: true })`
  width: 80px;
  height: 80px;
`;

type Props = {
  children?: ReactElement;
};
export function CreateOfferDialog({ children }: Props) {
  const [open, setOpen] = useState(false);
  const trigger = children ?? <Btn size="small">Neues Angebot</Btn>;
  return (
    <Dialog.Root trigger={trigger} open={open} onOpenChange={setOpen}>
      <Dialog.Header title="Leerchassis oder Überhang" icon={addIcon} />
      <Dialog.Body>
        <Row
          justify="center"
          align="stretch"
          gap="48px"
          style={{ margin: '0 40px' }}
        >
          <CreateCapacityDialog onCreate={() => setOpen(false)}>
            <CardButton
              title="Leerchassis"
              icon={<Animation animationData={Truck} />}
            >
              Ich habe Platz, um einen Container auf ein Fahrzeug zu laden.
            </CardButton>
          </CreateCapacityDialog>
          <CreateCargoDialog onCreate={() => setOpen(false)}>
            <CardButton
              title="Überhang"
              icon={<Animation animationData={WareHouse} />}
            >
              Ich habe überschüssige Fracht, die transportiert werden muss.
            </CardButton>
          </CreateCargoDialog>
        </Row>
      </Dialog.Body>
    </Dialog.Root>
  );
}
