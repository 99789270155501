import * as Sentry from '@sentry/react';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Api } from '../lib/Api';
import { AuthHeaders, UserData, validateToken } from '../lib/publicApi';
import { CompanyProfileResult } from '../lib/types/company';

export interface User {
  api: Api;
  data: UserData;
}
export interface UserWithCompany extends User {
  companyProfile: CompanyProfileResult | null;
  isAdmin: boolean;
  isDispatcher: boolean;
  hasPendingEmployeeRequests: boolean;
}

type OptionalUser = UserWithCompany | null | undefined;

type SetUser = (user: User | null) => Promise<void>;

export type UserState = Readonly<[user: OptionalUser, setUser: SetUser]>;

const UserStateContext = createContext<UserState | null>(null);

type Props = {
  children: ReactNode;
};
export function UserStateProvider({ children }: Props) {
  const state = useRestoreUser();
  return (
    <UserStateContext.Provider value={state}>
      {children}
    </UserStateContext.Provider>
  );
}

function useOptionalUserState() {
  const [user, setUser] = useState<OptionalUser>();

  // Wrap setUser() in order to fetch the company profile whenever the user changes:
  const setUserWrapper = useCallback(async (user: User | null) => {
    if (user) {
      const { api, data } = user;
      const companyProfile = data.selected_company_id
        ? await api.getCompanyProfile(data.selected_company_id)
        : null;

      const isDispatcher =
        companyProfile?.dispatchers?.some(
          (dispatcher: UserData) => dispatcher.id === data.id
        ) ?? false;

      const isAdmin =
        companyProfile?.admins?.some(
          (admin: UserData) => admin.id === data.id
        ) ?? false;

      const requests = await api.getEmployeeRequests();
      const hasPendingEmployeeRequests =
        requests.sent_by_company.length + requests.sent_by_user.length > 0;

      // Let Sentry know about the logged in user to help finding bugs:
      const { id, first_name, last_name } = data;
      Sentry.setUser({
        id: id.toString(),
        username: `${first_name} ${last_name}`,
      });
      setUser({
        api,
        data,
        companyProfile,
        isAdmin,
        isDispatcher,
        hasPendingEmployeeRequests,
      });
    } else {
      setUser(null);
    }
  }, []);
  const wrappedState = useMemo(() => [user, setUserWrapper] as const, [user]);
  return wrappedState;
}

function useRestoreUser() {
  const state = useOptionalUserState();
  useEffect(() => {
    const [, setUser] = state;
    // Check session storage in case of a page reload...
    let savedAuth = sessionStorage.getItem('auth');
    if (savedAuth) {
      sessionStorage.removeItem('auth');
    } else {
      savedAuth = localStorage.getItem('auth');
    }
    if (savedAuth) {
      const restoredAuth = JSON.parse(savedAuth);
      validateToken(restoredAuth).then((restored) => {
        if (restored) {
          const { data, auth } = restored;
          const invalidate = () => setUser(null);
          const api = new Api({ ...restoredAuth, ...auth }, invalidate);
          setUser({ api, data });
        } else {
          setUser(null);
        }
      });
    } else {
      setUser(null);
    }
  }, []);
  return state;
}

export function useUserState() {
  const state = useContext(UserStateContext);
  if (!state) throw new Error('Missing UserContext');
  return state;
}
// uses setUser to refetch company information
export function useRefetchUser() {
  const [user, setUser] = useUserState();
  return useCallback(() => {
    if (!user) throw new Error('No user');
    setUser({ ...user });
  }, [user]);
}

export function useUpdateUser() {
  const [user, setUser] = useUserState();
  if (!user) throw new Error('No user');
  return (newData: UserData) => {
    const { api } = user;
    setUser({ api, data: newData });
  };
}

export function useUser() {
  const [user] = useUserState();
  if (!user) throw new Error('No user');
  return user;
}

export function useUserData() {
  const [user] = useUserState();
  if (!user) throw new Error('No user');
  return user.data;
}

export function useOptionalUserData() {
  const [user] = useUserState();
  if (user === null) return null;
  if (user === undefined) return undefined;
  return user.data;
}

export function useApi() {
  const [user] = useUserState();
  if (!user) throw new Error('User not authenticated');
  return user.api;
}

export function useAuth() {
  const [, setUser] = useUserState();
  return async (
    auth: AuthHeaders,
    getUserData: (api: Api) => UserData | Promise<UserData | void>,
    rememberMe?: boolean
  ) => {
    const invalidate = () => setUser(null);
    const api = new Api(auth, invalidate, rememberMe);
    const data = await getUserData(api);
    if (data) setUser({ api, data });
    return data;
  };
}
