import styled from 'styled-components';

import { body6 } from './typography/Body';

export const Badge = styled.div`
  ${body6}
  display: inline-block;
  line-height: 18px;
  color: var(--white);
  background: var(--success500);
  border-radius: 16px;
  padding: 0 8px;
`;

export const TableBadge = styled.div`
  ${body6}
  color: var(--white);
  background: var(--secondary500);
  position: absolute;
  padding: 0 8px;
  line-height: 24px;
  border-radius: 16px;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;
