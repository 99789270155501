import React from 'react';

import { ConnectedContact } from '../../lib/types/connections';
import { TableTitle } from '../common/DataTable';
import { CancelIcon } from '../common/Icons';
import { StatusButton } from '../common/StatusButton';
import { useApi } from '../UserState';
import { ConnectionsTable } from './ConnectionsTable';

type Props = {
  items: ConnectedContact[];
  onAction: () => void;
};
export function SentContactRequestsTable({ items, onAction }: Props) {
  const api = useApi();
  return (
    <>
      <TableTitle>Gesendet</TableTitle>
      <ConnectionsTable
        items={items}
        columns={['image', 'name', 'company', 'location', 'dateSent']}
        renderActions={(item) => (
          <StatusButton
            variant="negative"
            onClick={async () => {
              await api.declineConnection(item.connection.id);
              onAction();
            }}
          >
            <CancelIcon />
            Abbrechen
          </StatusButton>
        )}
      />
    </>
  );
}
