import React from 'react';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';

import { useApiCall } from '../../lib/useApiCall';
import { Stack } from '../layout/Stack';
import { useApi } from '../UserState';
import { MessageArea } from './MessageArea';
import { Post } from './Post';

const Posts = styled(Masonry as any)`
  display: flex;
  margin-left: -24px;
  width: auto;
  & > * {
    padding-left: 24px; /* gutter size */
    background-clip: padding-box;
    & > * {
      margin-bottom: 24px;
    }
  }
`;
export function Timeline() {
  const { data, invalidate } = useApiCall('getTimelinePosts');
  const api = useApi();

  const addPost = async (content: string, image?: File) => {
    await api.addTimelinePost(content, image);
    invalidate();
  };

  const deletePost = async (id: number) => {
    await api.deleteTimelinePost(id);
    invalidate();
  };

  const editPost = (id: number, input: string) => {
    api.editTimelinePost(id, input);
    invalidate();
  };

  return (
    <Stack gap="36px">
      <MessageArea onSend={addPost} />
      <Posts
        breakpointCols={{
          default: 4,
          1100: 2,
          780: 1,
        }}
      >
        {data?.map((post) => (
          <Post
            key={post.timeline_post.id}
            post={post}
            onDelete={() => deletePost(post.timeline_post.id)}
            onEdit={(input) => editPost(post.timeline_post.id, input)}
          />
        ))}
      </Posts>
    </Stack>
  );
}
