import * as React from 'react';
import { forwardRef, useState } from 'react';
import styled from 'styled-components';

import { EyeClosedIcon, EyeOpenIcon } from '../common/Icons';
import { Body } from '../common/typography';

const Root = styled.div`
  box-sizing: border-box;
  border-radius: 9.5px;
  border: solid 1px var(--grey300);
  background-color: var(--input-background);
  display: flex;

  &:focus-within {
    box-shadow: 0px 0px 0px 4px rgba(29, 171, 242, 0.2);
  }
`;

const Input = styled.input`
  ${Body.body4}
  border: none;
  border-radius: 9.5px 0 0 9.5px;
  background: transparent;
  color: var(--grey800);
  padding: 10px 12px;
  margin: 0;
  flex-grow: 1;
  width: initial;
  ::placeholder {
    color: var(--grey500);
  }
  :focus {
    ::placeholder {
      color: var(--grey300);
    }
    position: relative;
  }
`;

const Toggle = styled.button`
  background-color: var(--white);
  border: none;
  border-left: solid 1px var(--grey300);
  padding: 0 12px;
  border-radius: 0 9.5px 9.5px 0;
  cursor: pointer;
`;

type Props = JSX.IntrinsicElements['input'];

export const PasswordField = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <Root>
        <Input {...props} ref={ref} type={showPassword ? 'text' : 'password'} />
        <Toggle type="button" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
        </Toggle>
      </Root>
    );
  }
);
