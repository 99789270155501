import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  padding: 24px;
  padding-top: 12px;
  box-sizing: border-box;
  color: var(--neutral500);
  background-color: var(--white);
  --input-background: var(--neutral100);
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 20px;
  letter-spacing: 0.15px;
  color: var(--primary500);
  padding-bottom: 12px;
  border-bottom: 1px solid var(--neutral300);
  margin-bottom: 24px;
`;

type Props = {
  title: string;
  children: ReactNode;
};
export function ActionBox({ title, children }: Props) {
  return (
    <Root>
      <Title>{title}</Title>
      {children}
    </Root>
  );
}
