import React from 'react';
import styled from 'styled-components';

import { h5, h7 } from '../../components/common/typography/Headings';
import { Stack } from '../../components/layout/Stack';
import { Paragrapgh, SmallerTitle } from './Consts';
import Carbon from './images/carbon.svg';
import Complex from './images/complexSystem.svg';
import Leaf from './images/leaf.svg';
import Simple from './images/simpleSystem.svg';
import TruckIcon from './images/truck.svg';
import WasteTime from './images/wasteTime.svg';
import { DoubleSide } from './Welcome';

const Territory = '/images/background3.jpg';

const Background = styled.div<{
  background?: string;
}>`
  background-attachment: fixed;
  background: linear-gradient(
      0deg,
      rgba(50, 70, 84, 0.8),
      rgba(50, 70, 84, 0.8)
    ),
    url(${Territory});
  padding-bottom: 30px;
  background-size: cover;
`;

const CardCon = styled.div`
  width: 50%;
  margin: 50px auto;
  @media screen and (max-width: 959px) {
    width: auto;
  }
`;

const TransparentCard = styled.div`
  background: rgba(20, 29, 40, 0.6);
  padding: 20px 32px;
  color: var(--white);
  max-width: 70%;
  margin: auto;
  height: 100%;
  border-radius: 10px;
`;

const BigTitle = styled(SmallerTitle)`
  @media screen and (max-width: 960px) {
    ${h5}
  }
`;

const SmallTitle = styled.div`
  ${h7}
  text-transform: uppercase;
  color: var(--white);
  margin-bottom: 16px;
`;

const Text20 = styled.div`
  ${h5}
  color: var(--white);
  margin-bottom: 8px;
`;

const Explain = styled.div`
  display: flex;
  margin: 24px auto;
`;

const Icon = styled.img`
  display: flex;
  margin-right: 20px;
  width: 48px;
  height: 48px;
`;

const EditParagraph = styled(Paragrapgh)`
  margin-top: 8px;
`;

const Box = styled.div`
  height: 200px;
  margin-bottom: 24px auto;

  @media screen and (max-width: 1200px) {
    margin-bottom: 44px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 24px;
    height: 186px;
  }
`;

const Card = ({ title, text, icon }: any) => {
  return (
    <Explain>
      <Icon src={icon} />
      <Stack>
        <Text20>{title}</Text20>
        <EditParagraph>{text}</EditParagraph>
      </Stack>
    </Explain>
  );
};

export const Solution = () => {
  return (
    <Background id="solution">
      <DoubleSide>
        <CardCon>
          <TransparentCard>
            <Box>
              <SmallTitle>Aktuelles System</SmallTitle>
              <BigTitle>
                Machen wir uns nichts vor - die Logistik von heute ist langsam
                und zu kompliziert.
              </BigTitle>
            </Box>
            <Card
              title="Ineffiziente Zeitnutzung"
              text="Ein Container-Disponent liest und bearbeitet bis zu 1.500 Angebote in
          Form von E-Mails am Tag."
              icon={WasteTime}
            />
            <Card
              title="Komplexes System"
              text="Durch das stetige Wachstum an Dienstleistern 
            und das Wiederverkaufen von Fracht wird die Supply Chain intransparent und unübersichtlich."
              icon={Complex}
            />
            <Card
              title="CO2-Belastung"
              text="Fehlende Kommunikation und Zeitdruck führen zu Leerfahrten und erhöhen den
             Schadstoffausstoß auf den Autobahnen."
              icon={Carbon}
            />
          </TransparentCard>
        </CardCon>
        <CardCon>
          <TransparentCard>
            <Box>
              <SmallTitle>Cargofaces</SmallTitle>
              <BigTitle>
                Cargofaces verpasst der Logistik ein Upgrade und vereinfacht
                deine Arbeit.
              </BigTitle>
            </Box>
            <Card
              title="Schnelles Match-System"
              text="Der intelligente ContainerMatch-Algorithmus macht E-Mail-Angebote überflüssig und automatisiert den Prozess."
              icon={TruckIcon}
            />
            <Card
              title="Einfache Konnektivität"
              text="Durch smartes Vernetzen und transparente Prozesse reduzieren wir die Komplexität in der Supply Chain drastisch."
              icon={Simple}
            />
            <Card
              title="Gut für das Klima"
              text="Kapazitäten und Überhänge werden smart gematcht und verhindern Leerfahrten - dies spart Kosten und trägt zum Klimaschutz bei."
              icon={Leaf}
            />
          </TransparentCard>
        </CardCon>
      </DoubleSide>
    </Background>
  );
};
