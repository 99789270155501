import { Cluster, ClusterStats } from '@googlemaps/markerclusterer';
import React from 'react';

import { Cluster as MarkerCluster, ClusterProps } from './Cluster';

export type SvgClusterMarker = {
  svg: string;
  label?: google.maps.MarkerLabel;
  title?: string;
  width?: number;
  height?: number;
};

export type SvgClusterProps = Omit<ClusterProps, 'renderer'> & {
  render: (count: number, stats: ClusterStats) => SvgClusterMarker;
};
export function SvgCluster({ render, ...props }: SvgClusterProps) {
  const renderer = {
    render({ position, count }: Cluster, stats: ClusterStats) {
      const { svg, label, title, width, height } = render(count, stats);
      return new google.maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${window.btoa(svg)}`,
          scaledSize: new google.maps.Size(width ?? 45, height ?? 45),
        },
        label,
        title,
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };
  return <MarkerCluster {...props} renderer={renderer} />;
}
