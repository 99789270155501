import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { formatDay, matchResponseToMatchDetails } from '../../lib/convert';
import { MatchDetails } from '../../lib/types/ui';
import { useApiResult } from '../../lib/useApiCall';
import { Chip } from '../common/Chip';
import { CalendarIcon } from '../common/Icons';
import { Body4 } from '../common/typography/Body';
import { Row } from '../layout/Row';
import { Requirements } from '../offer/Requirements';
import { SizeIcon } from '../offer/SizeIcon';
import { MatchChatWaypoints } from './MatchChatWaypoints';
import { MatchDetailModal } from './MatchDetailModal';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5em;
  margin-bottom: 2em;
`;

const Col = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 0.25em;
`;

type Props = {
  match_id: number;
};

export const MatchSubHeader = ({ match_id }: Props) => {
  const { t } = useTranslation();
  const match = useApiResult('getMatch', match_id);

  const matchDetails: MatchDetails | undefined = useMemo(() => {
    if (match) {
      try {
        return matchResponseToMatchDetails(match);
      } catch (err) {
        console.log(err);
      }
    }
  }, [match]);

  if (!match) {
    return <Body4>Matchdetails werden geladen.</Body4>;
  }
  if (matchDetails) {
    return (
      <Container>
        <Col>
          <Body4>{matchDetails.name}</Body4>
          <Row gap="0.5em">
            <Chip>
              <CalendarIcon /> {' ' + formatDay(matchDetails.date_start)}
            </Chip>
            <Chip>
              {matchDetails.capacity_size && (
                <SizeIcon size={matchDetails.capacity_size} />
              )}
              {matchDetails.capacity_size &&
                ' ' + t(matchDetails.capacity_size)}
            </Chip>
          </Row>
        </Col>
        <Col>
          <MatchChatWaypoints details={matchDetails} />
          <Row gap="0.5em">
            <Chip>
              {matchDetails.transport_type && t(matchDetails.transport_type)}
            </Chip>
            <Chip>
              <SizeIcon size={matchDetails.size} />
              {' ' + t(matchDetails.size)}
            </Chip>
            {matchDetails.requirements.length > 0 && (
              <Chip>
                <Requirements values={matchDetails.requirements} />
              </Chip>
            )}
          </Row>
        </Col>
        <MatchDetailModal details={matchDetails} />
      </Container>
    );
  } else {
    return null;
  }
};
