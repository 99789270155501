import styled from 'styled-components';

type Props = {
  gap?: string;
  sideBarWidth?: string;
  equalHeight?: boolean;
  contentMinWidth?: string;
};

/**
 * See https://every-layout.dev/layouts/sidebar/
 */
export const SideBySide = styled.div<Props>`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.gap ?? '0'};
  align-items: ${(p) => (p.equalHeight ? 'stretch' : 'flex-start')};
  & > :first-child {
    flex-basis: ${(p) => p.sideBarWidth ?? 'auto'};
    flex-grow: 1;
  }
  & > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: ${(p) => p.contentMinWidth ?? '50%'};
  }
`;
