import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

import { useIsActiveRoute } from '../../../lib/useIsActiveRoute';
import { WizardCard, WizardItem } from '../../common/Wizard';
import { useUser } from '../../UserState';

type Props = {
  path: string;
  children: ReactNode;
};
function WizardStep({ path, children }: Props) {
  const active = useIsActiveRoute(path);
  const navigate = useNavigate();

  return (
    <WizardItem onClick={() => navigate(path)} active={active}>
      {children}
    </WizardItem>
  );
}

export function WizardSteps() {
  const {
    isDispatcher,
    isAdmin,
    data: { selected_company_id },
  } = useUser();
  const bla = useUser();
  console.log(bla.data.selected_company_id);

  return (
    <WizardCard>
      {/* <WizardCardHeader>Einstellungen</WizardCardHeader> */}
      <WizardStep path="personal">Persönliche Daten</WizardStep>
      <WizardStep path="password">Passwort</WizardStep>
      {isAdmin && <WizardStep path="billing">Abrechnung</WizardStep>}
      {isDispatcher && <WizardStep path="matching">ContainerMatch</WizardStep>}
      {selected_company_id && (
        <WizardStep path="leaveCompany">Firma verlassen</WizardStep>
      )}

      <WizardStep path="app">Über die App</WizardStep>
    </WizardCard>
  );
}
