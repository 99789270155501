import React from 'react';

import { useApiCall } from '../../lib/useApiCall';
import { LoadingCircle } from '../common/LoadingCircle';
import { Body4 } from '../common/typography/Body';
import { Row } from '../layout/Row';
import companyPresets from './companyPresets.svg';
import { CapacityPresetListItem, CargoPresetListItem } from './PresetListItem';
import { TabContent } from './TabContent';

export function CompanyPresets() {
  const { data, invalidate } = useApiCall('getCompanyPresets');
  if (!data) return <LoadingCircle />;
  if (!data.capacity_presets.length && !data.cargo_presets.length) {
    return (
      <TabContent>
        <Row justify="center">
          <img src={companyPresets} width="250" height="250" />
        </Row>
        <Body4>
          Wenn andere aus deiner Firma Vorlagen erstellen, findest du diese hier
          und kannst sie zur Erstellung von Angeboten nutzen.
        </Body4>
      </TabContent>
    );
  }
  return (
    <div>
      {data.capacity_presets.map((p, i) => (
        <CapacityPresetListItem
          key={i}
          preset={p}
          invalidate={invalidate}
          readonly
        />
      ))}
      {data.cargo_presets.map((p, i) => (
        <CargoPresetListItem
          key={i}
          preset={p}
          invalidate={invalidate}
          readonly
        />
      ))}
    </div>
  );
}
