import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Btn } from '../../common/Btn';
import { Card, CardHeader } from '../../common/Card';
import { SpreadButtonRow } from '../../layout/SpreadButtonRow';
import { useApi, useUpdateUser, useUserData } from '../../UserState';

const StyledList = styled.div`
  padding: 24px;
  & p {
    white-space: break-spaces;
    max-width: 90%;
    margin: 16px;
  }
  & li {
    margin-left: 16px;
  }
`;

export function LeaveCompany() {
  const api = useApi();
  const navigate = useNavigate();
  const user = useUserData();
  const setUser = useUpdateUser();

  if (!user.selected_company_id) return null;

  const handleOnClick = async () => {
    if (!user.selected_company_id) return;
    const res = await api.leaveCompany(user.selected_company_id);
    console.log(res);
    if (res.status === 204) {
      setUser({
        ...user,
        selected_company_id: null,
        selected_company_name: null,
      });
      navigate('/dashboard');
    }
  };

  return (
    <>
      <Card>
        <CardHeader>Firma verlassen</CardHeader>

        <div>
          Hier kannst Du die aktuelle Firma verlassen. Dieses geht nur, wenn Du
          nicht der letzte Admin der Firma mit weiteren Mitarbeitern bist. Sonst
          könnte diese niemand mehr verwalten.
          <br />
          Solltest Du die Firma trotzdem verlassen wollen, mache über die
          Einstellungen der Firma einen anderen Mitarbeiter zum Admin.
        </div>
        <StyledList>
          <ul>
            <li>Deine Vorlagen für das Matching werden gelöscht.</li>
            <li>Deine erstellten Angebote werden gelöscht.</li>
            <li>Alle beidseitig akzeptierten Matches bleiben sichtbar.</li>
            <li>
              Wenn Du der letzte Mitarbeiter einer Firma bist, wird diese
              gelöscht!
            </li>
          </ul>
        </StyledList>
        <SpreadButtonRow>
          <Btn
            onClick={handleOnClick}
            color="error"
            type="button"
          >{`${user.selected_company_name} verlassen`}</Btn>
        </SpreadButtonRow>
      </Card>
    </>
  );
}
